import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";

import "../dashboard.scss";
import {
  meetindividualdelete,
  meetindividuallist,
  meetregistercheck,
} from "../../../services/meetservice";
import { Link, useNavigate } from "react-router-dom";
import { athleteview } from "../../../services/athlete";
import Swal from "sweetalert2";
import imageUrl from "../../../ImageUrl";
import { toast } from "react-toastify";
import fileString from "../../../assets/language/filepath.json";

function AthleteDashboard() {
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Logo",
      width: 80,
      resizable: true,
      field: "logo",
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={params.data.logo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Meet Name",
      width: 320,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 200,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("DD-MM-YYYY") +
          " - " +
          moment(params.data.end_date).format("DD-MM-YYYY")
        );
      },
    },
    {
      headerName: "Entry Open",
      width: 140,
      field: "entry_opens",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Entry Closed",
      width: 140,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "Circular",
      width: 100,
      field: "circular_sheet",
      filter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="action-btn text-center">
            <a download href={params.value} target={"_blank"}>
              <span className="col-reptwo">
                <i className="fa fa-file-pdf fa-lg" aria-hidden="true"></i>
              </span>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        if (params.data.paymentstatus === "") {
          return (
            <div>
              <a
                onClick={() => valiatemeet(params.value)}
                className="btn-sm btn-success me-3"
              >
                <i className="fa fa-user-plus"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div>
              <Link
                to={"/athlete/meetview/" + params.value}
                className="btn-sm btn-info me-3"
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          );
        }
      },
    },
  ];

  const navigate = useNavigate();
  function valiatemeet(mid) {
    const temp = new FormData();
    temp.append("tournament_meet_id", mid);
    meetregistercheck(temp).then((res) => {
      if (res.status === true) {
        if (res.data.status === "Success") {
          navigate("/athlete/meetadd/" + mid);
        } else {
          toast.error(res.data.message);
        }
      }
    });
  }
  var arr = [];
  useEffect(() => {
    athlist();
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

  function athlist() {
    athleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        meetindividuallist().then((res) => {
          if (res.status === true) {
            if (res.data.length !== 0) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].type === "State Entry") {
                  var pay = "";
                  if (
                    res.data[i].paymentstatus !== "" &&
                    res.data[i].paymentstatus !== null
                  ) {
                    pay = res.data[i].paymentstatus;
                  }
                  if(res.data[i].meet_type === 21){
                    arr.push({
                      meet_name: res.data[i].meet_name,
                      start_date: res.data[i].start_date,
                      end_date: res.data[i].end_date,
                      circular_sheet:
                        imageUrl +
                        fileString.tournamentfiles +
                        res.data[i].circular_file_1,
                      id: res.data[i].id,
                      paymentstatus: pay,
                      entry_opens: res.data[i].entry_opens,
                      entry_deadline_date: res.data[i].entry_deadline_date,
                      logo:
                        imageUrl +
                        fileString.tournamentfiles +
                        res.data[i].logo,
                    });
                  }else{
                    if (
                      response.data.details.state + "" ===
                      res.data[i].state
                    ) {
                      arr.push({
                        meet_name: res.data[i].meet_name,
                        start_date: res.data[i].start_date,
                        end_date: res.data[i].end_date,
                        circular_sheet:
                          imageUrl +
                          fileString.tournamentfiles +
                          res.data[i].circular_file_1,
                        id: res.data[i].id,
                        paymentstatus: pay,
                        entry_opens: res.data[i].entry_opens,
                        entry_deadline_date: res.data[i].entry_deadline_date,
                        logo:
                          imageUrl +
                          fileString.tournamentfiles +
                          res.data[i].logo,
                      });
                    }
                  }
                } else {
                  var pay = "";
                  if (
                    res.data[i].paymentstatus !== "" &&
                    res.data[i].paymentstatus !== null
                  ) {
                    pay = res.data[i].paymentstatus;
                  }
                  arr.push({
                    meet_name: res.data[i].meet_name,
                    start_date: res.data[i].start_date,
                    end_date: res.data[i].end_date,
                    circular_sheet:
                      imageUrl +
                      fileString.tournamentfiles +
                      res.data[i].circular_file_1,
                    id: res.data[i].id,
                    paymentstatus: pay,
                    entry_opens: res.data[i].entry_opens,
                    entry_deadline_date: res.data[i].entry_deadline_date,
                    logo:
                      imageUrl + fileString.tournamentfiles + res.data[i].logo,
                  });
                }
              }
            }
            setFilterProduct(arr);
          }
        });
        // if (response.data.details.improper_docs === 1) {
        //   Swal.fire({
        //     title:
        //       "You has been Reported for improper photo and documents.Kindly Upload your photo",
        //     allowOutsideClick: false,
        //   });
        // }
      }
    });
  }
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      <Row className=" row-sm  mt-lg-4">
        <Col sm={12} lg={12} xl={12}>
          <Card className="bg-black-2 custom-card card-box">
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col
                  sm={{ offset: 6 }}
                  xl={{ offset: 3 }}
                  className="col-xl-8 col-sm-6 col-12 img-bg "
                >
                  <h4 className="d-flex  mb-3">
                    <span className="font-weight-bold text-dark ">
                      {localStorage.getItem("User")}
                    </span>
                  </h4>
                  <p className="tx-black-5 mb-1">
                    Athletics Federation of India
                  </p>
                </Col>
                <img
                  src={require("../../../assets/images/athlete.png")}
                  alt="athlete"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!--Row--> */}
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className=" custom-card overflow-hidden">
            <Card.Header className=" border-bottom-0">
              <label className="main-content-label my-auto text-danger">
                Meet Lists
              </label>
            </Card.Header>
            <Card.Body>
              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    <Row className="row-sm">
                      <Col lg={12}>
                        <Card className="custom-card overflow-hidden">
                          <Card.Body>
                            <div className="responsive">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{
                                  height: 600,
                                  width: "100%",
                                }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  rowData={filterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                  suppressExcelExport={true}
                                  suppressDragLeaveHidesColumns={true}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

AthleteDashboard.propTypes = {};

AthleteDashboard.defaultProps = {};

export default AthleteDashboard;
