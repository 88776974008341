import axios from 'axios';
import { Link } from 'react-router-dom';
import baseUrl from '../Api';
import { toast } from "react-toastify";

export async function athleteview(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/athletedetails/${data}`, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function athleteappliednoc(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/user-already-applied/${data}`, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function imageupdate(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .post(`${baseUrl}/athletes/uploadpendingdocphoto`,data, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function profileupdate(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .post(`${baseUrl}/athletes/updateinfo`,data, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}


export async function changepassword(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .post(`${baseUrl}/athletes/changepassword`,data, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function nocapply(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .post(`${baseUrl}/athletes/nocapply`,data, { headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}


export async function idcardpreview(id,type) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/idcardpdf/${id}/${type}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}


export async function athleteresultlist() {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/results-list`,{ headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function athletecertificatelist() {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/certificate-list`,{ headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

export async function athletecertificatedownload(id,uid,cert,type) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/certificate-download?tournament_id=${id}&user_id=${uid}&certificate_name=${cert}&certificate_type=${type}`,{ headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}



export async function reapplyidcard() {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .get(`${baseUrl}/athletes/reapply-btn`,{ headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}


export async function athleteidcardsubmit(data) {
    try {
        const header = {
            Authorization: 'Bearer ' + localStorage.getItem('Token'),
            'Content-Type': 'application/json',
        };
        return await axios
            .post(`${baseUrl}/athletes/save-reapply-idcard`,data,{ headers: header })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                catcherror(error);
            });
    } catch (error) {
        catcherror(error);
    }
}

function catcherror(error) {
    // console.log(error.response);
    if (error.response.status === 401 || error.response.status === 422) {
        // window.location.replace("/#/login");
        localStorage.clear();
        window.location.reload();
        <Link to={"/login"}></Link>;
      } else if (error.response.status === 0) {
        toast.error(error.message);
      } else if (error.response.status === 500) {
        toast.error(error.response.statusText);
      } else if (
        error.response.status !== 200 &&
        error.response.data.status === false &&
        error.response.data.code !== 200
      ) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error.request.statusText);
      } else {
        toast.error(error.message);
      }
}
