import React, { Fragment, useEffect, useState } from "react";
import {
  Dropdown,
  Container,
  Form,
  Nav,
  Navbar,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../services/loginservice";
import { athleteview } from "../../services/athlete";
import imageUrl from "../../ImageUrl";
import fileString from "../../assets/language/filepath.json";

// FuScreen-start
// function Fullscreen() {
//     if (
//         (document.fullScreenElement && document.fullScreenElement === null) ||
//     (!document.mozFullScreen && !document.webkitIsFullScreen)
//     ) {
//         if (document.documentElement.requestFullScreen) {
//             document.documentElement.requestFullScreen();
//         } else if (document.documentElement.mozRequestFullScreen) {
//             document.documentElement.mozRequestFullScreen();
//         } else if (document.documentElement.webkitRequestFullScreen) {
//             document.documentElement.webkitRequestFullScreen(
//                 Element.ALLOW_KEYBOARD_INPUT
//             );
//         }
//     } else {
//         if (document.cancelFullScreen) {
//             document.cancelFullScreen();
//         } else if (document.mozCancelFullScreen) {
//             document.mozCancelFullScreen();
//         } else if (document.webkitCancelFullScreen) {
//             document.webkitCancelFullScreen();
//         }
//     }
// }

// FullScreen-end
function Header() {
  const navigate = useNavigate();
  const [formphoto, setPhotoFile] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Roleid") === "2") {
      athleteview(localStorage.getItem("Userid")).then((response) => {
        if (response.status === true) {
          setPhotoFile(
            imageUrl +
              fileString.athletephoto +
              response.data.details.photograph
          );
        }
      });
    }
  }, []);
  function signout() {
    logout().then((response) => {
      if (response.status === true) {
        localStorage.clear();
        navigate("/login");
      }
    });
  }

  function changepass() {
    if (localStorage.getItem("Roleid") === "2") {
      navigate("/athlete/changepassword");
    } else if (localStorage.getItem("Roleid") === "3") {
      navigate("/official/changepassword");
    } else if (localStorage.getItem("Roleid") === "4") {
      navigate("/state/changepassword");
    } else if (localStorage.getItem("Roleid") === "5") {
      navigate("/district/changepassword");
    } else if (localStorage.getItem("Roleid") === "6") {
      navigate("/club/changepassword");
    }
  }

  function changeprofile() {
    if (localStorage.getItem("Roleid") === "2") {
      navigate("/athlete/myprofile");
    } else if (localStorage.getItem("Roleid") === "3") {
      navigate("/official/myprofile");
    } else if (localStorage.getItem("Roleid") === "4") {
      navigate("/state/myprofile");
    } else if (localStorage.getItem("Roleid") === "5") {
      navigate("/district/myprofile");
    } else if (localStorage.getItem("Roleid") === "6") {
      navigate("/club/myprofile");
    }
  }

  function movelogo() {
    if (localStorage.getItem("Roleid") === "2") {
      navigate("/athlete/dashboard");
    } else if (localStorage.getItem("Roleid") === "3") {
      navigate("/official/dashboard");
    } else if (localStorage.getItem("Roleid") === "4") {
      navigate("/state/dashboard");
    } else if (localStorage.getItem("Roleid") === "5") {
      navigate("/district/dashboard");
    } else if (localStorage.getItem("Roleid") === "6") {
      navigate("/club/dashboard");
    }
  }
  // const openCloseSidebar1 = () => {
  //     document.querySelector('.header-settings').classList.toggle('show');
  //     document.querySelector('.sidebar-right').classList.toggle('sidebar-open');
  // };
  //  headerToggleButton
  const headerToggleButton = () => {
    let body = document.querySelector("body");
    let innerWidth = window.innerWidth;
    if (body !== !body) {
      if (innerWidth >= 992) {
        document.querySelector("body")?.classList.toggle("main-sidebar-hide");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
      } else if (document.body.classList.contains("horizontalmenu")) {
        document.querySelector("body")?.classList.toggle("main-navbar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      } else {
        document.querySelector("body")?.classList.toggle("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      }
    }
  };

  function errorhandle(ev) {
    ev.target.src = require("../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function Swicherbutton() {
    document.querySelector(".demo_changer").classList.toggle("active");
    document.querySelector(".demo_changer").style.right = "0px";
  }
  const Darkmode = () => {
    document.querySelector("body").classList.toggle("dark-theme");
  };
  return (
    <Fragment>
      <Navbar
        expand="lg"
        className="main-header side-header sticky"
        // style={{ marginBottom: "-64px" }}
      >
        <Container fluid className="main-container container-fluid">
          <div className="main-header-left">
            <Link
              to="#"
              className="main-header-menu-icon"
              id="mainSidebarToggle"
              onClick={() => headerToggleButton()}
            >
              <span></span>
            </Link>
            <div className="hor-logo">
              <a onClick={movelogo} className="main-logo">
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  className="header-brand-img desktop-logo"
                  alt="logo"
                />
              </a>
            </div>
          </div>
          {localStorage.getItem("Roleid") === "5" &&
          <div className="main-header-center">
            {" "}
            <h4 className="tx-28 text-dark text-center">
              Welcome {localStorage.getItem("User")}
            </h4>
          </div>
          }
          
          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>

            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  {/* <Dropdown className="header-search">
                    <Dropdown.Toggle variant="default" className="px-0">
                      <i className="fe fe-search header-icons fs-18 px-2 lh-5"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="p-2">
                      <InputGroup>
                        <Form.Control
                          type="search"
                          className="rounded-0"
                          placeholder="Search for anything..."
                        />
                        <InputGroup.Text className="btn search-btn">
                          <i className="fe fe-search"></i>
                        </InputGroup.Text>
                      </InputGroup>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Dropdown className="main-profile-menu">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <span className="main-img-user mx-1">
                        {formphoto !== "" && (
                          <img
                            alt="avatar"
                            src={formphoto}
                            onError={(e) => errorhandle(e)}
                          />
                        )}

                        {formphoto === "" && (
                          <img
                            alt="avatar"
                            src={require("../../assets/images/logo/logo-small.png")}
                          />
                        )}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ margin: "0px" }}>
                      <div className="header-navheading">
                        <h6 className="main-notification-title">
                          {localStorage.getItem("User")}
                        </h6>
                        <a className="main-notification-text">
                          Athletics Federation of India
                        </a>
                      </div>

                      {localStorage.getItem("Roleid") === "2" && (
                        <Dropdown.Item
                          onClick={changeprofile}
                          className="border-top"
                        >
                          <i className="fe fe-user"></i> My Profile
                        </Dropdown.Item>
                      )}
                      {localStorage.getItem("Roleid") === "2" && (
                        <Dropdown.Item onClick={changepass}>
                          <i className="fe fe-settings"></i> Account Settings
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={signout}>
                        <i className="fe fe-power"></i> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="dropdown d-flex main-header-theme">
                    <Nav.Link
                      className="nav-link icon layout-setting"
                      onClick={() => Darkmode()}
                    >
                      <span className="dark-layout">
                        <i className="fe fe-sun header-icons"></i>
                      </span>
                      <span className="light-layout">
                        <i className="fe fe-moon header-icons"></i>
                      </span>
                    </Nav.Link>
                  </Dropdown>
                </div>
              </Navbar.Collapse>

              {/* <div className="d-flex header-setting-icon demo-icon fa-spin">
                <Nav.Link className="nav-link icon" onClick={Swicherbutton}>
                  <i className="fe fe-settings settings-icon "></i>
                </Nav.Link>
              </div> */}
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
