import React, { useState, useRef, useEffect } from "react";
import { login } from "./action";
import { useDispatch } from "react-redux";
import "./login.css";
import { logindata, updatepassword } from "../../services/loginservice";
import { useNavigate } from "react-router-dom";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";

function Login() {
  // const passInputRef = useRef();
  const [user, setUser] = useState("");
  const [semail, setEmail] = useState("");

  const [pass, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [logbtn, setButton] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [lotp, setLOTP] = useState("");

  const [meter, setMeter] = useState(false);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confirmpass, setConfirm] = useState("");

  const [showold, setShowOld] = useState("far fa-eye eye-show");
  const [hideshowold, setHideOld] = useState("password");

  const [shownew, setShowNew] = useState("far fa-eye eye-show");
  const [hideshownew, setHideNew] = useState("password");

  const [showcon, setShowCon] = useState("far fa-eye eye-show");
  const [hideshowcon, setHideCon] = useState("password");
  function handleotp(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setLOTP(e.target.value);
    }
  }
  function oldeyeslash(pid) {
    if (pid === "far fa-eye eye-show") {
      setHideOld("text");
      setShowOld("far fa-eye-slash eye-hide");
    } else {
      setHideOld("password");
      setShowOld("far fa-eye eye-show");
    }
  }

  function neweyeslash(pid) {
    if (pid === "far fa-eye eye-show") {
      setHideNew("text");
      setShowNew("far fa-eye-slash eye-hide");
    } else {
      setHideNew("password");
      setShowNew("far fa-eye eye-show");
    }
  }

  function coneyeslash(pid) {
    if (pid === "far fa-eye eye-show") {
      setHideCon("text");
      setShowCon("far fa-eye-slash eye-hide");
    } else {
      setHideCon("password");
      setShowCon("far fa-eye eye-show");
    }
  }

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: newpass.match(atLeastOneUppercase),
    lowercase: newpass.match(atLeastOneLowercase),
    number: newpass.match(atLeastOneNumeric),
    specialChar: newpass.match(atLeastOneSpecialChar),
    eightCharsOrGreater: newpass.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chgeotp, setChangeOTP] = useState(false);

  function setAShow() {
    setOtpMsg("");
    setLOTP("");
    setChangeOTP(true);
  }
  function setAClose() {
    setOtpMsg("");
    setLOTP("");
    setChangeOTP(false);
  }
  const [otpmsg, setOtpMsg] = useState("");

  function submitotp() {
    setOtpMsg("");
    if (lotp !== "") {
      if (lotp === localStorage.getItem("OTP")) {
        setChangeOTP(false);
        setPassShow(true);
      } else {
        setOtpMsg("Please Enter Valid OTP");
      }
    } else {
      setOtpMsg("Please Enter the OTP");
    }
  }
  const [chgepwd, setChange] = useState(false);

  function setPassShow() {
    setErrMsg("");
    setOldpass("");
    setNewpass("");
    setConfirm("");
    setChange(true);
  }
  function setPassClose() {
    setErrMsg("");
    setOldpass("");
    setNewpass("");
    setConfirm("");
    setChange(false);
  }
  const [errmsg, setErrMsg] = useState("");
  function submitpass() {
    setErrMsg("");
    if (oldpass !== "") {
      if (newpass !== "") {
        if (
          passwordTracker.uppercase &&
          passwordTracker.lowercase &&
          passwordTracker.specialChar &&
          passwordTracker.number &&
          passwordTracker.eightCharsOrGreater
        ) {
          if (confirmpass !== "") {
            const tempData = {
              user_id: localStorage.getItem("Userid"),
              currentpassword: oldpass,
              newpassword: newpass,
              confirmpassword: confirmpass,
            };

            updatepassword(tempData).then((response) => {
              if (response.status === true) {
                localStorage.clear();
                setPassClose(false);
                toast.success("Password has been changed successfully");
              } else {
                setErrMsg(response.message);
              }
            });
          } else {
            setErrMsg("Please Enter Confirm Password");
          }
        } else {
          setErrMsg("New Password must be strong");
        }
      } else {
        setErrMsg("Please Enter New Password");
      }
    } else {
      setErrMsg("Please Enter Current Password");
    }
  }
  useEffect(() => {
    captcha();
    if (localStorage.getItem("Roleid") === "1") {
      navigate("/superadmin/dashboard");
    } else if (localStorage.getItem("Roleid") === "2") {
      navigate("/athlete/dashboard");
    } else if (localStorage.getItem("Roleid") === "3") {
      navigate("/official/dashboard");
    } else if (localStorage.getItem("Roleid") === "4") {
      navigate("/state/dashboard");
    } else if (localStorage.getItem("Roleid") === "5") {
      navigate("/district/dashboard");
    } else if (localStorage.getItem("Roleid") === "6") {
      navigate("/club/dashboard");
    }
  }, []);

  const [capvalue, setCapvalue] = useState("");
  const [ecapvalue, setECapvalue] = useState("");

  function captcha() {
    setCapvalue("");
    var a = Math.ceil(Math.random() * 10) + "";
    var b = Math.ceil(Math.random() * 10) + "";
    var c = Math.ceil(Math.random() * 10) + "";
    var d = Math.ceil(Math.random() * 10) + "";
    var e = Math.ceil(Math.random() * 10) + "";
    var f = Math.ceil(Math.random() * 10) + "";
    var g = Math.ceil(Math.random() * 10) + "";
    setCapvalue(a + "" + b + "" + c + "" + d + "" + e + "" + f + "" + g);
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setECapvalue(e.target.value);
    }
  };

  function Submitlogin(e) {
    e.preventDefault();
    setErrorMessage("");
    if (logbtn === false) {
      setButton(true);
    }

    setSubmitted(true);
    localStorage.clear();
    const tempData = {
      username: user,
      password: pass,
    };
    if (tempData.username !== "" && tempData.password !== "") {
      if (tempData.password.length < 6) {
        setErrorMessage("Password must be minimum 6 characters");
      } else {
        if (ecapvalue !== "") {
          if (capvalue === ecapvalue) {
            logindata(tempData).then((response) => {
              if (response.status === true) {
                setButton(false);
                if (response.data.roleid === 2) {
                  if (response.data.otp_sent === 0) {
                    setEmail(response.data.success_email);
                    localStorage.setItem("OTP", response.data.otp);
                    localStorage.setItem("Userid", response.data.userid);
                    localStorage.setItem("User", response.data.username);
                    localStorage.setItem(
                      "EnUser",
                      response.data.encrypted_username
                    );

                    localStorage.setItem("password", response.data.refnonhash);

                    setAShow(true);
                  } else {
                    localStorage.setItem(
                      "EnUser",
                      response.data.encrypted_username
                    );
                    localStorage.setItem("Userid", response.data.userid);
                    localStorage.setItem("User", response.data.username);
                    localStorage.setItem("Roleid", response.data.roleid);
                    localStorage.setItem(
                      "SubRoleid",
                      response.data.adminsubrole
                    );
                    localStorage.setItem("Token", response.data.token);
                    localStorage.setItem("State", response.data.state);
                    localStorage.setItem("password", response.data.refnonhash);

                    if (localStorage.getItem("Roleid") === "2") {
                      navigate("/athlete/dashboard");
                    }
                  }
                } else {
                  localStorage.setItem("Userid", response.data.userid);
                  localStorage.setItem("User", response.data.username);
                  localStorage.setItem("Roleid", response.data.roleid);
                  localStorage.setItem("SubRoleid", response.data.adminsubrole);
                  localStorage.setItem("Token", response.data.token);
                  localStorage.setItem("State", response.data.state);
                  localStorage.setItem("password", response.data.refnonhash);
                  if (localStorage.getItem("Roleid") === "1") {
                    navigate("/superadmin/dashboard");
                  } else if (localStorage.getItem("Roleid") === "2") {
                    navigate("/athlete/dashboard");
                  } else if (localStorage.getItem("Roleid") === "3") {
                    navigate("/official/dashboard");
                  } else if (localStorage.getItem("Roleid") === "4") {
                    navigate("/state/dashboard");
                  } else if (localStorage.getItem("Roleid") === "5") {
                    navigate("/district/dashboard");
                  } else if (localStorage.getItem("Roleid") === "6") {
                    navigate("/club/dashboard");
                  }
                }
              } else {
                setButton(false);
                setErrorMessage(response.message);
              }
            });
          } else {
            setButton(false);
            setErrorMessage("Captcha didn't match");
          }
        }else{
          setButton(false);
        }
      }
    }
  }

  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_logo">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
            />
          </div>
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <h1>Athletics Federation of India</h1>
                <h5>
                  The Athletics Federation of India is the apex body for running
                  and managing athletics in India.
                </h5>
              </div>
              <div className="loginpage_sectionone_bottom mt-3 desktopscreen">
                <h4>Register as</h4>

                <div className="loginpage_bottom_button">
                  <a
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    <div className="regisbtn text-center">
                      <i className="fa-solid fa-user"></i>
                      <h5 className="mb-0">Athlete Registration</h5>
                      <span>Click Here!</span>
                    </div>
                  </a>

                 
                </div>
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h6 className="text-center">
                    Welcome to Athletics Federation of India!
                  </h6>
                  <h2 className="text-center">Member Login</h2>
                  <fieldset className="username">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Enter your Username"
                      value={user}
                      id="name"
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                    />
                    {submitted && !user && (
                      <span className="text-danger">Username is required</span>
                    )}
                  </fieldset>

                  <fieldset className="password">
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      value={pass}
                      onChange={(e) => setPassword(e.target.value)}
                      // ref={passInputRef}
                      minLength={6}
                      id="pass"
                      autoComplete="off"
                    />
                    {submitted && !pass && (
                      <span className="text-danger">Password is required</span>
                    )}
                  </fieldset>
                  <div className="form-group d-flex">
                    <div className="w-40">
                      <input type="text" value={capvalue} readOnly />
                    </div>
                    <div className="w-20 text-center">
                      <a
                        onClick={captcha}
                        className="fa fa-fw fa-refresh refresh"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                      ></a>
                    </div>
                    <div className="w-40">
                      <input
                        type="text"
                        placeholder="Enter Here"
                        maxLength={12}
                        autoComplete="off"
                        value={ecapvalue}
                        onChange={handleChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      {submitted && !ecapvalue && (
                        <span className="text-danger">Captcha is required</span>
                      )}
                    </div>
                  </div>
                  {errorMessage && (
                    <span className="text-danger"> {errorMessage} </span>
                  )}
                  {logbtn === true && (
                    <button
                      type="submit"
                      className="btn"
                      onClick={Submitlogin}
                      disabled={true}
                    >
                      Login
                    </button>
                  )}
                  {logbtn === false && (
                    <button type="submit" className="btn" onClick={Submitlogin}>
                      Login
                    </button>
                  )}
                  <div className="row mx-0">
                    <div className="col-md-6">
                      <div className="forgotusername">
                        <a
                          className=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/forgotusername");
                          }}
                        >
                          Forgot Username
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forgotpassword">
                        <a
                          className=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/forgotpassword");
                          }}
                        >
                          Forgot Password
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="loginpage_sectionone_bottom mt-3 mobilescreen">
                <h4>Register as</h4>
                <div className="loginpage_bottom_button">
                  <a
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    <div className="regisbtn text-center">
                      <i className="fa-solid fa-user"></i>
                      <h5 className="mb-0">Athlete Registration</h5>
                      <span>Click Here!</span>
                    </div>
                  </a>

                  {/* <a>
                    <div className="regisbtn text-center">
                      <i className="fa-solid fa-user"></i>
                      <h5 className="mb-0">Official Registration</h5>
                      <span>Click Here!</span>
                    </div>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="loginpage_footer">
            <div className="loginpage_howtoregis">
              <h5>
                How to Register for the event?
                <a
                  className="ms-2 text-danger"
                  href={require("../../assets/images/Registration.pdf")}
                  target="_blank"
                >
                  <i
                    className="fa-solid fa-file-pdf"
                    style={{ fontSize: "2.6rem" }}
                    // style={'font-size=2.6rem'}
                  ></i>
                </a>
              </h5>
            </div>
            <div className="copyrights">
              <span>© 2023 Athletics Federation of India</span>
            </div>
          </div>
        </div>

        <Modal show={chgeotp} size="large">
          <Modal.Header>
            <h4 className="rep-head">OTP</h4>
            <i
              className="fa fa-window-close fa-lg"
              onClick={setAClose}
              aria-hidden="true"
            ></i>
          </Modal.Header>
          <Modal.Body className="mod-body">
            <h5>
              OTP has been sent to{" "}
              <span className="text-success">{semail}</span>
            </h5>
            <div className="rep__container mb-2">
              <label>OTP</label>
              <div className="col-lg">
                <div className="col-lg">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={lotp}
                    autoComplete="off"
                    onChange={handleotp}
                    maxLength={6}
                  />
                </div>
              </div>
            </div>
            {otpmsg !== "" && (
              <span className="errortxt text-danger">{otpmsg}</span>
            )}
            <hr />
            <div className="rep__container mb-3">
              <h6>Issues in receiving OTP?</h6>
              <img
                src={require("../../assets/images/support.png")}
                height="50"
                alt="Support"
                onClick={() =>
                  window.open(
                    "http://afisupport.sportingindia.club/customer/autologin/" +
                      localStorage.getItem("User") +
                      "/" +
                      localStorage.getItem("password"),
                    "_blank"
                  )
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-f btn-success" onClick={submitotp}>
              Submit
            </button>
            <button onClick={setAClose} className="btn-f btn-danger">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={chgepwd} size="large">
          <Modal.Header>
            <h4 className="rep-head">Change Password</h4>
            <i
              className="fa fa-window-close fa-lg"
              onClick={setPassClose}
              aria-hidden="true"
            ></i>
          </Modal.Header>
          <Modal.Body className="mod-body">
            <div className="row">
              <div className="col-md-5">
                <b className="text-inverse">
                  Current Password <span className="text-danger">*</span>
                </b>
              </div>
              <div className="col-md-7 mb-3 inner-addon right-addon">
                <input
                  type={hideshowold}
                  placeholder="Enter Your Current Password"
                  autoComplete="off"
                  value={oldpass}
                  onChange={(e) => {
                    setOldpass(e.target.value);
                  }}
                />
                <i
                  className={showold}
                  onClick={() => oldeyeslash(showold)}
                  style={{ marginLeft: "-55px" }}
                ></i>
              </div>
            </div>

            <div className="row">
              <div className="col-md-5">
                <b className="text-inverse">
                  New Password <span className="text-danger">*</span>
                </b>
              </div>
              <div className="col-md-7 mb-3 inner-addon right-addon">
                <input
                  type={hideshownew}
                  placeholder="Enter Your New Password"
                  autoComplete="off"
                  value={newpass}
                  onChange={(e) => {
                    setNewpass(e.target.value);
                  }}
                  maxLength={10}
                  onFocus={() => setMeter(true)}
                />
                <i
                  className={shownew}
                  onClick={() => neweyeslash(shownew)}
                  style={{ marginLeft: "-55px" }}
                ></i>
              </div>
            </div>
            {meter && (
              <div>
                <div className="password-strength-meter"></div>
                <div>
                  {passwordStrength < 5 && "Must contain "}
                  {!passwordTracker.uppercase && "uppercase, "}
                  {!passwordTracker.lowercase && "lowercase, "}
                  {!passwordTracker.specialChar && "special character, "}
                  {!passwordTracker.number && "number, "}
                  {!passwordTracker.eightCharsOrGreater &&
                    "eight characters or more"}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-5">
                <b className="text-inverse">
                  Confirm Password <span className="text-danger">*</span>
                </b>
              </div>
              <div className="col-md-7 mb-3 inner-addon right-addon">
                <input
                  type={hideshowcon}
                  placeholder="Enter Your Confirm Password"
                  autoComplete="off"
                  value={confirmpass}
                  onChange={(e) => {
                    setConfirm(e.target.value);
                  }}
                  maxLength={10}
                />
                <i
                  className={showcon}
                  onClick={() => coneyeslash(showcon)}
                  style={{ marginLeft: "-55px" }}
                ></i>
              </div>
            </div>
            <style jsx>
              {`
                .password-strength-meter {
                  height: 0.3rem;
                  background-color: lightgrey;
                  border-radius: 3px;
                  margin: 0.5rem 0;
                }

                .password-strength-meter::before {
                  content: "";
                  background-color: ${["red", "red", "red", "red", "#0ce052"][
                    passwordStrength - 1
                  ] || ""};
                  height: 100%;
                  width: ${(passwordStrength / 5) * 100}%;
                  display: block;
                  border-radius: 3px;
                  transition: width 0.2s;
                }
              `}
            </style>
            {errmsg !== "" && (
              <span className="errortxt text-danger">{errmsg}</span>
            )}
            <br />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-f btn-success" onClick={submitpass}>
              Change Password
            </button>
            <button onClick={setPassClose} className="btn-f btn-danger">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
}

export default Login;
