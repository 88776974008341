import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  athleteappliednoc,
  athleteview,
  nocapply,
} from "../../../../services/athlete";
import { statelist } from "../../../../services/registerservice";
import PayUrl from "../../../../Apiccavenue";
import dataString from "../../../../assets/language/ccavenue.json";

const Applynoc = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [formpresentnoc, setPresentNoc] = useState("");
  const [formstatenoc, setStateNoc] = useState("");
  const [formstateaddr, setStateAddr] = useState("");
  const [nocnotes, setNocnotes] = useState("");
  const navigate = useNavigate();

  function presentnoc(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPresentNoc(event.target.files[0]);
      } else {
        setErrorMessage(
          "Present State NOC should be jpg or png or jpeg format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Present State NOC size should be less than 3MB");
      }
    }
  }

  function statenoc(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setStateNoc(event.target.files[0]);
      } else {
        setErrorMessage(
          "Transfer State NOC should be jpg or png or jpeg format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Transfer State NOC size should be less than 3MB");
      }
    }
  }

  function stateaddr(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setStateAddr(event.target.files[0]);
      } else {
        setErrorMessage(
          "Transfer State Address should be jpg or png or jpeg format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Transfer State Address size should be less than 3MB");
      }
    }
  }

  function submitnoc() {
    const tempData = new FormData();
    tempData.append("user_id", localStorage.getItem("Userid"));
    tempData.append("from_state", pstate);
    tempData.append("to_state", tstate);
    tempData.append("noc_notes", nocnotes);
    tempData.append("present_state_noc_file", formpresentnoc);
    tempData.append("transfer_state_noc_file", formstatenoc);
    tempData.append("transfer_proof_file", formstateaddr);

    if (tstate !== "") {
      if (formpresentnoc !== "") {
        if (errorMessage === "") {
          setErrorMessage("");
          if (formstatenoc !== "") {
            if (errorMessage === "") {
              setErrorMessage("");
              if (nocnotes !== "") {
                if (formstateaddr !== "") {
                  if (errorMessage === "") {
                    setErrorMessage("");
                    nocapply(tempData).then((response) => {
                      if (response.status === true) {
                        window.open(
                          PayUrl +
                            dataString.nocathlete +
                            response.data.noc_data.unique_no,
                          "_self"
                        );
                      } else {
                        setErrorMessage(response.message);
                      }
                    });
                  }
                } else {
                  setErrorMessage("Please Select Your Transfer State Address");
                }
              } else {
                setErrorMessage("Please Enter Your Noc Notes");
              }
            }
          } else {
            setErrorMessage("Please Select Your Transfer State NOC");
          }
        }
      } else {
        setErrorMessage("Please Select Your Present State NOC");
      }
    } else {
      setErrorMessage("Please Select Your To State");
    }
  }

  const [pstate, setState] = useState("");
  function Selectpdist(e) {
    setErrorMessage("")
    setTState("")
    if(pstate !== parseInt(e.target.value)){
      setTState(e.target.value);
    }else{
      setErrorMessage("From State and To State must not be same")
    }
  }

  const [tstate, setTState] = useState("");
  const [pstatename, setStatename] = useState("");

  const [slist, setStatelist] = useState([]);

  const [appnoc, setAppliedNOC] = useState("");

  useEffect(() => {
    athleteappliednoc(localStorage.getItem("Userid")).then((response) => {
      if (response.status === false) {
        setAppliedNOC(response.message);
      }
    });
    athleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setState(response.data.details.permenant_state);
        setStatename(response.data.details.statename);
      }
    });
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Apply NOC</h2>
        </div>
      </div>

      {appnoc !== "" && appnoc !== "Your NOC Request has been Rejected by Superadmin" && appnoc !== "Your NOC Request has been Approved by Superadmin"&& (
        <Card className=" custom-card overflow-hidden">
          <Card.Body>
            <Container fluid className="p-3 text-center">
              <div className="text-center text-white background-text ">
                <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                  NOC Application
                </h1>
                <p className="tx-20 mb-3 text-success">{appnoc}</p>
              </div>
            </Container>
          </Card.Body>
          <Card.Footer>
            <div className="text-right">
              <a
                className="btn btn-secondary"
                onClick={() => navigate("/athlete/dashboard")}
              >
                Cancel
              </a>
            </div>
          </Card.Footer>
        </Card>
      )}

      {(appnoc === "" || appnoc === "Your NOC Request has been Rejected by Superadmin" || appnoc === "Your NOC Request has been Approved by Superadmin") && (
        <Card className=" custom-card overflow-hidden">
          <Card.Header>
            <h5>
              NOC Application Fees - INR 3000/- (Valid for 1 Year from the Date
              of Acceptance)
            </h5>
          </Card.Header>
          <Card.Body className="applynocpage">
            <div className="row mx-0">
              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse tx-13">From State</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{pstatename}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse tx-13">
                      To State
                      <span className="text-danger"> *</span>
                    </b>
                  </div>
                  <div className="col-md-8">
                    <select value={tstate} onChange={Selectpdist}>
                      <option value="" disabled>
                        Select Your State
                      </option>
                      {slist.map((data, i) => {
                        return (
                          <option value={data.stateuid} key={i}>
                            {data.statename}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0 ">
              <div className="col-md-6 mb-3">
                <div className="row ">
                  <div className="col-md-4 ">
                    <b className="text-inverse tx-13">
                      Present State NOC
                      <span className="text-danger"> *</span>
                    </b>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="file"
                      name="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={presentnoc}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse tx-13">
                      Transfer State NOC <span className="text-danger"> *</span>
                    </b>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="file"
                      name="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={statenoc}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse tx-13">
                      NOC Notes
                      <span className="text-danger"> *</span>
                    </b>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      rows={3}
                      value={nocnotes}
                      onChange={(e) => setNocnotes(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <b className="text-inverse tx-13">
                      Transfer State Address Proof{" "}
                      <span className="text-danger"> *</span>
                    </b>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="file"
                      name="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={stateaddr}
                    />
                  </div>
                </div>
              </div>
            </div>
            {errorMessage !== "" && (
              <span className="errortxt text-danger">{errorMessage}</span>
            )}
          </Card.Body>
          <Card.Footer>
            <div className="mobile-text-right">
              <a
                className="btn btn-success"
                style={{ marginRight: "10px" }}
                onClick={submitnoc}
              >
                Apply NOC
              </a>
              <a
                className="btn btn-secondary"
                onClick={() => navigate("/athlete/dashboard")}
              >
                Cancel
              </a>
            </div>
          </Card.Footer>
        </Card>
      )}
    </Fragment>
  );
};

export default Applynoc;
