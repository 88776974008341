import { Height } from "@material-ui/icons";
import { Padding } from "@mui/icons-material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { overageathletes } from "../../../services/superadmin";
import { toast } from "react-toastify";

const Overage = () => {
  const [meet, setMeet] = useState("");
  const [mloc, setMLoc] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [CFile, setCFile] = useState("");

  useEffect(() => {}, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [fileMessage, setFileMessage] = useState("");
  const inputRef = useRef(null);
  function certfile(event) {
    setCFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setCFile(event.target.files[0]);
      } else {
        inputRef.current.value = null;
        setFileMessage("Over Age File should be xls or xlsx format");
        setErrorMessage("Over Age File should be xls or xlsx format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setFileMessage("Over Age File size should be less than 5MB");
        setErrorMessage("Over Age File size should be less than 5MB");
      }
    }
  }

  function cleardata() {
    setMeet("");
    setMLoc("");
    setMSDate("");
    setMEDate("");
    setCFile("");
    inputRef.current.value = null;
  }

  const navigate = useNavigate();
  function overageimport() {
    setErrorMessage("");
    if (meet !== "") {
      if (mloc !== "") {
        if (msdate !== "") {
          if (medate !== "") {
            if (CFile !== "") {
              const temp = new FormData();
              temp.append("meet_name", meet);
              temp.append("meet_location", mloc);
              temp.append("meet_start_date", msdate);
              temp.append("uploaded_date", medate);
              temp.append("overage_excel", CFile);
              overageathletes(temp).then((response) => {
                if (response.status === true) {
                  navigate("/superadmin/overagehistory");
                  toast.success(response.data);
                }
              });
            } else {
              setErrorMessage("Please Select Over Age File");
            }
          } else {
            setErrorMessage("Please Select Upload Date");
          }
        } else {
          setErrorMessage("Please Select Meet Date");
        }
      } else {
        setErrorMessage("Please Enter Meet Location");
      }
    } else {
      setErrorMessage("Please Enter Meet Name");
    }
  }
  return (
    <Fragment>
      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Over Age Athletes</h2>
        </div>
      </div>
      <Card className=" custom-card profilepage">
        <Card.Body>
          <div className="panel profile-top">
            <div className="profile-cover__img"></div>
            <div className="text-right">
              <a className="btn btn-secondary me-2" href={require("../../../assets/images/Overage_List.xlsx")} download>Sample Excel</a>
            </div>
          </div>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6 mx-auto">
                    <form>
                      <div className="form-group">
                        <label>
                          Meet Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Meet Name"
                          value={meet}
                          onChange={(e) => setMeet(e.target.value)}
                        ></input>
                      </div>
                      <div className="form-group">
                        <label>
                          Meet Location <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Meet Location"
                          value={mloc}
                          onChange={(e) => setMLoc(e.target.value)}
                        ></input>
                      </div>
                      <div className="form-group">
                        <label>
                          Meet Date <span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Select Date"
                          type="date"
                          value={msdate}
                          onChange={(e) => setMSDate(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        ></input>
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Date <span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Select Date"
                          type="date"
                          value={medate}
                          onChange={(e) => setMEDate(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        ></input>
                      </div>
                      <div className="form-group">
                        <label>
                          Select Over Age File{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          ref={inputRef}
                          onChange={certfile}
                          accept=".xls,.xlsx"
                        ></input>
                      </div>
                      {errorMessage !== "" && (
                        <span className="errortxt text-danger">
                          {errorMessage}
                        </span>
                      )}
                      <hr />
                      <div className="form-group">
                        <div className="text-center">
                          <a
                            className="btn btn-sm btn-next btn-success me-2"
                            onClick={overageimport}
                          >
                            Add Overage
                          </a>
                          <a
                            className="btn btn-sm btn-next btn-danger"
                            onClick={cleardata}
                          >
                            Reset
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Overage;
