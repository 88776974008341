import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  statefedaddathlete,
  statefedagecategory,
  statefedathletetlist,
  statefedeventathletetlist,
  statefedeventlist,
  statefedpaymentstatus,
  statefedremoveathlete,
} from "../../../services/state";
import Select from "react-select";
import "./state.css";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import StateHeading from "./stateheading";
import fileString from "../../../assets/language/filepath.json";

const AddAthletes = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [agecat, setAgecat] = useState([]);
  const [elist, setEventlist] = useState([]);
  const [alist, setAthletelist] = useState([]);
  const [aclist, setAthCatlist] = useState([]);

  const [avalue, setAthlete] = useState("");
  const [athleteid, setAthleteID] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [agename, setAgeName] = useState("");
  const [ageid, setAgeId] = useState("");

  const [ename, setEventName] = useState("");

  const [apevent, setAthPerEvent] = useState("");
  const [epathlete, setEvePerAthlete] = useState("");
  const [tname, setTourName] = useState("");

  const [aname, setAthName] = useState("");
  const [adob, setAthDob] = useState("");
  const [aimage, setAthImage] = useState("");

  function setAShow() {
    setAthlete("");
    setAthleteID("");
    setAthName("");
    setAthDob("");
    setAthImage("");
    setErrorMessage("");
    setShow(true);
  }

  function setAClose() {
    setAthName("");
    setAthDob("");
    setErrorMessage("");
    setAthImage("");
    setShow(false);
  }
  var array = [];
  var arr2 = [];
  const [mcnt, setMcnt] = useState([]);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  useEffect(() => {
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    statefedagecategory(temp).then((response) => {
      if (response.status === true) {
        array = [];
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var act = "btn my-1 btn-dark me-2 tx-teko tx-uppercase";
            if (i == 0) {
              act = "btn my-1 btn-danger me-2 tx-teko tx-uppercase";
            }
            array.push({
              id: response.data[i].id,
              age_categories: response.data[i].age_categories,
              max_athlete_event: response.data[i].max_athlete_event,
              max_event_athlete: response.data[i].max_event_athlete,
              active: act,
            });
          }
        }

        setAgecat(array);

        setAthPerEvent(response.data[0].max_athlete_event);
        setEvePerAthlete(response.data[0].max_event_athlete);
        setAgeName(response.data[0].age_categories);
        setAgeId(response.data[0].id);

        agecatgoryevents(0, response.data[0].id);
      }
    });

    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedpaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });
  }, []);

  var arr = [];
  function agecatgoryevents(index, catid) {
    setAthPerEvent("");
    setEvePerAthlete("");
    setEventlist([]);
    setAgeName("");
    setAgeId("");
    setMcnt([]);
    if (agecat.length !== 0) {
      for (let i = 0; i < agecat.length; i++) {
        if (index === i) {
          agecat[index].active =
            "btn my-1 btn-danger me-2 tx-teko tx-uppercase";
        } else {
          agecat[i].active = "btn my-1 btn-dark me-2 tx-teko tx-uppercase";
        }
      }
    }
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    temp.append("tournament_category", catid);

    statefedeventlist(temp).then((response) => {
      if (response.status === true) {
        setAthletelist([]);
        // setAthPerEvent(response.data[0].max_athlete_event);
        setEvePerAthlete(response.data[0].max_event_athlete);
        setAgeId(response.data[0].age_category_id);

        setAgeName(response.data[0].age_category_name);
        setEventName(response.data[0].event_name);

        for (let i = 0; i < response.data.length; i++) {
          var clr = "navitxt";
          if (i == 0) {
            clr = "navitxt active";
          }
          arr.push({
            event_id: response.data[i].event_id,
            event_name: response.data[i].event_name,
            id: response.data[i].id,
            age_category_name: response.data[i].age_category_name,
            background: clr,
          });
        }
        setEventlist(arr);

        if (
          response.data[0].event_name === "4*100M Relay" ||
          response.data[0].event_name === "4*400M Relay" ||
          response.data[0].event_name === "Medley Relay"
        ) {
          if(id === "159"){
            setAthPerEvent(5);
            eventathletelist(catid, response.data[0].event_id, 5);
          }else{
            setAthPerEvent(6);
          eventathletelist(catid, response.data[0].event_id, 6);
          }
          
        } else if (response.data[0].event_name === "4*100M Mixed Relay") {
          setAthPerEvent(3);
          eventathletelist(catid, response.data[0].event_id, 3);
        } else if (response.data[0].event_name === "4*400M Mixed Relay") {
          setAthPerEvent(3);
          eventathletelist(catid, response.data[0].event_id, 3);
        } else {
          setAthPerEvent(response.data[0].max_athlete_event);
          eventathletelist(
            catid,
            response.data[0].event_id,
            response.data[0].max_athlete_event
          );
        }
      }
    });

    // loadsearchathlete(catid,"");
  }

  function loadsearchathlete(scid, ume) {
    setAthletelist([]);
    setErrorMessage("");
    setAthlete(ume);
    setAthleteID("");
    setAthName("");
    setAthDob("");
    setAthImage("");
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("tournament_category", scid);
tempData.append("search", ume);
    statefedathletetlist(tempData).then((res) => {
      if (res.status === true) {
        setAthInfo(res.data);
        if (res.data.length !== 0) {
          arr = [];
          for (let i = 0; i < res.data.length; i++) {
            var midname = "";
              if (res.data[i].middle_name !== null) {
                midname = res.data[i].middle_name + " ";
              }
            arr.push({
              value: res.data[i].user_id,
              label:
                res.data[i].first_name +
                " " +
                midname +
                res.data[i].last_name +
                " (" +
                res.data[i].username +
                " )",
            });
          }
          setAthletelist(arr);
        }
      }
    });
  }
  const [ainfo, setAthInfo] = useState([]);
  const checkplayer =(pname,pid)=>{
    setErrorMessage("");
    setAthlete(pname);
    setAthleteID(pid);
    setAthName("");
    setAthDob("");
    setAthImage("");
    setAthletelist([]);
    setAthInfo([]);
    if (ainfo.length !== 0) {
      for (let i = 0; i < ainfo.length; i++) {
        if (pid === ainfo[i].user_id) {
          if (ainfo[i].middle_name !== "") {
            if (ainfo[i].middle_name !== "") {
              setAthName(
                ainfo[i].first_name +
                  " " +
                  ainfo[i].middle_name +
                  " " +
                  ainfo[i].last_name
              );
            }
          } else {
            setAthName(ainfo[i].first_name + " " + ainfo[i].last_name);
          }

          setAthDob(moment(ainfo[i].date_of_birth).format("DD-MM-YYYY"));
          setAthImage(imageUrl + fileString.athletephoto + ainfo[i].photograph);
        }
      }
    }
  }
  const handleChange = (e) => {
    setErrorMessage("");
    setAthlete(e);
    setAthleteID(e.value);
    setAthName("");
    setAthDob("");
    setAthImage("");
    if (ainfo.length !== 0) {
      for (let i = 0; i < ainfo.length; i++) {
        if (e.value === ainfo[i].user_id) {
          if (ainfo[i].middle_name !== "") {
            if (ainfo[i].middle_name !== "") {
              setAthName(
                ainfo[i].first_name +
                  " " +
                  ainfo[i].middle_name +
                  " " +
                  ainfo[i].last_name
              );
            }
          } else {
            setAthName(ainfo[i].first_name + " " + ainfo[i].last_name);
          }

          setAthDob(moment(ainfo[i].date_of_birth).format("DD-MM-YYYY"));
          setAthImage(imageUrl + fileString.athletephoto + ainfo[i].photograph);
        }
      }
    }
  };

  function selectevent(evt, index) {
    setEventName("");
    setEventName(evt);
    setAthPerEvent("");
    setEvePerAthlete("");
    elist[index].background = "navitxt active";

    var eventid = "";
    if (elist.length !== 0) {
      for (let j = 0; j < elist.length; j++) {
        if (elist[j].event_name === evt) {
          eventid = elist[j].event_id;
        } else {
          elist[j].background = "navitxt";
        }
      }
      setEventlist([...elist]);
    }

    var ageid = "";
    var aper = "";
    var eper = "";
    if (agecat.length !== 0) {
      for (let j = 0; j < agecat.length; j++) {
        if (agecat[j].age_categories === agename) {
          ageid = agecat[j].id;
          aper = agecat[j].max_athlete_event;
          eper = agecat[j].max_event_athlete;
          setAthPerEvent(agecat[j].max_athlete_event);
          setEvePerAthlete(agecat[j].max_event_athlete);
        }
      }
    }

    if (
      evt === "4*100M Relay" ||
      evt === "4*400M Relay" ||
      evt === "Medley Relay"
    ) {
      if(id === "159"){
      setAthPerEvent(5);
      eventathletelist(ageid, eventid, 5);
      }else{
        setAthPerEvent(6);
        eventathletelist(ageid, eventid, 6);
      }
    } else if (evt === "4*100M Mixed Relay") {
      setAthPerEvent(3);
      eventathletelist(ageid, eventid, 3);
    } else if (evt === "4*400M Mixed Relay") {
      setAthPerEvent(3);
      eventathletelist(ageid, eventid, 3);
    } else {
      eventathletelist(ageid, eventid, aper);
      // eventathletelist(ageid, eventid, apevent);
    }
  }

  function eventathletelist(cid, eid, max) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("tournament_category", cid);
    tempData.append("event_id", eid);

    statefedeventathletetlist(tempData).then((res) => {
      if (res.status === true) {
        setAthCatlist(res.data);
        arr2 = [];
        var final = max;
        if (res.data.length !== 0) {
          final = max - res.data.length;
        }
        for (let i = 0; i < final; i++) {
          arr2.push({
            id: i,
            load: "",
          });
        }
        setMcnt([...arr2]);
      }
    });
  }

  function athletesubmit() {
    setErrorMessage("");
    var error = "";

    if (avalue !== "") {
      if(athleteid !== ""){
        if (aclist.length !== 0) {
          if (apevent <= aclist.length) {
            error = "Maximum 3 athletes allowed per event";
            setErrorMessage(
              "Maximum " + aclist.length + " athletes allowed per event"
            );
          }
        }
  
        if (error === "") {
          const tempDate = new FormData();
          var tcat = "";
          var tid = "";
          if (agecat.length !== 0) {
            for (let i = 0; i < agecat.length; i++) {
              if (
                agecat[i].active ===
                "btn my-1 btn-danger me-2 tx-teko tx-uppercase"
              ) {
                tid = agecat[i].id;
                tcat = agecat[i].age_categories;
                break;
              }
            }
          }
          var eventid = "";
          if (elist.length !== 0) {
            for (let j = 0; j < elist.length; j++) {
              if (elist[j].event_name === ename) {
                eventid = elist[j].event_id;
              }
            }
          }
  
          tempDate.append("tournament_meet_id", id);
          tempDate.append("tournament_category", tid);
          tempDate.append("event_id", eventid);
          tempDate.append("eventslist", ename);
          // tempDate.append("user_id", avalue.value);
          tempDate.append("user_id", athleteid);
  
  
          statefedaddathlete(tempDate).then((response) => {
            if (response.status === true) {
              setAthlete("");
              setAthleteID("");
              setShow(false);
              eventathletelist(tid, eventid, apevent);
            } else {
              setErrorMessage(response.message);
            }
          });
        }
      }else{
      setErrorMessage("Invalid UID");

      }
      
    } else {
      setErrorMessage("Please Select Your Athlete");
    }
  }

  function deleteathlete(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("unique_id", did);

    statefedremoveathlete(tempData).then((response) => {
      if (response.status === true) {
        var eventid = "";
        if (elist.length !== 0) {
          for (let j = 0; j < elist.length; j++) {
            if (elist[j].event_name === ename) {
              eventid = elist[j].event_id;
            }
          }
        }

        var ageid = "";
        if (agecat.length !== 0) {
          for (let j = 0; j < agecat.length; j++) {
            if (agecat[j].age_categories === agename) {
              ageid = agecat[j].id;
            }
          }
        }
        // arr2 = [];
        // if (apevent !== 0) {
        //   var final = apevent;
        //   if (aclist.length !== 0) {
        //     final = apevent - aclist.length;
        //   }
        //   for (let j = 0; j < final; j++) {
        //     arr2.push({
        //       id: j,
        //       load: "",
        //     });
        //   }
        // }
        // setMcnt(arr2);
        eventathletelist(ageid, eventid, apevent);
      }
    });
  }

  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Add Athletes
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/state/athleteentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Athlete List
                  </Link>
                  <Link
                    to={"/state/officialentrylist/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Officials Entry
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        <Row className="row-sm mx-0 bg-white py-2 mb-3">
          <Col lg={12} md={12} xl={12} sm={12}>
            <Card className="custom-card overflow-hidden eventslist">
              <Card.Header>
                <div>
                  <h3 className="card-title tx-18 lineheight1 mb-0">
                    <span className="main-content-label tx-18">
                      Select Age Categories
                    </span>
                  </h3>
                </div>
              </Card.Header>
              <Card.Body px-2 py-3>
                <div className="justify-content-center">
                  {agecat.map((data, i) => {
                    return (
                      <a
                        key={i}
                        variant="warning"
                        type="button"
                        className={data.active}
                        onClick={() => agecatgoryevents(i, data.id)}
                      >
                        {" "}
                        {data.age_categories}
                      </a>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-0 border-top bg-white">
          <Col lg={3} md={3} xl={3} sm={12}>
            <Card className="custom-card overflow-hidden eventslist">
              <Card.Header>
                <div>
                  <h3 className="card-title tx-18 lineheight1 mb-0">
                    <span className="main-content-label tx-18">
                      Select Events
                    </span>
                  </h3>
                </div>
              </Card.Header>
              <Card.Header className="px-2">
                {elist.map((data, i) => {
                  return (
                    <div key={i}>
                      {data.event_name !== "" && (
                        <div
                          className={data.background}
                          onClick={() => selectevent(data.event_name, i)}
                        >
                          <i className="fa fa-trophy icon1 me-2 tx-18"></i>
                          <h3 className="card-title tx-16 mb-0 lineheight1">
                            <span className="main-content-label tx-16 tx-roboto mb-0">
                              {data.event_name}
                            </span>
                          </h3>
                          <i className="fa fa-check-square icon1 ps-2 tx-16 ms-auto"></i>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Card.Header>
            </Card>
          </Col>
          <Col lg={9} md={9} xl={9} sm={12}>
            <div className="custom-card">
              <div className="card-header bg-light py-3 mb-4">
                <h4 className="tx-28 text-dark mb-0">
                  {agename} [<span className="text-danger">{ename}</span>]
                </h4>
                <h6 className="tx-18 text-muted mb-0">
                  Maximum {epathlete} event allowed per athletes{" "}
                  <span className="float-end text-danger tx-roboto tx-14">
                    PLEASE SELECT {apevent} ATHLETE(S) MAXIMUM.
                  </span>
                </h6>
              </div>
              <Row className="row-sm mx-0 mt-0 border-bottom bg-white">
                {aclist.map((list, i) => {
                  return (
                    <Col xl={3} lg={6} md={6} sm={6} key={i}>
                      <Card className="custom-card our-team border border-light shadow-base bg-light">
                        <Card.Body className="px-2 py-3">
                          <div className="picture avatar-lg online text-center">
                            <img
                              alt="avatar"
                              className="rounded-circle"
                              src={
                                imageUrl +
                                fileString.athletephoto +
                                list.photograph
                              }
                              onError={(e) => errorhandle(e)}
                            />
                          </div>
                          <div className="text-center mt-3">
                            <a>
                            {list.middle_name !== null && (
                                <h5 className="pro-user-username text-dark mt-2 mb-0">
                                  {list.first_name} {list.middle_name}{" "}
                                  {list.last_name}
                                </h5>
                              )}
                              {list.middle_name === null && (
                                <h5 className="pro-user-username text-dark mt-2 mb-0">
                                  {list.first_name} {list.last_name}
                                </h5>
                              )}
                            </a>
                            <p className="pro-user-desc text-danger mb-2">
                              ( {list.username} )
                            </p>
                            <div className="text-center tx-16 mb-0">
                              DOB :{" "}
                              {moment(list.date_of_birth).format("DD-MM-YYYY")}
                            </div>
                          </div>
                          <a
                            className="deleteathlet"
                            onClick={() => deleteathlete(list.id)}
                          >
                            <i className="fa fa-close"></i>
                          </a>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}

                {mcnt.map((data, k) => {
                  return (
                    <Col xl={3} lg={6} md={6} sm={6} key={k}>
                      <Card
                        className="custom-card our-team border border-light shadow-base bg-light"
                        onClick={setAShow}
                      >
                        <Card.Body px-2 py-3>
                          <div className="tx-26 d-block py-5 lineheight356 w-100 text-center">
                            {" "}
                            <i className="fa fa-plus-circle text-danger"></i>{" "}
                          </div>
                        </Card.Body>
                      </Card>
                      <Modal show={show}>
                        <Modal.Header>
                          <h6 className="rep-head">Slot</h6>
                          <i
                            className="fa fa-window-close fa-lg"
                            onClick={setAClose}
                            aria-hidden="true"
                          ></i>
                        </Modal.Header>
                        <Modal.Body className="mod-body">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Name/Username"
                            value={avalue}
                            onChange={(e) =>
                              loadsearchathlete(ageid, e.target.value)
                            }
                          />
                          <ul class="suggestions">
                            {alist.map((item, i) => {
                              return (
                                <li
                                  key={i}
                                  onClick={() => checkplayer(item.label, item.value)}                                >
                                  {item.label}
                                </li>
                              );
                            })}
                          </ul>
                          {/* <Select
                            placeholder="Select Athlete"
                            value={avalue}
                            options={alist}
                            onChange={handleChange}
                            isSearchable={true}
                          /> */}
                          {aname !== "" && (
                            <Card className="custom-card our-team border border-light shadow-base bg-light">
                              <Card.Body className="px-2 py-3">
                                <div className="picture avatar-lg online text-center">
                                  <img
                                    alt="avatar"
                                    className="rounded-circle"
                                    src={aimage}
                                    onError={(e) => errorhandle(e)}
                                  />
                                </div>
                                <div className="text-center mt-3">
                                  <h5 className="pro-user-username text-dark mt-2 mb-0 tx-18">
                                    {aname}
                                  </h5>

                                  <div className="text-center tx-16 mb-0">
                                    DOB : {adob}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          )}
                          {errorMessage !== "" && (
                            <span className="text-danger">{errorMessage}</span>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <a
                            className="btn btn-success btn-sm mb-0 me-2 tx-14"
                            onClick={athletesubmit}
                          >
                            Add Athlete
                          </a>
                          <button
                            onClick={setAClose}
                            className="text-center can-small"
                          >
                            Cancel
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                    // <div key={k}>
                    //   {data.load === "" && (

                    //   )}
                    // </div>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AddAthletes;
