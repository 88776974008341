import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { meetresultlist } from "../../../services/superadmin";
import { meetinfo } from "../../../services/meetservice";

const MeetResultList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const { id } = useParams();

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");
  const [elist, setEventList] = useState([]);

  useEffect(() => {
    meetinfo(id).then((response) => {
      if (response.status === true) {
        setTourname(response.data.tournament.meet_name);
        setTourloc(response.data.tournament.location);
        setTourdate(
          moment(response.data.tournament.start_date).format("DD/MM/YYYY") +
            " - " +
            moment(response.data.tournament.end_date).format("DD/MM/YYYY")
        );

        const tempData = new FormData();
        tempData.append("meet_id", id);
        tempData.append("meet_name", response.data.tournament.meet_name);

        meetresultlist(tempData).then((response) => {
          if (response.status === true) {
            setFilterProduct(response.data);
          }
        });
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Age Category",
      width: 320,
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      width: 225,
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
        headerName: "Time Taken",
        width: 150,
        field: "time_taken",
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        cellStyle: { textAlign: "left" },
      },
    {
      headerName: "Gender",
      width: 150,
      field: "meet_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value === "F") {
          return "Female";
        } else if (data.value === "M") {
          return "Male";
        }
      },
    },
    {
      headerName: "Status",
      width: 150,
      field: "event_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Meet Result List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Header>
              <div className="text-center">
                <h4 className="mb-0 mt-3">{tourname}</h4>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-map-marker me-2 text-danger"></i>
                    {tourloc}
                  </h6>
                </div>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-calendar me-2 text-danger"></i>
                    {tourdate}
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 520,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MeetResultList;
