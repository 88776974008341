import React, { useState, useEffect, Fragment, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { reapplyidcardlist } from "../../../services/superadmin";
import moment from "moment";
import { Link } from "react-router-dom";

const Pendingidcard = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  useEffect(() => {
    reapplyidcardlist(0, 0).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "UID",
      width: 195,
      field: "user_det.username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 300,
      field: "user_det.first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = params.data.user_det.first_name;

        if (params.data.user_det.middle_name !== null) {
          if (evt !== "") {
            evt = evt + " " + params.data.user_det.middle_name;
          }
        }
        if (params.data.user_det.last_name !== "") {
          if (evt === "") {
            evt = params.data.user_det.last_name;
          } else if (evt !== "") {
            evt = evt + " " + params.data.user_det.last_name;
          }
        }
        return evt;
      },
    },
    {
      headerName: "Status",
      width: 180,
      field: "status",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return "Pending";
      },
    },
    {
      headerName: "Date",
      width: 180,
      field: "order_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      headerName: "Actions",
      width: 150,
      field: "user_id",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        return (
          <div className="text-center">
            <Link
              to={"/superadmin/athleteidcardview/" + params.value}
              className="btn-sm btn-info me-3"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        );
      },
    },
  ];
  const gridRef = useRef();
  const onexport = useCallback(() => {
    var excelParams = {
      fileName: "Pending IDCard.csv",
    };
    // gridRef.current.api.exportDataAsExcel();
    gridRef.current.api.exportDataAsCsv(excelParams);
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Pending ID Cards</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
            {/* <a className="btn btn-success mb-2" onClick={onexport}>Export</a> */}
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    // ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Pendingidcard;
