import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./state.css";
import StateHeading from "./stateheading";
import { meetdetails } from "../../../../services/meetservice";
import moment from "moment";
import { nidjamadddeparture, nidjampaymentstatus, nidjamviewdeparture } from "../../../../services/nidjamdistrict";

const NidjamAddDepaturedetails = () => {
  const [datehr, setHourlist] = useState([]);
  const [datesec, setSeclist] = useState([]);

  const [DDate, setDepDate] = useState("");
  const [DHour, setDepHour] = useState("");
  const [DSec, setDepSec] = useState("");
  const [DMode, setDepMode] = useState("");
  const [DNum, setDepNum] = useState("");
  const [DRemark, setDepRemark] = useState("");
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [viewlist, setViewList] = useState(false);

  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const navigate = useNavigate();
  const { aid } = useParams();

  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");

    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status === "Paid") {
          navigate("/district/meetconfirmation/" + id);
        }
      }
    });

    meetdetails(id).then((response) => {
      if (response.status === true) {
        var dte = moment(response.data.tournament.end_date).add(
          2,
          "days"
        );
        setMaxDate(moment(dte).format("YYYY-MM-DD"));

        setMinDate(response.data.tournament.end_date);
      }
    });

    if (link[1] === "viewdeparture") {
      setViewList(true);
      nidjamviewdeparture(aid).then((res) => {
        setDepDate(res.data.dateof_dep);
        var tarr = res.data.dateof_dep_time.split(":");
        setDepHour(tarr[0]);
        setDepSec(tarr[1]);
        setDepMode(res.data.dep_travel_by);
        setDepNum(res.data.dep_other_details);
        setDepRemark(res.data.dep_remarks);
      });
    } else {
      setViewList(false);
    }

    var hr = [];
    var add = "";
    for (let i = 1; i <= 23; i++) {
      if (i < 10) {
        add = String(i).padStart(2, "0");
      } else {
        add = i;
      }
      hr.push(add);
    }
    setHourlist(hr);

    var sec = [];
    var zero = "";
    for (let j = 0; j <= 59; j++) {
      if (j < 10) {
        zero = String(j).padStart(2, "0");
      } else {
        zero = j;
      }
      sec.push(zero);
    }
    setSeclist(sec);
  }, []);

  function departureadd(e) {
    e.preventDefault();
    setErrorMessage("");
    if (DDate !== "") {
      if (DHour !== "") {
        if (DSec !== "") {
          if (DMode !== "") {
            if (DNum !== "") {
              if (DRemark !== "") {
                var time = "";
                time = DHour + ":" + DSec + ":00";
                const temp = new FormData();
                if (viewlist === true) {
                  temp.append("travel_details_id", aid);
                } else {
                  temp.append("travel_details_id", 0);
                }
                temp.append("tournament_meet_id", id);
                temp.append("date_of_departure", DDate);
                temp.append("time_of_departure", time);
                temp.append("departure_travel_by", DMode);
                temp.append("departure_vehicle_no", DNum);
                temp.append("departure_remarks", DRemark);

                nidjamadddeparture(temp).then((res) => {
                  if (res.status === true) {
                    navigate("/district/travelentrylist/" + id);
                  }
                });
              } else {
                setErrorMessage("Please Enter Remarks");
              }
            } else {
              setErrorMessage("Please Enter " + DMode + " No");
            }
          } else {
            setErrorMessage("Please Select Mode of Travel");
          }
        } else {
          setErrorMessage("Please Select Departure Minutes");
        }
      } else {
        setErrorMessage("Please Select Departure Hour");
      }
    } else {
      setErrorMessage("Please Select Departure Date");
    }
  }
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                {viewlist === true && (
                  <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                    Update Departure Details
                  </h2>
                )}
                {viewlist !== true && (
                  <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                    Add Departure Details
                  </h2>
                )}
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/district/officialentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Official Entry
                  </Link>
                  <Link
                    to={"/district/selectioncommittee/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Selection Committee Mins
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-0 border-top bg-white">
          <Col lg={12} md={12} xl={12} sm={12}>
            <Card className="custom-card overflow-hidden eventslist">
              {/* <Card.Header className="text-center">
                <div>
                  <h3 className="card-title tx-18 lineheight1 mb-0">
                    <span className="main-content-label tx-20">
                    Departure Details
                    </span>
                  </h3>
                </div>
              </Card.Header> */}
              <Card.Body className="pb-0">
                <form>
                  {/* <!-- Row --> */}
                  <Row className="row-sm mx-0">
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Departure Date : <b className="text-danger">*</b></label>
                        <input
                          name="numberOfGuests"
                          type="date"
                          placeholder="Enter Departure Date"
                          value={DDate}
                          min={minDate}
                          max={maxDate}
                          onChange={(e) => setDepDate(e.target.value)}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3}>
                      <div className="form-group">
                        <label className="mb-2">Departure Hour : <b className="text-danger">*</b></label>
                        <select
                          value={DHour}
                          onChange={(e) => setDepHour(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Hour
                          </option>
                          {datehr.map((data, i) => {
                            return (
                              <option value={data} key={i}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} md={3}>
                      <div className="form-group">
                        <label className="mb-2">Departure Minutes : <b className="text-danger">*</b></label>
                        <select
                          value={DSec}
                          onChange={(e) => setDepSec(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Minutes
                          </option>
                          {datesec.map((data, i) => {
                            return (
                              <option value={data} key={i}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Mode of Travel : <b className="text-danger">*</b></label>
                        <select
                          value={DMode}
                          onChange={(e) => setDepMode(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Mode of Travel
                          </option>

                          <option value="Train">Train</option>
                          <option value="Bus">Bus</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">{DMode} No : <b className="text-danger">*</b></label>
                        <input
                          name="numberOfGuests"
                          type="text"
                          placeholder="Enter Bus / Train Number"
                          value={DNum}
                          onChange={(e) => setDepNum(e.target.value)}
                          maxLength={20}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">Remarks : <b className="text-danger">*</b></label>
                        <textarea
                          name="postContent"
                          rows={4}
                          cols={40}
                          value={DRemark}
                          onChange={(e) => setDepRemark(e.target.value)}
                        />
                      </div>
                    </Col>
                    <hr className="w-100" />
                  </Row>
                  {/* <!-- End Row --> */}
                </form>
                {errorMessage !== "" && (
                  <span className="text-danger">{errorMessage}</span>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12} md={12} xl={12} sm={12}>
            <div className="text-center">
              {viewlist === true && (
                <a
                  onClick={departureadd}
                  variant="success"
                  type="button"
                  className="btn my-1 btn-success tx-teko tx-uppercase"
                >
                  {" "}
                  Update Departure Details
                </a>
              )}

              {viewlist !== true && (
                <a
                  onClick={departureadd}
                  variant="success"
                  type="button"
                  className="btn my-1 btn-success tx-teko tx-uppercase"
                >
                  {" "}
                  ADD Departure Details
                </a>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NidjamAddDepaturedetails;
