import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  meetaddtournament,
  meetagelist,
  meetdetails,
  meeteventlist,
} from "../../../services/meetservice";

const Addmeetmember = () => {
  const [agecat, setAgecat] = useState([]);
  const [elist, setEventList] = useState("");
  const [tournament, setTournament] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  function handleChange(e, index) {
    tournament[index].eventcat = e;
    setTournament([...tournament]);
  }

  function checkage(e, index) {
    if (tournament.length !== 0) {
      var name = "";
      for (let i = 0; i < tournament.length; i++) {
        if (tournament[i].agecategory !== "") {
          if (tournament[i].agecategory === e.target.value && i !== index) {
            if (name === "") {
              name = tournament[i].agecategory;
            } else {
              name = name + "," + tournament[i].agecategory;
            }
          }
        }
      }
    }
    if (name !== "") {
      Swal.fire({
        title: name + " Already Exists",
        allowOutsideClick: true,
      });
    } else {
      tournament[index].agecategory = e.target.value;
    }
    setTournament([...tournament]);
  }

  function checksdob(e, index) {
    tournament[index].sdob = e.target.value;
    setTournament([...tournament]);
  }

  function checkedob(e, index) {
    tournament[index].edob = e.target.value;
    setTournament([...tournament]);
  }

  function checkathlete(e, index) {
    tournament[index].maxathlete = e.target.value;
    setTournament([...tournament]);
  }

  function checkevent(e, index) {
    tournament[index].maxevents = e.target.value;
    setTournament([...tournament]);
  }

  function checkfee(e, index) {
    tournament[index].fees = e.target.value;
    setTournament([...tournament]);
  }

  function addmulti(index) {
    if (index + 1 < tournament.length) {
      tournament[index].button = 0;
      tournament[index + 1].button = 1;
      tournament[index + 1].active = 1;
      setTournament([...tournament]);
    } else {
      Swal.fire({
        title: "Maximum Age Category reached",
        allowOutsideClick: true,
      });
    }
  }

  const navigate = useNavigate();
  function addmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    if (tournament.length !== 0) {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);
      tempData.append("age_category_count", tournament.length);

      var msg = "";
      for (let i = 0; i < tournament.length; i++) {
        if (tournament[i].active === 1) {
          if (tournament[i].agecategory !== "") {
            if (tournament[i].eventcat !== "") {
              if (tournament[i].sdob !== "") {
                if (tournament[i].edob !== "") {
                  if (tournament[i].maxathlete !== "") {
                    if (tournament[i].maxevents !== "") {
                      if (tournament[i].fees !== "") {
                        var eve = "";
                        if (tournament[i].eventcat.length !== 0) {
                          for (
                            let j = 0;
                            j < tournament[i].eventcat.length;
                            j++
                          ) {
                            if (eve === "") {
                              eve = tournament[i].eventcat[j].label;
                            } else {
                              eve = eve + "," + tournament[i].eventcat[j].label;
                            }
                          }
                        }
                        tempData.append(
                          "age_category_" + [i + 1],
                          tournament[i].agecategory
                        );
                        tempData.append(
                          "dob_start_date_" + [i + 1],
                          tournament[i].sdob
                        );
                        tempData.append(
                          "dob_end_date_" + [i + 1],
                          tournament[i].edob
                        );
                        tempData.append(
                          "max_athlete_event_" + [i + 1],
                          tournament[i].maxathlete
                        );
                        tempData.append(
                          "max_event_athlete_" + [i + 1],
                          tournament[i].maxevents
                        );
                        tempData.append("events_" + [i + 1], eve);
                        tempData.append("fees_" + [i + 1], tournament[i].fees);
                      } else {
                        msg = "Please Enter Your Fees";
                        setErrorMessage("Please Enter Your Fees");
                        break;
                      }
                    } else {
                      msg = "Please Enter Maximum Event/Athlete";

                      setErrorMessage("Please Enter Maximum Event/Athlete");
                      break;
                    }
                  } else {
                    msg = "Please Enter Maximum Athlete/Event";

                    setErrorMessage("Please Enter Maximum Athlete/Event");
                    break;
                  }
                } else {
                  msg = "Please Select DOB End Date";

                  setErrorMessage("Please Select DOB End Date");
                  break;
                }
              } else {
                msg = "Please Select DOB Start Date";

                setErrorMessage("Please Select DOB Start Date");
                break;
              }
            } else {
              msg = "Please Select Event Category";

              setErrorMessage("Please Select Event Category");
              break;
            }
          } else {
            msg = "Please Select Age Category";
            setErrorMessage("Please Select Age Category");
            break;
          }
        } else {
          msg = "Please Select All Age Categories";

          setErrorMessage("Please Select All Age Categories");
        }
      }
      if (msg === "") {
        meetaddtournament(tempData).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/listmeet");
          }
        });
      }
    }
  }
  var arr = [];
  var arr1 = [];
  const { id } = useParams();
  const [msdob, setMSDob] = useState("");
  const [medob, setMEDob] = useState("");

  useEffect(() => {
    meeteventlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].event_uid,
              label: response.data[i].event_name,
            });
          }
        }
        setEventList(arr);
      }
    });

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setMSDob(response.data.tournament.dob_start_date);
        setMEDob(response.data.tournament.dob_end_date);
      }
    });
    meetagelist(id).then((response) => {
      if (response.status === true) {
        setAgecat(response.data);
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var dtype = 0;
            if (i == 0) {
              dtype = 1;
            }
            var butt = 0;
            if (i == 0) {
              butt = 1;
            }
            arr1.push({
              agecategory: "",
              eventcat: "",
              sdob: "",
              edob: "",
              maxathlete: "",
              maxevents: "",
              fees: "",
              active: dtype,
              button: butt,
            });
          }
        }
        setTournament(arr1);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Meet</h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                {tournament.map((data, i) => {
                  return (
                    <div key={i}>
                      {data.active === 1 && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Age Category{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    value={data.agecategory}
                                    onChange={(e) => checkage(e, i)}
                                  >
                                    <option value="" disabled>
                                      Select Your Age Category
                                    </option>
                                    {agecat.map((age, j) => {
                                      return (
                                        <option
                                          value={age.age_categories}
                                          key={j}
                                        >
                                          {age.age_categories}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Events{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <Select
                                    className="dropdown"
                                    placeholder="Select Your Events"
                                    value={data.eventcat}
                                    options={elist}
                                    onChange={(e) => handleChange(e, i)}
                                    isMulti
                                    isClearable
                                    hidden
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    DOB Start Date{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="date"
                                    placeholder="Enter Your DOB Start Date"
                                    min={msdob}
                                    max={medob}
                                    value={data.sdob}
                                    onChange={(e) => checksdob(e, i)}
                                    onKeyPress={(event) => {
                                      event.preventDefault();
                                    }}
                                    onKeyDown={(event) => {
                                      event.preventDefault();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    DOB End Date{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="date"
                                    placeholder="Enter Your DOB End Date"
                                    min={msdob}
                                    max={medob}
                                    value={data.edob}
                                    onChange={(e) => checkedob(e, i)}
                                    onKeyPress={(event) => {
                                      event.preventDefault();
                                    }}
                                    onKeyDown={(event) => {
                                      event.preventDefault();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Max Athlete/Event{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your Max Athlete/Event"
                                    value={data.maxathlete}
                                    onChange={(e) => checkathlete(e, i)}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Max Event/Athlete{" "}
                                    <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your Max Event/Athlete"
                                    value={data.maxevents}
                                    onChange={(e) => checkevent(e, i)}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Fees <span className="text-danger">*</span>
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your Fees"
                                    value={data.fees}
                                    onChange={(e) => checkfee(e, i)}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {data.button === 1 && (
                            <div className="col-md-6">
                              <div className="reg">
                                <a
                                  className="btn btn-next btn-success"
                                  style={{
                                    float: "right",
                                    marginRight: "30px",
                                  }}
                                  onClick={() => addmulti(i)}
                                >
                                  Add
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {data.active === 1 && <hr />}
                    </div>
                  );
                })}
              </div>
            </div>
            {errorMessage !== "" && (
              <span className="errortxt text-danger">{errorMessage}</span>
            )}
            <div className="reg">
              <a
                className="btn btn-next btn-info"
                id="next3"
                style={{ float: "right" }}
                onClick={addmeet}
              >
                Add Meet
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Addmeetmember;
