import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./state.css";
import moment from "moment";
import {
  statefedofficialentrylist,
  statefedpaymentstatus,
  statefedremoveofficial,
} from "../../../services/state";
import { AgGridReact } from "ag-grid-react";
import StateHeading from "./stateheading";

const OfficialEntryList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filterProduct, setFilterProduct] = useState([]);
  useEffect(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedpaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });
    offilist();
  }, []);

  function offilist() {
    setFilterProduct([]);
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    statefedofficialentrylist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.officials_list);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "First Name",
      width: 200,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 200,
      field: "last_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 200,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Phone",
      width: 150,
      field: "phone",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Post",
      width: 150,
      field: "post",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      filter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <a
            className="btn-sm btn-danger"
            onClick={() => deletelist(params.value)}
          >
            <i className="fa fa-trash fa-lg " aria-hidden="true"></i>
          </a>
        );
      },
    },
  ];

  function deletelist(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("officials_id", did);

    statefedremoveofficial(tempData).then((response) => {
      if (response.status === true) {
        offilist();
      }
    });
  }

  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <Row className="row-sm mx-0 mt-3">
          <Col xl={12} lg={12}>
            <Card className=" custom-card" id="basic">
              <Card.Body>
                <div className="page-header mt-2 mb-0">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                      Official List
                    </h2>
                  </div>
                  <div className="d-flex pe-4">
                    <div className="justify-content-center">
                      <Link
                        to={"/state/athleteentrylist/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Athlete Entry
                      </Link>
                      <Link
                        to={"/state/addofficial/" + id}
                        variant="success"
                        type="button"
                        className="btn my-1 btn-success me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Add Official
                      </Link>

                      <Link
                        to={"/state/travelentrylist/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Travel Details
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 600,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default OfficialEntryList;
