import { configureStore } from '@reduxjs/toolkit';
import userReducer from './action';

// const store = configureStore(userReducer);

// export default store;

const Loginware = (store) => (next) => (action) => {
    
    return next(action);
};



export default configureStore({
    reducer: {
        user: userReducer,
    },
    middleware: [Loginware]
});
