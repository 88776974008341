import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

function PrivateRoute(props) {
  const isAuth = localStorage.getItem("Token");
  // useEffect(() => {
  //   if (localStorage.getItem("Roleid") !== 1) {
  //   //   const script = document.createElement("script");
  //   //   script.src = "https://www.googletagmanager.com/gtag/js?id=G-NK33JPXFG5";
  //   //   script.async = true;

  //   //   document.body.appendChild(script);

  //     // <script>
  //     //   window.dataLayer = window.dataLayer || [];
  //     //   function gtag() {
  //     //     dataLayer.push(arguments);
  //     //   }
  //     //   gtag("js", new Date());
  //     //   gtag("config", "G-NK33JPXFG5");
  //     // </script>
  //   }
  // }, []);
  return isAuth ? (
    <Routes>
      <Route {...props} />
    </Routes>
  ) : (
    <Navigate to="/login" />
  );
}
export default PrivateRoute;
