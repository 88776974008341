import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Col,
  Dropdown,
  Nav,
  Pagination,
  Row,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PayUrl from "../../../Apiccavenue";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

import {
  districtccavenuepayment,
  districtcreateorderpayment,
  districtpaymentstatus,
  districtsummarydetail,
  meetdetails,
} from "../../../services/meetservice";
import dataString from "../../../assets/language/ccavenue.json";

const Districtsummary = () => {
  const { id } = useParams();
  const [cmen, setCMen] = useState(0);
  const [cwomen, setCWomen] = useState(0);
  const [rmen, setRMen] = useState(0);
  const [rwomen, setRWomen] = useState(0);
  const [rmamt, setRMAmt] = useState(0);
  const [rwamt, setRWAmt] = useState(0);

  const [tramt, setTRAmt] = useState(0);
  const [trcnt, setTRCnt] = useState(0);

  const [mamt, setMAmt] = useState(0);
  const [wamt, setWAmt] = useState(0);
  const [tamt, setTAmt] = useState(0);
  const [tcnt, setTCnt] = useState(0);

  const [tmcnt, setTMCnt] = useState(0);
  const [twcnt, setTWCnt] = useState(0);
  const [menamt, setMenAmt] = useState(0);
  const [womenamt, setWomenAmt] = useState(0);
  const [relayamt, setRelayAmt] = useState(0);

  const [tname, setTName] = useState("");
  const [tdate, setTDate] = useState("");
  const [tcode, setTCode] = useState("");

  const [athlete, setAthlete] = useState([]);
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  const [free, setFree] = useState("");
  const [paytype, setPayType] = useState("");

  const [paystatus, setPayStatus] = useState("");
  const [gateway, setGateway] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.tournament.meet_name);
        setTDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY") +
            " to " +
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setTCode(response.data.tournament.meet_code);

        setFree(response.data.tournament.free_tournament);
        setPayType(response.data.tournament.payment_gateway);
        if (response.data.tournament.gateway_charges === "Y") {
          setGateway("(Including Gateway Charges)");
        } else {
          setGateway("");
        }
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }

        const temp = new FormData();
        temp.append("current_meet_id", id);
        districtpaymentstatus(temp).then((response) => {
          if (response.status === true) {
            setPayStatus(response.data.Payment_Status);
            if (response.data.Payment_Status === "Paid") {
              navigate("/district/confirmation/" + id);
            }
          }
        });
        districtsummarydetail(id, localStorage.getItem("Userid")).then(
          (response) => {
            if (response.status === true) {
              setCMen(response.data.no_of_men);
              setCWomen(response.data.no_of_women);
              setMAmt(response.data.total_male_amount);
              setWAmt(response.data.total_female_amount);
              setTCnt(
                response.data.total_count +
                  response.data.total_relay_total_count
              );
              setTAmt(
                response.data.total_amount +
                  response.data.total_relay_total_amount
              );

              setTMCnt(
                response.data.no_of_men + response.data.total_relay_male_count
              );
              setTWCnt(
                response.data.no_of_women +
                  response.data.total_relay_female_amount
              );
              setMenAmt(
                parseInt(response.data.total_male_amount) +
                  response.data.total_relay_male_amount
              );
              setWomenAmt(
                parseInt(response.data.total_female_amount) +
                  response.data.total_relay_female_amount
              );

              setRelayAmt(response.data.total_relay_total_amount);
              setRMen(response.data.total_relay_male_count);
              setRWomen(response.data.total_relay_female_count);
              setRMAmt(response.data.total_relay_male_amount);
              setRWAmt(response.data.total_relay_female_amount);

              setTRCnt(response.data.total_relay_team);
              setTRAmt(response.data.relayTeamCount_fees);

              setAthlete(response.data.added_athletes);
            }
          }
        );
      }
    });
  }, []);

  function paysubmit() {
    setErrorMessage("");
    if (free === "N") {
      if (paytype === "Razor Pay") {
        if (tamt !== 0) {
          const tempData = new FormData();
          tempData.append("dist_club_id", localStorage.getItem("Userid"));
          tempData.append("districtname", localStorage.getItem("User"));
          tempData.append("tournament_meet_id", id);
          tempData.append("state_id", localStorage.getItem("State"));
          tempData.append("amount", tamt);
          tempData.append("total_men", tmcnt);
          tempData.append("total_women", twcnt);
          tempData.append("total_men_amount", menamt);
          tempData.append("total_women_amount", womenamt);
          tempData.append("total_relay_fees", relayamt);

          districtcreateorderpayment(tempData).then((response) => {
            if (response.status === true) {
              navigate("/district/payment/" + id);
            }
          });
        } else {
          setErrorMessage("Please add athlete before send entries");
        }
      } else {
        if (tamt !== 0) {
          const tempData = new FormData();
          tempData.append("dist_club_id", localStorage.getItem("Userid"));
          tempData.append("districtname", localStorage.getItem("User"));
          tempData.append("tournament_meet_id", id);
          tempData.append("state_id", localStorage.getItem("State"));
          tempData.append("amount", tamt);
          tempData.append("total_men", tmcnt);
          tempData.append("total_women", twcnt);
          tempData.append("total_men_amount", menamt);
          tempData.append("total_women_amount", womenamt);
          tempData.append("total_relay_fees", relayamt);
          districtccavenuepayment(tempData).then((response) => {
            if (response.status === true) {
              window.open(
                PayUrl +
                  dataString.statedistrict +
                  response.data.info.unique_no,
                "_self"
              );
            }
          });
        } else {
          setErrorMessage("Please add athlete before send entries");
        }
      }
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }
  return (
    <div>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5 text-danger">
            {tname}
          </h2>
          <Breadcrumb>
            <BreadcrumbItem active>
              Event Code : <span>{tcode}</span>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {" "}
              Date : <span>{tdate}</span>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <a
              href={imageUrl + fileString.tournamentfiles + cir}
              download
              className="btn btn-info btn-icon-text my-2 me-2"
            >
              <i className="fe fe-eye me-2"></i> View Circular
            </a>
            {cir2 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir2}
                download
                className="btn btn-info btn-icon-text my-2 me-2"
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            {cir3 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir3}
                download
                className="btn btn-info btn-icon-text my-2 me-2"
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            {cir4 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir4}
                download
                className="btn btn-info btn-icon-text my-2 me-2"
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            {cir5 !== "" && (
              <a
                href={imageUrl + fileString.tournamentfiles + cir5}
                download
                className="btn btn-info btn-icon-text my-2 me-2"
              >
                <i className="fe fe-eye me-2"></i> View Circular
              </a>
            )}

            <Link
              to={"/district/addathlete/" + id}
              className="btn btn-danger btn-icon-text my-2 me-2"
            >
              <i className="fe fe-corner-up-left me-2"></i> Back
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      {paystatus === "failed" && (
        <div>
          <hr />
          <div className="text-center">
            <h5 className="text-danger">Payment Failed. Please try again.</h5>
          </div>
          <hr />
        </div>
      )}

      {/* <!-- row --> */}
      <div className="row row-sm">
        <div className="col-lg-4 col-xxl-4">
          <Card className="custom-card mb-0">
            <div className="card-body text-center py-3 py-3 bg-light border border-primary">
              <h2 className="main-content-title tx-24 mg-b-0 text-primary">
                View Summary
              </h2>
            </div>
            <div className="card custom-card cart-details summbox mb-0">
              <Card.Body>
                <h5 className="mb-3 font-weight-bold tx-14 tx-uppercase">
                  Athlete DETAILs{" "}
                  <span className="float-end">Amount (INR)</span>
                </h5>
                <dl className="dlist-align">
                  <dt className="">
                    Number of Men <code>({cmen})</code>
                  </dt>
                  <dd className="text-end ms-auto">{mamt}</dd>
                </dl>
                <dl className="dlist-align">
                  <dt>
                    Number of Women <code>({cwomen})</code>
                  </dt>
                  <dd className="text-end ms-auto">{wamt}</dd>
                  {/* text-danger  */}
                </dl>
                <dl className="dlist-align">
                  <dt>
                    Relay Team <code>({trcnt})</code>
                  </dt>
                  <dd className="text-end ms-auto">{tramt}</dd>
                </dl>

                {/* <dl className="dlist-align">
                  <dt>
                    Relay Team - Men <code>({rmen})</code>
                  </dt>
                  <dd className="text-end ms-auto">{rmamt}</dd>
                </dl>
                <dl className="dlist-align">
                  <dt>
                    Relay Team - Women <code>({rwomen})</code>
                  </dt>
                  <dd className="text-end ms-auto">{rwamt}</dd>
                </dl> */}
                <hr />
                <dl className="dlist-align">
                  <dt>
                    Total {gateway}: <code>({tcnt})</code>
                  </dt>
                  <dd className="text-end text-danger ms-auto">
                    <strong>{tamt}</strong>
                  </dd>
                </dl>
                <div className="step-footer">
                  {free === "N" && paystatus !== "paid" && (
                    <a
                      onClick={paysubmit}
                      className="btn btn-success btn-block"
                    >
                      Submit &amp; Pay
                    </a>
                  )}

                  <div className="text-center">
                    <span>
                      * Once you submit and paid the amount for the entries,
                      entries cannot be added/edited
                    </span>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Card>
        </div>
        <div className="col-lg-8 col-xxl-8 selectedathletes">
          <div className="text-dark mb-0 ms-1 tx-20 font-weight-semibold">
            All Selected Athletes
          </div>
          <hr className="my-1" />
          {errorMessage !== "" && (
            <span className="text-danger">{errorMessage}</span>
          )}
          {/* <!-- Row --> */}
          {/* <div className="text-warning mb-1 mt-2 tx-16 ">Men Athletes</div> */}
          <Row>
            {athlete.map((data, i) => {
              return (
                <Col xxl={6} lg={6} md={6} key={i}>
                  <Card className="custom-card">
                    <Card.Body className="athsummbox p-2">
                      <div className="d-flex">
                        <div className="media align-items-center pos-relative w-100">
                          <span className="tx-16 my-auto">
                            <img
                              alt="media11"
                              src={
                                imageUrl +
                                fileString.athletephoto +
                                data.photograph
                              }
                              className="athsummbox-img"
                              onError={(e) => errorhandle(e)}
                            />
                          </span>
                          <div className="media-body ms-3">
                            {data.middle_name !== null && (
                              <h6 className="abname">
                                {data.first_name} {data.middle_name}{" "}
                                {data.last_name}
                              </h6>
                            )}
                            {data.middle_name === null && (
                              <h6 className="abname">
                                {data.first_name} {data.last_name}
                              </h6>
                            )}

                            <h6 className="abuid">
                              <span>UID - </span>
                              {data.username}
                            </h6>
                            <h6 className="abdob">
                              <span>DOB - </span>
                              {moment(data.date_of_birth).format("DD-MM-YYYY")}
                            </h6>
                            <h6 className="abdob">
                              <span>{data.age_category}</span>
                            </h6>
                            <h6 className="abdob">
                              <span>{data.events}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Districtsummary;
