import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  stateadd,
  statelist,
  statestatus,
  statevalidate,
  stateview,
} from "../../../services/master";

const States = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [status, setStatus] = useState("1");
  const [id, setID] = useState(0);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("S");
  const [RSecret, setRSecret] = useState("");
  const [RKey, setRKey] = useState("");
  const [TRSecret, setTRSecret] = useState("");
  const [TRKey, setTRKey] = useState("");
  const [CCMerid, setCCMerid] = useState("");
  const [errorage, setAgeMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function Selectstatus(event) {
    setStatus(event.target.value);
  }

  function Selecttype(event) {
    setType(event.target.value);
  }

  var arr = [];
  useEffect(() => {
    funlist();
  }, []);

  function funlist() {
    setFilterProduct([]);
    statelist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "State",
      width: 520,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State Code",
      width: 150,
      field: "statecode",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "isactive",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => handleSwitch(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },

    {
      headerName: "Actions",
      width: 180,
      field: "stateuid",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewshow(params.value)}
              className="btn-sm btn-info"
            >
              <i className="fa fa-edit"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function handleSwitch(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.stateuid);
          temp.append("active", 0);

          statestatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.stateuid);
          temp.append("active", 1);

          statestatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    }
  }

  const [show, setShow] = useState(false);
  function handleshow() {
    setID(0);
    setName("");
    setCode("");
    setType("S");
    setRSecret("");
    setRKey("");
    setTRSecret("");
    setTRKey("");
    setCCMerid("");
    setStatus("1");
    setShow(true);
    setAgeMessage("");
    setErrorMessage("");
    setStatus("1");
  }

  function handleclose() {
    setID(0);
    setShow(false);
  }

  const [vshow, setVShow] = useState(false);
  function viewshow(vid) {
    setID(vid);
    setAgeMessage("");
    setErrorMessage("");
    setVShow(true);
    stateview(vid).then((response) => {
      if (response.status === true) {
        setName(response.data.statename);
        setCode(response.data.statecode);
        setType(response.data.type);
        setRSecret(response.data.razorpaysecret);
        setRKey(response.data.razorpaykey);
        setTRSecret(response.data.razorpaysecrettest);
        setTRKey(response.data.razorpaykeytest);
        setCCMerid(response.data.ccmerchantid);
        setStatus(response.data.isactive + "");
      }
    });
  }

  function viewclose() {
    setID(0);
    setVShow(false);
  }

  function validate(event) {
    setAgeMessage("");
    setErrorMessage("");
    const temp = new FormData();
    temp.append("state", event.target.value);
    statevalidate(temp).then((response) => {
      if (response.status === false) {
        setAgeMessage(response.message);
        setErrorMessage(response.message);
      }
    });
  }

  function Submitage() {
    setErrorMessage("");

    if (name !== "") {
      if (code !== "") {
        const tempData = new FormData();
        tempData.append("stateuid", id);
        tempData.append("statename", name);
        tempData.append("statecode", code);
        tempData.append("type", type);
        tempData.append("razorpaysecret", RSecret);
        tempData.append("razorpaykey", RKey);
        tempData.append("razorpaysecrettest", TRSecret);
        tempData.append("razorpaykeytest", TRKey);
        tempData.append("ccmerchantid", CCMerid);
        tempData.append("isactive", status);

        stateadd(tempData).then((response) => {
          if (response.status === true) {
            if (id === 0) {
              handleclose();
            } else {
              viewclose();
            }
            funlist();
            toast.success(response.message);
          } else {
            setErrorMessage(response.data.message);
          }
        });
      } else {
        setErrorMessage("Please Enter Your State Code");
      }
    } else {
      setErrorMessage("Please Enter Your State Name");
    }
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">State List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    onClick={handleshow}
                    variant="success"
                    type="button"
                    className="btn my-1 me-2 btn-success tx-teko"
                  >
                    {" "}
                    Add
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}

                  />
                </div>
              </div>

              <Modal show={show}>
                <Modal.Header>
                  <h4>Add State</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Name <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your State Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={validate}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Code <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your State Code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Type <span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div className="row" onChange={Selecttype}>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="S"
                              checked={type === "S"}
                            />
                            States
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="U"
                              checked={type === "U"}
                            />
                            Union Territory
                          </label>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Secret</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Secret"
                          value={RSecret}
                          onChange={(e) => setRSecret(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Key</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Key"
                          value={RKey}
                          onChange={(e) => setRKey(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Secret Test</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Secret Test"
                          value={TRSecret}
                          onChange={(e) => setTRSecret(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Key Test</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Key Test"
                          value={TRKey}
                          onChange={(e) => setTRKey(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">CC Merchant ID</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your CC Merchant ID"
                          value={CCMerid}
                          onChange={(e) => setCCMerid(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div className="row" onChange={Selectstatus}>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="1"
                              checked={status === "1"}
                            />
                            Active
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="0"
                              checked={status === "0"}
                            />
                            Inactive
                          </label>
                        </div>
                      </div>
                    </Col>
                    {errorMessage !== "" && (
                      <span className="text-danger">{errorMessage}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={vshow}>
                <Modal.Header>
                  <h4>View State</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Name <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your State Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Code <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your State Code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Type <span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div className="row" onChange={Selecttype}>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="S"
                              checked={type === "S"}
                            />
                            States
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="U"
                              checked={type === "U"}
                            />
                            Union Territory
                          </label>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Secret</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Secret"
                          value={RSecret}
                          onChange={(e) => setRSecret(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Key</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Key"
                          value={RKey}
                          onChange={(e) => setRKey(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Secret Test</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Secret Test"
                          value={TRSecret}
                          onChange={(e) => setTRSecret(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">Razorpay Key Test</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your Razorpay Key Test"
                          value={TRKey}
                          onChange={(e) => setTRKey(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">CC Merchant ID</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your CC Merchant ID"
                          value={CCMerid}
                          onChange={(e) => setCCMerid(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div className="row" onChange={Selectstatus}>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="1"
                              checked={status === "1"}
                            />
                            Active
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="0"
                              checked={status === "0"}
                            />
                            Inactive
                          </label>
                        </div>
                      </div>
                    </Col>
                    {errorMessage !== "" && (
                      <span className="text-danger">{errorMessage}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={viewclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default States;
