import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { statelist } from "../../../services/registerservice";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addmeetentry,
  meetagecategory,
  meetentrylist,
  meetentrytypelist,
  meetinfo,
  meettypelist,
  updatemeetentry,
} from "../../../services/meetservice";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

const Addmeet = () => {
  const [meetname, setMeetname] = useState("");
  const [meetloc, setMeetLoc] = useState("");
  const [meetcode, setMeetCode] = useState("");
  const [meettype, setMeetType] = useState("");
  const [tlist, setMeetTypeList] = useState([]);
  const [mtype, setType] = useState("");
  const [mttype, setMTType] = useState("");

  const [mtlist, setTypeList] = useState([]);

  const [etype, setEType] = useState("");
  const [etlist, setETypeList] = useState([]);

  const [slist, setStatelist] = useState([]);
  const [svalue, setState] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [ddate, setDDate] = useState("");
  const [odate, setODate] = useState("");

  const [rfees, setRelayFees] = useState("");
  const [sdobdate, setSDOBDate] = useState("");
  const [edobdate, setEDOBDate] = useState("");
  const [gatech, setGateway] = useState("N");
  const [mevent, setMulti] = useState("N");

  const [addentry, setAdditional] = useState("N");
  const [tour, setFree] = useState("N");
  const [headfee, setHeadFee] = useState("N");
  const [oage, setOverAge] = useState("N");

  const [meetcat, setMeetCat] = useState([]);
  const [catlist, setMeetCatList] = useState([]);
  const [payst, setStatus] = useState("Razor Pay");
  const [proof1st, setProof1] = useState(false);
  const [proof2st, setProof2] = useState(false);

  const [cirst, setCircular] = useState("0");
  const [cirlink, setCirLink] = useState("");

  const [cfile1, setCirFile1] = useState("");
  const [cfile2, setCirFile2] = useState("");
  const [cfile3, setCirFile3] = useState("");
  const [cfile4, setCirFile4] = useState("");
  const [cfile5, setCirFile5] = useState("");

  const [cerror1, setCirError1] = useState("");
  const [cerror2, setCirError2] = useState("");
  const [cerror3, setCirError3] = useState("");
  const [cerror4, setCirError4] = useState("");
  const [cerror5, setCirError5] = useState("");

  function selecttype(e) {
    setEType("");
    if (mtlist.length !== 0) {
      for (let i = 0; i < mtlist.length; i++) {
        if (mtlist[i].id + "" === e.target.value) {
          setMTType(mtlist[i].tournament_type_name);
          break;
        }
      }
    }
    setType(e.target.value);
    meetentrytypelist(e.target.value).then((response) => {
      if (response.status === true) {
        setETypeList(response.data);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }
  // function checkedate(e) {
  //   setErrorMessage("");
  //   setEDate("");
  //   if (sdate !== "") {
  //     var start = moment(sdate).format("DD-MM-YYYY");
  //     var end = moment(e.target.value).format("DD-MM-YYYY");

  //     if (end < start) {
  //       setErrorMessage("End Date must be greater than Start Date");
  //     } else {
  //       setEDate(e.target.value);
  //     }
  //   } else {
  //     setErrorMessage("Please Select Your Start Date");
  //   }
  // }

  // function checkddate(e) {
  //   setErrorMessage("");
  //   setDDate("");
  //   if (edate !== "") {
  //     var start = moment(edate).format("DD-MM-YYYY");
  //     var end = moment(e.target.value).format("DD-MM-YYYY");
  //     if (end > start) {
  //       setErrorMessage("Deadline Date must be less than End Date");
  //     } else {
  //       setDDate(e.target.value);
  //     }
  //   } else {
  //     setErrorMessage("Please Select Your End Date");
  //   }
  // }

  // function selectedobdate(e) {
  //   setErrorMessage("");
  //   setEDOBDate("");
  //   if (sdobdate !== "") {
  //     var start = moment(sdobdate).format("DD-MM-YYYY");
  //     var end = moment(e.target.value).format("DD-MM-YYYY");

  //     if (end > start) {
  //       setErrorMessage("End DOB Date must be greater than Start DOB Date");
  //     } else {
  //       setEDOBDate(e.target.value);
  //     }
  //   } else {
  //     setErrorMessage("Please Select Your Start DOB Date");
  //   }
  // }

  function Selectproof1(e) {
    setProof1(e.target.checked);
  }
  function Selectproof2(e) {
    setProof2(e.target.checked);
  }

  function Selectcir(e) {
    setCircular(e.target.value);
  }
  function handleChange(e) {
    setMeetCat(e);
  }
  function Selectstate(e) {
    setState(e.target.value);
  }

  const [count, setCount] = useState(1);
  function addmulti() {
    if (count < 5) {
      setCount(count + 1);
    }
  }

  function addremove() {
    setCount(count - 1);
    if (count == 4) {
      setCirFile5("");
    } else if (count == 3) {
      setCirFile4("");
      setCirFile5("");
    } else if (count == 2) {
      setCirFile3("");
      setCirFile4("");
      setCirFile5("");
    }
  }

  function changefile(e, index) {
    e.preventDefault();
    if (index == 1) {
      setCirFile1();
      setCirError1("");
    } else if (index == 2) {
      setCirFile2();
      setCirError2("");
    } else if (index == 3) {
      setCirFile3();
      setCirError3("");
    } else if (index == 4) {
      setCirFile4();
      setCirError4("");
    } else if (index == 5) {
      setCirFile5();
      setCirError5("");
    }
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(pdf)$/i)) {
        if (index == 1) {
          setCirFile1(e.target.files[0]);
        } else if (index == 2) {
          setCirFile2(e.target.files[0]);
        } else if (index == 3) {
          setCirFile3(e.target.files[0]);
        } else if (index == 4) {
          setCirFile4(e.target.files[0]);
        } else if (index == 5) {
          setCirFile5(e.target.files[0]);
        }
      } else {
        if (index == 1) {
          setCirError1("Circular 1 should be pdf format");
        } else if (index == 2) {
          setCirError2("Circular 2 should be pdf format");
        } else if (index == 3) {
          setCirError3("Circular 3 should be pdf format");
        } else if (index == 4) {
          setCirError4("Circular 4 should be pdf format");
        } else if (index == 5) {
          setCirError5("Circular 5 should be pdf format");
        }
      }
      var size = e.target.files[0].size / 1024 / 1024;

      if (size > 3) {
        if (index == 1) {
          setCirError1("Circular size should be 3MB");
        } else if (index == 2) {
          setCirError2("Circular size should be 3MB");
        } else if (index == 3) {
          setCirError3("Circular size should be 3MB");
        } else if (index == 4) {
          setCirError4("Circular size should be 3MB");
        } else if (index == 5) {
          setCirError5("Circular size should be 3MB");
        }
      }
    }
  }

  const [formLogo, setLogoFile] = useState("");
  const [formLogoupload, setLogoUpload] = useState("");
  const [formLogoerror, setLogoErrors] = useState("");

  function logofile(e) {
    e.preventDefault();
    setLogoErrors("");
    setLogoUpload("");
    setLogoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setLogoUpload(URL.createObjectURL(e.target.files[0]));
        setLogoFile(e.target.files[0]);
      } else {
        setLogoErrors("Logo should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setLogoErrors("Logo size should be less than 3MB");
      }
    }
  }

  var arr = [];
  const [curdate, setCurdate] = useState("");
  const [linkurl, setLink] = useState("");
  const { id } = useParams();
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setLink(link[1]);
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });

    meetagecategory().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].age_category_name,
            });
          }
        }
      }
      setMeetCatList(arr);
    });
    meettypelist().then((response) => {
      if (response.status === true) {
        setMeetTypeList(response.data);
      }
    });

    meetentrylist().then((response) => {
      if (response.status === true) {
        setTypeList(response.data);
      }
    });

    if (eid === "meeteditbasicinformation") {
      meetinfo(id).then((response) => {

        setMeetname(response.data.tournament.meet_name);
        setMeetLoc(response.data.tournament.location);
        setMeetCode(response.data.tournament.meet_code);
        setMeetType(response.data.tournament.meet_type);
        setState(response.data.tournament.state);
        setSDate(response.data.tournament.start_date);
        setEDate(response.data.tournament.end_date);

        setODate(response.data.tournament.entry_opens);
        setDDate(response.data.tournament.entry_deadline_date);
        setType(response.data.tournament.type);
        setMTType(response.data.tournament.type);
        setEType(response.data.tournament.entry_type);
        setSDOBDate(response.data.tournament.dob_start_date);
        setEDOBDate(response.data.tournament.dob_end_date);
        if (response.data.tournament.relay_fees === null) {
          setRelayFees(0);
        } else {
          setRelayFees(response.data.tournament.relay_fees);
        }
        setGateway(response.data.tournament.gateway_charges);
        setAdditional(response.data.tournament.allow_additional_entry);
        setFree(response.data.tournament.free_tournament);
        setHeadFee(response.data.tournament.per_head_fees);
        setOverAge(response.data.tournament.overage_applied_athletes);
        setMulti(response.data.tournament.multiple_events_allowed_flag);
        setStatus(response.data.tournament.payment_gateway);
        if (response.data.tournament.event_proof_one_req === "Y") {
          setProof1(true);
        } else {
          setProof1(false);
        }
        if (response.data.tournament.event_proof_two_req === "Y") {
          setProof2(true);
        } else {
          setProof2(false);
        }
        setLogoFile(imageUrl + fileString.tournamentfiles + response.data.tournament.logo);
        setCirFile1(response.data.tournament.circular_file_1);
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCirFile2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCirFile3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCirFile4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCirFile5(response.data.tournament.circular_file_5);
          }
        }
        if (response.data.tournament.type === "National Meet") {
          meetentrytypelist(1).then((res) => {
            if (res.status === true) {
              setETypeList(res.data);
            }
          });
        } else if (response.data.tournament.type === "State Entry") {
          meetentrytypelist(2).then((res) => {
            if (res.status === true) {
              setETypeList(res.data);
            }
          });
        }
      });
    }
  }, []);

  function updatemeet(e) {
    e.preventDefault();
    setErrorMessage("");
    if (meetname !== "") {
      if (meetloc !== "") {
        if (meetcode !== "") {
          if (meettype !== "") {
            if (svalue !== "") {
              if (sdate !== "") {
                if (edate !== "") {
                  if (ddate !== "") {
                    if (mttype !== "") {
                      if (etype !== "") {
                        if (sdobdate !== "") {
                          if (edobdate !== "") {
                            const tempData = new FormData();
                            tempData.append("meet_id", id);

                            tempData.append("meet_name", meetname);
                            tempData.append("location", meetloc);
                            tempData.append("meet_code", meetcode);
                            tempData.append("meet_type", meettype);
                            tempData.append("state", svalue);
                            tempData.append("start_date", sdate);
                            tempData.append("end_date", edate);
                            tempData.append("entry_deadline_date", ddate);
                            tempData.append("entry_opens", odate);
                            tempData.append("type", mttype);
                            tempData.append("entry_type", etype);
                            tempData.append("dob_start_date", sdobdate);
                            tempData.append("dob_end_date", edobdate);
                            tempData.append("gateway_charges", gatech);
                            tempData.append("allow_additional_entry", addentry);
                            tempData.append("free_tournament", tour);
                            tempData.append("per_head_fees", headfee);
                            tempData.append("relay_fees", rfees);
                            tempData.append("overage_applied_athletes", oage);
                            tempData.append(
                              "multiple_events_allowed_flag",
                              mevent
                            );
                            if (proof1st === true) {
                              tempData.append("event_proof_one_req", "Y");
                            } else {
                              tempData.append("event_proof_one_req", "N");
                            }
                            if (proof2st === true) {
                              tempData.append("event_proof_two_req", "Y");
                            } else {
                              tempData.append("event_proof_two_req", "N");
                            }
                            tempData.append("payment_gateway", payst);

                            updatemeetentry(tempData).then((response) => {
                              if (response.status === true) {
                                navigate("/superadmin/viewmeet/" + id);
                              }
                            });
                          } else {
                            setErrorMessage("Please Enter Your DOB End Date");
                          }
                        } else {
                          setErrorMessage("Please Enter Your DOB Start Date");
                        }
                      } else {
                        setErrorMessage("Please Select Your Entry Type");
                      }
                    } else {
                      setErrorMessage("Please Select Your Type");
                    }
                    // } else {
                    //   setErrorMessage(
                    //     "Deadline Date must be less than End Date"
                    //   );
                    // }
                  } else {
                    setErrorMessage("Please Select Your Deadline Date");
                  }
                  // } else {
                  //   setErrorMessage("End Date must be greater than Start Date");
                  // }
                } else {
                  setErrorMessage("Please Select Your End Date");
                }
              } else {
                setErrorMessage("Please Select Your Start Date");
              }
            } else {
              setErrorMessage("Please Select Your State");
            }
          } else {
            setErrorMessage("Please Enter Your Meet Type");
          }
        } else {
          setErrorMessage("Please Enter Your Meet Code");
        }
      } else {
        setErrorMessage("Please Enter Your Meet Location");
      }
    } else {
      setErrorMessage("Please Enter Your Meet Name");
    }
  }

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  function regmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    if (meetname !== "") {
      if (meetloc !== "") {
        if (meetcode !== "") {
          if (meettype !== "") {
            if (svalue !== "") {
              if (sdate !== "") {
                if (edate !== "") {
                  // if (edate >= sdate) {
                  if (ddate !== "") {
                    // if (ddate < edate) {
                    if (mttype !== "") {
                      if (etype !== "") {
                        if (sdobdate !== "") {
                          if (edobdate !== "") {
                            var catarray = "";
                            if (meetcat.length !== 0) {
                              for (let i = 0; i < meetcat.length; i++) {
                                if (catarray === "") {
                                  catarray = meetcat[i].label;
                                } else {
                                  catarray = catarray + "," + meetcat[i].label;
                                }
                              }
                            }
                            if (catarray !== "") {
                              var cir = "";
                              // if (cirst === "0") {
                              //   if (cirlink === "") {
                              //     cir = "Please Enter Your Circular Link";
                              //     setErrorMessage(
                              //       "Please Enter Your Circular Link"
                              //     );
                              //   }
                              // } else {
                              if (cfile1 === "") {
                                cir = "Please Select Your Circular File";
                                setErrorMessage(
                                  "Please Select Your Circular File"
                                );
                              }
                              // }

                              if (cir === "") {
                                if (formLogo !== "") {
                                  if (formLogoerror === "") {
                                    const tempData = new FormData();
                                    tempData.append("meet_name", meetname);
                                    tempData.append("location", meetloc);
                                    tempData.append("meet_code", meetcode);
                                    tempData.append("meet_type", meettype);
                                    tempData.append("state", svalue);
                                    tempData.append("start_date", sdate);
                                    tempData.append("end_date", edate);
                                    tempData.append(
                                      "entry_deadline_date",
                                      ddate
                                    );
                                    tempData.append("entry_opens", odate);
                                    tempData.append("type", mttype);
                                    tempData.append("entry_type", etype);
                                    tempData.append("dob_start_date", sdobdate);
                                    tempData.append("dob_end_date", edobdate);
                                    tempData.append("gateway_charges", gatech);
                                    tempData.append(
                                      "allow_additional_entry",
                                      addentry
                                    );
                                    tempData.append("free_tournament", tour);
                                    tempData.append("per_head_fees", headfee);
                                    tempData.append("relay_fees", rfees);
                                    tempData.append(
                                      "overage_applied_athletes",
                                      oage
                                    );
                                    tempData.append(
                                      "multiple_events_allowed_flag",
                                      mevent
                                    );
                                    tempData.append("categories", catarray);
                                    if (proof1st === true) {
                                      tempData.append(
                                        "event_proof_one_req",
                                        "Y"
                                      );
                                    } else {
                                      tempData.append(
                                        "event_proof_one_req",
                                        "N"
                                      );
                                    }
                                    if (proof2st === true) {
                                      tempData.append(
                                        "event_proof_two_req",
                                        "Y"
                                      );
                                    } else {
                                      tempData.append(
                                        "event_proof_two_req",
                                        "N"
                                      );
                                    }
                                    tempData.append("payment_gateway", payst);
                                    // if(cirst === "0"){
                                    //   tempData.append('circular_sheet_type','link');
                                    // }else if(cirst === "1"){
                                    //   tempData.append('circular_sheet_type','file');
                                    // }

                                    // tempData.append('circular_sheet',cirlink);

                                    tempData.append("circular_file_1", cfile1);
                                    tempData.append("circular_file_2", cfile2);
                                    tempData.append("circular_file_3", cfile3);
                                    tempData.append("circular_file_4", cfile4);
                                    tempData.append("circular_file_5", cfile5);
                                    tempData.append("logo", formLogo);

                                    addmeetentry(tempData).then((response) => {
                                      if (response.status === true) {
                                        navigate(
                                          "/superadmin/addtournament/" +
                                          response.data.tournament.id
                                        );
                                      }
                                    });
                                  }
                                } else {
                                  setErrorMessage("Please Select Your Logo");
                                }
                              }
                            } else {
                              setErrorMessage("Please Select Your Categories");
                            }
                            // } else {
                            //   setErrorMessage(
                            //     "End DOB Date must be greater than Start DOB Date"
                            //   );
                            // }
                          } else {
                            setErrorMessage("Please Enter Your DOB End Date");
                          }
                        } else {
                          setErrorMessage("Please Enter Your DOB Start Date");
                        }
                      } else {
                        setErrorMessage("Please Select Your Entry Type");
                      }
                    } else {
                      setErrorMessage("Please Select Your Type");
                    }
                    // } else {
                    //   setErrorMessage(
                    //     "Deadline Date must be less than End Date"
                    //   );
                    // }
                  } else {
                    setErrorMessage("Please Select Your Deadline Date");
                  }
                  // } else {
                  //   setErrorMessage("End Date must be greater than Start Date");
                  // }
                } else {
                  setErrorMessage("Please Select Your End Date");
                }
              } else {
                setErrorMessage("Please Select Your Start Date");
              }
            } else {
              setErrorMessage("Please Select Your State");
            }
          } else {
            setErrorMessage("Please Enter Your Meet Type");
          }
        } else {
          setErrorMessage("Please Enter Your Meet Code");
        }
      } else {
        setErrorMessage("Please Enter Your Meet Location");
      }
    } else {
      setErrorMessage("Please Enter Your Meet Name");
    }
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          {linkurl === "addmeet" && (
            <h2 className="main-content-title tx-24 mg-b-5">Add Meet</h2>
          )}
          {linkurl !== "addmeet" && (
            <h2 className="main-content-title tx-24 mg-b-5">Edit Meet</h2>
          )}
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Meet Name <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Meet Name"
                            value={meetname}
                            onChange={(e) => {
                              setMeetname(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Meet Location <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Meet Location"
                            value={meetloc}
                            onChange={(e) => {
                              setMeetLoc(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Meet Code <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Meet Code"
                            value={meetcode}
                            onChange={(e) => {
                              setMeetCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Meet Type <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={meettype}
                            onChange={(e) => {
                              setMeetType(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Meet Type
                            </option>
                            {tlist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.event_type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            State <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select value={svalue} onChange={Selectstate}>
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Start Date <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your Start Date"
                            value={sdate}
                            onChange={(e) => {
                              setSDate(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            End Date <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your End Date"
                            min={sdate}
                            value={edate}
                            onChange={(e) => {
                              setEDate(e.target.value);
                            }}
                            // onChange={checkedate}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Entry Open Date{" "}
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your Open Date"
                            min={curdate}
                            max={edate}
                            value={odate}
                            onChange={(e) => {
                              setODate(e.target.value);
                            }}
                            // onChange={checkddate}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Deadline Date{" "}
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your Deadline Date"
                            min={curdate}
                            max={edate}
                            value={ddate}
                            onChange={(e) => {
                              setDDate(e.target.value);
                            }}
                            // onChange={checkddate}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Type <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select value={mtype} onChange={selecttype}>
                            <option value="" disabled>
                              Select Your Type
                            </option>
                            {mtlist.map((data, i) => {
                              return (
                                <option value={data.id} key={i}>
                                  {data.tournament_type_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Entry Type <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={etype}
                            onChange={(e) => {
                              setEType(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Entry Type
                            </option>
                            {etlist.map((data, i) => {
                              return (
                                <option
                                  value={data.tournament_type_category_name}
                                  key={i}
                                >
                                  {data.tournament_type_category_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            DOB Start Date{" "}
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your DOB Start Date"
                            value={sdobdate}
                            onChange={(e) => {
                              setSDOBDate(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            DOB End Date <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your DOB End Date"
                            min={sdobdate}
                            value={edobdate}
                            onChange={(e) => {
                              setEDOBDate(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Relay Fees
                            {/* <span className="text-danger">*</span> */}
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Relay Fees"
                            value={rfees}
                            onChange={(e) => {
                              setRelayFees(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gateway Charges</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gatech}
                            onChange={(e) => {
                              setGateway(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Gateway Charges
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Allow Additional Entry</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={addentry}
                            onChange={(e) => {
                              setAdditional(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Additional Entry
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Free Tournament</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={tour}
                            onChange={(e) => {
                              setFree(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Free Tournament
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Per Head Fees</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={headfee}
                            onChange={(e) => {
                              setHeadFee(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Per Head Fees
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Over Age Applied</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={oage}
                            onChange={(e) => {
                              setOverAge(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Over Age
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {linkurl === "addmeet" && (
                    <div className="col-md-6">
                      <div className="add-meet">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">
                              Categories <span className="text-danger">*</span>
                            </b>
                          </div>
                          <div className="col-md-8">
                            <Select
                              className="dropdown"
                              placeholder="Select Option"
                              value={meetcat}
                              options={catlist}
                              onChange={handleChange}
                              isMulti
                              isClearable
                              hidden
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Multiple Events Allowed
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={mevent}
                            onChange={(e) => {
                              setMulti(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Events Allowed
                            </option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Payment Gateway{" "}
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={payst}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Payment Gateway
                            </option>
                            <option value="Razor Pay">Razor Pay</option>
                            <option value="CC Avenue">CC Avenue</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-2">
                          <b className="text-inverse">
                            Event Proof
                            {/* <span className="text-danger">*</span> */}
                          </b>
                        </div>
                        <div className="col-md-10">
                          <div className="checkbox checkbox-1">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="checkbox"
                                  className="reg-chk"
                                  checked={proof1st}
                                  onChange={Selectproof1}
                                />
                                <label>
                                  Click if event proof 1 is mandatory
                                </label>
                              </div>

                              {/* <div className="col-md-6">
                                <input
                                  type="checkbox"
                                  style={{ marginTop: "15px" }}
                                  className="reg-chk"
                                  checked={proof2st}
                                  onChange={Selectproof2}
                                />
                                <label>
                                  Click if event proof 2 is mandatory
                                </label>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="add-meet">
                      <div className="row">
                        <div className="col-md-2">
                          <b className="text-inverse">
                            Circular Sheet as
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-10">
                          <div className="checkbox checkbox-1">
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  style={{ marginTop: "15px" }}
                                  className="reg-chk"
                                  name="circular"
                                  value="0"
                                  checked={cirst === "0"}
                                  onChange={Selectcir}
                                />
                                <label>Link</label>
                              </div>

                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  style={{ marginTop: "15px" }}
                                  className="reg-chk"
                                  name="circular"
                                  value="1"
                                  checked={cirst === "1"}
                                  onChange={Selectcir}
                                />
                                <label>File</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {cirst === "0" && (
                    <div className="col-md-12">
                      <div className="add-meet">
                        <div className="row">
                          <div className="col-md-2">
                            <b className="text-inverse">
                              Circular Link
                              <span className="text-danger">*</span>
                            </b>
                          </div>
                          <div className="col-md-10">
                            <input
                              type="text"
                              placeholder="Enter Your Circular Link"
                              value={cirlink}
                              onChange={(e) => {
                                setCirLink(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {cirst === "1" && ( */}
                  {linkurl === "addmeet" && (
                    <div className="col-md-12">
                      <div className="circular-update">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="input-group form-upload">
                              <div className="row">
                                <div className="col-md-9">
                                  <b className="text-inverse">
                                    Circular
                                    <span className="text-danger">*</span>
                                  </b>
                                  <div className="mt-3">
                                    <input
                                      type="file"
                                      name="file"
                                      id="cirphoto"
                                      onChange={(e) => changefile(e, 1)}
                                      accept=".pdf"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="imagecontainer">
                                    <img
                                      src={require("../../../assets/images/pdf.png")}
                                      width="100%"
                                      height="auto"
                                    />
                                  </div>
                                </div>
                              </div>
                              {cerror1 !== "" && (
                                <span className="errortxt text-danger">
                                  {cerror1}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="reg">
                              <a
                                className="btn btn-next btn-success"
                                style={{ marginTop: "50px" }}
                                onClick={addmulti}
                              >
                                Add
                              </a>
                            </div>
                          </div>
                        </div>

                        {count >= 2 && (
                          <div className="row">
                            <div className="col-md-10">
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-9">
                                    <b className="text-inverse">
                                      Circular
                                      <span className="text-danger">*</span>
                                    </b>
                                    <div className="mt-3">
                                      <input
                                        type="file"
                                        name="file"
                                        id="cirphoto"
                                        accept=".pdf"
                                        onChange={(e) => changefile(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="imagecontainer">
                                      <img
                                        src={require("../../../assets/images/pdf.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {cerror2 !== "" && (
                                  <span className="errortxt text-danger">
                                    {cerror2}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="reg">
                                {count == 2 && (
                                  <a
                                    className="btn btn-next btn-danger"
                                    style={{ marginTop: "50px" }}
                                    onClick={addremove}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {count >= 3 && (
                          <div className="row">
                            <div className="col-md-10">
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-9">
                                    <b className="text-inverse">
                                      Circular
                                      <span className="text-danger">*</span>
                                    </b>
                                    <div className="mt-3">
                                      <input
                                        type="file"
                                        name="file"
                                        id="cirphoto"
                                        accept=".pdf"
                                        onChange={(e) => changefile(e, 3)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="imagecontainer">
                                      <img
                                        src={require("../../../assets/images/pdf.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {cerror3 !== "" && (
                                  <span className="errortxt text-danger">
                                    {cerror3}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="reg">
                                {count == 3 && (
                                  <a
                                    className="btn btn-next btn-danger"
                                    style={{ marginTop: "50px" }}
                                    onClick={addremove}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {count >= 4 && (
                          <div className="row">
                            <div className="col-md-10">
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-9">
                                    <b className="text-inverse">
                                      Circular
                                      <span className="text-danger">*</span>
                                    </b>
                                    <div className="mt-3">
                                      <input
                                        type="file"
                                        name="file"
                                        id="cirphoto"
                                        accept=".pdf"
                                        onChange={(e) => changefile(e, 4)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="imagecontainer">
                                      <img
                                        src={require("../../../assets/images/pdf.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {cerror4 !== "" && (
                                  <span className="errortxt text-danger">
                                    {cerror4}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="reg">
                                {count == 4 && (
                                  <a
                                    className="btn btn-next btn-danger"
                                    style={{ marginTop: "50px" }}
                                    onClick={addremove}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {count >= 5 && (
                          <div className="row">
                            <div className="col-md-10">
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-9">
                                    <b className="text-inverse">
                                      Circular
                                      <span className="text-danger">*</span>
                                    </b>
                                    <div className="mt-3">
                                      <input
                                        type="file"
                                        name="file"
                                        id="cirphoto"
                                        accept=".pdf"
                                        onChange={(e) => changefile(e, 5)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="imagecontainer">
                                      <img
                                        src={require("../../../assets/images/pdf.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {cerror5 !== "" && (
                                  <span className="errortxt text-danger">
                                    {cerror5}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="reg">
                                {count == 5 && (
                                  <a
                                    className="btn btn-next btn-danger"
                                    style={{ marginTop: "50px" }}
                                    onClick={addremove}
                                  >
                                    Remove
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {linkurl === "addmeet" && (
                  <div className="input-group form-upload">
                    <div className="col-md-9">
                      <b className="text-inverse">
                        Logo
                        <span className="text-danger">*</span>
                      </b>
                      <div className="mt-3">
                        <input
                          type="file"
                          name="file"
                          id="logo"
                          onChange={logofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="imagecontainer">
                        {formLogoupload !== "" && (
                          <img
                            src={formLogoupload}
                            width="100%"
                            height="auto"
                          />
                        )}

                        {formLogoupload === "" && (
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        )}
                      </div>
                    </div>

                    {formLogoerror !== "" && (
                      <span className="errortxt text-danger">
                        {formLogoerror}
                      </span>
                    )}
                  </div>
                )}
                {linkurl !== "addmeet" && (
                  <div className="row mx-0">
                    <div className="col-md-4 bpl-gap">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Logo</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={formLogo}
                            alt="photo"
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 bpl-gap">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Circular</b>
                        </div>
                        <div className="col-md-8">
                          <a
                            href={imageUrl + fileString.tournamentfiles + cfile1}
                            target={"_blank"}
                          >
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/pdf.png")}
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    {cfile2 !== "" && (
                      <div className="col-md-4 bpl-gap">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Circular 2</b>
                          </div>
                          <div className="col-md-8">
                            <a
                              href={imageUrl + fileString.tournamentfiles + cfile2}
                              target={"_blank"}
                            >
                              <img
                                className="profile_img"
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {cfile3 !== "" && (
                      <div className="col-md-4 bpl-gap">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Circular 3</b>
                          </div>
                          <div className="col-md-8">
                            <a
                              href={imageUrl + fileString.tournamentfiles + cfile3}
                              target={"_blank"}
                            >
                              <img
                                className="profile_img"
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    {cfile4 !== "" && (
                      <div className="col-md-4 bpl-gap">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Circular 4</b>
                          </div>
                          <div className="col-md-8">
                            <a
                              href={imageUrl + fileString.tournamentfiles + cfile4}
                              target={"_blank"}
                            >
                              <img
                                className="profile_img"
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    {cfile5 !== "" && (
                      <div className="col-md-4 bpl-gap">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Circular 5</b>
                          </div>
                          <div className="col-md-8">
                            <a
                              href={imageUrl + fileString.tournamentfiles + cfile5}
                              target={"_blank"}
                            >
                              <img
                                className="profile_img"
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <br />
                <br />
                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}
                <div className="reg">
                  {linkurl === "addmeet" && (
                    <a
                      className="btn btn-next btn-success"
                      id="next3"
                      style={{ float: "right" }}
                      onClick={regmeet}
                    >
                      Submit
                    </a>
                  )}

                  {linkurl !== "addmeet" && (
                    <>
                      <a
                        className="btn btn-next btn-success me-2"
                        id="next3"
                        style={{ float: "right" }}
                        onClick={updatemeet}
                      >
                        Update
                      </a>
                      <Link
                        className="btn btn-next btn-danger me-2"
                        id="next3"
                        style={{ float: "right" }}
                        to={"/superadmin/viewmeet/" + id}
                      >
                        Back
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Addmeet;
