import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Table, Button, Nav } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import imageUrl from "../../../../ImageUrl";
import { meetdetails } from "../../../../services/meetservice";
import "./state.css";
import StateHeading from "./stateheading";
import fileString from "../../../../assets/language/filepath.json";
import exportUrl from "../../../../Export";
import { useReactToPrint } from "react-to-print";
import {
  nidjamfederationaccountdetail,
  nidjamsummarylist,
} from "../../../../services/nidjamdistrict";

const NidjamSummary = () => {
  const { id } = useParams();
  const [alist, setAthList] = useState([]);
  const [olist, setOffList] = useState([]);
  const [arrlist, setArrList] = useState([]);
  const [deplist, setDepList] = useState([]);
  const [tname, setTourName] = useState("");

  const [accname, setAccName] = useState("");
  const [accno, setAccNo] = useState("");
  const [ifscode, setIFSC] = useState("");
  const [bname, setBName] = useState("");
  const [branch, setBranch] = useState("");
  const [formpass, setPassbook] = useState("");
  const [formcheque, setCheque] = useState("");

  useEffect(() => {
    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTourName(response.data.tournament.meet_name);
      }
    });

    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    nidjamsummarylist(tempData).then((response) => {
      if (response.status === true) {
        setAthList(response.data.athletes_list);
        setOffList(response.data.officials_list);
        setArrList(response.data.travel_arrival_details);
        setDepList(response.data.travel_departure_details);
      }
    });

    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjamfederationaccountdetail(temp).then((response) => {
      if (response.status === true && response.data.account_details !== null) {
        setAccName(response.data.account_details.account_name);
        setAccNo(response.data.account_details.account_number);
        setIFSC(response.data.account_details.ifsc_code);
        setBName(response.data.account_details.bank_name);
        setBranch(response.data.account_details.branch_name);
        setPassbook(
          response.data.s3_path + response.data.account_details.pass_book_image
        );
        if (response.data.account_details.cancelled_cheque_copy !== "-") {
          setCheque(
            response.data.s3_path +
              response.data.account_details.cancelled_cheque_copy
          );
        }
      }
    });
  }, []);
  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const columnDefs = [
    {
      headerName: "Arrival Date",
      width: 200,
      field: "dateof_arr",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Arrival Time",
      width: 200,
      field: "dateof_arr_time",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Arrival Details",
      width: 200,
      field: "arr_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 430,
      field: "remark",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDep = [
    {
      headerName: "Departure Date",
      width: 200,
      field: "dateof_dep",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Departure Time",
      width: 200,
      field: "dateof_dep_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Departure Details",
      width: 200,
      field: "dep_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 430,
      field: "dep_remarks",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const printRef = useRef();

  const downloadPDF = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Summary
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/district/selectioncommittee/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Selection Committee Mins
                  </Link>
                  <Link
                    to={"/district/sendentries/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Send Entries
                  </Link>
                  {/* <a
                    variant="success"
                    type="button"
                    className="btn my-1 btn-warning  me-2 tx-teko tx-uppercase"
                    onClick={() =>
                      window.open(
                        exportUrl +
                          "FederationEntryList/" +
                          id +
                          "/" +
                          localStorage.getItem("Userid"),
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Export Excel
                  </a> */}
                  <a
                    variant="success"
                    type="button"
                    className="btn my-1 btn-danger tx-teko tx-uppercase"
                    onClick={downloadPDF}
                  >
                    {" "}
                    Download PDF
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}
        <div ref={printRef}>
          <Row className="row-sm mx-0 border-top">
            <Col lg={12} md={12} xl={12} sm={12}>
              <div className="custom-card pt-3">
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-8">
                      <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                        Athletes Details
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <h2 className="main-content-title tx-20 mg-b-2 ps-4">
                        {new Date().toLocaleString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                        {" , ("}
                        {new Date().getTime()}
                        {")"}
                      </h2>
                    </div>
                  </div>
                </div>
                <Row className="row-sm mx-3">
                  {alist.map((data, i) => {
                    return (
                      <Col xl={3} lg={3} md={6} sm={6} key={i}>
                        <Card className="custom-card our-team">
                          <Card.Body className="px-3 pt-3 pb-0 bg-cusimg">
                            <div className="picture avatar-lg online text-center">
                              <img
                                alt="avatar"
                                className=""
                                src={
                                  imageUrl +
                                  fileString.athletephoto +
                                  data.photograph
                                }
                                onError={(e) => errorhandle(e)}
                              />
                            </div>
                            <div className="text-center mt-2">
                              <a>
                                {data.middle_name !== null && (
                                  <h5 className="pro-user-username text-dark mt-2 mb-0">
                                    {data.first_name} {data.middle_name}{" "}
                                    {data.last_name}
                                  </h5>
                                )}
                                {data.middle_name === null && (
                                  <h5 className="pro-user-username text-dark mt-2 mb-0">
                                    {data.first_name} {data.last_name}
                                  </h5>
                                )}
                              </a>
                              <p className="pro-user-desc text-danger mb-2">
                                ({data.username})
                              </p>
                              <Card.Body className="px-1 py-0 athlelst">
                                <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                                  <span>Date of Birth </span>{" "}
                                  {moment(data.date_of_birth).format(
                                    "DD-MM-YYYY"
                                  )}
                                </h5>
                                <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-18">
                                  <span>Category </span> {data.age_categories}
                                </h5>
                                <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18 border-top">
                                  <span>Events </span> {data.events}
                                  {data.age_categories.includes("Under 14") &&
                                    ",Kids Javelin Throw"}
                                </h5>
                              </Card.Body>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>

              <div className="custom-card pt-3">
                <div className="mb-3">
                  <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                    Officials Details
                  </h2>
                </div>
                <Row className="row-sm mx-3">
                  {olist.map((data, i) => {
                    return (
                      <Col xl={3} lg={3} md={6} sm={6} key={i}>
                        <Card className="custom-card our-team">
                          <Card.Body className="px-3 pt-3 pb-0 bg-cusimg">
                            <div className="p-1 border-bottom text-center">
                              <img
                                alt="avatar"
                                className="summaryimage"
                                src={
                                  imageUrl +
                                  fileString.stateofficial +
                                  data.photograph
                                }
                                onError={(e) => errorhandle(e)}
                              />
                              <h4 className="mb-1 tx-dark tx-24">
                                {data.first_name} {data.last_name}
                              </h4>
                            </div>
                            <div className="text-center mt-2">
                              <Card.Body className="px-1 py-0 athlelst">
                                <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                                  <span>Gender </span>
                                  {data.gender}
                                </h5>
                                <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-18">
                                  <span>Mobile </span> {data.phone}
                                </h5>
                                <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18 border-top">
                                  <span>Post </span> {data.post}
                                </h5>
                              </Card.Body>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="row-sm mx-0 bg-white border-top">
          <Col lg={12} md={12} xl={12} sm={12}>
            <div className="custom-card pt-3">
              <div className="mb-3">
                <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                  Arrival Travel Details
                </h2>
              </div>
              <Row className="row-sm mx-0 mt-3">
                <Col xxl={12} lg={12} md={12}>
                  <div className="table-responsive">
                    <div
                      className="ag-theme-alpine ag-style"
                      style={{
                        height: 300,
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={arrlist}
                        pagination="true"
                        paginationPageSize="10"
                        suppressDragLeaveHidesColumns={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="custom-card pt-3">
              <div className="mb-3">
                <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                  Departure Travel Details
                </h2>
              </div>
              <Row className="row-sm mx-0 mt-3">
                <Col xxl={12} lg={12} md={12}>
                  <div className="table-responsive">
                    <div
                      className="ag-theme-alpine ag-style"
                      style={{
                        height: 300,
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        columnDefs={columnDep}
                        rowData={deplist}
                        pagination="true"
                        paginationPageSize="10"
                        suppressDragLeaveHidesColumns={true}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="custom-card pt-3">
              <div className="mb-3">
                <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                  Account Details
                </h2>
              </div>
              <Row className="row-sm mx-0 mt-3">
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                      <span>Account Name </span>
                      {accname}
                    </h5>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                      <span>Account Number </span>
                      {accno}
                    </h5>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                      <span>IFSC Code </span>
                      {ifscode}
                    </h5>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                      <span>Bank Name </span>
                      {bname}
                    </h5>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                      <span>Branch </span>
                      {branch}
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row className="row-sm mx-0">
                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <Row>
                      <Col lg={6} md={6}>
                        <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                          <span>Passbook </span>
                        </h5>
                      </Col>
                      <Col lg={6} md={6}>
                        {formpass !== "" && (
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={formpass}
                          />
                        )}

                        {formpass === "" && (
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={require("../../../../assets/images/placeholder.png")}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="athlelst">
                    <Row>
                      <Col lg={6} md={6}>
                        <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                          <span>Cancelled Cheque Copy </span>
                        </h5>
                      </Col>
                      <Col lg={6} md={6}>
                        {formcheque !== "" && (
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={formcheque}
                          />
                        )}

                        {formcheque === "" && (
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={require("../../../../assets/images/placeholder.png")}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NidjamSummary;
