import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { overageathleteslist } from "../../../services/superadmin";
const OverageHistory = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  useEffect(() => {
    overageathleteslist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "State",
      width: 250,
      field: "state_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AFI UID",
      width: 150,
      field: "afi_uid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 250,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        // if (params.data.middle_name !== "" || params.data.middle_name !== null || params.data.middle_name !== undefined) {
        //   return params.data.first_name + " " + params.data.middle_name + " " + params.data.last_name;
        // } else {
          var name = "";
          if(params.data.first_name !== null){
            name = params.data.first_name
          }
          if(params.data.middle_name !== null){
            if(name !== ""){
              name = name + " " + params.data.middle_name
            }else{
              name = params.data.middle_name
            }
          }
          if(params.data.last_name !== null){
            if(name !== ""){
              name = name + " " + params.data.last_name
            }else{
              name = params.data.last_name
            }
          }
          return name
        // }
      },
    },
    {
      headerName: "Age Group",
      width: 150,
      field: "age_group",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "DOB",
    //   width: 150,
    //   field: "dob",
    //   sortable: true,
    //   resizable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: (data) => {
    //     return moment(data.value).format("DD-MM-YYYY");
    //   },
    // },
    {
      headerName: "Events",
      width: 150,
      field: "event_1",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = params.data.event_1;

        if (params.data.event_2 !== null) {
          if (evt !== "") {
            evt = evt + "," + params.data.event_2;
          }
        }
        if (params.data.event_3 !== null) {
          if (evt === "") {
            evt = params.data.event_3;
          } else if (evt !== "") {
            evt = evt + "," + params.data.event_3;
          }
        }
        return evt;
      },
    },
    {
      headerName: "Registered DOB",
      width: 150,
      field: "registered_dob",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if(data.value !== null){
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Meet Name",
      width: 350,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Over Age Athletes History
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default OverageHistory;
