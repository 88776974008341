import { Height } from "@material-ui/icons";
import { Padding } from "@mui/icons-material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import {
  certificatemeetlist,
  importcertificate,
} from "../../../services/superadmin";
import { meetdetails, meetinformation } from "../../../services/meetservice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Importdata = () => {
  const [mlist, setMeetlist] = useState([]);
  const [meet, setMeet] = useState("");
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [CFile, setCFile] = useState("");

  useEffect(() => {
    const temp = new FormData();
    temp.append("meet_type", "National Meet");
    temp.append("entry_type", "Federation");

    certificatemeetlist(temp).then((response) => {
      if (response.status === true) {
        setMeetlist(response.data);
      }
    });
  }, []);

  function meetlist(ent) {
    setErrorMessage("");
    cleardata();
    var mtype = "";
    var etype = "";
    if (ent === "federation") {
      mtype = "National Meet";
      etype = "Federation";
    } else if (ent === "individual") {
      mtype = "National Meet";
      etype = "Individual";
    } else if (ent === "state") {
      mtype = "State Entry";
      etype = "0";
    } else if (ent === "notincluded") {
      mtype = "State Entry";
      etype = "1";
    }
    const temp = new FormData();
    temp.append("meet_type", mtype);
    temp.append("entry_type", etype);

    certificatemeetlist(temp).then((response) => {
      if (response.status === true) {
        setMeetlist(response.data);
      }
    });
  }

  function selectmeet(e) {
    setMeet(e.target.value);
    setMName("");
    setMLoc("");
    setMSDate("");
    setMEDate("");
    meetinformation(e.target.value).then((response) => {
      setMName(response.data.meet_name);
      setMLoc(response.data.location);
      setMSDate(response.data.start_date);
      setMEDate(response.data.end_date);
    });
  }

  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  function certfile(event) {
    setCFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setCFile(event.target.files[0]);
      } else {
        inputRef.current.value = null;
        setFileMessage("Certificate File size should be less than 5MB");
        setErrorMessage("Certificate File should be xls or xlsx format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setFileMessage("Certificate File size should be less than 5MB");
        setErrorMessage("Certificate File size should be less than 5MB");
      }
    }
  }
  const [fileMessage, setFileMessage] = useState("");

  const navigate = useNavigate();
  function certimport() {
    setErrorMessage("");
    if (meet !== "") {
      if (fileMessage === "") {
        const temp = new FormData();
        temp.append("meet_name", mname);
        temp.append("meet_id", meet);
        temp.append("meet_location", mloc);
        temp.append("meet_start_date", msdate);
        temp.append("meet_end_date", medate);
        temp.append("certificate_excel", CFile);

        importcertificate(temp).then((res) => {
          if (res.status === true) {
            navigate("/superadmin/generatecertificate");
          }
        });
      } else {
        setErrorMessage(fileMessage);
      }
    } else {
      setErrorMessage("Please Enter Your Meet");
    }
  }

  function cleardata() {
    setMName("");
    setMeet("");
    setMLoc("");
    setMSDate("");
    setMEDate("");
    setCFile("");
    inputRef.current.value = null;
  }
  return (
    <Fragment>
      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Import Certificate Data
          </h2>
        </div>
      </div>
      <Card className=" custom-card profilepage">
        <Card.Body>
          <div className="panel profile-top">
            <div className="profile-cover__img"></div>
          </div>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container
                  defaultActiveKey="federation"
                  onSelect={meetlist}
                >
                  <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="federation"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Federation Meets
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="individual"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Individual Meets
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="state"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        State Entries
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="notincluded"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        State Entries But Not Concluded
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="federation" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Certificate File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                ref={inputRef}
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group">
                        <div className="text-center">
                          <a
                            className="btn btn-success me-2 mt-2"
                            onClick={certimport}
                          >
                            Import Certificate
                          </a>
                          <a
                            className="btn btn-danger me-2 mt-2"
                            onClick={cleardata}
                          >
                            Reset
                          </a>
                          <a
                            className="btn btn-secondary me-2 mt-2"
                            href={require("../../../assets/images/Nationalmeet_List.xlsx")}
                            download
                          >
                            Sample Excel
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="individual" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Certificate File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group">
                        <div className="text-center">
                          <a
                            className="btn btn-success me-2 mt-2"
                            onClick={certimport}
                          >
                            Import Certificate
                          </a>
                          <a className="btn btn-danger me-2 mt-2" onClick={cleardata}>
                            Reset
                          </a>
                          <a
                            className="btn btn-secondary me-2 mt-2"
                            href={require("../../../assets/images/Nationalmeet_List.xlsx")}
                            download
                          >
                            Sample Excel
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="state" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id} key={i}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Certificate File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="form-group">
                        <div className="text-center">
                          <a
                            className="btn btn-success me-2 mt-2"
                            onClick={certimport}
                          >
                            Import Certificate
                          </a>
                          <a
                            className="btn btn-danger me-2 mt-2"
                            onClick={cleardata}
                          >
                            Reset
                          </a>
                          <a
                            className="btn btn-secondary me-2 mt-2"
                            href={require("../../../assets/images/Statemeet_List.xlsx")}
                            download
                          >
                            Sample Excel
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="notincluded" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id} key={i}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Certificate File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </form>
                        </div>
                      </div>

                      <hr />
                      <div className="form-group">
                        <div className="text-center">
                          <a
                            className="btn btn-success me-2 mt-2"
                            onClick={certimport}
                          >
                            Import Certificate
                          </a>
                          <a
                            className="btn btn-danger me-2 mt-2"
                            onClick={cleardata}
                          >
                            Reset
                          </a>
                          <a
                            className="btn btn-secondary me-2 mt-2"
                            href={require("../../../assets/images/Statemeet_List.xlsx")}
                            download
                          >
                            Sample Excel
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Importdata;
