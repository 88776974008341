import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./registration.css";
// import ccavReqHandler from '../../ccavRequestHandler.js'
// import ccavResHandler from '../../ccavResponseHandler.js'

function Payment() {
  const [Merid, setMerId] = useState("161520");
  const [Ordid, setOrdId] = useState("");
  const [Curr, setCurrency] = useState("INR");
  const [Amt, setAmount] = useState("1.00");
  const [Lang, setLang] = useState("EN");

  const [BName, setBName] = useState("Peter");
  const [BAddr, setBAddress] = useState("Santacruz");
  const [BCity, setBCity] = useState("Mumbai");
  const [BState, setBState] = useState("MH");
  const [BCoun, setBCountry] = useState("India");
  const [BZip, setBZip] = useState("400054");
  const [BTel, setBTel] = useState("9876543210");
  const [BEmail, setBEmail] = useState("testing@domain.com");

  const [SName, setSName] = useState("Sam");
  const [SAddr, setSAddress] = useState("Vile Parle");
  const [SCity, setSCity] = useState("Mumbai");
  const [SState, setSState] = useState("Maharashtra");
  const [SCoun, setSCountry] = useState("India");
  const [SZip, setSZip] = useState("400038");
  const [STel, setSTel] = useState("0123456789");

  const [Mer1, setMer1] = useState("additional Info.");
  const [Mer2, setMer2] = useState("additional Info.");
  const [Mer3, setMer3] = useState("additional Info.");
  const [Mer4, setMer4] = useState("additional Info.");
  const [Mer5, setMer5] = useState("additional Info.");
  
  const [Pcode, setPCode] = useState("");
  const [CustId, setCustId] = useState("");

  function ccavenue() {
    const temp ={
      merchid: "merchant_id=" + Merid
    }
  }

  
  return (
    <div id="registrationpage">
      {/* action="/ccavRequestHandler" */}
      <form>
        <table width="40%" height="100" border="1" align="center">
          <caption>
            <font size="4" color="blue">
              <b>Integration Kit</b>
            </font>
          </caption>
        </table>
        <table width="40%" height="100" border="1" align="center">
          <tr>
            <td>Parameter Name:</td>
            <td>Parameter Value:</td>
          </tr>
          <tr>
            <td colspan="2">Compulsory information</td>
          </tr>
          <tr>
            <td>Merchant Id</td>

            <td>
              <input
                type="text"
                name="merchant_id"
                id="merchant_id"
                value={Merid}
                onChange={(e)=>setMerId(e.target.value)}
              />{" "}
            </td>
          </tr>
          <tr>
            <td>Order Id</td>
            <td>
              <input type="text" name="order_id" value={Ordid}
                onChange={(e)=>setOrdId(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>
              <input type="text" name="currency" value={Curr}
                onChange={(e)=>setCurrency(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>
              <input type="text" name="amount" value={Amt}
                onChange={(e)=>setAmount(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Redirect URL</td>
            <td>
              <input
                type="text"
                name="redirect_url"
                value="http://localhost:3000/#/login"
              />
            </td>
          </tr>
          <tr>
            <td>Cancel URL</td>
            <td>
              <input
                type="text"
                name="cancel_url"
                value="http://localhost:3000/#/login"
              />
            </td>
          </tr>
          <tr>
            <td>Language</td>
            <td>
              <input type="text" name="language" id="language" value={Lang}
                onChange={(e)=>setLang(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td colspan="2">Billing information(optional):</td>
          </tr>
          <tr>
            <td>Billing Name</td>
            <td>
              <input type="text" name="billing_name" value={BName}
                onChange={(e)=>setBName(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing Address:</td>
            <td>
              <input type="text" name="billing_address" value={BAddr}
                onChange={(e)=>setBAddress(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing City:</td>
            <td>
              <input type="text" name="billing_city" value={BCity}
                onChange={(e)=>setBCity(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing State:</td>
            <td>
              <input type="text" name="billing_state" value={BState}
                onChange={(e)=>setBState(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing Zip:</td>
            <td>
              <input type="text" name="billing_zip" value={BZip}
                onChange={(e)=>setBZip(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing Country:</td>
            <td>
              <input type="text" name="billing_country"value={BCoun}
                onChange={(e)=>setBCountry(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing Tel:</td>
            <td>
              <input type="text" name="billing_tel" value={BTel}
                onChange={(e)=>setBTel(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Billing Email:</td>
            <td>
              <input
                type="text"
                name="billing_email"
                value={BEmail}
                onChange={(e)=>setBEmail(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colspan="2">Shipping information(optional):</td>
          </tr>
          <tr>
            <td>Shipping Name</td>
            <td>
              <input type="text" name="delivery_name" value={SName}
                onChange={(e)=>setSName(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping Address:</td>
            <td>
              <input type="text" name="delivery_address" value={SAddr}
                onChange={(e)=>setSAddress(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping City:</td>
            <td>
              <input type="text" name="delivery_city" value={SCity}
                onChange={(e)=>setSCity(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping State:</td>
            <td>
              <input type="text" name="delivery_state" value={SState}
                onChange={(e)=>setSState(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping Zip:</td>
            <td>
              <input type="text" name="delivery_zip" value={SZip}
                onChange={(e)=>setSZip(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping Country:</td>
            <td>
              <input type="text" name="delivery_country" value={SCoun}
                onChange={(e)=>setSCountry(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Shipping Tel:</td>
            <td>
              <input type="text" name="delivery_tel" value={STel}
                onChange={(e)=>setSTel(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Merchant Param1</td>
            <td>
              <input
                type="text"
                name="merchant_param1"
                value={Mer1}
                onChange={(e)=>setMer1(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Merchant Param2</td>
            <td>
              <input
                type="text"
                name="merchant_param2"
                value={Mer2}
                onChange={(e)=>setMer2(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Merchant Param3</td>
            <td>
              <input
                type="text"
                name="merchant_param3"
                value={Mer3}
                onChange={(e)=>setMer3(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Merchant Param4</td>
            <td>
              <input
                type="text"
                name="merchant_param4"
                value={Mer4}
                onChange={(e)=>setMer4(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Merchant Param5</td>
            <td>
              <input
                type="text"
                name="merchant_param5"
                value={Mer5}
                onChange={(e)=>setMer5(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Promo Code:</td>
            <td>
              <input type="text" name="promo_code" value={Pcode}
                onChange={(e)=>setPCode(e.target.value)}/>
            </td>
          </tr>
          <tr>
            <td>Customer Id:</td>
            <td>
              <input type="text" name="customer_identifier" value={CustId}
                onChange={(e)=>setCustId(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input type="submit" />
              Checkout
            </td>
          </tr>
        </table>
      </form>
    </div>
  );
}

export default Payment;
