import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Container } from "react-bootstrap";
import { Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  athleteidcardsubmit,
  reapplyidcard,
} from "../../../../services/athlete";
import PayUrl from "../../../../Apiccavenue";
import dataString from "../../../../assets/language/ccavenue.json";
import { func } from "prop-types";

const Reapplyidcard = () => {
  const navigate = useNavigate();
  const [appidcard, setAppID] = useState("");
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uniqueid, setUnique] = useState("");

  function submitidcard() {
    if (reason !== "") {
      setErrorMessage("");
      const tempData = new FormData();
      tempData.append("reason", reason);
      athleteidcardsubmit(tempData).then((response) => {
        if (response.status === true) {
          window.open(
            PayUrl + dataString.athletereapplyidcard + response.data.unique_no,
            "_self"
          );
        } else {
          setErrorMessage(response.message);
        }
      });
    } else {
      setErrorMessage("Please Enter Your Reason");
    }
  }

  function paynow() {
    window.open(PayUrl + dataString.athletereapplyidcard + uniqueid, "_self");
  }
  useEffect(() => {
    reapplyidcard().then((response) => {
      if (response.status === false) {
        if (response.message === "Payment Not Yet Completed") {
          setReason(response.data.reason);
          setUnique(response.data.unique_no);
          setAppID("NotPayment");
        } else if (response.message === "Athlete has been reapplied for idcard"){
          navigate("/athlete/idcardsuccess");
        }else{
          setAppID(response.message);
        }
      } else {
        setAppID("");
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Reapply ID Card</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className=" custom-card overflow-hidden">
            <Card.Header>
              <h5>Reapply ID Card Application Fees - INR 1000/-</h5>
            </Card.Header>
            <Card.Body className="applynocpage">
              {appidcard === "NotPayment" && (
                <div className="row mx-0">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-2">
                        <b className="text-inverse">
                          Reason
                          <span className="text-danger"> *</span>
                        </b>
                      </div>
                      <div className="col-md-10">
                        <textarea
                          rows={3}
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          readOnly
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {appidcard === "" && (
                <div className="row mx-0">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-2">
                        <b className="text-inverse">
                          Reason
                          <span className="text-danger"> *</span>
                        </b>
                      </div>
                      <div className="col-md-10">
                        <textarea
                          rows={3}
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {appidcard !== "NotPayment" && (
                <Container fluid className="p-3 text-center">
                  <div className="text-center text-white background-text ">
                    <p className="tx-20 mb-3 text-danger">{appidcard}</p>
                  </div>
                </Container>
              )}
              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}
            </Card.Body>
            <Card.Footer>
              <div className="mobile-text-right">
                {appidcard === "NotPayment" && (
                  <a
                    className="btn btn-success"
                    style={{ marginRight: "10px" }}
                    onClick={paynow}
                  >
                    Pay Now
                  </a>
                )}
                {appidcard === "" && (
                  <a
                    className="btn btn-success"
                    style={{ marginRight: "10px" }}
                    onClick={submitidcard}
                  >
                    Submit & Pay
                  </a>
                )}

                <a
                  className="btn btn-secondary"
                  onClick={() => navigate("/athlete/dashboard")}
                >
                  Back
                </a>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Reapplyidcard;
