import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import { meetdetails } from "../../../services/meetservice";
import fileString from "../../../assets/language/filepath.json";
import {
  statefedfiledelete,
  statefedmoslist,
  statefedmosupload,
  statefedpaymentstatus,
  statefedperdelete,
  statefedperlist,
  statefedperupload,
} from "../../../services/state";
import "./state.css";
import StateHeading from "./stateheading";

const AddCommittee = () => {
  const { id } = useParams();

  const [fileerror, setFileErrors] = useState("");
  const [formfile, setFile] = useState("");

  function uploadfile(event) {
    setFileErrors("");
    setFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf|doc|docs|docs|xls)$/i)) {
        setFile(event.target.files[0]);
      } else {
        setFileErrors(
          "File should be pdf or doc or docs or docx or xls format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setFileErrors("File size should be less than 5MB");
      }
    }
  }

  function addfile() {
    if (formfile !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("mos", formfile);
      statefedmosupload(temp).then((response) => {
        if (response.status === true) {
          setFile("");
          setFileErrors("");
          fileList();
        }
      });
    } else {
      setFileErrors("Please Select Your Upload File");
    }
  }

  const [pererror, setPerErrors] = useState("");
  const [formper, setPer] = useState("");
  function uploadper(event) {
    setPerErrors("");
    setPer("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf|doc|docs|docs|xls)$/i)) {
        setPer(event.target.files[0]);
      } else {
        setPerErrors(
          "Performance File should be Pdf or doc or docs or docx or xls format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPerErrors("Performance File size should be less than 5MB");
      }
    }
  }

  function addper() {
    if (formper !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("performanceproof", formper);
      statefedperupload(temp).then((response) => {
        if (response.status === true) {
          setPer("");
          setPerErrors("");
          perlist();
        }
      });
    } else {
      setPerErrors("Please Select Your Performance File");
    }
  }
  const [filename, setFileName] = useState("");
  const [filetime, setFileTime] = useState("");
  const [fileid, setFileId] = useState("");
  const [filestatus, setFileStatus] = useState("");

  const [pername, setPerName] = useState("");
  const [pertime, setPerTime] = useState("");
  const [perid, setPerId] = useState("");
  const [perstatus, setPerStatus] = useState("");

  function fileList() {
    setFileStatus("");
    setFileName("");
    setFileTime("");
    setFileId("");
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    statefedmoslist(temp).then((res) => {
      if (res.status === true) {
        setFileStatus(res.data.mos);
        if (res.data.mos !== null) {
          setFileName(res.data.mos.file_name);
          setFileTime(moment(res.data.mos.created_at).format("hh:mm:ss a"));
          setFileId(res.data.mos.id);
        }
      }
    });
  }

  function perlist() {
    setPerStatus("");
    setPerName("");
    setPerTime("");
    setPerId("");
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    statefedperlist(temp).then((response) => {
      if (response.status === true) {
        setPerStatus(response.data.mos);
        if (response.data.mos !== null) {
          setPerName(response.data.mos.file_name);
          setPerTime(moment(response.data.mos.created_at).format("hh:mm:ss a"));
          setPerId(response.data.mos.id);
        }
      }
    });
  }

  function filedelete(did) {
    statefedfiledelete(did).then((response) => {
      if (response.status === true) {
        fileList();
      }
    });
  }

  function perdelete(did) {
    statefedperdelete(did).then((response) => {
      if (response.status === true) {
        perlist();
      }
    });
  }
  const navigate = useNavigate();
  useEffect(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedpaymentstatus(temp1).then((response) => {
      if (response.status === true) {   
        
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });
    fileList();
    perlist();
  }, []);
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Selection Committee Minutes
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/state/travelentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Travel Details
                  </Link>
                  <Link
                    to={"/state/summary/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Summary
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-0 border-top bg-white pb-3">
          {filestatus === null && (
            <Col lg={6} md={6} xl={6} sm={12}>
              <Card className="custom-card overflow-hidden eventslist mb-0">
                <Card.Header className="text-center">
                  <div>
                    <h3 className="card-title tx-18 lineheight1 mb-0">
                      <span className="main-content-label tx-20">
                        Upload File Details
                      </span>
                    </h3>
                  </div>
                </Card.Header>
                <Card.Body className="pb-0">
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            MOS File : <b className="text-danger">*</b>
                            <span>
                              Upload PDF,XLS,DOC,DOCS,DOCX files{" "}
                              <b className="text-danger">(Max 5MB)</b>
                            </span>
                          </label>
                          <input
                            type="file"
                            name="file"
                            id="photo"
                            onChange={uploadfile}
                            accept=".pdf,.xls,.doc,.docs,.docx"
                          />
                        </div>
                      </Col>
                      {fileerror !== "" && (
                        <span className="text-danger">{fileerror}</span>
                      )}
                      <hr className="w-100" />
                    </Row>
                  </form>

                  <a
                    onClick={addfile}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Upload
                  </a>
                </Card.Body>
              </Card>
            </Col>
          )}

          {perstatus === null && (
            <Col lg={6} md={6} xl={6} sm={12}>
              <Card className="custom-card overflow-hidden eventslist mb-0">
                <Card.Header className="text-center">
                  <div>
                    <h3 className="card-title tx-18 lineheight1 mb-0">
                      <span className="main-content-label tx-20">
                        Performance Proof
                      </span>
                    </h3>
                  </div>
                </Card.Header>
                <Card.Body className="pb-0">
                  <form>
                    {/* <!-- Row --> */}
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Performance Proof : <b className="text-danger">*</b>
                            <span>
                              Upload PDF,XLS,DOC,DOCS,DOCX files{" "}
                              <b className="text-danger">(Max 5MB)</b>
                            </span>
                          </label>
                          <input
                            id="upload"
                            type="file"
                            accept=".pdf,.xls,.doc,.docs,.docx"
                            onChange={uploadper}
                          />
                        </div>
                      </Col>
                      {pererror !== "" && (
                        <span className="text-danger">{pererror}</span>
                      )}
                      <hr className="w-100" />
                    </Row>
                    {/* <!-- End Row --> */}
                  </form>

                  <a
                    onClick={addper}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Upload Performance
                  </a>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        {filestatus !== null && (
          <Row className="row-sm mx-0 bg-white">
            <Col lg={12} md={12} xl={12} sm={12}>
              <div className="custom-card px-4">
                <div
                  className="table-responsive border border-bottom-0"
                  responsive="true"
                >
                  <Table
                    hover
                    className="table text-nowrap border table-striped"
                  >
                    <thead className="table border-bottom">
                      <tr>
                        <th className="">MOS File</th>
                        <th className="text-center">Uploaded Time</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={imageUrl + fileString.statemos + filename}
                            download
                          >
                            {filename}
                          </a>
                        </td>
                        <td className="text-center">{filetime}</td>
                        <td className="text-center">
                          <a
                            onClick={() => filedelete(fileid)}
                            className="btn btn-danger btn-sm"
                          >
                            DELETE
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {perstatus !== null && (
          <Row className="row-sm mx-0 bg-white">
            <Col lg={12} md={12} xl={12} sm={12}>
              <div className="custom-card px-4">
                <div
                  className="table-responsive border border-bottom-0"
                  responsive="true"
                >
                  <Table
                    hover
                    className="table text-nowrap border table-striped"
                  >
                    <thead className="table border-bottom">
                      <tr>
                        <th className="">Performance Proof File</th>
                        <th className="text-center">Uploaded Time</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={
                              imageUrl +
                              fileString.stateperformance +
                              pername
                            }
                            download
                          >
                            {pername}
                          </a>
                        </td>
                        <td className="text-center">{pertime}</td>
                        <td className="text-center">
                          <a
                            onClick={() => perdelete(perid)}
                            className="btn btn-danger btn-sm"
                          >
                            DELETE
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default AddCommittee;
