import React, { Fragment, useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { idcardpreview } from "../../../services/athlete";

const Athleteidcard = () => {
  const [idcard, setIDCard] = useState("");
  useEffect(() => {
    idcardpreview(localStorage.getItem("Userid"), 2).then((response) => {
      setIDCard(response.data);
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">ID Card</h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <iframe src={idcard} width="100%" style={{height:"400px"}}></iframe>
         
        </Card.Body>
      </Card>
    </Fragment>
  );
};
export default Athleteidcard;
