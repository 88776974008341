import { AgGridReact } from "ag-grid-react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { nidjamfederationsentdistrictlist } from "../../../services/nidjamdistrict";
import moment from "moment";

export const NidjamSentDistrict = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const { id, dist } = useParams();
  const gridRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    nidjamfederationsentdistrictlist(id, dist).then((response) => {
      if (response.status === true) {
        let arr = [];
        if (response.data.district_athletes_list.length !== 0) {
          for (
            let i = 0;
            i < response.data.district_athletes_list.length;
            i++
          ) {
            var cstat = "Pending";
            var fname=response.data.district_athletes_list[i].first_name;
            if (response.data.district_athletes_list[i].idcard_count > 0) {
              cstat = "Received";
            }
            if(response.data.district_athletes_list[i].middle_name !== ""){
                if(response.data.district_athletes_list[i].middle_name !== null){
                    fname = fname + " " + response.data.district_athletes_list[i].middle_name
                }
            }
            fname = fname + " " + response.data.district_athletes_list[i].last_name
            arr.push({
              idcard_count: cstat,
              username: response.data.district_athletes_list[i].username,
              fullname: fname,
              date_of_birth: moment(
                response.data.district_athletes_list[i].date_of_birth
              ).format("DD-MM-YYYY"),
              events: response.data.district_athletes_list[i].events,
              statename: response.data.district_athletes_list[i].statename,
            });
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);
  const columnDefs = [
    {
      headerName: "ID Card Status",
      width: 150,
      field: "idcard_count",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AFI UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Full Name",
      width: 200,
      field: "fullname",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 200,
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 200,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function sendexcel() {
    var excelParams = {
      fileName: "District_Send_Federation_List.csv",
    };
    gridRef.current.api.exportDataAsCsv(excelParams);
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Sent Federation District List
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a className="btn btn-success me-2" onClick={sendexcel}>
                    District Federation Export
                  </a>

                  <a
                    className="btn btn-danger"
                    onClick={() =>
                      navigate("/superadmin/nidjamsentfederation/" + id)
                    }
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
