import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { statefedupcoming } from "../../../services/state";
const Upcoming = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [s3path, setPath] = useState("");
  let arr = [];

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/logo/logo-small.png");
    ev.target.onerror = null;
  }

  useEffect(() => {
    statefedupcoming().then((res) => {
      if (res.status === true) {
        setPath(res.data.s3path);
        if (res.data.tournament.length !== 0) {
          for (let i = 0; i < res.data.tournament.length; i++) {
            let butt = "";
            if (res.data.tournament[i].sent_status === 1) {
              butt = res.data.tournament[i].sent_status;
            } else {
              if (
                moment(new Date()).format("YYYY-MM-DD") <=
                moment(res.data.tournament[i].entry_deadline_date).format(
                  "YYYY-MM-DD"
                )
              ) {
                if (res.data.tournament[i].sent_status === 1) {
                  butt = res.data.tournament[i].sent_status;
                } else {
                  if (
                    moment(res.data.tournament[i].entry_opens).format(
                      "YYYY-MM-DD"
                    ) <= moment(new Date()).format("YYYY-MM-DD")
                  ) {
                    butt = 0;
                  }
                }
              } else {
                butt = "Entry Closed";
              }
            }
            arr.push({
              id: res.data.tournament[i].id,
              meet_name: res.data.tournament[i].meet_name,
              start_date: res.data.tournament[i].start_date,
              end_date: res.data.tournament[i].end_date,
              entry_opens: res.data.tournament[i].entry_opens,
              entry_deadline_date: res.data.tournament[i].entry_deadline_date,
              sent_status: butt,
              logo: res.data.tournament[i].logo,
              allow_additional_entry: res.data.tournament[i].allow_additional_entry,
              additionalentry_status: res.data.tournament[i].additionalentry_status

            });
          }
        }

        setFilterProduct(arr);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Logo",
      width: 80,
      field: "logo",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={params.data.logo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Meet Name",
      width: 320,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 220,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("DD-MM-YYYY") +
          " - " +
          moment(params.data.end_date).format("DD-MM-YYYY")
        );
      },
    },

    {
      headerName: "Entry Open",
      width: 140,
      field: "entry_opens",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Entry Closed",
      width: 140,
      field: "entry_deadline_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        if (params.data.sent_status === 1) {
          return (
            <div>
              <Link
                to={"/state/confirmation/" + params.value}
                className="btn-sm btn-info me-2"
              >
                <i className="fa fa-eye fa-lg"></i>
              </Link>
              {params.data.allow_additional_entry === "Y" && params.data.additionalentry_status === 0 &&
              <Link
                to={"/state/additionalathleteentrylist/" + params.value}
                className="btn-sm btn-success"
              >
                <i className="fa fa-user-plus fa-lg"></i>
              </Link>
              }
              
            </div>
          );
        } else if (params.data.sent_status === 0) {
          return (
            <Link
              to={"/state/athleteentrylist/" + params.value}
              className="btn-sm btn-success"
            >
              <i className="fa fa-user-plus fa-lg"></i>
            </Link>
          );
        } else if (params.data.sent_status !== "") {
          return <a style={{ color: "red" }}>{params.data.sent_status}</a>;
        }
        // if (params.data.sent_status === 1) {
        //   return (
        //     <Link
        //       to={"/state/confirmation/" + params.value}
        //       className="btn-sm btn-info"
        //     >
        //       <i className="fa fa-eye fa-lg"></i>
        //     </Link>
        //   );
        // } else if (params.data.sent_status === 0) {
        //   return (
        //     <Link
        //       to={"/state/athleteentrylist/" + params.value}
        //       className="btn-sm btn-success"
        //     >
        //       <i className="fa fa-user-plus fa-lg"></i>
        //     </Link>
        //   );
        // } else if (params.data.sent_status !== "") {
        //   return <a>{params.data.sent_status}</a>;
        // }
      },
    },
  ];

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Upcoming Meet</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Upcoming;
