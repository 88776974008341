import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    email: null
};

const forgotSlice = createSlice({
    name: 'forgot',
    initialState,
    reducers: {
        forgotuser: (state, action) => {
            return Object.assign({}, state, { email: action.payload.email });
        },
        forgotpass: (state, action) => {
            return Object.assign({}, state, { user: action.payload.user });
        },
    },
});

export const { forgotpass,forgotuser } = forgotSlice.actions;
export const selectUser = (state) => state.forgot.user;


export default forgotSlice.reducer;