import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
import {
  federationathletelist,
  federationmoslist,
  federationofficiallist,
  federationperformancelist,
  federationsentlist,
  federationtravellist,
} from "../../../services/superadmin";
import exportUrl from "../../../Export";

const NationalEntries = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterOfficial, setFilterOfficial] = useState([]);
  const [filterArrival, setFilterArrival] = useState([]);
  const [filterDeparture, setFilterDeparture] = useState([]);
  const [filterMOS, setFilterMOS] = useState([]);
  const [filterPerf, setFilterPerf] = useState([]);
  const [filterSent, setFilterSent] = useState([]);

  const [linkurl, setLink] = useState("");

  const { id } = useParams();
  const gridRef = useRef();
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setLink(link[1]);
    if (eid === "nationalsentfederation") {
      federationsentlist(id).then((response) => {
        if (response.status === true) {
          setFilterSent(response.data.individual_units_sent);
        }
      });
    } else if (eid === "nationalathleteentries") {
      federationathletelist(id).then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data.federation_sent_athletes_list);
        }
      });
    } else if (eid === "nationalofficialentries") {
      federationofficiallist(id).then((response) => {
        if (response.status === true) {
          setFilterOfficial(response.data.federation_sent_officials_list);
        }
      });
    } else if (eid === "nationalarrivalentries") {
      federationtravellist(id).then((response) => {
        if (response.status === true) {
          setFilterArrival(response.data.federation_arraival_details_list);
        }
      });
    } else if (eid === "nationaldepartureentries") {
      federationtravellist(id).then((response) => {
        if (response.status === true) {
          setFilterDeparture(response.data.federation_departure_details_list);
        }
      });
    } else if (eid === "nationalmosentries") {
      federationmoslist(id).then((response) => {
        if (response.status === true) {
          setFilterMOS(response.data.federation_mos_list);
        }
      });
    } else if (eid === "nationalperformancceentries") {
      federationperformancelist(id).then((response) => {
        if (response.status === true) {
          setFilterPerf(response.data.federation_performance_proof_list);
        }
      });
    }
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const columnDefs = [
    {
      headerName: "Photo",
      width: 140,
      field: "photograph",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.athletephoto + params.data.photograph}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "AFI UID",
      width: 130,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 140,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (params.data.middle_name !== null ) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
          
        }
      },
    },

    {
      headerName: "Age Category",
      width: 140,
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Federation",
      width: 200,
      field: "federation_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "paymentstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order Id",
      width: 260,
      field: "orderid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Reference Id",
      width: 150,
      field: "referenceid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    
    {
      headerName: "Actions",
      width: 90,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={"/superadmin/viewprofile/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const columnOffs = [
    {
      headerName: "Photo",
      width: 100,
      field: "photograph",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.stateofficial + params.data.photograph}
            // onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Full Name",
      width: 200,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        return params.data.first_name + " " + params.data.last_name;
      },
    },

    {
      headerName: "Post",
      width: 140,
      field: "post",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "phone",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 270,
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const columnArri = [
    {
      headerName: "Arrival Date",
      width: 150,
      field: "dateof_arr",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },

    {
      headerName: "Arrival Time",
      width: 160,
      field: "dateof_arr_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Detail",
      width: 250,
      field: "arr_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mode",
      width: 180,
      field: "travel_by",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remark",
      width: 260,
      field: "remark",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDept = [
    {
      headerName: "Departure Date",
      width: 150,
      field: "dateof_dep",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },

    {
      headerName: "Departure Time",
      width: 160,
      field: "dateof_dep_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Detail",
      width: 250,
      field: "dep_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mode",
      width: 180,
      field: "dep_travel_by",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remark",
      width: 260,
      field: "dep_remarks",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnMos = [
    {
      headerName: "MOS File",
      width: 900,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.statemos + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnPerf = [
    {
      headerName: "Performance File",
      width: 900,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.stateperformance + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnSent = [
    {
      headerName: "State",
      width: 200,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "paymentstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order Id",
      width: 260,
      field: "orderid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Reference Id",
      width: 150,
      field: "referenceid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 120,
      field: "totalevent",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount",
      width: 120,
      field: "amount",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function sendexcel() {
    if (linkurl === "nationalathleteentries") {
      window.open(exportUrl + "nationalunitsentathletesexcel/" + id, "_self");
    } else if (linkurl === "nationalofficialentries") {
      window.open(exportUrl + "nationalunitsentofficialsexcel/" + id, "_self");
    } else if (linkurl === "nationalarrivalentries") {
      window.open(exportUrl + "nationalunitsentarrivalexcel/" + id, "_self");
    } else if (linkurl === "nationaldepartureentries") {
      window.open(exportUrl + "nationalunitsentdepartureexcel/" + id, "_self");
    } else if (linkurl === "nationalmosentries") {
      window.open(exportUrl + "nationalunitsentmosexcel/" + id, "_self");
    } else if (linkurl === "nationalperformancceentries") {
      window.open(
        exportUrl + "nationalunitsentperformanceproofexcel/" + id,
        "_self"
      );
    }
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          {linkurl === "nationalsentfederation" && (
            <h2 className="main-content-title tx-24 mg-b-5">Federation Sent</h2>
          )}
          {linkurl === "nationalathleteentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Athlete Entries</h2>
          )}
          {linkurl === "nationalofficialentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Official Entries
            </h2>
          )}
          {linkurl === "nationalarrivalentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Arrival Entries</h2>
          )}
          {linkurl === "nationaldepartureentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Departure Entries
            </h2>
          )}
          {linkurl === "nationalmosentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">MOS Entries</h2>
          )}
          {linkurl === "nationalperformancceentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Performance Entries
            </h2>
          )}
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  {linkurl === "nationalathleteentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Athlete Export
                    </a>
                  )}
                  {linkurl === "nationalofficialentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Official Export
                    </a>
                  )}
                  {linkurl === "nationalarrivalentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Arrival Export
                    </a>
                  )}
                  {linkurl === "nationaldepartureentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Departure Export
                    </a>
                  )}
                  {linkurl === "nationalmosentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      MOS Export
                    </a>
                  )}
                  {linkurl === "nationalperformancceentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Performance Export
                    </a>
                  )}
                  <a
                    className="btn btn-danger"
                    onClick={() => navigate("/superadmin/viewmeet/" + id)}
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  {linkurl === "nationalsentfederation" && (
                    <AgGridReact
                      columnDefs={columnSent}
                      rowData={filterSent}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationalathleteentries" && (
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationalofficialentries" && (
                    <AgGridReact
                      columnDefs={columnOffs}
                      rowData={filterOfficial}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationalarrivalentries" && (
                    <AgGridReact
                      columnDefs={columnArri}
                      rowData={filterArrival}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationaldepartureentries" && (
                    <AgGridReact
                      columnDefs={columnDept}
                      rowData={filterDeparture}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationalmosentries" && (
                    <AgGridReact
                      columnDefs={columnMos}
                      rowData={filterMOS}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nationalperformancceentries" && (
                    <AgGridReact
                      columnDefs={columnPerf}
                      rowData={filterPerf}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NationalEntries;
