import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { downloadcertificate } from "../../../services/superadmin";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

const CertificateQR = () => {
  const { id } = useParams();
  const [cert, setCert] = useState("");
  useEffect(() => {
    downloadcertificate(id).then((response) => {
      if (response.status === true) {
        setCert(imageUrl + fileString.athletecertificate + response.data.certificate_name);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="qrcertificate">
        <iframe src={cert} />
      </div>
      <div className="text-center mt-3">
        <a className="btn btn-success" target="_blank" href={cert} download>
          Download
        </a>
      </div>
      <div className="mt-3"></div>
    </Fragment>
  );
};

export default CertificateQR;
