import React, { Fragment, useEffect, useState } from "react";
import { Card, Form, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
import {
  nocapproveathlete,
  nocathleteview,
  nocrejectathlete,
  viewprofile,
} from "../../../services/superadmin";
import moment from "moment/moment";
const ViewNOC = () => {
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");

  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");

  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [commstate, setCommState] = useState("");
  const [commdistname, setCommDistname] = useState("");
  const [commpin, setCommPincode] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [state, setState] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");

  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [bpl, setBpl] = useState("");
  const [bplstatus, setBplstatus] = useState("");
  const [formaddr, setAddrFile] = useState("");

  const [fromnoc, setFromNOC] = useState("");
  const [tonoc, setToNOC] = useState("");
  const [addrnoc, setAddressNOC] = useState("");
  const [nocstatus, setNOCStatus] = useState("");
  const [nocreason, setNOCReason] = useState("");
  const [orderid, setNOCOrder] = useState("");
  const [nocfrom, setNOCFrom] = useState("");
  const [nocto, setNOCTo] = useState("");
  const [nocnotes, setNOCNotes] = useState("");

  const { id } = useParams();
  const { nid } = useParams();

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  const [ashow, setShow1] = useState(false);

  function setAShow() {
    setErrorMessage("");
    setShow1(true);
  }

  function setAClose() {
    setShow1(false);
  }

  const [rshow, setShow2] = useState(false);

  function setRShow() {
    setErrorMessage("");
    setShow2(true);
  }

  function setRClose() {
    setShow2(false);
  }

  const navigate = useNavigate();

  useEffect(() => {
    nocathleteview(nid, id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.user_details.first_name);
        setLast(response.data.user_details.last_name);
        setMiddle(response.data.user_details.middle_name)
        setDOB(
          moment(response.data.user_details.date_of_birth).format("DD-MM-YYYY")
        );
        setGender(response.data.user_details.gender);
        setEmail(response.data.user_details.email_id);
        setMobile(response.data.user_details.mobile_no);
        setCommAddr(response.data.user_details.communication_address_one);
        setCommaddr2(response.data.user_details.communication_address_two);
        setCommDistname(response.data.user_details.communication_district);

        for (let i = 0; i < response.data.comm_state.length; i++) {
          if (
            response.data.user_details.communication_state ===
            response.data.comm_state[i].stateuid
          ) {
            setCommState(response.data.comm_state[i].statename);
          }
        }
        setCommPincode(response.data.user_details.communication_zipcode);
        setAddr(response.data.user_details.permenant_address_one);
        setAddr2(response.data.user_details.permenant_address_two);
        setDistname(response.data.user_details.permenant_district);
        setPincode(response.data.user_details.permenant_zipcode);
        setState(response.data.user_details.statename);
        setFather(response.data.user_details.father_name);
        setMother(response.data.user_details.mother_name);
        setBlood(response.data.user_details.blood_group);
        setAadhar(response.data.user_details.aadhaar_no);
        setStatus(response.data.user_details.status);
        setBplstatus(response.data.user_details.below_poverty_card);
        setPhotoFile(
          imageUrl + fileString.athletephoto + response.data.user_details.photograph
        );
        setIDFile(
          imageUrl + fileString.athleteidproof + response.data.user_details.dob_proof
        );
        setAddrFile(
          imageUrl +
            fileString.athleteaddress +
            response.data.user_details.address_proof
        );
        setBpl(
          imageUrl +
            fileString.athletebplcard +
            response.data.user_details.below_poverty_file
        );
        setNOCFrom(response.data.noc_details.from_state_name);
        setNOCTo(response.data.noc_details.to_state_name);
        setNOCNotes(response.data.noc_details.to_state_name);

        setFromNOC(
          imageUrl + fileString.athletenoc + response.data.noc_details.from_state_noc
        );
        setToNOC(imageUrl + fileString.athletenoc + response.data.noc_details.to_state_noc);
        setAddressNOC(
          imageUrl + fileString.athletenoc + response.data.noc_details.to_state_address
        );
        setUid(response.data.user_details.username);
        if (response.data.noc_details.approval_notes !== null) {
          setNOCReason(response.data.noc_details.approval_notes);
        }

        setNOCStatus(response.data.noc_details.from_state_approve);
        setNOCOrder(response.data.noc_details.order_id);
      }
    });
  }, []);

  const [apprreason, setApprReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function nocapprove() {
    if (apprreason !== "") {
      const tempData = new FormData();
      tempData.append("nocid", nid);
      tempData.append("user_id", id);
      tempData.append("approval_notes", apprreason);
      nocapproveathlete(tempData).then((response) => {
        if (response.status === true) {
          navigate("/superadmin/nocapproved");
        }
      });
    } else {
      setErrorMessage("Please Enter Your Reason");
    }
  }

  const [rejreason, setRejReason] = useState("");

  function nocreject() {
    if (rejreason !== "") {
      const tempData = new FormData();
      tempData.append("nocid", nid);
      tempData.append("user_id", id);
      tempData.append("approval_notes", rejreason);
      nocrejectathlete(tempData).then((response) => {
        if (response.status === true) {
          navigate("/superadmin/nocrejected");
        }
      });
    } else {
      setErrorMessage("Please Enter Your Reason");
    }
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">User Profile</h2>
        </div>
      </div>{" "}
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <h5>PERSONAL INFORMATION</h5>

                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">FIRST NAME</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{first}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">MIDDLE NAME</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{middle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">LAST NAME</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{last}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">GENDER</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">EMAIL</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BLOOD GROUP</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{blood}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">MOBILE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">AADHAR NUMBER</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{aadhar}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">FATHER&apos;S NAME</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{father}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">MOTHER&apos;S NAME</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mother}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">AFI-UID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uid}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h5>PERMANENT ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>COMMUNICATION ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DISTRICT</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DISTRICT</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">STATE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">STATE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commstate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">PINCODE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{ppin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">PINCODE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commpin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr></hr>
                <h5>NOC DETAILS</h5>

                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">FROM STATE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{nocfrom}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">TO STATE</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{nocto}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">NOC NOTES</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{nocnotes}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {nocstatus !== 0 && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">NOC STATUS</b>
                          </div>
                          <div className="col-md-8">
                            {nocstatus === 1 && (
                              <span className="text-inverse">APPROVED</span>
                            )}
                            {nocstatus === 2 && (
                              <span className="text-inverse">REJECTED</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {nocstatus !== 0 && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">REASON</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{nocreason}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {nocstatus !== 0 && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">ORDER ID</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{orderid}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <hr></hr>

                <div className="row">
                  <div className="col-md-4">
                    <h6>Photograph</h6>
                    <img
                      className="profile_img"
                      src={formphoto}
                      alt="photo"
                      onClick={() => handleshow(formphoto)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>ID Proof</h6>
                    <img
                      className="profile_img"
                      src={formid}
                      alt="photo"
                      onClick={() => handleshow(formid)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                  <div className="col-md-4">
                    <h6>Address Proof</h6>
                    <img
                      className="profile_img"
                      src={formaddr}
                      alt="photo"
                      onClick={() => handleshow(formaddr)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                  {bplstatus === "Y" && (
                    <div className="col-md-4 bpl-gap">
                      <h6>BPL Card</h6>

                      <img
                        className="profile_img"
                        src={bpl}
                        alt="photo"
                        onClick={() => handleshow(bpl)}
                        onError={(e) => errorhandle(e)}
                      />
                    </div>
                  )}

                  <div className="col-md-4 bpl-gap">
                    <h6>Present State NOC</h6>

                    <img
                      className="profile_img"
                      src={fromnoc}
                      alt="photo"
                      onClick={() => handleshow(fromnoc)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>

                  <div className="col-md-4 bpl-gap">
                    <h6>Transfer State NOC</h6>

                    <img
                      className="profile_img"
                      src={tonoc}
                      alt="photo"
                      onClick={() => handleshow(tonoc)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                  <div className="col-md-4 bpl-gap">
                    <h6>Transfer State Address</h6>

                    <img
                      className="profile_img"
                      src={addrnoc}
                      alt="photo"
                      onClick={() => handleshow(addrnoc)}
                      onError={(e) => errorhandle(e)}
                    />
                  </div>
                </div>

                <hr />
                <div className="grp-btns">
                  <button
                    className="btn-f btn-secondary"
                    onClick={() => navigate(-1)}
                  >
                    back
                  </button>
                  {nocstatus === 0 && (
                    <button className="btn-f btn-success" onClick={setAShow}>
                      approve
                    </button>
                  )}
                  {nocstatus === 0 && (
                    <button className="btn-f btn-danger" onClick={setRShow}>
                      reject
                    </button>
                  )}
                </div>

                <br />
                <hr></hr>
              </div>
            </div>
          </div>

          <Modal show={show}>
            <Modal.Body>
              <img src={file} alt="Image" onError={(e) => errorhandle(e)} />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-success"
                style={{ float: "right" }}
                href={file}
                download
                target="_blank"
              >
                Download
              </a>
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={handleclose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>

          <Modal show={ashow}>
            <Modal.Header>
              <h6>Do You want to Approve NOC Application</h6>
            </Modal.Header>
            <Modal.Body>
              <label>Reason :</label>
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  value={apprreason}
                  onChange={(e) => {
                    setApprReason(e.target.value);
                  }}
                ></Form.Control>
              </div>
              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-success"
                style={{ float: "right" }}
                onClick={nocapprove}
              >
                Approve
              </a>
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={setAClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>

          <Modal show={rshow}>
            <Modal.Header>
              <h6>Do You want to Reject NOC Application</h6>
            </Modal.Header>
            <Modal.Body>
              <label>Reason :</label>
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  value={rejreason}
                  onChange={(e) => {
                    setRejReason(e.target.value);
                  }}
                ></Form.Control>
              </div>
              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-danger"
                style={{ float: "right" }}
                onClick={nocreject}
              >
                Reject
              </a>
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={setRClose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ViewNOC;
