import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
const RegisterFailure = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <Fragment>
      <div id="registrationpage">
        <div className="registrationmain">
          <div className="registrationleft">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>

          <div className="registrationright">
            <form action="#" className="form">
              <div className="text-right">
                <Link className="btn btn-secondary mr-2" to={"/login"}>
                  Back
                </Link>
              </div>

              <Container fluid className="p-3 text-center mt-5">
                <div className="text-center text-white background-text ">
                  <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                    Registration failed !
                  </h1>
                  <p className="tx-20 mb-3 text-success">
                  Your payment for AFI UID registered has been failed {" "}
                    <br></br>Please try again.
                  </p>
                </div>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFailure;
