import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { meetdetails } from "../../../../services/meetservice";
import "./state.css";
import StateHeading from "./stateheading";
import dataString from "../../../../assets/language/ccavenue.json";
import PayUrl from "../../../../Apiccavenue";
import { nidjamfreepayment, nidjamoveralllist, nidjampayment, nidjampaymentstatus, nidjamsummarylist } from "../../../../services/nidjamdistrict";

const NidjamSendEntries = () => {
  const { id } = useParams();
  const [agelist, setAgeList] = useState([]);
  const [alist, setAllList] = useState("");

  const [totevent, setTotalEvent] = useState("");
  const [totamt, setTotalAmt] = useState("");
  const [totrelay, setTotalRelay] = useState("");
  const [relayfee, setRelayFee] = useState("");

  const [acount, setAthCount] = useState(0);
  const [ocount, setOffCount] = useState(0);
  const [arrcount, setArrCount] = useState(0);
  const [depcount, setDepCount] = useState(0);
  const [moscount, setMosCount] = useState(0);
  const [percount, setPerCount] = useState(0);
  const [presscount, setPressCount] = useState(0);
  const [photocount, setPhotoCount] = useState(0);
  const [acccount, setAccCount] = useState(0);

  const navigate = useNavigate();

  const [free, setFree] = useState("");
  const [paytype, setPayType] = useState("");
  const [paystatus, setPayStatus] = useState("");
  const [gateway, setGateway] = useState("");
  useEffect(() => {
    meetdetails(id).then((response) => {
      if (response.status === true) {
        setFree(response.data.tournament.free_tournament);
        setPayType(response.data.tournament.payment_gateway);
        if (response.data.tournament.gateway_charges === "Y") {
          setGateway("(Including Gateway Charges)");
        } else {
          setGateway("");
        }
      }
    });

    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        setPayStatus(response.data.Payment_Status);
        if (response.data.Payment_Status === "Paid") {
          navigate("/district/meetconfirmation/" + id);
        }
      }
    });

    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    nidjamoveralllist(tempData).then((response) => {
      if (response.status === true) {
        setTotalAmt(response.data.over_all_amount);
        setTotalEvent(response.data.totaleventscount);
        setTotalRelay(response.data.total_relay_team);
        setRelayFee(response.data.relayTeamCount_fees);

        setAllList(response.data);
        setTotalEvent(response.data.totaleventscount);
        setAgeList(response.data.totalathletescountbasedonagecategory);
      }
    });

    nidjamsummarylist(tempData).then((response) => {
      if (response.status === true) {
        setAthCount(response.data.athletes_list.length);
        setOffCount(response.data.officials_list.length);
        setArrCount(response.data.travel_arrival_details.length);
        setDepCount(response.data.travel_departure_details.length);
        setMosCount(response.data.Minutesofselection);
        setPerCount(response.data.performanceproof);
        setPressCount(response.data.presscuttings);
        setPhotoCount(response.data.nidjam_photos);
        setAccCount(response.data.account_details);
      }
    });
  }, []);

  function submitpay() {
    if (
      acount !== 0 &&
      ocount !== 0 &&
      arrcount !== 0 &&
      depcount !== 0 &&
      moscount !== 0 &&
      percount !== 0 &&
      presscount !== 0 &&
      acccount !== 0 &&
      photocount !== 0
    ) {
      // setAShow();
      // if (paytype === "Razor Pay") {
      //   navigate("/state/confirmation/" + id);
      // } else {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("totalevent", totevent);
      temp.append("totalamount", totamt);

      // nidjampayment(temp).then((response) => {
      //   if (response.status === true) {
      //     window.open(
      //       PayUrl + dataString.natfederation + response.data.info.unique_no,
      //       "_self"
      //     );
      //   }
      // });
    }
  }

  function submitfree() {
    if (
      acount !== 0 &&
      ocount !== 0 &&
      arrcount !== 0 &&
      depcount !== 0 &&
      moscount !== 0 &&
      percount !== 0 &&
      presscount !== 0 && presscount >= 2 &&
      acccount !== 0 &&
      photocount !== 0 && photocount >= 2
    ) {
      const temp = new FormData();
      temp.append("current_meet_id", id);
      temp.append("totalevent", totevent);
      temp.append("amount", totamt);
      nidjamfreepayment(temp).then((response) => {
        if (response.status === true) {
          navigate("/district/meetconfirmation/" + id);
        }
      });
    }
  }
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2 border-bottom">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Athlete Entry
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/district/meetsummary/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Summary
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        <Row className="row-sm mx-0 bg-white sendentry">
          <Col lg={8} md={8} xl={8} sm={12} className="mx-auto">
            <Card className="custom-card">
              {agelist.map((data, i) => {
                return (
                  <Card.Body key={i}>
                    <div className="border">
                      <div className="main-list-item p-3 bg-light border-bottom border-danger">
                        <div>
                          <h6 className="mb-0">{data.age_categories}</h6>
                        </div>
                        <div>
                          <h6>Count</h6>
                        </div>
                      </div>

                      <div className="main-list-item p-3 border-top">
                        <div>
                          <h6 className="mb-0">Total Events</h6>
                        </div>
                        <div>
                          <h6>{data.totalevent}</h6>
                        </div>
                      </div>
                      {/* <div className="main-list-item p-3 border-top bg-dark">
                        <div>
                          <h6 className="mb-0 text-white">Total Amount</h6>
                        </div>
                        <div>
                          <h6 className="mb-0 text-white">{data.fees} INR</h6>
                        </div>
                      </div> */}
                    </div>
                  </Card.Body>
                );
              })}

              {/* <Card.Body>
                <div className="border">
                  <div className="main-list-item p-3 bg-light border-bottom border-danger">
                    <div>
                      <h6 className="mb-0">Relay</h6>
                    </div>
                    <div>
                      <h6>Count</h6>
                    </div>
                  </div>

                  <div className="main-list-item p-3 border-top">
                    <div>
                      <h6 className="mb-0">Total Events</h6>
                    </div>
                    <div>
                      <h6>{totrelay}</h6>
                    </div>
                  </div>
                  <div className="main-list-item p-3 border-top bg-dark">
                    <div>
                      <h6 className="mb-0 text-white">Total Amount</h6>
                    </div>
                    <div>
                      <h6 className="mb-0 text-white">{relayfee} INR</h6>
                    </div>
                  </div>
                </div>
              </Card.Body>*/}

              <div className="main-list-item p-3 border-top bg-dark">
                <div>
                  <h6 className="mb-0 text-white">Overall Total Events</h6>
                </div>
                <div>
                  <h6 className="mb-0 text-white">{totevent}</h6>
                </div>
              </div> 

              <hr className="mb-4" />
              <div className="text-center">
                {free === "N" && paystatus !== "paid" && (
                  <a
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                    onClick={submitpay}
                  >
                    {" "}
                    Submit & Pay
                  </a>
                )}
                {free === "Y" && paystatus !== "paid" && (
                  <a
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                    onClick={submitfree}
                  >
                    {" "}
                    Send Entries
                  </a>
                )}
              </div>

              <Card.Footer className="send-footer">
                {acount === 0 && <p>* ADD ATHLETES BEFORE SEND ENTRIES</p>}
                {ocount === 0 && <p>* ADD OFFICIALS BEFORE SEND ENTRIES</p>}
                {arrcount === 0 && (
                  <p>* ADD ARRIVAL TRAVEL DETAILS BEFORE SEND ENTRIES</p>
                )}
                {depcount === 0 && (
                  <p>* ADD DEPARTURE TRAVEL DETAILS BEFORE SEND ENTRIES</p>
                )}
                {moscount === 0 && (
                  <p>* ADD SELECTION COMMITTEE MINUTES BEFORE SEND ENTRIES</p>
                )}
                {percount === 0 && (
                  <p>* ADD PERFORMANCE PROOF BEFORE SEND ENTRIES</p>
                )}
                {presscount < 2 && (
                  <p>* ADD PRESS CUTTINGS BEFORE SEND ENTRIES (Minimum 2 files is mandatory)</p>
                )}
                {photocount < 2 && (
                  <p>* ADD PHOTO OF MEET BEFORE SEND ENTRIES (Minimum 2 files is mandatory)</p>
                )}
                {acccount === 0 && (
                  <p>* ADD ACCOUNT DETAILS BEFORE SEND ENTRIES</p>
                )}

              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NidjamSendEntries;
