export const MENUITEMS = [
  {
    Items: [
      {
        path: "/superadmin/dashboard",
        icon: "fa fa-home",
        type: "link",
        active: true,
        selected: true,
        title: "Dashboard",
      },
     
      {
        title: "Athletes",
        icon: "fa fa-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/pendingathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Athletes",
          },
          {
            path: "/superadmin/approvedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Athletes",
          },
          {
            path: "/superadmin/rejectedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Athletes",
          },
          {
            path: "/superadmin/blockedathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Athletes",
          },
          {
            path: "/superadmin/holdathletes",
            type: "link",
            active: false,
            selected: false,
            title: "Hold Athletes",
          },
          {
            path: "/superadmin/allathletes",
            type: "link",
            active: false,
            selected: false,
            title: "All Athletes",
          },
          {
            path: "/superadmin/pendingdoc",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Doc List",
          },
          {
            path: "/superadmin/deletedathlete",
            type: "link",
            active: false,
            selected: false,
            title: "Deleted Athletes",
          },
          {
            path: "/superadmin/docupdate",
            type: "link",
            active: false,
            selected: false,
            title: "Document Updated Athletes",
          },
          {
            path: "/superadmin/similar",
            type: "link",
            active: false,
            selected: false,
            title: "Similar Profiles",
          },
          // {
          //   path: "/superadmin/addathlete",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Add Athlete",
          // },
        ],
      },
      {
        path: "/superadmin/approvedrejectlist",
        icon: "fa fa-user-o",
        type: "link",
        active: true,
        selected: true,
        title: "Approved & Rejected",
      },
         
      
      {
        path: "/superadmin/athletestatus",
        icon: "fa fa-book",
        type: "link",
        active: true,
        selected: true,
        title: "Athlete Status",
      },
      
      {
        title: "NOC",
        icon: "fa fa-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/nocathletelist",
            type: "link",
            active: false,
            selected: false,
            title: "Athlete List",
          },
          {
            path: "/superadmin/nocapproved",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Athletes",
          },
          {
            path: "/superadmin/nocrejected",
            type: "link",
            active: false,
            selected: false,
            title: "Rejected Athletes",
          },
          {
            path: "/superadmin/noclog",
            type: "link",
            active: false,
            selected: false,
            title: "NOC Log",
          },
        ],
      }, 

      {
        path: "/superadmin/paymentlist",
        icon: "fa fa-credit-card",
        type: "link",
        active: true,
        selected: true,
        title: "Payment",
      },
      {
        title: "IDCard",
        icon: "fa fa-address-card",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/superadmin/inhand",
            type: "link",
            active: false,
            selected: false,
            title: "Inhand IDCard",
          },
          {
            path: "/superadmin/delivered",
            type: "link",
            active: false,
            selected: false,
            title: "Delivered IDCard",
          },
        ],
      }, 
    ],
  },
];
