import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "./pendingathlete.css";
import {
  bloodlist,
  distlist,
  statelist,
  validatemail,
  validatemobile,
} from "../../../services/registerservice";
import { superadminregister } from "../../../services/superadmin";
import moment from "moment";
const Addathlete = () => {
  const navigate = useNavigate();
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [step1submit, setStep1] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [emailerror, setEmailError] = useState("");
  const [reemailerror, setReEmailError] = useState("");
  const [moberror, setMobError] = useState("");
  const [remoberror, setReMobError] = useState("");
  const [aaderror, setAadError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [reemail, setReEmail] = useState("");
  const [blood, setBlood] = useState("");
  const [mob, setMob] = useState("");
  const [remob, setReMob] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [father, setFathar] = useState("");
  const [mother, setMothar] = useState("");

  const [check, setCheck] = useState("");
  const [padd1, setPAdd1] = useState("");
  const [padd2, setPAdd2] = useState("");
  const [pdist, setPDist] = useState("");
  const [ppin, setPPin] = useState("");
  const [pstate, setPState] = useState("");
  const [cadd1, setCAdd1] = useState("");
  const [cadd2, setCAdd2] = useState("");
  const [cdist, setCDist] = useState("");
  const [cpin, setCPin] = useState("");
  const [cstate, setCState] = useState("");
  //date
  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }
  const [blist, setBloodlist] = useState([]);
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);
  const [cdlist, setCDList] = useState([]);

  function registerlist() {
    bloodlist().then((response) => {
      if (response.status === true) {
        setBloodlist(response.data);
      }
    });
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  //validations

  function checkcomm(e) {
    setCheck(e.target.checked);
    setCAdd1("");
    setCAdd2("");
    setCDist("");
    setCPin("");
    setCState("");
    if (e.target.checked === true) {
      setCAdd1(padd1);
      setCAdd2(padd2);
      setCDist(pdist);
      setCPin(ppin);
      setCState(pstate);
      setCDList([]);
      distlist(pstate).then((response) => {
        setCDList(response.data);
      });
    } else {
      setCAdd1("");
      setCAdd2("");
      setCDist("");
      setCPin("");
      setCState("");
    }
  }

  ///Permanent District
  function Selectpdist(e) {
    setPState(e.target.value);
    setPDist("");
    distlist(e.target.value).then((response) => {
      setPDList(response.data);
    });
  }

  ///Communication District
  function Selectcdist(e) {
    setCState(e.target.value);
    setCDist("");
    distlist(e.target.value).then((response) => {
      setCDList(response.data);
    });
  }
  // Photograph
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        setPhotoUpload(event.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  // DOB Proof
  const [formdoberror, setDobErrors] = useState("");
  const [formdob, setDobFile] = useState("");
  const [formdobupload, setDobUpload] = useState("");

  function dobfile(event) {
    setDobErrors("");
    setDobFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setDobFile(URL.createObjectURL(event.target.files[0]));
        setDobUpload(event.target.files[0]);
      } else {
        setDobErrors("DOB Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setDobErrors("DOB Proof size should be less than 3MB");
      }
    }
  }

  // Address
  const [formaddrerror, setAddrErrors] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [formaddrupload, setAddrUpload] = useState("");

  function addrfile(event) {
    setAddrErrors("");
    setAddrFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setAddrFile(URL.createObjectURL(event.target.files[0]));
        setAddrUpload(event.target.files[0]);
      } else {
        setAddrErrors("Address Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setAddrErrors("Address Proof size should be less than 3MB");
      }
    }
  }

  // BPL Status
  const [bplstatus, setBplStatus] = useState("N");
  function Selectbplstatus(event) {
    setBplStatus(event.target.value);
    if (event.target.value === "N") {
      setBPLErrors("");
      setBPLFile("");
    }
  }

  // BPL Card
  const [formbplerror, setBPLErrors] = useState("");
  const [formbpl, setBPLFile] = useState("");
  const [formbplupload, setBPLUpload] = useState("");

  function bplfile(event) {
    setBPLErrors("");
    setBPLFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setBPLFile(URL.createObjectURL(event.target.files[0]));
        setBPLUpload(event.target.files[0]);
      } else {
        setBPLErrors("BPL Card should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setBPLErrors("BPL Card size should be less than 3MB");
      }
    }
  }

  // Disclaimers
  const [term1checked, setTerm1] = useState(false);
  const checkterm1 = (e) => {
    setTerm1(e.target.checked);
  };

  const [term2checked, setTerm2] = useState(false);
  const checkterm2 = (e) => {
    setTerm2(e.target.checked);
  };

  const [term3checked, setTerm3] = useState(false);
  const checkterm3 = (e) => {
    setTerm3(e.target.checked);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paynow() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      console.log("Razorpay SDK failed to load . Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_zt5DDs1PmkkyDy",
      amount: "5000",
      currency: "INR",
      order_id: "",
      name: "Athletics Federation of India",
      description: "Thank you for nothing. Please give us some money",
      image: "",
      handler: function (response) {
        
        navigate("/superadmin/pendingathletes");
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }
  // if there is no error
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  function Previewstep(e) {
    e.preventDefault();
    setStep1(true);
    var valid = "";
    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      valid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }
    if (!regEmail.test(reemail)) {
      valid = "Please Enter a valid Email Address";
      setReEmailError("Please Enter a valid Email Address");
    } else if (email !== "" && reemail !== "" && email !== reemail) {
      valid = "Email didn't match";
      setReEmailError("Email didn't match");
    }

    if (mob.length < 10) {
      valid = "Mobile Number must be 10 characters";
      setMobError("Mobile Number must be 10 characters");
    }

    if (remob.length < 10) {
      valid = "Mobile Number must be 10 characters";
      setReMobError("Mobile Number must be 10 characters");
    } else if (mob !== "" && remob !== "" && mob !== remob) {
      valid = "Mobile Number didn't match";
      setReMobError("Mobile Number didn't match");
    }

    if (aadhar !== "" && aadhar.length < 12) {
      valid = "Aadhar Number must be 12 characters";
      setAadError("Aadhar Number must be 12 characters");
    }
    if (formphoto === "" && formdob === "" && formaddr === "") {
      valid = "Photograph is required";
      setPhotoErrors("Photograph is required");
      setDobErrors("DOB Proof is required");
      setAddrErrors("Address Proof is required");
    }
    if (formphoto === "") {
      valid = "Photograph is required";
      setPhotoErrors("Photograph is required");
    } else if (formdob === "") {
      valid = "DOB Proof is required";
      setDobErrors("DOB Proof is required");
    } else if (formaddr === "") {
      valid = "Address Proof is required";
      setAddrErrors("Address Proof is required");
    }
    if (
      term1checked === false &&
      term2checked === false &&
      term3checked === false
    ) {
      valid = "Please Select All Disclaimer";
      setDiscerror("Please Select All Disclaimer");
    } else if (
      term1checked === true &&
      term2checked === true &&
      term3checked === true &&
      fname !== "" &&
      daygroup !== "" &&
      monthgroup !== "" &&
      yeargroup !== "" &&
      gender !== "" &&
      email !== "" &&
      valemail === "" &&
      reemail !== "" &&
      valremail === "" &&
      blood !== "" &&
      mob !== "" &&
      remob !== "" &&
      valmob === "" &&
      valremob === "" &&
      father !== "" &&
      padd1 !== "" &&
      pdist !== "" &&
      ppin !== "" &&
      ppin.length === 6 &&
      pstate !== "" &&
      cadd1 !== "" &&
      cdist !== "" &&
      cpin !== "" &&
      cstate !== "" &&
      cpin.length === 6 &&
      formphotoerror === "" &&
      formaddrerror === "" &&
      formdoberror === "" &&
      formphoto !== "" &&
      formaddr !== "" &&
      formdob !== "" &&
      valid === ""
    ) {
      setDiscerror("");
      handleShow();
    }
  }

  const [discerror, setDiscerror] = useState("");
  //   const dispatch = useDispatch();
  // const navigate = useNavigate();
  function regsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const tempData = new FormData();
    tempData.append("first_name", fname);
    tempData.append("middle_name", "");
    tempData.append("last_name", lname);
    tempData.append("date_of_birth", dob);
    tempData.append("gender", gender);
    tempData.append("email_id", email);
    tempData.append("blood_group", blood);
    tempData.append("mobile_no", mob);
    tempData.append("aadhaar_no", aadhar);
    tempData.append("father_name", father);
    tempData.append("mother_name", mother);
    tempData.append("permenant_address_one", padd1);
    tempData.append("permenant_address_two", padd2);
    tempData.append("permenant_state", pstate);
    tempData.append("permenant_district", pdist);
    tempData.append("permenant_zipcode", ppin);
    tempData.append("communication_address_one", cadd1);
    tempData.append("communication_address_two", cadd2);
    tempData.append("communication_state", cstate);
    tempData.append("communication_district", cdist);
    tempData.append("communication_zipcode", cpin);
    tempData.append("photograph", formphotoupload);
    tempData.append("address_proof", formaddrupload);
    tempData.append("dob_proof", formdobupload);
    tempData.append("below_poverty_file", formbplupload);
    tempData.append("below_poverty_card", bplstatus);

    superadminregister(tempData).then((response) => {
      if (response.status === true) {
        setLoading(false);
        navigate("/superadmin/pendingathletes");
      } else {
        setLoading(false);
        setErrorMessage(response.message);
      }
    });
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    if(e.target.value !== ''){
      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setValEmail(response.message);
        }
      });
    }
    
  }
  const [valremail, setValReEmail] = useState("");
  function checkreemail(e) {
    e.preventDefault();
    setValReEmail("");
    setReEmailError("");
    if(e.target.value !== ''){
      validatemail(e.target.value).then((response) => {
        if (response.status === false) {
          setValReEmail(response.message);
        }
      });
    }
    
  }
  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setMobError("");
    if(e.target.value !== ''){
      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setValMob(response.message);
        }
      });
    }
    
  }
  const [valremob, setValReMob] = useState("");
  function checkremobile(e) {
    e.preventDefault();
    setValReMob("");
    setReMobError("");
    if(e.target.value !== ''){
      validatemobile(e.target.value).then((response) => {
        if (response.status === false) {
          setValReMob(response.message);
        }
      });
    }
    
  }

  useEffect(() => {
    datelist();
    registerlist();
  }, []);
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Home - Add Athlete
          </h2>
        </div>
      </div>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            First name <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your first name"
                            autoComplete="off"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            value={fname}
                            onChange={(e) => {
                              setFName(e.target.value);
                            }}
                          />
                          {step1submit && !fname && (
                            <span className="errortxt text-danger">
                              First Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your last name"
                            autoComplete="off"
                            value={lname}
                            onChange={(e) => setLName(e.target.value)}
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Gender <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            id="gender"
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {step1submit && !gender && (
                            <span className="errortxt text-danger">
                              Gender is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Date of Birth <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-4 col-sm-4">
                              <select
                                value={daygroup}
                                onChange={(e) => {
                                  setDateDay(e.target.value);
                                }}
                                id="day"
                              >
                                <option value="" disabled>
                                  Day
                                </option>
                                {dateday.map((data, i) => {
                                  return (
                                    <option value={data} key={i}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <select
                                value={monthgroup}
                                onChange={(e) => {
                                  setDateMonth(e.target.value);
                                }}
                                id="month"
                              >
                                <option value="" disabled>
                                  Month
                                </option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <select
                                value={yeargroup}
                                onChange={(e) => {
                                  setDateYear(e.target.value);
                                }}
                                id="year"
                              >
                                <option value="" disabled>
                                  Year
                                </option>
                                {dateyear.map((data, i) => {
                                  return (
                                    <option value={data} key={i}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {step1submit && !dob && (
                            <span className="errortxt text-danger">
                              Date of Birth is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* email */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Email <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Email"
                            onBlur={checkemail}
                            id="email"
                            autoComplete="off"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            onPaste={(e)=>{
                              e.preventDefault()
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                            }} 
                          />
                          {step1submit && !email && (
                            <span className="errortxt text-danger">
                              Email is required
                            </span>
                          )}
                          {emailerror !== "" && email && (
                            <span className="errortxt text-danger">
                              {emailerror}
                            </span>
                          )}
                          {email && valemail !== "" && emailerror === "" && (
                            <span className="errortxt text-danger">
                              {valemail}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Re-enter Email{" "}
                            <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Re Enter Email"
                            onBlur={checkreemail}
                            autoComplete="off"
                            id="remail"
                            value={reemail}
                            onChange={(e) => {
                              setReEmail(e.target.value);
                            }}
                            onPaste={(e)=>{
                              e.preventDefault()
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                            }} 
                          />
                          {step1submit && !reemail && (
                            <span className="errortxt text-danger">
                              Re Enter Email is required
                            </span>
                          )}
                          {reemailerror !== "" && reemail && (
                            <span className="errortxt text-danger">
                              {reemailerror}
                            </span>
                          )}
                          {valremail !== "" &&
                            reemail &&
                            reemailerror === "" && (
                              <span className="errortxt text-danger">
                                {valremail}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Blood group <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={blood}
                            onChange={(e) => {
                              setBlood(e.target.value);
                            }}
                            id="blood"
                          >
                            <option value="" disabled>
                              Select Your Blood Group
                            </option>
                            {blist.map((data, i) => {
                              return (
                                <option value={data.blooduid} key={i}>
                                  {data.bloodtype}
                                </option>
                              );
                            })}
                          </select>
                          {step1submit && !blood && (
                            <span className="errortxt text-danger">
                              Blood Group is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar no</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={12}
                            autoComplete="off"
                            id="aadno"
                            placeholder="Enter Your Aadhar Number"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={aadhar}
                            onChange={(e) => {
                              setAadhar(e.target.value);
                            }}
                            onPaste={(e)=>{
                              e.preventDefault()
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                            }} 
                          />
                          {step1submit && aadhar && (
                            <span className="errortxt text-danger">
                              {aaderror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Mobile <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Mobile Number"
                            maxLength={10}
                            onBlur={checkmobile}
                            id="mobile"
                            autoComplete="off"
                            value={mob}
                            onChange={(e) => {
                              setMob(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(e)=>{
                              e.preventDefault()
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                            }} 
                          />
                          {step1submit && !mob && (
                            <span className="errortxt text-danger">
                              Mobile Number is required
                            </span>
                          )}
                          {moberror !== "" && mob && (
                            <span className="errortxt text-danger">
                              {moberror}
                            </span>
                          )}
                          {valmob !== "" && mob && moberror === "" && (
                            <span className="errortxt text-danger">
                              {valmob}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Re-enter Mobile </b>
                          <span className="text-danger">*</span>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Re Enter Mobile Number"
                            maxLength={10}
                            onBlur={checkremobile}
                            autoComplete="off"
                            id="remobile"
                            value={remob}
                            onChange={(e) => {
                              setReMob(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(e)=>{
                              e.preventDefault()
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                            }} 
                          />
                          {step1submit && !remob && (
                            <span className="errortxt text-danger">
                              Mobile Number is required
                            </span>
                          )}
                          {remoberror !== "" && remob && (
                            <span className="errortxt text-danger">
                              {remoberror}
                            </span>
                          )}
                          {valremob !== "" && mob && remoberror === "" && (
                            <span className="errortxt text-danger">
                              {valremob}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Father's name <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Father's Name"
                            onKeyPress={LetterOnly}
                            autoComplete="off"
                            id="faname"
                            onPaste={onPasteLetter}
                            value={father}
                            onChange={(e) => {
                              setFathar(e.target.value);
                            }}
                          />
                          {step1submit && !father && (
                            <span className="errortxt text-danger">
                              Father&apos;s Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mother's Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            id="moname"
                            placeholder="Enter Your Mother's Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            value={mother}
                            autoComplete="off"
                            onChange={(e) => {
                              setMothar(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Permanent Address</h6>
                  </div>
                  <div className="col-md-6">
                    <h6>Communication Address</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Address 1 <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            id="peadd1"
                            value={padd1}
                            onChange={(e) => {
                              setPAdd1(e.target.value);
                            }}
                          />
                          {step1submit && !padd1 && (
                            <span className="errortxt text-danger">
                              Address 1 is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Address 1 <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            id="coadd1"
                            value={cadd1}
                            onChange={(e) => {
                              setCAdd1(e.target.value);
                            }}
                          />
                          {step1submit && !cadd1 && (
                            <span className="errortxt text-danger">
                              Address 1 is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            id="peadd2"
                            value={padd2}
                            onChange={(e) => {
                              setPAdd2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            id="coadd2"
                            value={cadd2}
                            onChange={(e) => {
                              setCAdd2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            State <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pstate}
                            onChange={Selectpdist}
                            id="pestate"
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                          {step1submit && !pstate && (
                            <span className="errortxt text-danger">
                              State is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            State <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cstate}
                            onChange={Selectcdist}
                            id="costate"
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                          {step1submit && !cstate && (
                            <span className="errortxt text-danger">
                              State is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            District <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pdist}
                            onChange={(e) => {
                              setPDist(e.target.value);
                            }}
                            id="pedist"
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {pdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                          {step1submit && !pdist && (
                            <span className="errortxt text-danger">
                              District is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            District <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cdist}
                            onChange={(e) => {
                              setCDist(e.target.value);
                            }}
                            id="codist"
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {cdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                          {step1submit && !cdist && (
                            <span className="errortxt text-danger">
                              District is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Pincode <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            id="pepin"
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={ppin}
                            onChange={(e) => {
                              setPPin(e.target.value);
                            }}
                          />
                          {step1submit && !ppin && (
                            <span className="errortxt text-danger">
                              Pincode/Zipcode is required
                            </span>
                          )}
                          {ppin !== "" && ppin.length !== 6 && (
                            <span className="errortxt text-danger">
                              Pincode must be 6 digits
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-update">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">
                            Pincode <span className="text-danger">*</span>
                          </b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            id="copin"
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={cpin}
                            onChange={(e) => {
                              setCPin(e.target.value);
                            }}
                          />
                          {step1submit && !cpin && (
                            <span className="errortxt text-danger">
                              Pincode/Zipcode is required
                            </span>
                          )}
                          {cpin !== "" && cpin.length !== 6 && (
                            <span className="errortxt text-danger">
                              Pincode must be 6 digits
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkbox checkbox-1 chk-gap">
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    className="reg-chk"
                    onChange={checkcomm}
                    checked={check}
                  />
                  <label htmlFor="checkbox-1">
                    Communication address is same as permanent address
                  </label>
                </div>
                <hr></hr>
                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-9">
                      <label>
                        1. Photograph : <b className="text-danger">*</b>
                      </label>
                      <span className="desc">
                        Upload only clear passport size photo with plain
                        background
                      </span>
                      <div className="mt-3">
                        <input
                          type="file"
                          name="file"
                          id="photo"
                          onChange={photofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                    {formphoto !== "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img src={formphoto} width="100%" height="auto" />
                        </div>
                      </div>
                    )}
                    {formphoto === "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formphotoerror !== "" && (
                    <span className="errortxt text-danger">
                      {formphotoerror}
                    </span>
                  )}
                </div>
                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-9">
                      <label>
                        2. Date of birth Proof :{" "}
                        <b className="text-danger">*</b>
                      </label>
                      <span className="desc">
                        PAN Card, Voter ID, Birth Certificate and Class X
                        Certificate With Date, Month and Year
                      </span>
                      <div className="mt-3">
                        <input
                          type="file"
                          name="file"
                          id="dob"
                          onChange={dobfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                    {formdob !== "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img src={formdob} width="100%" height="auto" />
                        </div>
                      </div>
                    )}
                    {formdob === "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formdoberror !== "" && (
                    <span className="errortxt text-danger">{formdoberror}</span>
                  )}
                </div>
                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-9">
                      <label>
                        3. Address proof : <b className="text-danger">*</b>
                      </label>
                      <span className="desc">
                        AADHAR Card, Voter ID With Clearly Visible Address,
                        Photo ID proof is must , Submit your entire Aadhar card
                      </span>
                      <div className="mt-3">
                        <input
                          type="file"
                          name="file"
                          id="address"
                          onChange={addrfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>

                    {formaddr !== "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img src={formaddr} width="100%" height="auto" />
                        </div>
                      </div>
                    )}
                    {formaddr === "" && (
                      <div className="col-md-3">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formaddrerror !== "" && (
                    <span className="errortxt text-danger">
                      {formaddrerror}
                    </span>
                  )}
                </div>
                <hr></hr>
                {/* last step */}
                <div className="form-step form-step-active">
                  <div className="form-inner">
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <label htmlFor="">
                          Do you hold a Below Poverty Line Card?
                        </label>
                      </div>
                      <div className="col-md-6">
                        <div className="radioclass">
                          <input
                            type="radio"
                            id="no"
                            className="reg-chk"
                            name="fav_language"
                            value="N"
                            checked={bplstatus === "N"}
                            onChange={Selectbplstatus}
                          />
                          <label htmlFor="no" className="reg-radio">
                            No
                          </label>
                          <input
                            type="radio"
                            id="yes"
                            className="reg-chk"
                            name="fav_language"
                            value="Y"
                            checked={bplstatus === "Y"}
                            onChange={Selectbplstatus}
                          />
                          <label htmlFor="yes">Yes</label>
                        </div>
                      </div>
                    </div>
                    {bplstatus === "Y" && (
                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="dob">
                              4. BPL Card (Below Poverty Line Card)
                              <b className="text-danger">*</b>
                            </label>
                            <span className="desc">
                              AADHAR Card, Voter ID With Address Clearly
                              Visible,Photo ID proof is must , Submit your
                              entire Aadhar card & If it is a duplicate card
                              then refund cannot be done
                            </span>
                            <div className="mt-3">
                              <input
                                type="file"
                                name="file"
                                id="bplphoto"
                                onChange={bplfile}
                                accept=".png,.jpg,.jpeg"
                              />
                            </div>
                          </div>
                          {formbpl !== "" && (
                            <div className="col-md-3">
                              <div className="imagecontainer">
                                <img src={formbpl} width="100%" height="auto" />
                              </div>
                            </div>
                          )}

                          {formbpl === "" && (
                            <div className="col-md-3">
                              <div className="imagecontainer">
                                <img
                                  src={require("../../../assets/images/placeholder.png")}
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {formbplerror !== "" && (
                          <span className="errortxt text-danger">
                            {formbplerror}
                          </span>
                        )}
                      </div>
                    )}
                    <h4>Disclaimer</h4>

                    <div className="checkbox disclaimer checkbox-1">
                      <input
                        type="checkbox"
                        id="checkbox-2"
                        className="reg-chk"
                        checked={term1checked}
                        onChange={checkterm1}
                      />
                      <label htmlFor="checkbox-2">
                        I certify that all the information is true to the best
                        of my knowledge <br />
                      </label>
                    </div>
                    <div className="checkbox disclaimer checkbox-1">
                      <input
                        type="checkbox"
                        id="checkbox-3"
                        className="reg-chk"
                        checked={term2checked}
                        onChange={checkterm2}
                      />
                      <label htmlFor="checkbox-3">
                        {" "}
                        I agree that my AFI UID is liable to be cancelled if at
                        any stage discovered any of the above informaion is
                        incorrect <br />
                      </label>
                    </div>
                    <div className="checkbox disclaimer checkbox-1 chk-horiz">
                      <div>
                        {" "}
                        <input
                          type="checkbox"
                          id="checkbox-4"
                          className="reg-chk"
                          checked={term3checked}
                          onChange={checkterm3}
                        />
                      </div>
                      <div>
                        <label htmlFor="checkbox-4">
                          If your Application is rejected, From the time of
                          rejection mail the sent link for correction or
                          updation of document will be active only for 15 days.
                          If you fail to update within specific time the link
                          will expire and Athlete need to apply again as a new
                          athlete. <br />
                        </label>
                      </div>
                    </div>
                    {discerror !== "" && (
                      <span className="errortxt text-danger">{discerror}</span>
                    )}
                  </div>
                  {errorMessage !== "" && (
                    <span className="errortxt text-danger">{errorMessage}</span>
                  )}
                  <div className="reg__sub">
                    <Link
                      className="btn btn-next btn-primary btn-g"
                      id="next3"
                      style={{ float: "right" }}
                      to="/superadmin/dashboard"
                    >
                      Cancel
                    </Link>
                    <a
                      className="btn btn-next btn-primary"
                      id="next3"
                      style={{ float: "right" }}
                      onClick={Previewstep}
                    >
                      Preview
                    </a>
                  </div>

                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="xl"
                    backdrop="static"
                  >
                    <Modal.Header>
                      <h4>ID Card</h4>
                      <i
                        className="fa fa-window-close fa-lg"
                        aria-hidden="true"
                        onClick={handleClose}
                      ></i>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="registrationmain preview">
                        <div className="registrationright">
                          <div id="idcard">
                            <div className="id_main">
                              <div className="card card_left">
                                <div className="profil">
                                  <img
                                    src={require("../../../assets/images/user.png")}
                                    alt="user"
                                  />
                                </div>

                                <div className="area">
                                  <h5>
                                    {" "}
                                    {fname} {lname}
                                  </h5>
                                  <div className="id_content">
                                    <h6 className="id_dob">
                                      {moment(dob).format("DD-MM-YYYY")}
                                    </h6>
                                    <h6 className="id_gender">{gender}</h6>
                                  </div>
                                  <div className="id_uid">
                                    <h6>ATNMXXXXXX</h6>
                                  </div>
                                  <div className="id_signature">
                                    <img
                                      src={require("../../../assets/images/signature.png")}
                                      height="20"
                                      alt="sign"
                                    />
                                    <h6>Ravinder Chaudhry</h6>
                                    <p>Secretary</p>
                                    <p>Authorised Signatory</p>
                                  </div>
                                </div>
                                <div className="id_preview">
                                  <h6 className="blink">preview</h6>
                                </div>
                              </div>
                              <div className="card card_right">
                                <div className="id_address">
                                  <h6>
                                    {" "}
                                    {padd1} {padd2}
                                  </h6>
                                </div>
                                <div className="id_qrcode">
                                  <img
                                    src={require("../../../assets/images/qrcode.png")}
                                    alt="qrcode"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="id_final">
                              <div className="id_bottom">
                                <div className="checkbox checkbox-5">
                                  <input type="checkbox" className="reg-chk" />
                                  <label htmlFor="checkbox-5">
                                    I agree that above details are correct!
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <a
                        className="btn btn-success mr-3"
                        style={{ float: "right" }}
                        onClick={regsubmit}
                      >
                        Submit
                      </a>
                      <a
                        className="btn btn-next btn-secondary"
                        style={{ float: "right" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </a>
                    </Modal.Footer>
                  </Modal>
                </div>
                {/* last step end */}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Addathlete;
