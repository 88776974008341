import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./state.css";
import StateHeading from "./stateheading";
import moment from "moment";
import {
  nidjamfederationaccountdetail,
  nidjamfederationaddaccountdetail,
  nidjampaymentstatus,
} from "../../../../services/nidjamdistrict";
import { meetdetails } from "../../../../services/meetservice";
import { toast } from "react-toastify";

const NidjamAddAccountdetails = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [accname, setAccName] = useState("");
  const [accno, setAccNo] = useState("");
  const [reaccno, setReAccNo] = useState("");
  const [ifscode, setIFSC] = useState("");
  const [bname, setBName] = useState("");
  const [branch, setBranch] = useState("");
  const [passfile, setPFile] = useState("");
  const [chqfile, setCFile] = useState("");

  const [passid, setPassID] = useState(0);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const url = window.location.href;
    const head = url.split("/#/");
    const link = head[1].split("/");
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status === "Paid") {
          navigate("/district/meetconfirmation/" + id);
        }
      }
    });
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjamfederationaccountdetail(temp).then((response) => {
      if (response.status === true && response.data.account_details !== null) {
        setAccName(response.data.account_details.account_name);
        setAccNo(response.data.account_details.account_number);
        setReAccNo(response.data.account_details.account_number);
        setIFSC(response.data.account_details.ifsc_code);
        setBName(response.data.account_details.bank_name);
        setBranch(response.data.account_details.branch_name);
        setPassID(response.data.account_details.id);
        setPassbook(
          response.data.s3_path + response.data.account_details.pass_book_image
        );
        if(response.data.account_details.cancelled_cheque_copy !== "-"){
          setCheque(
            response.data.s3_path + response.data.account_details.cancelled_cheque_copy
          );
        }
        
      }
    });
  }, []);

  const [passErrors, setPassErrors] = useState("");
  const [formpass, setPassbook] = useState("");

  function photofile(event) {
    setPassErrors("");
    setPassbook("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPassbook(URL.createObjectURL(event.target.files[0]));
        setPFile(event.target.files[0]);
      } else {
        setErrorMessage("Passbook should be jpg or png or jpeg format");
        setPassErrors("Passbook should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Passbook size should be less than 3MB");
        setPassErrors("Passbook size should be less than 3MB");
      }
    }
  }


  const [cheErrors, setCheErrors] = useState("");
  const [formcheque, setCheque] = useState("");

  function checkfile(event) {
    setCheErrors("");
    setCheque("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setCheque(URL.createObjectURL(event.target.files[0]));
        setCFile(event.target.files[0]);
      } else {
        setErrorMessage("Cancelled Cheque Copy should be jpg or png or jpeg format");
        setCheErrors("Cancelled Cheque Copy should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Cancelled Cheque Copy size should be less than 3MB");
        setCheErrors("Cancelled Cheque Copy size should be less than 3MB");
      }
    }
  }

  function addaccount() {
    setErrorMessage("");
    var valid = "";
    if (accname !== "") {
      if (accno !== "") {
        if (reaccno !== "") {
          if (accno === reaccno) {
            if (ifscode !== "") {
              if (bname !== "") {
                if (branch !== "") {
                  if (passid === 0) {
                    if (passfile !== "") {
                      if (passErrors !== "") {
                        valid = "invalid";
                        setErrorMessage(passErrors);
                      }else{
                        if (chqfile !== "") {
                          if (cheErrors !== "") {
                            valid = "invalid";
                            setErrorMessage(cheErrors);
                          }
                        } else {
                          valid = "invalid";
                          setErrorMessage("Please Upload Cancelled Cheque Copy");
                        }
                      }
                     
                    } else {
                      valid = "invalid";
                      setErrorMessage("Please Upload Passbook");
                    }

                    
                  }else{
                    if (passErrors !== "") {
                      valid = "invalid";
                      setErrorMessage(passErrors);
                    }
                    if (cheErrors !== "") {
                      valid = "invalid";
                      setErrorMessage(cheErrors);
                    }
                  }

                 
                  if (valid === "") {
                    const temp = new FormData();
                    temp.append("account_details_id", passid);
                    temp.append("tournament_meet_id", id);
                    temp.append("account_name", accname);
                    temp.append("account_number", accno);
                    temp.append("bank_name", bname);
                    temp.append("branch_name", branch);
                    temp.append("ifsc_code", ifscode);
                    temp.append("pass_book_image", passfile);
                    temp.append("cancelled_cheque_copy", chqfile);

                    nidjamfederationaddaccountdetail(temp).then((response) => {
                      if (response.status === true) {
                        toast.success(response.message);
                        navigate("/district/meetsummary/" + id);
                      }
                    });
                  }
                } else {
                  setErrorMessage("Please Enter Branch Name");
                }
              } else {
                setErrorMessage("Please Enter Bank Name");
              }
            } else {
              setErrorMessage("Please Enter IFSC Code");
            }
          } else {
            setErrorMessage(
              "Account Number and Re-Enter Account Number should be same"
            );
          }
        } else {
          setErrorMessage("Please Re-Enter Account Number");
        }
      } else {
        setErrorMessage("Please Enter Account Number");
      }
    } else {
      setErrorMessage("Please Enter Account Name");
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setAccNo(e.target.value);
    }
  };

  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Account Details
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/district/selectioncommittee/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Selection Committee Mins
                  </Link>
                  <Link
                    to={"/district/meetsummary/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Summary
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        <Row className="row-sm mx-0 mt-0 border-top bg-white">
          <Col lg={12} md={12} xl={12} sm={12}>
            <Card className="custom-card overflow-hidden eventslist">
              <Card.Body className="pb-0">
                <form>
                  <Row className="row-sm mx-0">
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Account Name : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Account Name"
                          value={accname}
                          onChange={(e) => setAccName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Account Number : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Account Number"
                          value={accno}
                          //   onChange={(e) => setAccNo(e.target.value)}
                          onChange={handleChange}
                          maxLength={20}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Re-Enter Account Number :{" "}
                          <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Re-Enter Account Name"
                          maxLength={20}
                          value={reaccno}
                          onChange={(e) => setReAccNo(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          IFSC Code : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter IFSC Code"
                          value={ifscode}
                          maxLength={12}
                          onChange={(e) => setIFSC(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Bank Name : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Bank Name"
                          value={bname}
                          onChange={(e) => setBName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Branch : <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Branch"
                          value={branch}
                          onChange={(e) => setBranch(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Passbook : <span className="text-danger"> *</span>
                        </label>
                        <input
                          id="upload"
                          type="file"
                          onChange={photofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Cancelled Cheque Copy :{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          id="upload"
                          type="file"
                          onChange={checkfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      {formpass !== "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={formpass}
                          />
                        </div>
                      )}

                      {formpass === "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={require("../../../../assets/images/placeholder.png")}
                          />
                        </div>
                      )}
                    </Col>

                    <Col lg={6} md={6}>
                      {formcheque !== "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={formcheque}
                          />
                        </div>
                      )}

                      {formcheque === "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={require("../../../../assets/images/placeholder.png")}
                          />
                        </div>
                      )}
                    </Col>
                    <hr className="w-100" />
                  </Row>
                </form>
                {errorMessage !== "" && (
                  <span className="text-danger">{errorMessage}</span>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12} md={12} xl={12} sm={12}>
            <div className="text-center">
              <a
                onClick={addaccount}
                variant="success"
                type="button"
                className="btn my-1 btn-success tx-teko tx-uppercase"
              >
                {" "}
                Submit
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NidjamAddAccountdetails;
