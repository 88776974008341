import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const Configuration = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [status, setStatus] = useState("1");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [page, setPage] = useState("");

  const [errorage, setAgeMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function Selectstatus(event) {
    setStatus(event.target.value);
  }

  var arr = [];
  useEffect(() => {}, []);

  const columnDefs = [
    {
      headerName: "Reg No",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "First Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      width: 170,
      field: "last_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "D.O.B",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "State",
      width: 150,
      field: "state",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOR",
      width: 150,
      field: "registerdate",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Order Id",
      width: 150,
      field: "id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              to={"/athlete/meetview/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  function handleshow() {
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  function validate(event) {
    setAgeMessage("");
    setErrorMessage("");
    // agevalidate(event.target.value).then(response => {
    //     if (response !== undefined) {
    //         if (response.status === false) {
    //             setAgeMessage(response.message);
    //             setErrorMessage(response.message);
    //         }
    //     }
    // })
  }

  function Submitage() {
    setErrorMessage("");
    const tempData = {
      agegroup: name,
      description: desc,
      isactive: status,
    };
    if (name !== "") {
      if (errorage === "") {
        if (desc !== "") {
          if (page !== "") {
            // ageadd(tempData).then(response => {
            //     if (response !== undefined) {
            //         if (response.status === true) {
            //             window.location.replace("/agegroup/agegrouplist");
            //             // navigate("/agegroup/agegrouplist")
            //             toast.success(response.message)
            //         } else {
            //             setErrorMessage(response.data.errors[0].message);
            //         }
            //     }
            // })
          } else {
            setErrorMessage("Please Enter Your System Configuration Page");
          }
        } else {
          setErrorMessage("Please Enter Your System Configuration Description");
        }
      } else {
        setErrorMessage(errorage);
      }
    } else {
      setErrorMessage("Please Enter Your System Configuration Name");
    }
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            System Configuration List
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    onClick={handleshow}
                    variant="success"
                    type="button"
                    className="btn my-1 me-2 btn-success tx-teko"
                  >
                    {" "}
                    Add
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}

                  />
                </div>
              </div>

              <Modal show={show}>
                <Modal.Header>
                  <h4>Add System Configuration</h4>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            System Configuration Name{" "}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your System Configuration Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={validate}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Description <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="form-group">
                          <textarea
                            placeholder="Enter Your Description"
                            rows={4}
                            cols={40}
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            System Configuration Page{" "}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your System Configuration Page"
                            value={page}
                            onChange={(e) => setPage(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Status <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />
                              Inactive
                            </label>
                          </div>
                        </div>
                      </Col>
                      {errorMessage !== "" && (
                        <span className="text-danger">{errorMessage}</span>
                      )}
                    </Row>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Configuration;
