import { createSlice } from '@reduxjs/toolkit';

export const athleteSlice = createSlice({
    name: 'athlete',
    initialState: {
        first: null,
        last: null,
        date: null,
        gen: null,
        email: null,
        bgroup: null,
        phone: null,
        aadno: null,
        faname: null,
        moname: null,
        peradd1: null,
        peradd2: null,
        perst: null,
        perdt: null,
        perpin: null,
        comadd1: null,
        comadd2: null,
        comst: null,
        comdt: null,
        compin: null,
        photo: null,
        dobphoto: null,
        addphoto: null,
        bplst: null,
        bplphoto: null,
    },
    reducers: {
        athletereg: (state, action) => {
            return Object.assign({}, state, { 
                first : action.payload.first,
                last :  action.payload.last,
                date :  action.payload.date,
                gen :  action.payload.gen,
                email :  action.payload.email,
                bgroup :  action.payload.bgroup,
                phone :  action.payload.phone,
                aadno :  action.payload.aadno,
                faname :  action.payload.faname,
                moname :  action.payload.moname,
                peradd1 :  action.payload.peradd1,
                peradd2 :  action.payload.peradd2,
                perst :  action.payload.perst,
                perdt :  action.payload.perdt,
                perpin :  action.payload.perpin,
                comadd1 :  action.payload.comadd1,
                comadd2 :  action.payload.comadd2,
                comst :  action.payload.comst,
                comdt :  action.payload.comdt,
                compin :  action.payload.compin,
                photo :  action.payload.photo,
                dobphoto :  action.payload.dobphoto,
                addphoto :  action.payload.addphoto,
                bplst :  action.payload.bplst,
                bplphoto :  action.payload.bplphoto,
            });
        },
    },
});

export const { athletereg } = athleteSlice.actions;
export const selectFirst = (state) => state.athlete.first;
export const selectLast = (state) => state.athlete.last;
export const selectDate = (state) => state.athlete.date;
export const selectGender = (state) => state.athlete.gen;
export const selectEmail = (state) => state.athlete.email;
export const selectBlood = (state) => state.athlete.bgroup;
export const selectPhone = (state) => state.athlete.phone;
export const selectAadhar = (state) => state.athlete.aadno;
export const selectFathar = (state) => state.athlete.faname;
export const selectMothar = (state) => state.athlete.moname;
export const selectPAdd1 = (state) => state.athlete.peradd1;
export const selectPAdd2 = (state) => state.athlete.peradd2;
export const selectPState = (state) => state.athlete.perst;
export const selectPDist = (state) => state.athlete.perdt;
export const selectPPin = (state) => state.athlete.perpin;
export const selectCAdd1 = (state) => state.athlete.comadd1;
export const selectCAdd2 = (state) => state.athlete.comadd2;
export const selectCState = (state) => state.athlete.comst;
export const selectCDist = (state) => state.athlete.comdt;
export const selectCPin = (state) => state.athlete.compin;
export const selectPhoto = (state) => state.athlete.photo;
export const selectDobPhoto = (state) => state.athlete.dobphoto;
export const selectAddrPhoto = (state) => state.athlete.addphoto;
export const selectBPLStatus = (state) => state.athlete.bplst;
export const selectBPLPhoto = (state) => state.athlete.bplphoto;


export default athleteSlice.reducer;
