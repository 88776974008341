import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  districtadd,
  districtlist,
  districtstatus,
  districtvalidate,
  districtview,
} from "../../../services/master";
import { statelist } from "../../../services/registerservice";

const Districts = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [slist, setStatelist] = useState([]);

  const [status, setStatus] = useState("1");
  const [id, setID] = useState(0);
  const [sname, setSName] = useState("");
  const [name, setName] = useState("");
  const [errorage, setAgeMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function Selectstatus(event) {
    setStatus(event.target.value);
  }

  var arr = [];
  useEffect(() => {
    funlist();
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }, []);

  function funlist() {
    setFilterProduct([]);
    districtlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "District",
      width: 370,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Password",
      width: 200,
      field: "refnonhash",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 350,
      field: "statename",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "active",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        if (params.value === null) {
          return (
            <div className="text-center">
              <label className="custom-switch mb-0">
                <input
                  type="checkbox"
                  name="custom-switch-checkbox"
                  className="custom-switch-input"
                  checked={params.value === null ? true : false}
                  onChange={() => handleSwitch(params)}
                />

                <span className="custom-switch-indicator"></span>
              </label>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <label className="custom-switch mb-0">
                <input
                  type="checkbox"
                  name="custom-switch-checkbox"
                  className="custom-switch-input"
                  checked={params.value === 1 ? true : false}
                  onChange={() => handleSwitch(params)}
                />

                <span className="custom-switch-indicator"></span>
              </label>
            </div>
          );
        }
      },
    },

    {
      headerName: "Actions",
      width: 130,
      field: "id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => viewshow(params.value)}
              className="btn-sm btn-info"
            >
              <i className="fa fa-edit"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function handleSwitch(list) {
    var swivalue = 0;
    if (list.value === null) {
      swivalue = 1;
    } else {
      swivalue = list.value;
    }
    if (swivalue === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.id);
          temp.append("active", 0);

          districtstatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.id);
          temp.append("active", 1);

          districtstatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    }
  }

  const [show, setShow] = useState(false);
  function handleshow() {
    setID(0);
    setStatus("1");
    setSName("");
    setName("");
    setAgeMessage("");
    setErrorMessage("");
    setShow(true);
  }

  function handleclose() {
    setID(0);
    setShow(false);
  }

  const [vshow, setVShow] = useState(false);
  function viewshow(vid) {
    setID(vid);
    setAgeMessage("");
    setErrorMessage("");
    setVShow(true);
    districtview(vid).then((response) => {
      if (response.status === true) {
        setSName(response.data.stateuid);
        setName(response.data.districtname);
        setStatus(response.data.isactive + "");
      }
    });
  }

  function viewclose() {
    setID(0);
    setVShow(false);
  }

  function validate(event) {
    setAgeMessage("");
    setErrorMessage("");
    const temp = new FormData();
    temp.append("district", event.target.value);
    districtvalidate(temp).then((response) => {
      if (response.status === false) {
        setAgeMessage(response.message);
        setErrorMessage(response.message);
      }
    });
  }

  function Submitage() {
    setErrorMessage("");

    if (sname !== "") {
      if (errorage === "") {
        if (name !== "") {
          const tempData = new FormData();
          tempData.append("districtuid", id);
          tempData.append("stateuid", sname);
          tempData.append("districtname", name);
          tempData.append("isactive", status);

          districtadd(tempData).then((response) => {
            if (response.status === true) {
              if (id === 0) {
                handleclose();
              } else {
                viewclose();
              }
              funlist();
              toast.success(response.message);
            } else {
              setErrorMessage(response.data.message);
            }
          });
        } else {
          setErrorMessage("Please Enter Your District");
        }
      } else {
        setErrorMessage(errorage);
      }
    } else {
      setErrorMessage("Please Select Your State");
    }
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">District List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    onClick={handleshow}
                    variant="success"
                    type="button"
                    className="btn my-1 me-2 btn-success tx-teko"
                  >
                    {" "}
                    Add
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}

                  />
                </div>
              </div>

              <Modal show={show}>
                <Modal.Header>
                  <h4>Add District</h4>
                </Modal.Header>
                <Modal.Body>
                  <Row className="row-sm mx-0">
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          State Name <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <select
                          value={sname}
                          onChange={(e) => setSName(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Your State
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.stateuid} key={i}>
                                {data.statename}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          District Name <span className="text-danger"> *</span>
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter Your District Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={validate}
                        />
                      </div>
                    </Col>

                    <Col lg={12} md={12}>
                      <div className="form-group">
                        <label className="mb-2">
                          Status <span className="text-danger"> *</span>
                        </label>
                      </div>

                      <div className="row" onChange={Selectstatus}>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="1"
                              checked={status === "1"}
                            />
                            Active
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="custom-control custom-radio custom-control-md">
                            <input
                              type="radio"
                              className="reg-chk"
                              id="active"
                              value="0"
                              checked={status === "0"}
                            />
                            Inactive
                          </label>
                        </div>
                      </div>
                    </Col>
                    {errorMessage !== "" && (
                      <span className="text-danger">{errorMessage}</span>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={vshow}>
                <Modal.Header>
                  <h4>View District</h4>
                </Modal.Header>
                <Modal.Body>
                  <Modal.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            State Name <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <select
                            value={sname}
                            onChange={(e) => setSName(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            District Name{" "}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your District Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={validate}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Status <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />
                              Inactive
                            </label>
                          </div>
                        </div>
                      </Col>
                      {errorMessage !== "" && (
                        <span className="text-danger">{errorMessage}</span>
                      )}
                    </Row>
                  </Modal.Body>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={viewclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Districts;
