import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Form, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  statefedgenderathletelist,
  statefednocathletelist,
  statefedoverageathletelist,
  statefedreportsubmit,
  statefedtotalathletelist,
} from "../../../../services/state";
const AthletesList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [urllink, setUrllink] = useState("");
  const [repreason, setRepreason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setFilterProduct([]);
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setUrllink(link[1]);
    if (eid === "athletelist") {
      statefedtotalathletelist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (eid === "maleathletelist") {
      statefedgenderathletelist("Male").then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (eid === "femaleathletelist") {
      statefedgenderathletelist("Female").then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (eid === "nocathletelist") {
      statefednocathletelist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    } else if (eid === "overageathletelist") {
      statefedoverageathletelist().then((response) => {
        if (response.status === true) {
          setFilterProduct(response.data);
        }
      });
    }
  }, []);

  const columnDefs = [
    {
      headerName: "Full Name",
      width: 250,
      field: "logo",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = params.data.first_name;

        if (params.data.middle_name !== null) {
          if (evt !== "") {
            evt = evt + " " + params.data.middle_name;
          }
        }
        if (params.data.last_name !== "") {
          if (evt === "") {
            evt = params.data.last_name;
          } else if (evt !== "") {
            evt = evt + " " + params.data.last_name;
          }
        }
        return evt;
      },
    },

    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "Mobile",
      width: 180,
      field: "mobile_no",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "AFI UID",
      width: 180,
      field: "username",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={"/state/athleteprofile/" + params.value}
              className="btn-sm btn-info me-2"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
            <a
              className="btn-sm btn-danger"
              onClick={() => handleshow(params.value)}
            >
              <i className="fa fa-file-text-o fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];
  const [uid, setUid] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(tid) {
    setUid("");
    setErrorMessage("");
    setUid(tid);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
    setUid("");
  }

  function submitreport() {
    setErrorMessage("");
    if (repreason !== "") {
      const tempData = new FormData();
      tempData.append("user_id", uid);
      tempData.append("reason", repreason);

      statefedreportsubmit(tempData).then((response) => {
        if (response.status === true) {
          setUid("");
          handleclose();
        }
      });
    } else {
      setErrorMessage("Please Enter Your Reason");
    }
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          {urllink === "athletelist" && (
            <h2 className="main-content-title tx-24 mg-b-5">All Athletes</h2>
          )}
          {urllink === "maleathletelist" && (
            <h2 className="main-content-title tx-24 mg-b-5">Male Athletes</h2>
          )}
          {urllink === "femaleathletelist" && (
            <h2 className="main-content-title tx-24 mg-b-5">Female Athletes</h2>
          )}
          {urllink === "nocathletelist" && (
            <h2 className="main-content-title tx-24 mg-b-5">NOC Athletes</h2>
          )}
          {urllink === "overageathletelist" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Overage Athletes
            </h2>
          )}
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    className="btn btn-secondary me-2"
                    onClick={() => navigate("/state/dashboard/")}
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show}>
        <Modal.Header>
          <h6 className="rep-head">Report Athlete</h6>
        </Modal.Header>
        <Modal.Body>
          <span>Reason</span>
          <div className="col-lg textarea-reason">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter the reason"
              onChange={(e) => {
                setRepreason(e.target.value);
              }}
            ></Form.Control>
          </div>
          {errorMessage !== "" && (
            <span className="errortxt text-danger">{errorMessage}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            onClick={submitreport}
          >
            Submit
          </a>
          <a
            className="btn btn-next btn-secondary"
            style={{ float: "right" }}
            onClick={handleclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AthletesList;
