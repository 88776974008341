import React, { Fragment, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate } from 'react-router-dom';

const Accountdetail = () => {
    const navigate = useNavigate();
    
    const columns = [
        {
            name: 'Events',
            selector: row => [row.ID],
            sortable: true,
            cell: row => <div className="font-weight-bold">
                {row.ID}
            </div>,

        },

        {
            name: 'Amount',
            selector: row => [row.Invoice],
            cell: row =>
                <div className="font-weight-bold">
                    {row.Invoice}
                </div>,
            sortable: true
        },
        {
            name: 'Full Name',
            selector: row => [row.Name],
            sortable: true,
            cell: row =>
                <div>
                    {row.Name}
                </div>
        },
        {
            name: 'Bank Name',
            selector: row => [row.Date],
            sortable: true,
            cell: row =>
                <div>
                    {row.Date}
                </div>
        },
        {
            name: 'Branch Name',
            selector: row => [row.Total],
            cell: row =>
                <div className="d-flex my-auto">

                    <span className="my-auto">{row.Total}</span>
                </div>,

            sortable: false,

        },
        {
            name: 'Account No',
            selector: row => [row.Warhouse],
            sortable: true,
            cell: row =>
                <div className="font-weight-semibold">
                    {row.Warhouse}
                </div>

        },
        {
            name: 'IFSC Code',
            selector: row => [row.Status],
            sortable: true,
            cell: row =>
                <div>
                    <span className={`status bg-${row.Statusinfo}`}></span>
                    {row.Status}
                </div>
        },

        {
            name: 'Proof',
            selector: row => [row.ACTIONS],
            sortable: true,
            // cell: row =>
            //     <div className="button-list" >
            //         Hi {row}
            //     </div>
        }
    ];
    let data1 = [
        {

            ID: '#W83549801',
            Invoice: '2',
            Name: 'Anna sthesia',
            Date: '08/11/2020',
            Total: '$1,000',
            Warhouse: 'Boston',
            Status: 'pending',
            Statusinfo: 'warning',
            Placement: 'top'
        },
        {
            ID: '#W83549802',
            Invoice: '5',
            Name: 'Barb Dwyer',
            Date: '15/11/2020',
            Total: '$4,577',
            Warhouse: 'Washington Dc',
            Status: 'Delivered',
            Statusinfo: 'success',
        },
        {
            ID: '#W83549803',
            Invoice: '3',
            Name: 'Wilma Mumduya',
            Date: '17/11/2020',
            Total: '$4,500',
            Warhouse: 'San Francisco',
            Status: 'Delivered',
            Statusinfo: 'success',
        },
        {
            ID: '#W83549804',
            Invoice: '4',
            Name: 'Zack Lee',
            Date: '18/11/2020',
            Total: '$3,266',
            Warhouse: 'Las Vegas',
            Status: ' Refunded',
            Statusinfo: 'info',
        },
        {
            ID: '#W83549805',
            Invoice: '5',
            Name: 'Tom Foolery',
            Date: '20/11/2020',
            Total: '$1,30,000',
            Warhouse: 'Los Angel',
            Status: 'Cancelled',
            Statusinfo: 'danger',
        },
        {
            ID: '#W83549806',
            Invoice: '6',
            Name: 'Pat Agonia',
            Date: '22/11/2020',
            Total: '$2,535',
            Warhouse: 'Chicago',
            Status: 'Delivered',
            Statusinfo: 'success',
        },
        {
            ID: '#W83549807',
            Invoice: '6',
            Name: 'Mary Christmas',
            Date: '26/11/2020',
            Total: '$1,526',
            Warhouse: 'Los Angels',
            Status: 'Cancelled',
            Statusinfo: 'danger',
        },
        {
            ID: '#W83549808',
            Invoice: '5',
            Name: 'Ella Vator',
            Date: '29/11/2020',
            Total: '$1,500',
            Warhouse: 'Chicago',
            Status: 'pending',
            Statusinfo: 'warning',
        },
        {
            ID: '#W83549809',
            Invoice: '8',
            Name: 'Sharon Needles',
            Date: '01/12/2020',
            Total: '$2,30,000',
            Warhouse: 'Uk',
            Status: 'Delivered',
            Statusinfo: 'success',
        },
        {
            ID: '#W835498010',
            Invoice: '7',
            Name: 'Anne Fibbiyon',
            Date: '04/12/2020',
            Total: '$33,990',
            Warhouse: 'Chicago',
            Status: ' Refunded',
            Statusinfo: 'info',
        },
        {
            ID: '#W835498011',
            Invoice: '1',
            Name: 'Frank Senbeans',
            Date: '09/12/2020',
            Total: '$12,999',
            Warhouse: 'Chicago',
            Status: 'Cancelled',
            Statusinfo: 'danger',
        },
        {
            ID: '#W835498012',
            Invoice: '12',
            Name: 'Chris P. Bacon',
            Date: '12/12/2020',
            Total: '$15,993',
            Warhouse: 'Brazil',
            Status: 'Delivered',
            Statusinfo: 'success',
        },
    ];

    let [data, setData] = useState(data1);

    
    const tableData = {
        columns,
        data,
    };
    
    return (
        <Fragment>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
            Account Details
                    </h2>
                </div>
            </div>

            <Row className="row-sm">
                <Col md={12} lg={12}>
                    <Card className=" custom-card">
                        <Card.Header className=" border-bottom-0 pb-0">
                            <div>
                                <div className="d-flex">
                                    <label className="main-content-label my-auto pt-2">
                    Account Details
                                    </label>
                                </div>
                                <a
                                    className="btn btn-success"
                                    style={{ float: 'right' }} onClick={() => {
                                        navigate('/athlete/addaccount');
                                    }} 
                                ><i className='fa fa-plus'></i>
                                               &nbsp; Add Account
                                </a>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row className="table-filter">
                                <Col lg={3}>
                                </Col>
                                <Col lg={9} className="d-lg-flex">
                                    <div className="d-flex ms-auto mt-4 me-4 mt-lg-0"></div>
                                    <div className="d-flex mt-4 mt-lg-0">
                                        <div className="filter-group"></div>
                                    </div>
                                </Col>
                            </Row>

              
              
                            <DataTableExtensions {...tableData} >
                                <DataTable
                                    columns={columns}
                                    defaultSortAsc={false}
                                    // striped={true}
                                    pagination

                                />
                            </DataTableExtensions>

             
              
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


           
        </Fragment>
       
      
    );
};

export default Accountdetail;
