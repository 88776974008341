import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { statedownloadcertificate } from "../../../services/superadmin";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
const StateCertificateQR = () => {
  const { id } = useParams();
  const [cert, setCert] = useState("");
  useEffect(() => {
    statedownloadcertificate(id).then((response) => {
      if (response.status === true) {
        setCert(imageUrl + fileString.athletecertificate + response.data.certificate_name);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="qrcertificate">
        <iframe src={cert} />
      </div>
      <div className="text-center mt-3">
        <a className="btn btn-success" target="_blank" href={cert} download>
          Download
        </a>
      </div>
      <div className="mt-3"></div>
    </Fragment>
  );
};

export default StateCertificateQR;
