import React, { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
export function Backtotop1() {
  const [BacktoTop, setBacktopTop] = useState();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setBacktopTop("d-block") : setBacktopTop("");
    });
  }, []);
  const screenup = () => {
    window.scrollTo({ top: 10, behavior: "auto" });
  };
  return (
    <Fragment>
      <div className="d-block" id="athlete-support">
        <img
          src={require("../../../assets/images/support.png")}
          alt="Support"
          onClick={() =>
            window.open(
              "http://afisupport.sportingindia.club/customer/autologin/" +
                localStorage.getItem("User") +
                "/" +
                localStorage.getItem("password"),
              "_blank"
            )
          }
        />
      </div>
    </Fragment>
  );
}
