import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { athleteview } from "../../services/athlete";
import "./dashboard.scss";
import { dashboarcount } from "../../services/superadmin";
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const [all, setAll] = useState("");
  const [approved, setApproved] = useState("");
  const [pending, setPending] = useState("");
  const [reject, setRejecte] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    dashboarcount().then((response) => {
      if (response.status === true) {
        setAll(response.data.all);
        setApproved(response.data.approved);
        setPending(response.data.pending);
        setRejecte(response.data.rejected);
      }
    });
  }, []);
  return (
    <Fragment>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      <Row className=" row-sm  mt-lg-4">
        <Col sm={12} lg={12} xl={12}>
          <Card className="bg-black-2 custom-card card-box">
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col
                  sm={{ offset: 6 }}
                  xl={{ offset: 3 }}
                  className="col-xl-8 col-sm-6 col-12 img-bg "
                >
                  <h4 className="d-flex  mb-3">
                    <span className="font-weight-bold text-dark ">
                      {localStorage.getItem("User")}
                    </span>
                  </h4>
                  <p className="tx-black-5 mb-1">
                    Athletics Federation of India
                  </p>
                </Col>
                <img
                  src={require("../../assets/images/athlete.png")}
                  alt="athlete"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!--Row--> */}
      <label className="main-content-label my-auto text-danger">
        Athletes Lists
      </label>
      <Row className="row-sm mt-3">
        <Col sm={12} md={3} lg={3} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div
                  className="card-item-icon card-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/superadmin/allathletes/")}
                >
                  <i className="fe fe-eye tx-20 text-danger"></i>
                </div>
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    Applied Athletes
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{all}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div
                  className="card-item-icon card-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/superadmin/approvedathletes/")}
                >
                  <i className="fe fe-eye tx-20 text-danger"></i>
                </div>
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    Approved Athletes
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{approved}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div
                  className="card-item-icon card-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/superadmin/pendingathletes/")}
                >
                  <i className="fe fe-eye tx-20 text-danger"></i>
                </div>
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    Pending Athletes
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{pending}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={3} lg={3} xl={3}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div
                  className="card-item-icon card-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/superadmin/rejectedathletes/")}
                >
                  <i className="fe fe-eye tx-20 text-danger"></i>
                </div>
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    Rejected Athletes
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{reject}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col sm={12} md={4} lg={4} xl={4}>
          <Card className=" custom-card overflow-hidden">
            <Card.Header className=" border-bottom-0">
              <label className="main-content-label my-auto text-danger">
                Athletes Lists
              </label>
            </Card.Header>
            <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
              <li className="list-item mt-1 mb-0 px-3 mt-0">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-15">Applied Athletes</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-16 mb-0">{all}</h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-15">Approved Athletes</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-16 mb-0">
                    {approved}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-15">Pending Athletes</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-16 mb-0">{pending}</h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-3 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-15">Rejected Athletes</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-16 mb-0">{reject}</h5>
                </div>
              </li>
            </ul>
          </Card>
        </Col> */}

        {/* <Col sm={12} md={4} lg={4} xl={4}>
                    <Card className=" custom-card overflow-hidden">
                        <Card.Header className=" border-bottom-0">
                            <label className="main-content-label my-auto">Officials Lists</label>
                        </Card.Header>
                        <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
                            <li className="list-item mt-1 mb-0 px-3 mt-0">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Applied Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Approved Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Pending Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-3 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Rejected Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>  
                                </div>
                            </li>
                        </ul>
                    </Card>
                </Col>

                <Col sm={12} md={4} lg={4} xl={4}>
                    <Card className=" custom-card overflow-hidden">
                        <Card.Header className=" border-bottom-0">
                            <label className="main-content-label my-auto">Coaches Lists</label>
                        </Card.Header>
                        <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
                            <li className="list-item mt-1 mb-0 px-3 mt-0">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Applied Coaches</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Approved Coaches</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Pending Coaches</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-3 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-15">Rejected Coaches</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-16 mb-0">03485</h5>  
                                </div>
                            </li>

                        </ul>
                    </Card>
                </Col> */}
      </Row>
      {/* <!-- Row end --> */}
    </Fragment>
  );
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
