import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { statefedreportedlist } from "../../../../services/state";
const ReportedList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    statefedreportedlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Full Name",
      width: 250,
      field: "logo",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        var evt = params.data.first_name;

          if (params.data.middle_name !== null) {
            if (evt !== "") {
              evt = evt + " " + params.data.middle_name;
            }
          }
        if (params.data.last_name !== "") {
          if (evt === "") {
            evt = params.data.last_name;
          } else if (evt !== "") {
            evt = evt + " " + params.data.last_name;
          }
        }
        return evt;
      },
    },

    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== null) {
          return moment(data.value).format("DD-MM-YYYY");
        } else {
          return "-";
        }
      },
    },

    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "Mobile",
      width: 180,
      field: "mobile_no",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "AFI UID",
      width: 180,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/state/athleteprofile/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Reported Athletes</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    className="btn btn-secondary me-2"
                    onClick={() => navigate("/state/dashboard/")}
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ReportedList;
