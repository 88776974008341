import React from 'react';
import { useNavigate } from 'react-router-dom';
import './registration.css';

function Athleteidcard() {
    const navigate = useNavigate();

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function paynow(){
        const res = await loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
        );
        if (!res) {
            console.log('Razorpay SDK failed to load . Are you online?');
            return;
        }
        const options = {
            key: 'rzp_test_zt5DDs1PmkkyDy',
            amount: '5000',
            currency: 'INR',
            order_id: '',
            name: 'Athletics Federation of India',
            description: 'Thank you for nothing. Please give us some money',
            image: '',
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
            },
            prefill: {
                email: '',
                phone_number: '',
            },
        };
        const paymentObj = new window.Razorpay(options);
        paymentObj.open();
  
    }

    return (
        <div id="registrationpage">
            <div className="registrationmain preview">
                <div className="registrationleft">
                    <img src={require('../../assets/images/logo/logo.png')} height="60" alt='AFI' onClick={()=>{ navigate('/login');}}/>
                </div>
                <div className="registrationright">
                    <div id="idcard">
                        <div className="id_main">
                            <div className="card card_left">
                                <div className="profil">
                                    <img src={require('../../assets/images/user.png')} alt='user'/>
                                </div>

                                <div className="area">
                                    <h5>Senthil Kumar AS</h5>
                                    <div className="id_content">
                                        <h6 className="id_dob">21/11/1977</h6>
                                        <h6 className="id_gender">Male</h6>
                                    </div>
                                    <div className="id_uid">
                                        <h6>ATNMXXXXXX</h6>
                                    </div>
                                    <div className="id_signature">
                                        <img src={require('../../assets/images/signature.png')} height="20" alt='sign' />
                                        <h6>Ravinder Chaudhry</h6>
                                        <p>Secretary</p>
                                        <p>Authorised Signatory</p>
                                    </div>
                                </div>
                                <div className="id_preview">
                                    <h6 className="blink">preview</h6>
                                </div>

                            </div>
                            <div className="card card_right">
                                <div className="id_address">
                                    <h6>No.6, Gurusamy Nagar, Thaneerpandal Road, Peelamedu Po, Coimbatore, Tamilnadu - 641004.</h6>
                                </div>
                                <div className="id_qrcode">
                                    <img src={require('../../assets/images/qrcode.png')} alt='qrcode' />
                                </div>
                            </div>
                        </div>
                        <div className="id_fields">
                            <div className="id_buttons text-center">
                                <a  className="btn btn-info btn-sm">Edit Details</a>
                            </div>
                        </div>
                        <div className="id_final">
                            <div className="id_bottom">
                                <div className="checkbox checkbox-1">
                                    <input type="checkbox" id="checkbox-1" />
                                    <label htmlFor="checkbox-1">I agree that above details are correct!</label>
                                </div>
                                <div className="id_bottombtn">
                                    <a  className="btn btn-success btn-sm" onClick={paynow}>Submit</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Athleteidcard;
