import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tourdetails, usertourview, uservalidate } from "../../services/tourreg";
import moment from "moment";

function TourRegister() {
  const [user, setUser] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");
  const [tourclose, setTourClose] = useState("");

  useEffect(() => {
    tourdetails(id).then((res) => {
      if (res.status === true) {
        setTourname(res.data.meet_name);
        setTourloc(res.data.location);
        setTourdate(
          moment(res.data.start_date).format("DD/MM/YYYY") +
            " - " +
            moment(res.data.end_date).format("DD/MM/YYYY")
        );
        setTourClose(
          moment(res.data.entry_deadline_date).format("DD/MM/YYYY")
        );
      }
    });
  }, []);
  function Submitlogin(e) {
    e.preventDefault();
    setErrorMessage("");

    if (user !== "") {
      localStorage.clear();
      uservalidate(user, id).then((response) => {
        if (response.status === true) {
          localStorage.setItem("Userid", response.data.athlete.user_id);
          localStorage.setItem("User", response.data.athlete.username);
          localStorage.setItem("Roleid", response.data.athlete.role_id);
          localStorage.setItem("State", response.data.athlete.state);

          navigate("/meetadd/" + id);
        } else {
          setErrorMessage(response.message);
        }
      });
    } else {
      setErrorMessage("Please Enter Your Username");
    }
  }

  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_logo">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <h3>{tourname}</h3>
                <h6>
                  <i className="fa fa-map-marker me-2 text-danger"></i>
                  {tourloc}
                </h6>
                <h6>
                  <i className="fa fa-calendar me-2 text-danger"></i>
                  {tourdate}
                </h6>
                <h6>
                  <span className="text-danger">Entry Close - </span>
                  {tourclose}
                </h6>
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h6 className="text-center">
                    Welcome to Athletics Federation of India!
                  </h6>
                  <h2 className="text-center">Tournament Register</h2>
                  <fieldset className="username">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Enter your Username"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                      autoComplete="off"
                    />
                    {errorMessage && (
                      <span className="text-danger"> {errorMessage} </span>
                    )}
                  </fieldset>

                  <button type="submit" className="btn" onClick={Submitlogin}>
                    Register
                  </button>
                  <div className="text-right">
                    <a
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="loginpage_footer">
            <div className="loginpage_howtoregis"></div>
            <div className="copyrights">
              <span>© 2023 Athletics Federation of India</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TourRegister;
