import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./state.css";
import { meetdetails } from "../../../services/meetservice";
import { statefedsummarylist } from "../../../services/state";

const StateHeading = () => {
  const [tname, setTourName] = useState("");
  const navigate = useNavigate();
  const [id, setID] = useState("");

  const [acount, setAthCount] = useState("navitxt");
  const [ocount, setOffCount] = useState("navitxt");
  const [tracount, setTraCount] = useState("navitxt");
  const [moscount, setMosCount] = useState("navitxt");

  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[2];
    setID(eid);
    meetdetails(eid).then((response) => {
      if (response.status === true) {
        setTourName(response.data.tournament.meet_name);
      }
    });

    // const tempData=new FormData();
    // tempData.append("tournament_meet_id", eid);

    // statefedsummarylist(tempData).then((response) => {
    //   if (response.status === true) {
    //     if (response.data.athletes_list.length !== 0) {
    //       setAthCount('navitxt active');
    //     }
    //     if (response.data.officials_list.length !== 0) {
    //       setOffCount('navitxt active');
    //     }

    //     if (response.data.travel_arrival_details.length !== 0 && response.data.travel_departure_details.length !== 0) {
    //       setTraCount('navitxt active');
    //     }
    //     if (response.data.Minutesofselection !== 0 && response.data.performanceproof !== 0) {
    //       setMosCount('navitxt active');
    //     }

    //   }
    // });
  }, []);
  return (
    <Fragment>
      <div id="statedash">
        <div className="page-header d-block">
          <div>
            <Card className="card card-aside custom-card">
              <Card.Body>
                <div className="d-flex align-items-center pt-0 mt-auto">
                  <div className="me-3">
                    <a>
                      <h5 className="main-content-label tx-24 mb-0 tx-medium">
                        {tname}
                      </h5>
                    </a>
                  </div>

                  <div className="ms-auto">
                    <Link
                      to={"/state/dashboard"}
                      className="icon d-none d-md-inline-block ms-3 logout text-primary"
                    >
                      <i className="fe fe-home me-1 tx-20"></i> Back to home
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* <!-- End Page Header --> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-4 topheadnav">
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/athleteentrylist/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className={acount}>
                  <i className="fa fa-user-circle me-2 icon1 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Add Athlete
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/officialentrylist/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className={ocount}>
                  <i className="fa fa-stopwatch me-2 icon1 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Officials Entry
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/travelentrylist/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className={tracount}>
                  <i className="fa fa-bus me-2 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Travel Details
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/selectioncommittee/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className={moscount}>
                  <i className="fa fa-group me-2 icon1 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Selection Committee Mins
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/summary/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className="navitxt">
                  <i className="fa fa-pie-chart me-2 icon1 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Summary
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Col
            lg={2}
            md={2}
            xl={2}
            sm={6}
            onClick={() => navigate("/state/sendentries/" + id)}
          >
            <Card className="custom-card overflow-hidden">
              <Card.Header>
                <div className="navitxt">
                  <i className="fa fa-send me-2 icon1 tx-18"></i>
                  <h3 className="card-title tx-16 mb-0 lineheight1">
                    <span className="main-content-label tx-16 tx-amarnath mb-0">
                      Send Entries
                    </span>
                  </h3>
                </div>
              </Card.Header>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default StateHeading;
