import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  agecategoryeventadd,
  agecategoryeventdelete,
  agecategoryeventlist,
  meeteventlist,
  meetinfo,
} from "../../../services/meetservice";
import { toast } from "react-toastify";

const EditEventAgeCategory = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { age } = useParams();

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");
  const [elist, setEventList] = useState([]);

  useEffect(() => {
    meetinfo(id).then((response) => {
      if (response.status === true) {
        setTourname(response.data.tournament.meet_name);
        setTourloc(response.data.tournament.location);
        setTourdate(
          moment(response.data.tournament.start_date).format("DD/MM/YYYY") +
            " - " +
            moment(response.data.tournament.end_date).format("DD/MM/YYYY")
        );
      }
    });
    meeteventlist().then((response) => {
      if (response.status === true) {
        setEventList(response.data);
      }
    });
    loadlist();
  }, []);

  function loadlist() {
    agecategoryeventlist(id, age).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.age_category_events);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "Age Category",
      width: 450,
      field: "age_category_name",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Events",
      width: 450,
      field: "event_name",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "id",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => deleteage(params.value)}
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function deleteage(did) {
    const tempData = new FormData();
    tempData.append("event_table_id", did);
    agecategoryeventdelete(tempData).then((response) => {
      if (response.status === true) {
        toast.success(response.data[0]);
        loadlist();
      }
    });
  }

  const [show, setShow] = useState(false);
  const [eventid, setEventid] = useState("");

  function ageshow() {
    setShow(true);
  }

  function ageclose() {
    setShow(false);
  }

  function selectevent(e) {
    setEventid(e.target.value);
  }
  const [errorMessage, setErrorMessage] = useState("");

  function updateage() {
    setErrorMessage("");
    if (eventid !== "") {
      const tempData = new FormData();
      tempData.append("meet_id", id);
      tempData.append("age_category_id", age);
      tempData.append("event_id", eventid);

      agecategoryeventadd(tempData).then((response) => {
        if (response.status === true) {
          ageclose();
          toast.success(response.data[0]);
          loadlist();
        }
      });
    } else {
      setErrorMessage("Please Select Your Event");
    }
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Edit Age Category Events
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Header>
              <div className="text-center">
                <h4 className="mb-0 mt-3">{tourname}</h4>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-map-marker me-2 text-danger"></i>
                    {tourloc}
                  </h6>
                </div>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-calendar me-2 text-danger"></i>
                    {tourdate}
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="responsive">
                <div className="text-right">
                  <a className="btn btn-success me-2" onClick={ageshow}>
                    Add New Event
                  </a>
                  <a
                    className="btn btn-danger"
                    onClick={() =>
                      navigate("/superadmin/editagecategory/" + id)
                    }
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 520,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show}>
        <Modal.Header>
          <h4>Add Age Category Events</h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row className="row-sm mx-0">
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Select Event <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <select value={eventid} onChange={selectevent}>
                    <option value="" disabled>
                      Select Event
                    </option>
                    {elist.map((data, i) => {
                      return (
                        <option value={data.event_uid} key={i}>
                          {data.event_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>

              {errorMessage !== "" && (
                <span className="text-danger">{errorMessage}</span>
              )}
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            onClick={updateage}
          >
            Add
          </a>
          <a
            className="btn btn-next btn-danger"
            style={{ float: "right" }}
            onClick={ageclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default EditEventAgeCategory;
