import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

export async function logindata(data) {
  try {
    return await axios
      .post(`${baseUrl}/login`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function updatepassword(data) {
  try {
    return await axios
      .post(`${baseUrl}/athletechangepassword`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function logout() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"), //store.getState().user.token,
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/logout`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function forgotpassdata(data) {
  try {
    return await axios
      .post(`${baseUrl}/forgotpassword`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function forgotuserdata(data) {
  try {
    return await axios
      .post(`${baseUrl}/forgotusername`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  // console.log(error.response);
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
