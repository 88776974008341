import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { distlist } from "../../services/registerservice";
import {
  userathleteview,
  usertouradd,
  usertourview,
} from "../../services/tourreg";
const TourAdd = () => {
  const [cat, setCategory] = useState("");
  const [catname, setCategoryName] = useState("");

  const [state, setStateid] = useState("");
  const [catlist, setCatList] = useState("");
  const [elist, setEvent] = useState([]);

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");

  const [event1, setEvent1] = useState("");
  const [event1per, setEvent1Per] = useState("");
  const [event1proof, setEvent1Proof] = useState("");
  const [event1man, setEvent1Man] = useState("");

  const [event2, setEvent2] = useState("");
  const [event2per, setEvent2Per] = useState("");
  const [event2proof, setEvent2Proof] = useState("");
  const [event2man, setEvent2Man] = useState("");

  const [district, setDistrict] = useState("");

  const [dob, setDOB] = useState("");
  const { id } = useParams();
  const [mtype, setMType] = useState("");
  const [multi, setMulti] = useState("N");

  const [payment, setPayment] = useState("Razor Pay");
  const [free, setFree] = useState("N");

  const [event1error, setEvent1Errors] = useState("");
  const [event1load, setEvent1File] = useState("");
  function eventfile1(event) {
    setEvent1Errors("");
    setEvent1Proof("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|pdf)$/i)) {
        var last = event.target.files[0].name.substr(
          event.target.files[0].name.length - 4
        );
        if (last === ".pdf") {
          setEvent1File(require("../../assets/images/pdf.png"));
        } else {
          setEvent1File(URL.createObjectURL(event.target.files[0]));
        }
        setEvent1Proof(event.target.files[0]);
      } else {
        setEvent1Errors("Event File should be jpg or jpeg or pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setEvent1Errors("Event File size should be less than 5MB");
      }
    }
  }

  const [event2error, setEvent2Errors] = useState("");
  const [event2load, setEvent2File] = useState("");
  function eventfile2(event) {
    setEvent2Errors("");
    setEvent2Proof("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|pdf)$/i)) {
        var last = event.target.files[0].name.substr(
          event.target.files[0].name.length - 4
        );
        if (last === ".pdf") {
          setEvent2File(require("../../assets/images/pdf.png"));
        } else {
          setEvent2File(URL.createObjectURL(event.target.files[0]));
        }
        setEvent2Proof(event.target.files[0]);
      } else {
        setEvent2Errors("Event File should be jpg or jpeg or pdf format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setEvent2Errors("Event File size should be less than 5MB");
      }
    }
  }
  let arr = [];
  let arr1 = [];
  const [dlist, setDistlist] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("Userid") === null) {
      navigate("/tournamentregister/" + id);
    }
    userathleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setStateid(response.data.details.state);
        setDOB(response.data.details.date_of_birth);
        usertourview(id, localStorage.getItem("Userid")).then((res) => {
          if (res.status === true) {
            if (res.data.tournament.type === "National Meet") {
              if (res.data.national_ind_entry !== null) {
                navigate("/meetview/" + id);
              }
            }
            if (res.data.tournament.type === "State Entry") {
              if (res.data.state_ind_entry.length !== 0) {
                navigate("/meetview/" + id);
              }
            }
            setTourname(res.data.tournament.meet_name);
            setTourloc(res.data.tournament.location);
            setTourdate(
              moment(res.data.tournament.start_date).format("DD/MM/YYYY") +
                " - " +
                moment(res.data.tournament.end_date).format("DD/MM/YYYY")
            );
            setPayment(res.data.tournament.payment_gateway);
            setFree(res.data.tournament.free_tournament);

            setMType(res.data.tournament.type);
            setEvent1Man(res.data.tournament.event_proof_one_req);
            setEvent2Man(res.data.tournament.event_proof_two_req);
            setMulti(res.data.tournament.multiple_events_allowed_flag);
            setCategoryName(res.data.tournament_age_category);

            if (res.data.tounamentage.length !== 0) {
              for (let i = 0; i < res.data.tounamentage.length; i++) {
                if (
                  res.data.tournament_age_category ===
                  res.data.tounamentage[i].age_category_name
                ) {
                  setCategory(res.data.tounamentage[i].age_category_id);
                }
              }
            }
            setCatList(arr);
            if (res.data.tournamentevents.length !== 0) {
              for (let i = 0; i < res.data.tournamentevents.length; i++) {
                if (
                  res.data.tournamentevents[i].age_category_name ===
                  res.data.tournament_age_category
                ) {
                  arr1.push({
                    id: res.data.tournamentevents[i].id,
                    event: res.data.tournamentevents[i].event_name,
                    event_type: res.data.tournamentevents[i].event_type,
                  });
                }
              }
              setEvent(arr1);
            }
          }
        });
        distlist(response.data.details.state).then((datas) => {
          if (datas.status === true) {
            setDistlist(datas.data);
          }
        });
      }
    });
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  function selectevent(e) {
    setErrorMessage("");
    if (event1 !== "") {
      if (event1 === e.target.value) {
        Swal.fire({
          title: "Event 1 and Event 2 should not be same",
          allowOutsideClick: true,
        });
      } else {
        setEvent2(e.target.value);
      }
    }
  }

  const [erelay, setRelay] = useState("");
  function stateevent1(e) {
    setEvent1(e.target.value);
    if (event2 !== "") {
      if (e.target.value === event2) {
        setErrorMessage("Event 1 and Event 2 should not be same");
      }
    }
    if (elist.length !== 0) {
      for (let i = 0; i < elist.length; i++) {
        if (elist[i].event === e.target.value) {
          setRelay(elist[i].event_type);
        }
      }
    }
  }

  const [errorRelay, setErrorRelay] = useState("");
  function stateevent2(e) {
    setErrorMessage("");
    setErrorRelay("");
    setEvent2(e.target.value);
    if (e.target.value !== "") {
      if (event1 !== "") {
        if (event1 === e.target.value) {
          setErrorMessage("Event 1 and Event 2 should not be same");
        }
      }
    }
  }

  function regmeet(e) {
    e.preventDefault();
    setErrorMessage("");
    if (mtype === "National Meet") {
      if (cat !== "") {
        if (event1 !== "") {
          if (event1per !== "") {
            var eproof1 = "";
            if (event1man === "Y") {
              if (event1proof !== "") {
                eproof1 = "";
              } else {
                eproof1 = "Event 1 Proof is required";
                setEvent1Errors("Event 1 Proof is required");
              }
            }
            var eproof2 = "";

            if (eproof1 === "") {
              if (event2man === "Y") {
                if (event2proof !== "") {
                  eproof2 = "";
                } else {
                  eproof2 = "Event 2 Proof is required";
                  setEvent2Errors("Event 2 Proof is required");
                }
              }
            }

            if (eproof1 === "" && eproof2 === "") {
              var ent = "";
              if (ent === "") {
                ent = event1;
              }
              var evt2 = "-"; //Event2 empty means need to sent as "-"
              if (event2 !== "") {
                evt2 = event2;
                if (ent !== "") {
                  ent = ent + "," + event2;
                }
              }
              if (event1 !== event2) {
                const tempData = new FormData();
                tempData.append("tournament_meet_id", id);
                tempData.append("tournament_category", cat);
                tempData.append("eventslist", ent);
                tempData.append("event_1", event1);
                tempData.append("event_2", evt2);
                tempData.append("performance", event1per);
                tempData.append("performance_event_1", event2per);
                tempData.append("performance_proof", event1proof);
                tempData.append("performance_proof_event_1", event2proof);

                // addnationalindividual(tempData).then((response) => {
                //   if (response.status === true) {
                //     navigate("/meetview/" + id);
                //   }
                // });
              } else {
                setErrorMessage("Event 1 and Event 2 should not be same");
              }
            }
          } else {
            setErrorMessage("Please Select Your Event 1 Performance");
          }
        } else {
          setErrorMessage("Please Select Your Event 1");
        }
      } else {
        setErrorMessage("Please Select Your Category");
      }
    } else {
      var ent = "";
      if (ent === "") {
        ent = event1;
      }
      if (event2 !== "") {
        if (ent !== "") {
          ent = ent + "," + event2;
        }
      }

      if (district !== "") {
        if (event1 !== "") {
          var merror = "";

          if (event1 !== event2) {
            if (catname === "Under 18-Girls" || catname === "Under 18-Boys") {
              if (event2 !== "") {
                if (
                  event1 !== "Medley Relay" &&
                  event1 !== "4*100m Relay" &&
                  event1 !== "4*400m Relay" &&
                  event1 !== "4*400m Mixed Relay" &&
                  event1 !== "Long Jump" &&
                  event1 !== "Triple Jump" &&
                  event1 !== "High Jump" &&
                  event1 !== "Pole Vault" &&
                  event1 !== "Shot Put" &&
                  event1 !== "Discus Throw" &&
                  event1 !== "Hammer Throw" &&
                  event1 !== "Javelin Throw" &&
                  event1 !== "Heptathlon" &&
                  event1 !== "Decathlon" &&
                  event1 !== "Pentathlon(Two Days)" &&
                  event1 !== "Triathlon(One Day)" &&
                  event1 !== "Long Jump" &&
                  event2 !== "Medley Relay" &&
                  event2 !== "4*100m Relay" &&
                  event2 !== "4*400m Relay" &&
                  event2 !== "4*400m Mixed Relay" &&
                  event2 !== "Long Jump" &&
                  event2 !== "Triple Jump" &&
                  event2 !== "High Jump" &&
                  event2 !== "Pole Vault" &&
                  event2 !== "Shot Put" &&
                  event1 !== "Discus Throw" &&
                  event1 !== "Hammer Throw" &&
                  event1 !== "Javelin Throw" &&
                  event1 !== "Heptathlon" &&
                  event1 !== "Decathlon" &&
                  event1 !== "Pentathlon(Two Days)" &&
                  event1 !== "Triathlon(One Day)" &&
                  event1 !== "Long Jump" &&
                  event2 !== "Medley Relay" &&
                  event2 !== "4*100m Relay" &&
                  event2 !== "4*400m Relay" &&
                  event2 !== "4*400m Mixed Relay" &&
                  event2 !== "Long Jump" &&
                  event2 !== "Triple Jump" &&
                  event2 !== "High Jump" &&
                  event2 !== "Pole Vault" &&
                  event2 !== "Shot Put" &&
                  event2 !== "Discus Throw" &&
                  event2 !== "Hammer Throw" &&
                  event2 !== "Javelin Throw" &&
                  event2 !== "Heptathlon" &&
                  event2 !== "Decathlon" &&
                  event2 !== "Pentathlon(Two Days)" &&
                  event2 !== "Triathlon(One Day)" &&
                  event2 !== "Long Jump"
                ) {
                  if (
                    event1 !== "100M" &&
                    event1 !== "200M" &&
                    event1 !== "100MH" &&
                    event1 !== "110MH"
                  ) {
                    if (
                      event2 !== "100M" &&
                      event2 !== "200M" &&
                      event2 !== "100MH" &&
                      event2 !== "110MH"
                    ) {
                      merror = "required";
                      setErrorMessage(
                        "If the two individual events are track events, only one of these may be longer than 200m"
                      );
                    }
                  }
                }
              }
            }
            if (merror === "") {
              const tempData = new FormData();
              tempData.append("tournament_meet_id", id);
              tempData.append("tournament_category", catname);
              tempData.append("eventslist", ent);
              tempData.append("category_id", cat);
              tempData.append("club_dist_id", district);
              tempData.append("user_id", localStorage.getItem("Userid"));

              usertouradd(tempData).then((response) => {
                if (response.status === true) {
                  navigate("/meetview/" + id);
                }
              });
            }
          } else {
            setErrorMessage("Event 1 and Event 2 should not be same");
          }
        } else {
          setErrorMessage("Please Select Your Event 1");
        }
      } else {
        setErrorMessage("Please Select Your District");
      }
    }
  }

  return (
    <Fragment>
      <div id="registrationpage">
        <div className="registrationmain">
          <div className="registrationleft">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
          <div className="registrationright mt-2">
            <form action="#" className="form">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <h2 className="text-right">Add Meet</h2>
                </div>
              </div>
              <Row className="row-sm mt-2">
                <Col
                  sm={12}
                  lg={9}
                  xl={9}
                  md={9}
                  className="align-self-center border-end"
                >
                  <h4 className="mb-0">{tourname}</h4>
                </Col>
                <Col sm={12} lg={3} xl={3} md={9}>
                  <div className="eventdet ps-2">
                    <h6>
                      <i className="fa fa-map-marker me-2 text-danger"></i>
                      {tourloc}
                    </h6>
                    <h6>
                      <i className="fa fa-calendar me-2 text-danger"></i>
                      {tourdate}
                    </h6>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    {mtype === "National Meet" && (
                      <div>
                        <div className="profile-update individualentry">
                          <div className="row mx-0">
                            <div className="col-12 text-center pb-3 mb-3 border-bottom">
                              <h6>
                                Category <span className="text-danger">*</span>
                              </h6>

                              <button className="tabbtn active my-2 me-2">
                                {" "}
                                <h5 className="mb-0 tx-14">{catname}</h5>
                              </button>
                            </div>

                            <div className="col-md-6 mb-3">
                              <Card className="custom-card border mb-0">
                                <Card.Header className="bg-light py-3 mb-4">
                                  <h6 className="tx-22 text-dark mb-0">
                                    Event One{" "}
                                  </h6>
                                </Card.Header>
                                <Card.Body>
                                  <div className="row mx-0 row-sm">
                                    <div className="col-md-12">
                                      <div className="profile-update">
                                        <div className="form-group">
                                          <h6>
                                            {" "}
                                            Event 1{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </h6>
                                          <select
                                            value={event1}
                                            onChange={(e) => {
                                              setEvent1(e.target.value);
                                            }}
                                          >
                                            <option value="" disabled>
                                              Select Your Event
                                            </option>
                                            {elist.map((data, i) => {
                                              return (
                                                <option
                                                  value={data.event}
                                                  key={i}
                                                >
                                                  {data.event}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                        <div className="form-group">
                                          <h6>
                                            {" "}
                                            Event 1 Performance{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </h6>
                                          <input
                                            type="text"
                                            placeholder="Enter Your Event Performance"
                                            value={event1per}
                                            onChange={(e) => {
                                              setEvent1Per(e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <h6>
                                            {" "}
                                            Event 1 Performance Proof{" "}
                                            {event1man === "Y" && (
                                              <span className="text-danger">
                                                *
                                              </span>
                                            )}
                                            <span className="desc text-danger tx-14">
                                              {" "}
                                              (Accepted Files are
                                              .jpg,.jpeg,.pdf)
                                            </span>
                                            <span className="desc text-danger tx-14">
                                              {" "}
                                              (Maximum Size - 5Mb)
                                            </span>
                                          </h6>
                                          <div className="mt-3">
                                            <input
                                              type="file"
                                              name="file"
                                              id="logo"
                                              onChange={eventfile1}
                                              accept=".jpg,.jpeg,.pdf"
                                            />
                                          </div>
                                          {event1error !== "" && (
                                            <span className="errortxt text-danger">
                                              {event1error}
                                            </span>
                                          )}

                                          <div className="imagecontainer mx-auto mt-3">
                                            {event1proof !== "" && (
                                              <img
                                                src={event1load}
                                                width="100%"
                                                height="220"
                                              />
                                            )}

                                            {event1proof === "" && (
                                              <img
                                                src={require("../../assets/images/placeholder.png")}
                                                width="233"
                                                height="220"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>

                            {multi === "Y" && (
                              <div className="col-md-6 mb-3">
                                <Card className="custom-card border mb-0">
                                  <Card.Header className="bg-light py-3 mb-4">
                                    <h6 className="tx-22 text-dark mb-0">
                                      Event Two{" "}
                                    </h6>
                                  </Card.Header>
                                  <Card.Body>
                                    <div className="row mx-0 row-sm">
                                      <div className="col-md-12">
                                        <div className="profile-update">
                                          <div className="form-group">
                                            <h6>
                                              {" "}
                                              Event 2{" "}
                                              {/* <span className="text-danger">
                                            *
                                          </span> */}
                                            </h6>
                                            <select
                                              value={event2}
                                              onChange={selectevent}
                                            >
                                              <option value="" disabled>
                                                Select Your Event
                                              </option>
                                              {elist.map((data, i) => {
                                                return (
                                                  <option
                                                    value={data.event}
                                                    key={i}
                                                  >
                                                    {data.event}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <h6>
                                              {" "}
                                              Event 2 Performance{" "}
                                              {/* <span className="text-danger">
                                            *
                                          </span> */}
                                            </h6>
                                            <input
                                              type="text"
                                              placeholder="Enter Your Event Performance"
                                              value={event2per}
                                              onChange={(e) => {
                                                setEvent2Per(e.target.value);
                                              }}
                                            />
                                          </div>
                                          <div className="form-group">
                                            <h6>
                                              {" "}
                                              Event 2 Performance Proof{" "}
                                              {event2man === "Y" && (
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              )}
                                              <span className="desc text-danger tx-14">
                                                {" "}
                                                (Accepted Files are
                                                .jpg,.jpeg,.pdf)
                                              </span>
                                              <span className="desc text-danger tx-14">
                                                {" "}
                                                (Maximum Size - 5Mb)
                                              </span>
                                            </h6>
                                            <div className="mt-3">
                                              <input
                                                type="file"
                                                name="file"
                                                id="logo"
                                                onChange={eventfile2}
                                                accept=".jpg,.jpeg,.pdf"
                                              />
                                            </div>
                                            {event2error !== "" && (
                                              <span className="errortxt text-danger">
                                                {event2error}
                                              </span>
                                            )}

                                            <div className="imagecontainer mx-auto mt-3">
                                              {event2proof !== "" && (
                                                <img
                                                  src={event2load}
                                                  width="100%"
                                                  height="220"
                                                />
                                              )}

                                              {event2proof === "" && (
                                                <img
                                                  src={require("../../assets/images/placeholder.png")}
                                                  width="233"
                                                  height="220"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {mtype !== "National Meet" && (
                      <div className="row mx-0">
                        <div className="col-12 text-center pb-3 mb-3 border-bottom">
                          <h6>
                            Category <span className="text-danger">*</span>
                          </h6>

                          <button className="tabbtn active my-2 me-2">
                            <h5 className="mb-0 tx-14">{catname}</h5>
                          </button>
                        </div>

                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">
                                  District{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={district}
                                  onChange={(e) => {
                                    setDistrict(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Your District
                                  </option>
                                  {dlist.map((data, i) => {
                                    return (
                                      <option value={data.districtuid} key={i}>
                                        {data.districtname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">
                                  Event 1 <span className="text-danger">*</span>
                                </b>
                              </div>
                              <div className="col-md-8">
                                <select value={event1} onChange={stateevent1}>
                                  <option value="" disabled>
                                    Select Your Event
                                  </option>
                                  {elist.map((data, i) => {
                                    return (
                                      <option value={data.event} key={i}>
                                        {data.event}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {multi === "Y" && (
                          <div className="col-md-12">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Event 2
                                    {/* <span className="text-danger">*</span> */}
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <select value={event2} onChange={stateevent2}>
                                    <option value="" disabled>
                                      Select Your Event
                                    </option>
                                    {elist.map((data, i) => {
                                      return (
                                        <option value={data.event} key={i}>
                                          {data.event}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {errorRelay !== "" && (
                              <span className="errortxt text-danger">
                                {errorRelay}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {errorMessage !== "" && (
                    <span className="errortxt text-danger">{errorMessage}</span>
                  )}
                </div>
                <hr />
              </div>

              <div className="mobile-text-right">
                <Link
                  className="btn btn-secondary me-2"
                  to={"/tournamentregister/" + id}
                >
                  Back
                </Link>
                <a className="btn btn-success" onClick={regmeet}>
                  Submit
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TourAdd;
