import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./state.css";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import StateHeading from "./stateheading";
import fileString from "../../../../assets/language/filepath.json";
import {
  nidjamathleteentrylist,
  nidjampaymentstatus,
  nidjamremoveathlete,
} from "../../../../services/nidjamdistrict";
import { meetdetails } from "../../../../services/meetservice";
import imageUrl from "../../../../ImageUrl";

const NidjamAthleteEntryList = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status === "Paid") {
          navigate("/district/meetconfirmation/" + id);
        }
      }
    });
    funlist();
  }, []);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 100,
      resizable: true,
      field: "id",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => athletedelete(params.value)}
              className="btn-sm btn-danger"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "AFI UID",
      width: 150,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 200,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (params.data.middle_name !== null) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },
    {
      headerName: "Category",
      width: 150,
      field: "age_categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 300,
      field: "event_selected",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== null) {
          if (params.data.age_categories.includes("Under 14")) {
            return params.value + ", Kids Javelin Throw";
          } else {
            return params.value;
          }
        }
      },
    },
    {
      headerName: "Gender",
      width: 130,
      field: "gender",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 140,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
  ];

  function athletedelete(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("unique_id", did);

    nidjamremoveathlete(tempData).then((response) => {
      if (response.status === true) {
        funlist();
      }
    });
  }

  function funlist() {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    nidjamathleteentrylist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });

    meetdetails(id).then((response) => {
      if (response.status === true) {
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
      }
    });
  }
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <Row className="row-sm mx-0 mt-3">
          <Col xl={12} lg={12}>
            <Card className=" custom-card" id="basic">
              <Card.Body>
                <div className="page-header mt-2 mb-0">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                      Athlete List
                    </h2>
                  </div>
                  <div className="d-flex pe-4">
                    <div className="justify-content-center">
                      <a
                        href={imageUrl + fileString.tournamentfiles + cir}
                        download
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Download Circular
                      </a>

                      {cir2 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir2}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir3 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir3}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir4 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir4}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir5 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir5}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      <Link
                        to={"/district/meetaddathlete/" + id}
                        variant="success"
                        type="button"
                        className="btn my-1 btn-success me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Add Athletes
                      </Link>

                      <Link
                        to={"/district/officialentrylist/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        OFFICIAL ENTRY
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: 600,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NidjamAthleteEntryList;
