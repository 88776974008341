import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { athletepaymentlist } from "../services/superadmin";
import { Link } from "react-router-dom";

export const PaymentList = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [fdate, setFDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [tdate, setTDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  useEffect(() => {
    loaddata();
  }, []);

  const columnDefs = [
    {
      headerName: "Name",
      width: 200,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (params.data.middle_name !== null) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },
    {
      headerName: "AFI UID",
      width: 170,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   if(data.value !== null){
      //     return moment(data.value).format("DD-MM-YYYY");
      //   }
      // },
    },
    {
      headerName: "Email",
      width: 230,
      field: "email_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "paidstatus",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 150,
      field: "paymentdatetime",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   if(data.value !== null){
      //     return moment(data.value).format("DD-MM-YYYY");
      //   }
      // },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <Link
            to={"/superadmin/viewathletedetail/" + params.value}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];

  function loaddata() {
    setFilterProduct([]);
    const tempData = new FormData();
    tempData.append("start_date", fdate);
    tempData.append("end_date", tdate);
    athletepaymentlist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.payedlist);
      }
    });
  }
  function applyfilter() {
    loaddata();
  }

  function clearfilter() {
    setFDate(moment(new Date()).format("YYYY-MM-DD"));
    setTDate(moment(new Date()).format("YYYY-MM-DD"));
    setFilterProduct([]);
    const tempData = new FormData();
    tempData.append("start_date", moment(new Date()).format("YYYY-MM-DD"));
    tempData.append("end_date", moment(new Date()).format("YYYY-MM-DD"));
    athletepaymentlist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.payedlist);
      }
    });
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Payment List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>From Date</label>
                    <input
                      type="date"
                      value={fdate}
                      onChange={(e) => {
                        setFDate(e.target.value);
                      }}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>To Date</label>
                    <input
                      type="date"
                      value={tdate}
                      min={fdate}
                      onChange={(e) => {
                        setTDate(e.target.value);
                      }}
                      onKeyPress={(event) => {
                        event.preventDefault();
                      }}
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2 mt-4">
                  <a className="btn btn-success me-2" onClick={applyfilter}>
                    Apply
                  </a>
                </div>
                <div className="col-md-2 mt-4">
                  <a className="btn btn-danger me-2" onClick={clearfilter}>
                    Reset
                  </a>
                </div>
              </div>

              <div className="responsive mt-3">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
