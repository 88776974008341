
export const MENUITEMS = [
    {
        Items: [
            {
                path: '/state/dashboard',
                icon: 'fa fa-home',
                type: 'link',
                active: false,
                selected: false,
                title: 'Dashboard'
            },
            {
                path: '/state/upcoming',
                icon: 'fa fa-calendar',
                type: 'link',
                active: false,
                selected: false,
                title: 'Upcoming Meet'
            },
            {
                path: '/state/completed',
                icon: 'fa fa-calendar',
                type: 'link',
                active: false,
                selected: false,
                title: 'Completed Meet'
            },
            // {
            //     path: '/state/officials',
            //     icon: 'fa fa-user',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Officials'
            // },
            // {
            //     path: '/state/account',
            //     icon: 'fa fa-bank',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Account Details'
            // },
            // {
            //     path: '/state/NOC',
            //     icon: 'fa fa-briefcase',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'NOC Certificate'
            // }
    
        ]
    }
];


