import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

//Roles
export async function rolelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/role/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function rolestatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/role/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function rolevalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/role/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function roleadd(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/role/create-update`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function roleview(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/role/view/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

//States
export async function statelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/state/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statestatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/state/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statevalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/state/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateview(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/superadmin/state/view/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function stateadd(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/superadmin/state/create-update`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  


//District
export async function districtlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/district/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function districtstatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/district/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function districtvalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/district/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function districtview(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/superadmin/district/view/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function districtadd(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/superadmin/district/create-update`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  
//Age Category
export async function agecatlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/agegroup/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecatstatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/agegroup/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecatvalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/agegroup/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function agecatview(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/superadmin/agegroup/view/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function agecatadd(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/superadmin/agegroup/create-update`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


//Meet Category
export async function meetcatlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/meetcategory/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetcatstatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/meetcategory/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetcatvalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/meetcategory/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetcatview(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/superadmin/meetcategory/view/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function meetcatadd(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/superadmin/meetcategory/create-update`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }



  
//Entry Category
export async function meetentrycatlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/meetentrytype/list`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetentrystatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/meetentrytype/deactive`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetentryvalidate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/meetentrytype/validate`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function meetentryview(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/superadmin/meetentrytype/view/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function meetentryadd(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/superadmin/meetentrytype/create-update`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  function catcherror(error) {
  if (error.response.status === 401 || error.response.status === 422) {
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
