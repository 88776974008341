import { configureStore } from '@reduxjs/toolkit';
import athleteReducer from '../registration/action';

const Athleteware = (store) => (next) => (action) => {
    
    return next(action);
};
export default configureStore({
    reducer: {
        athlete: athleteReducer,
    },
    middleware: [Athleteware]
});
