import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Nav,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import fileString from "../../../../assets/language/filepath.json";
import moment from "moment";
import imageUrl from "../../../../ImageUrl";
import { meetdetails } from "../../../../services/meetservice";
import {
  nidjamconfirmation,
  nidjamfederationaccountdetail,
  nidjampaymentstatus,
  nidjamsummarylist,
} from "../../../../services/nidjamdistrict";
const NidjamConfirmation = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [tdate, setTDate] = useState("");
  const [tcode, setTCode] = useState("");
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  const [alist, setAthList] = useState([]);
  const [olist, setOffList] = useState([]);
  const [arrlist, setArrList] = useState([]);
  const [deplist, setDepList] = useState([]);

  const [accname, setAccName] = useState("");
  const [accno, setAccNo] = useState("");
  const [ifscode, setIFSC] = useState("");
  const [bname, setBName] = useState("");
  const [branch, setBranch] = useState("");
  const [formpass, setPassbook] = useState("");
  const [formcheque, setCheque] = useState("");

  const columnDefs = [
    {
      headerName: "Arrival Date",
      width: 200,
      field: "dateof_arr",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Arrival Time",
      width: 200,
      field: "dateof_arr_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Arrival Details",
      width: 200,
      field: "arr_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 430,
      field: "remark",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDep = [
    {
      headerName: "Departure Date",
      width: 200,
      field: "dateof_dep",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Departure Time",
      width: 200,
      field: "dateof_dep_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Departure Details",
      width: 200,
      field: "dep_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 430,
      field: "dep_remarks",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const navigate = useNavigate();
  useState(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status !== "Paid") {
          navigate("/district/sendentries/" + id);
        }
      }
    });

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.tournament.meet_name);
        setTDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY") +
            " to " +
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setTCode(response.data.tournament.meet_code);
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
      }
    });

    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    nidjamsummarylist(tempData).then((response) => {
      if (response.status === true) {
        setAthList(response.data.athletes_list);
        setOffList(response.data.officials_list);
        setArrList(response.data.travel_arrival_details);
        setDepList(response.data.travel_departure_details);
      }
    });

    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjamfederationaccountdetail(temp).then((response) => {
      if (response.status === true && response.data.account_details !== null) {
        setAccName(response.data.account_details.account_name);
        setAccNo(response.data.account_details.account_number);
        setIFSC(response.data.account_details.ifsc_code);
        setBName(response.data.account_details.bank_name);
        setBranch(response.data.account_details.branch_name);
        setPassbook(
          response.data.s3_path + response.data.account_details.pass_book_image
        );
        if (response.data.account_details.cancelled_cheque_copy !== "-") {
          setCheque(
            response.data.s3_path +
              response.data.account_details.cancelled_cheque_copy
          );
        }
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function conpdf() {
    nidjamconfirmation(id, localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        window.open(response.data, "_blank");
      }
    });
  }
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">{tname}</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              Event Code : <span>{tcode}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Date : <span>{tdate}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <a href={imageUrl + fileString.tournamentfiles + cir} download>
              <Button
                type="button"
                variant="danger"
                size="md"
                className="my-2 me-2"
              >
                <i className="fe fe-download-cloud me-2"></i> Download Circular
              </Button>
            </a>
            {cir2 !== "" && (
              <a href={imageUrl + fileString.tournamentfiles + cir2} download>
                <Button
                  type="button"
                  variant="danger"
                  size="md"
                  className="my-2 me-2"
                >
                  <i className="fe fe-download-cloud me-2"></i> Download
                  Circular
                </Button>
              </a>
            )}
            {cir3 !== "" && (
              <a href={imageUrl + fileString.tournamentfiles + cir3} download>
                <Button
                  type="button"
                  variant="danger"
                  size="md"
                  className="my-2 me-2"
                >
                  <i className="fe fe-download-cloud me-2"></i> Download
                  Circular
                </Button>
              </a>
            )}
            {cir4 !== "" && (
              <a href={imageUrl + fileString.tournamentfiles + cir4} download>
                <Button
                  type="button"
                  variant="danger"
                  size="md"
                  className="my-2 me-2"
                >
                  <i className="fe fe-download-cloud me-2"></i> Download
                  Circular
                </Button>
              </a>
            )}
            {cir5 !== "" && (
              <a href={imageUrl + fileString.tournamentfiles + cir5} download>
                <Button
                  type="button"
                  variant="danger"
                  size="md"
                  className="my-2 me-2"
                >
                  <i className="fe fe-download-cloud me-2"></i> Download
                  Circular
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>

      <Row className="mx-0">
        <Col md={12} className="px-0">
          <Card className="pt-0  custom-card pt-2 bg-background2 card pb-2 border-0 overflow-hidden text-center">
            <div className="header-text mb-0">
              <Container fluid className="p-3 text-center">
                <div className="text-center text-white background-text ">
                  <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                    Thank you for submitting all the entries !
                  </h1>
                  <p className="tx-20 mb-3 text-success">
                    All of the Entries were successfully submitted by you.{" "}
                    <br></br>Download the entry registration{" "}
                    <b>confirmation PDF</b> by clicking the button below..
                  </p>
                </div>
                <Row>
                  <Col xl={12} lg={12} md={12} className="d-block mx-auto">
                    <div className="text-center background-text">
                      <a
                        onClick={conpdf}
                        className="btn btn-danger ps-6 pe-6 pt-2 pb-2 mx-auto mt-1"
                      >
                        <i className="fe fe-download-cloud me-2"></i> Download
                        Confirmation PDF
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="row-sm mx-0 border-top">
        <Col lg={12} md={12} xl={12} sm={12}>
          <div className="custom-card pt-3">
            <div className="mb-3">
              <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                Athletes Details
              </h2>
            </div>
            <Row className="row-sm mx-3">
              {alist.map((data, i) => {
                return (
                  <Col xl={3} lg={3} md={6} sm={6} key={i}>
                    <Card className="custom-card our-team">
                      <Card.Body className="px-3 pt-3 pb-0 bg-cusimg">
                        <div className="picture avatar-lg online text-center">
                          <img
                            alt="avatar"
                            className=""
                            src={
                              imageUrl +
                              fileString.athletephoto +
                              data.photograph
                            }
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                        <div className="text-center mt-2">
                          <a>
                            {data.middle_name !== null && (
                              <h5 className="pro-user-username text-dark mt-2 mb-0">
                                {data.first_name} {data.middle_name}{" "}
                                {data.last_name}
                              </h5>
                            )}
                            {data.middle_name === null && (
                              <h5 className="pro-user-username text-dark mt-2 mb-0">
                                {data.first_name} {data.last_name}
                              </h5>
                            )}
                          </a>
                          <p className="pro-user-desc text-danger mb-2">
                            ({data.username})
                          </p>
                          <Card.Body className="px-1 py-0 athlelst">
                            <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                              <span>DOB </span>{" "}
                              <b>
                                {moment(data.date_of_birth).format(
                                  "DD-MM-YYYY"
                                )}
                              </b>
                            </h5>
                            <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-18">
                              <span>Category </span>{" "}
                              <b>{data.age_categories}</b>
                            </h5>
                            <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18 border-top">
                              <span>Events </span>{" "}
                              <b>
                                {data.events}{" "}
                                {data.age_categories.includes("Under 14") &&
                                  ",Kids Javelin Throw"}
                              </b>
                            </h5>
                          </Card.Body>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="custom-card pt-3">
            <div className="mb-3">
              <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                Officials Details
              </h2>
            </div>
            <Row className="row-sm mx-3">
              {olist.map((data, i) => {
                return (
                  <Col xl={3} lg={3} md={6} sm={6} key={i}>
                    <Card className="custom-card our-team">
                      <Card.Body className="px-3 pt-3 pb-0 bg-cusimg">
                        <div className="p-1 border-bottom text-center">
                          <img
                            alt="avatar"
                            className="summaryimage"
                            src={
                              imageUrl +
                              fileString.stateofficial +
                              data.photograph
                            }
                            onError={(e) => errorhandle(e)}
                          />
                          <h4 className="mb-1 tx-dark tx-24">
                            {data.first_name} {data.last_name}
                          </h4>
                        </div>
                        <div className="text-center mt-2">
                          <Card.Body className="px-1 py-0 athlelst">
                            <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18">
                              <span>Gender </span>
                              <b>{data.gender}</b>
                            </h5>
                            <h5 className="list-content-label border-bottom-none tx-dark tx-medium mg-b-10 tx-18">
                              <span>Mobile </span> <b>{data.phone}</b>
                            </h5>
                            <h5 className="list-content-label tx-dark tx-medium mg-b-10 tx-18 border-top">
                              <span>Post </span> <b>{data.post}</b>
                            </h5>
                          </Card.Body>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="row-sm mx-0 bg-white border-top">
        <Col lg={12} md={12} xl={12} sm={12}>
          <div className="custom-card pt-3">
            <div className="mb-3">
              <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                Arrival Travel Details
              </h2>
            </div>
            <Row className="row-sm mx-0 mt-3">
              <Col xxl={12} lg={12} md={12}>
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 300,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={arrlist}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="custom-card pt-3">
            <div className="mb-3">
              <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                Departure Travel Details
              </h2>
            </div>
            <Row className="row-sm mx-0 mt-3">
              <Col xxl={12} lg={12} md={12}>
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 300,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDep}
                      rowData={deplist}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="custom-card pt-3">
            <div className="mb-3">
              <h2 className="main-content-title tx-24 mg-b-2 ps-4">
                Account Details
              </h2>
            </div>
            <Row className="row-sm mx-0 mt-3">
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                    <span>Account Name </span>
                    {accname}
                  </h5>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                    <span>Account Number </span>
                    {accno}
                  </h5>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                    <span>IFSC Code </span>
                    {ifscode}
                  </h5>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                    <span>Bank Name </span>
                    {bname}
                  </h5>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                    <span>Branch </span>
                    {branch}
                  </h5>
                </div>
              </Col>
            </Row>
            <Row className="row-sm mx-0">
              <Col lg={6} md={6}>
                <div className="athlelst">
                  <Row>
                    <Col lg={6} md={6}>
                      <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                        <span>Passbook </span>
                      </h5>
                    </Col>
                    <Col lg={6} md={6}>
                      {formpass !== "" && (
                        <img
                          alt="avatar"
                          className="uploadimage"
                          src={formpass}
                        />
                      )}

                      {formpass === "" && (
                        <img
                          alt="avatar"
                          className="uploadimage"
                          src={require("../../../../assets/images/placeholder.png")}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6} md={6}>
                <div className="athlelst">
                  <Row>
                    <Col lg={6} md={6}>
                      <h5 className="acc-content-label tx-dark tx-medium mg-b-10 tx-18">
                        <span>Cancelled Cheque Copy </span>
                      </h5>
                    </Col>
                    <Col lg={6} md={6}>
                      {formcheque !== "" && (
                        <img
                          alt="avatar"
                          className="uploadimage"
                          src={formcheque}
                        />
                      )}

                      {formcheque === "" && (
                        <img
                          alt="avatar"
                          className="uploadimage"
                          src={require("../../../../assets/images/placeholder.png")}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NidjamConfirmation;
