import React from 'react';
import { Link } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
const Footer = () => (

    <div className="main-footer text-center">
        <Container>
            <Row className="row-sm">
                <Col md={6} className="col-md-6 mobile-text-right">
                    <span>Copyright ©  {new Date().getFullYear()} <Link className='text-danger' href="https://indianathletics.in/" target="_blank">Athletics Federation of India</Link>. All rights reserved.</span>
                </Col>
                <Col md={6} className="col-md-6 mobile-text-right">
                    <span>Designed by <Link className='text-warning' href="https://sportingindia.tech/" target="_blank">Sportingindia</Link> </span>
                </Col>
            </Row>
        </Container>
    </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
