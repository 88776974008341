import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

export async function nidjampaymentstatus(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/check-natfedpayment-status`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  export async function nidjamremoveathlete(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/deleteathlete`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjamathleteentrylist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addedathleteslist`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  
  export async function nidjamofficialentrylist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addedofficialslist`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamremoveofficial(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/deleteofficial`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamtravelentrylist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/travelarrivaldetails`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  export async function nidjamdeptravelentrylist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/traveldetailsdeparture`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamremovearrival(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/deletetravelarrivaldetails`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamremovedeparture(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/deletetraveldeparturedetails`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  export async function nidjammosupload(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addmos`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamperupload(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addperformanceproof`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  export async function nidjammoslist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/mosinfo`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamperlist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/performanceproofinfo`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjampresslist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/presscuttingsinfo`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjampresscreate(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addpresscuttings`,data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjampressdelete(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/deletepresscuttings/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamfiledelete(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/delete_mos/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamperdelete(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/delete_performanceproof/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamphotolist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/nidjam-photos-info`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamphotocreate(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/add-nidjam-photos`,data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamphotodelete(id) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/delete-nidjam-photos/${id}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  export async function nidjamsummarylist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/viewsummarypage`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjampayment(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/submitentry`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


export async function nidjamfederationaccountdetail(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/added-account-details-list`,data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function nidjamfederationaddaccountdetail(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/districtunit/add-account-details`,data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

  export async function nidjamoveralllist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/viewoverallsummary`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjamfreepayment(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/free-tournament`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjamconfirmation(id, fid) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/nidjam-confirmation-pdf/${id}/${fid}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
  export async function nidjamviewarrival(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/viewtravelarrivaldetails/${data}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjamaddarrival(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addupdatetravelarrivaldata`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamviewdeparture(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .get(`${baseUrl}/districtunit/viewtraveldeparturedetails/${data}`, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamadddeparture(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addupdatetraveldeparturedata`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamaddofficial(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addnewofficial`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamagecategory(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/getallagecategory`, data, { headers: header })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjameventlist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/getalleventsbasedonagecategory`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjamathletetlist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/getathletesbasedoncategory`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function nidjameventathletetlist(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addedathletesforevents`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  export async function nidjamaddathlete(data) {
    try {
      const header = {
        Authorization: "Bearer " + localStorage.getItem("Token"),
        "Content-Type": "application/json",
      };
      return await axios
        .post(`${baseUrl}/districtunit/addnewathlete`, data, {
          headers: header,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  ///Federation Sent Entries
export async function nidjamfederationsentlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictsaddedsent/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


 ///Federation Sent District Athlete Entries
 export async function nidjamfederationsentdistrictlist(id,dist) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictathletes/${id}/${dist}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
///Federation Sent Entries Counts
export async function nidjamfederationsentcountlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictsaddedsentcounts/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Federation Athlete Entries
export async function nidjamfederationathletelist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentathletes/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Federation Official Entries
export async function nidjamfederationofficiallist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentofficials/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Federation Travel Entries
export async function nidjamfederationtravellist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssenttraveldetails/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Federation MOS Entries
export async function nidjamfederationmoslist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentmos/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

///Federation Performance Entries
export async function nidjamfederationperformancelist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentperformanceproof/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


///Federation Press Cuttings Entries
export async function nidjamfederationpresslist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentpresscuttings/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
///Federation Photo Entries
export async function nidjamfederationphotolist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentphotos/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


///Federation Account Details
export async function nidjamfederationaccountlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/nidjamdistrictssentaccountdetails/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
  function catcherror(error) {
    // console.log(error.response);
    if (error.response.status === 401 || error.response.status === 422) {
      // window.location.replace("/#/login");
      localStorage.clear();
      window.location.reload();
      <Link to={"/login"}></Link>;
    } else if (error.response.status === 0) {
      toast.error(error.message);
    } else if (error.response.status === 500) {
      toast.error(error.response.statusText);
    } else if (
      error.response.status !== 200 &&
      error.response.data.status === false &&
      error.response.data.code !== 200
    ) {
      toast.error(error.response.data.message);
    } else if (error.request) {
      toast.error(error.request.statusText);
    } else {
      toast.error(error.message);
    }
  }