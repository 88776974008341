import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import imageUrl from "../../../../ImageUrl";
import {
  athleteview,
  imageupdate,
  profileupdate,
} from "../../../../services/athlete";
import { distlist, statelist } from "../../../../services/registerservice";
import fileString from "../../../../assets/language/filepath.json";

// import { useDispatch } from 'react-redux';
// import { profile } from './action';
import "./profile.css";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const navigate = useNavigate();
  const [formphoto, setPhotoFile] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formaddrupload, setAddrUpload] = useState("");
  const [formbplupload, setBplUpload] = useState("");

  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");

  const [padd1, setAdd1] = useState("");
  const [padd2, setAdd2] = useState("");
  const [pstate, setState] = useState("");
  const [pdist, setDist] = useState("");
  const [pstatename, setStatename] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");

  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [commstate, setCommState] = useState("");
  const [commdistname, setCommDistname] = useState("");
  const [commpin, setCommPincode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [bplstatus, setBplstatus] = useState("");

  const [fromnoc, setFromNOC] = useState("");
  const [tonoc, setToNOC] = useState("");

  const [nocstatus, setNOCStatus] = useState(0);
  const [nocreason, setNOCReason] = useState("");
  const [orderid, setNOCOrder] = useState("");
  const [nocfrom, setNOCFrom] = useState("");
  const [nocto, setNOCTo] = useState("");
  const [nocaddress, setNOCAddress] = useState("");

  const [nocnotes, setNOCNotes] = useState("");

  // const dispatch = useDispatch();

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        setPhotoUpload(event.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  function submitphoto(e) {
    e.preventDefault();
    const tempData = new FormData();
    tempData.append("user_id", localStorage.getItem("Userid"));
    tempData.append("photograph", formphotoupload);

    if (formphotoerror === "") {
      imageupdate(tempData).then((response) => {
        if (response.status === true) {
          window.location.reload();
        } else {
          setPhotoErrors(response.message);
        }
      });
    } else {
      setPhotoErrors("Photograph is required");
    }
  }

  function submitinfo(e) {
    e.preventDefault();
    const tempFile = {
      user_id: localStorage.getItem("Userid"),
      permenant_address_one: padd1,
      permenant_address_two: padd2,
      permenant_state: pstate,
      permenant_district: pdist,
      permenant_zipcode: ppin + "",
    };
    // dispatch(
    //     profile({
    //         add1:padd1,
    //         add2: padd2,
    //         state: pstate,
    //         dist: pdist,
    //         pin: ppin,
    //     })
    // );
    if (tempFile.permenant_address_one !== "") {
      if (tempFile.permenant_state !== "") {
        if (tempFile.permenant_district !== "") {
          if (tempFile.permenant_zipcode !== "") {
            if (tempFile.permenant_zipcode.length >= 6) {
              profileupdate(tempFile).then((response) => {
                if (response.status === true) {
                  window.location.reload();
                } else {
                  setErrorMessage(response.message);
                }
              });
            } else {
              setErrorMessage("Pincode must be 6 digits");
            }
          } else {
            setErrorMessage("Please Enter Your Pincode");
          }
        } else {
          setErrorMessage("Please Select Your District");
        }
      } else {
        setErrorMessage("Please Select Your State");
      }
    } else {
      setErrorMessage("Please Enter Your Address 1");
    }
  }
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);

  function profilelist() {
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function Selectpdist(e) {
    setState(e.target.value);
    setDist("");
    distlist(e.target.value).then((response) => {
      setPDList(response.data);
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);

  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  useEffect(() => {
    athleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setMiddle(response.data.details.middle_name);

        setLast(response.data.details.last_name);
        setDOB(
          moment(response.data.details.date_of_birth).format("DD-MM-YYYY")
        );
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        setBlood(response.data.details.blood_group);
        setAadhar(response.data.details.aadhaar_no);
        setFather(response.data.details.father_name);
        setMother(response.data.details.mother_name);

        setAdd1(response.data.details.permenant_address_one);
        setAdd2(response.data.details.permenant_address_two);
        setState(response.data.details.permenant_state);
        setDist(response.data.details.permenant_district);
        setStatename(response.data.details.statename);
        setDistname(response.data.details.permenant_district);
        setPincode(response.data.details.permenant_zipcode);

        setCommAddr(response.data.details.communication_address_one);
        setCommaddr2(response.data.details.communication_address_two);
        setCommDistname(response.data.details.communication_district);
        for (let i = 0; i < response.data.comm_state.length; i++) {
          if (
            response.data.details.communication_state ===
            response.data.comm_state[i].stateuid
          ) {
            setCommState(response.data.comm_state[i].statename);
          }
        }
        setCommPincode(response.data.details.communication_zipcode);

        setPhotoUpload(
          imageUrl + fileString.athletephoto + response.data.details.photograph
        );
        setPhotoFile(
          imageUrl + fileString.athletephoto + response.data.details.photograph
        );
        setIDUpload(
          imageUrl + fileString.athleteidproof + response.data.details.dob_proof
        );
        setAddrUpload(
          imageUrl +
            fileString.athleteaddress +
            response.data.details.address_proof
        );
        setBplstatus(response.data.details.below_poverty_card);

        setBplUpload(
          imageUrl +
            fileString.athletebplcard +
            response.data.details.below_poverty_file
        );
        if(response.data.noc !== null){
          if (response.data.noc.from_state_name !== null) {
            setNOCFrom(response.data.noc.from_state_name);
          }
          if (response.data.noc.to_state_name !== null) {
            setNOCTo(response.data.noc.to_state_name);
          }
          if (response.data.noc.from_state_noc !== null) {
            setFromNOC(
              imageUrl + fileString.athletenoc + response.data.noc.from_state_noc
            );
          }
          if (response.data.noc.to_state_noc !== null) {
            setToNOC(
              imageUrl + fileString.athletenoc + response.data.noc.to_state_noc
            );
          }
          if (response.data.noc.noc_notes !== null) {
            setNOCNotes(response.data.noc.noc_notes);
          }
          if (response.data.noc.to_state_address !== null) {
            setNOCAddress(
              imageUrl +
                fileString.athletenoc +
                response.data.noc.to_state_address
            );
          }
  
          if (response.data.noc.from_state_approve !== null) {
            setNOCStatus(response.data.noc.from_state_approve);
          }
          if (response.data.noc.order_id !== null) {
            setNOCOrder(response.data.noc.order_id);
          }
          if (response.data.noc.approval_notes !== null) {
            setNOCReason(response.data.noc.approval_notes);
          }
        }
       
        

        

        distlist(response.data.details.permenant_state).then((response1) => {
          setPDList(response1.data);
        });
      }
    });
    profilelist();
  }, []);
  return (
    <Fragment>
      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Profile</h2>
        </div>
      </div>
      <Card className=" custom-card profilepage">
        <Card.Header className="border-none pb-0">
          <h3 className="mb-0">
            {first} {last}
          </h3>
          <h4 className="tx-26">
            AFI-UID - ({" "}
            <span className="text-danger">{localStorage.getItem("User")}</span>{" "}
            )
          </h4>
        </Card.Header>
        <Card.Body>
          <div className="panel profile-top">
            <div className="profile-cover__img">
              <img
                src={formphotoupload}
                alt="img"
                onError={(e) => errorhandle(e)}
              />
            </div>
          </div>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container defaultActiveKey="info">
                  {/* <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="info"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Your Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="update"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Update Your Information
                      </Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                  <Tab.Content>
                    <Tab.Pane eventKey="info" className="border">
                      <h5>Personal Information</h5>

                      <div className="row mx-0">
                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">First Name</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{first}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Middle Name</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{middle}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Last Name</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{last}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Date of Birth</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{dob}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Gender</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{gender}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Email</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Blood Group</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{blood}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Mobile</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">Aadhar</b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{aadhar}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">
                                  Father&apos;s Name
                                </b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{father}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="add-profile">
                            <div className="row">
                              <div className="col-md-4 col-sm-5">
                                <b className="text-inverse">
                                  Mother&apos;s Name
                                </b>
                              </div>
                              <div className="col-md-8 col-sm-7">
                                <span className="text-inverse">{mother}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                    
                      <div className="add-profile row mx-0">
                        <div className="col-md-6">
                        <h5 className="addhead">Permanent Address</h5>

                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Address 1</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{padd1}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Address 2</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{padd2}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">State</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{pstatename}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">District</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{pdistname}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Pincode</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{ppin}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <h5 className="addhead">Communication Address</h5>

                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Address 1</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{commaddr}</span>
                            </div>
                          </div>

                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Address 2</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{commaddr2}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">State</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{commstate}</span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">District</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">
                                {commdistname}
                              </span>
                            </div>
                          </div>
                          <div className="add-profile row">
                            <div className="col-md-4 col-sm-5">
                              <b className="text-inverse">Pincode</b>
                            </div>
                            <div className="col-md-8 col-sm-7">
                              <span className="text-inverse">{commpin}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      {nocstatus !== 0 && (
                        <div>
                          <div className="w-100">
                            <h5>NOC Details</h5>
                          </div>
                          <div className="row mx-0">
                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">From State</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    <span className="text-inverse">
                                      {nocfrom}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">To State</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    <span className="text-inverse">
                                      {nocto}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">NOC Notes</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    <span className="text-inverse">
                                      {nocnotes}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">NOC Order</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    <span className="text-inverse">
                                      {orderid}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">NOC Status</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    {nocstatus === 1 && (
                                      <span className="text-inverse">
                                        APPROVED
                                      </span>
                                    )}
                                    {nocstatus === 2 && (
                                      <span className="text-inverse">
                                        REJECTED
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="add-profile">
                                <div className="row">
                                  <div className="col-md-4 col-sm-5">
                                    <b className="text-inverse">NOC Reason</b>
                                  </div>
                                  <div className="col-md-8 col-sm-7">
                                    <span className="text-inverse">
                                      {nocreason}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row mx-0">
                            <div className="col-md-4">
                              <h6>Present State</h6>
                              <img
                                className="profiledocs_img"
                                src={fromnoc}
                                alt="photo"
                                onClick={() => handleshow(fromnoc)}
                                onError={(e) => errorhandle(e)}
                              />
                            </div>
                            <div className="col-md-4">
                              <h6>Transfer State</h6>
                              <img
                                className="profiledocs_img"
                                src={tonoc}
                                alt="photo"
                                onClick={() => handleshow(tonoc)}
                                onError={(e) => errorhandle(e)}
                              />
                            </div>
                            <div className="col-md-4">
                              <h6>State Address</h6>
                              <img
                                className="profiledocs_img"
                                src={nocaddress}
                                alt="photo"
                                onClick={() => handleshow(nocaddress)}
                                onError={(e) => errorhandle(e)}
                              />
                            </div>
                          </div>

                          <br />
                          <hr />
                        </div>
                      )}
                      <div className="w-100 mb-4">
                        <h5>Important Documents</h5>
                      </div>
                      <div className="row mx-0">
                        <div className="col-md-4">
                          <h6>ID Proof</h6>
                          <img
                            className="profiledocs_img"
                            src={formidupload}
                            alt="photo"
                            onClick={() => handleshow(formidupload)}
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                        <div className="col-md-4">
                          <h6>Address Proof</h6>
                          <img
                            className="profiledocs_img"
                            src={formaddrupload}
                            alt="photo"
                            onClick={() => handleshow(formaddrupload)}
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                        {bplstatus === "Y" && (
                          <div className="col-md-4">
                            <h6>BPL Card</h6>
                            <img
                              className="profiledocs_img"
                              src={formbplupload}
                              alt="photo"
                              onClick={() => handleshow(formbplupload)}
                              onError={(e) => errorhandle(e)}
                            />
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="text-right">
                        <a
                          className="btn btn-secondary"
                          onClick={() => navigate("/athlete/dashboard")}
                        >
                          Back
                        </a>
                      </div>

                      <Modal show={show}>
                        <Modal.Body>
                          <img
                            src={file}
                            alt="Address"
                            onError={(e) => errorhandle(e)}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <a
                            className="btn btn-next btn-success"
                            style={{ float: "right" }}
                            href={file}
                            download
                            target="_blank"
                          >
                            Download
                          </a>
                          <a
                            className="btn btn-next btn-danger"
                            style={{ float: "right" }}
                            onClick={handleclose}
                          >
                            Close
                          </a>
                        </Modal.Footer>
                      </Modal>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="update" className="border">
                      <h5>Profile Information</h5>

                      <div className="input-group form-upload mb-0">
                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="photo"> Upload Photograph</label>
                            <span className="desc text-danger">
                              {" "}
                              (Accepted Files are .png, .jpg, .jpeg. Max file
                              size 3Mb)
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={photofile}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img
                                src={formphoto}
                                className="profile_img"
                                onError={(e) => errorhandle(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-9">
                          {formphotoerror !== "" && (
                            <span className="errortxt text-danger">
                              {formphotoerror}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <a
                            className="btn btn-success me-2"
                            style={{ float: "right" }}
                            onClick={submitphoto}
                          >
                            {" "}
                            Save
                          </a>
                        </div>
                      </div>

                      <hr></hr>

                      <h5>Personal Information</h5>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">First Name</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{first}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Last Name</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{last}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Email</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Mobile</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Address 1</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  autoComplete="off"
                                  value={padd1}
                                  onChange={(e) => {
                                    setAdd1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Address 2</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 2"
                                  autoComplete="off"
                                  value={padd2}
                                  onChange={(e) => {
                                    setAdd2(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Aadhar</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{aadhar}</span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">State</b>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={pstate}
                                  onChange={Selectpdist}
                                  disabled
                                >
                                  <option value="" disabled>
                                    Select Your State
                                  </option>
                                  {slist.map((data, i) => {
                                    return (
                                      <option value={data.stateuid} key={i}>
                                        {data.statename}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">District</b>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={pdist}
                                  onChange={(e) => {
                                    setDist(e.target.value);
                                  }}
                                  disabled
                                >
                                  <option value="" disabled>
                                    Select Your District
                                  </option>
                                  {pdlist.map((data, i) => {
                                    return (
                                      <option value={data.districtuid} key={i}>
                                        {data.districtname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">Pincode</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Pincode"
                                  autoComplete="off"
                                  maxLength={6}
                                  value={ppin}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setPincode(e.target.value);
                                  }}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {errorMessage !== "" && (
                        <span className="errortxt text-danger">
                          {errorMessage}
                        </span>
                      )}
                      <br />
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <a
                            className="btn btn-secondary"
                            style={{}}
                            onClick={submitinfo}
                          >
                            Update Details
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Profile;
