export const MENUITEMS = [
    {
        Items: [
            {
                path: '/official/dashboard',
                icon: 'fa fa-home',
                type: 'link',
                active: false,
                selected: false,
                title: 'Dashboard',
            },
        ],
    },
];
