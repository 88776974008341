import React, { Fragment, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import './account.css';

const Accountinfo = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  

    return (
        <Fragment>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
            Account Detail
                    </h2>
                </div>
            </div>
            <Card className=" custom-card overflow-hidden">                
                <Card.Body>
                    <h4>Account Detail</h4>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Events
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Events"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Amount
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Amount"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Full Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Full Name"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Bank Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Bank Name"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Branch Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Branch Name"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Account No
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Account No"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Re-Enter Account No
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your Re-Enter Account No"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">IFSC Code
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="text"
                                placeholder="Enter Your IFSC Code"
                                autoComplete='off'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Cheque/Passbook Copy
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                            />
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-8 mb-3'>
                            <div className="imagecontainer">
                                <img src={require('../../../../assets/images/logo/logo.png')} alt='photo' height={120} width={120}/>
                            </div>
                        </div>
                        
                    </div>
                    <div className='text-right'>
                        <a
                            className="btn btn-success"
                            style={{ marginRight: '10px' }} onClick={handleShow}
                        >
                                                Proceed
                        </a>
                        <a
                            className="btn btn-secondary"
                        >
                                                Cancel
                        </a>
                    </div>
                   
                </Card.Body>
            </Card>


            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <h4>Account Information</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Events
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Amount
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Full Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Bank Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Branch Name
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Account No
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Re-Enter Account No
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">IFSC Code
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <span className="text-inverse">Yuva
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <b className="text-inverse">Cheque/Passbook Copy
                            </b>
                        </div>
                        <div className='col-md-8 mb-3'>
                            <div className="imagecontainer">
                                <img src={require('../../../../assets/images/logo/logo.png')} alt='photo' height={120} width={120}/>
                            </div>
                        </div>
                    </div>
                    
                   
                </Modal.Body>
                <Modal.Footer>
                    <a
                        className="btn btn-success mr-3"
                        style={{ float: 'right' }} onClick={handleClose}
                    >
                                                Confirm
                    </a>
                    <a
                        className="btn btn-secondary"
                        style={{ float: 'right' }} onClick={handleClose}
                    >
                                                Edit
                    </a>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Accountinfo;
