import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";
import exportUrl from "../../../Export";
import {
  nidjamfederationaccountlist,
  nidjamfederationathletelist,
  nidjamfederationmoslist,
  nidjamfederationofficiallist,
  nidjamfederationperformancelist,
  nidjamfederationphotolist,
  nidjamfederationpresslist,
  nidjamfederationsentcountlist,
  nidjamfederationsentlist,
  nidjamfederationtravellist,
} from "../../../services/nidjamdistrict";

const NidjamNationalEntries = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterOfficial, setFilterOfficial] = useState([]);
  const [filterArrival, setFilterArrival] = useState([]);
  const [filterDeparture, setFilterDeparture] = useState([]);
  const [filterMOS, setFilterMOS] = useState([]);
  const [filterPerf, setFilterPerf] = useState([]);
  const [filterPress, setFilterPress] = useState([]);
  const [filterPhoto, setFilterPhoto] = useState([]);
  const [filterAccount, setFilterAccount] = useState([]);

  const [filterSent, setFilterSent] = useState([]);
  const [filterSentCount, setFilterSentCount] = useState([]);

  const [linkurl, setLink] = useState("");

  const { id } = useParams();
  const gridRef = useRef();
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setLink(link[1]);
    if (eid === "nidjamsentfederation") {
      nidjamfederationsentlist(id).then((response) => {
        if (response.status === true) {
          if (response.data.length !== 0) {
            let arr = [];
            for (let i = 0; i < response.data.length; i++) {
              var submit = "";
              if (response.data[i].sent === 1) {
                submit = "Entry Submitted";
              } else {
                submit = "Entry Pending";
              }
              arr.push({
                state_name: response.data[i].state_name,
                district_id: response.data[i].district_id,
                district_name: response.data[i].district_name,
                female_athletes_count: response.data[i].female_athletes_count,
                male_athletes_count: response.data[i].male_athletes_count,
                totalevent: response.data[i].totalevent,
                sent: submit,
              });
            }
            setFilterSent(arr);
          }
        }
      });

      nidjamfederationsentcountlist(id).then((response) => {
        if (response.status === true) {
          let arr = [];
          if (response.data !== null) {
            arr.push({
              sdistrict: response.data.entry_submitted[0].sent_district_count,
              smalecnt: response.data.entry_submitted_athletes[0].male_count,
              sfemalecount:
                response.data.entry_submitted_athletes[0].female_count,
              pdistrict: response.data.entry_pending[0].not_sent_district_count,
              pmalecount: response.data.entry_pending_athletes[0].male_count,
              pfemalecount:
                response.data.entry_pending_athletes[0].female_count,
            });
          }
          setFilterSentCount(arr);
        }
      });
    } else if (eid === "nidjamathleteentries") {
      nidjamfederationathletelist(id).then((response) => {
        if (response.status === true) {
          if (response.data.federation_sent_athletes_list.length !== 0) {
            let arr = [];
            for (
              let i = 0;
              i < response.data.federation_sent_athletes_list.length;
              i++
            ) {
              var submit = "";
              if (response.data.federation_sent_athletes_list[i].sent === 1) {
                submit = "Entry Submitted";
              } else {
                submit = "Entry Pending";
              }
              arr.push({
                photograph:
                  response.data.federation_sent_athletes_list[i].photograph,
                username:
                  response.data.federation_sent_athletes_list[i].username,
                first_name:
                  response.data.federation_sent_athletes_list[i].first_name,
                middle_name:
                  response.data.federation_sent_athletes_list[i].middle_name,
                last_name:
                  response.data.federation_sent_athletes_list[i].last_name,
                age_category:
                  response.data.federation_sent_athletes_list[i].age_category,
                events: response.data.federation_sent_athletes_list[i].events,
                date_of_birth:
                  response.data.federation_sent_athletes_list[i].date_of_birth,
                gender: response.data.federation_sent_athletes_list[i].gender,
                statename:
                  response.data.federation_sent_athletes_list[i].statename,
                federation_name:
                  response.data.federation_sent_athletes_list[i]
                    .federation_name,
                user_id: response.data.federation_sent_athletes_list[i].user_id,
                sent: submit,
              });
            }
            setFilterProduct(arr);
          }
        }
      });
    } else if (eid === "nidjamofficialentries") {
      nidjamfederationofficiallist(id).then((response) => {
        if (response.status === true) {
          setFilterOfficial(response.data.federation_sent_officials_list);
        }
      });
    } else if (eid === "nidjamarrivalentries") {
      nidjamfederationtravellist(id).then((response) => {
        if (response.status === true) {
          setFilterArrival(response.data.federation_arraival_details_list);
        }
      });
    } else if (eid === "nidjamdepartureentries") {
      nidjamfederationtravellist(id).then((response) => {
        if (response.status === true) {
          setFilterDeparture(response.data.federation_departure_details_list);
        }
      });
    } else if (eid === "nidjammosentries") {
      nidjamfederationmoslist(id).then((response) => {
        if (response.status === true) {
          setFilterMOS(response.data.federation_mos_list);
        }
      });
    } else if (eid === "nidjamperformancceentries") {
      nidjamfederationperformancelist(id).then((response) => {
        if (response.status === true) {
          setFilterPerf(response.data.federation_performance_proof_list);
        }
      });
    } else if (eid === "nidjampresscuttingentries") {
      nidjamfederationpresslist(id).then((response) => {
        if (response.status === true) {
          setFilterPress(response.data.federation_press_cuttings_list);
        }
      });
    } else if (eid === "nidjamphotoentries") {
      nidjamfederationphotolist(id).then((response) => {
        if (response.status === true) {
          setFilterPhoto(response.data.federation_photos_list);
        }
      });
    } else if (eid === "nidjamaccountdetails") {
      nidjamfederationaccountlist(id).then((response) => {
        if (response.status === true) {
          setFilterAccount(response.data.federation_account_details_list);
        }
      });
    }
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const columnDefs = [
    {
      headerName: "Photo",
      width: 120,
      field: "photograph",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.athletephoto + params.data.photograph}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Status",
      width: 150,
      field: "sent",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AFI UID",
      width: 130,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 140,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (params.data.middle_name !== null) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },

    {
      headerName: "Age Category",
      width: 140,
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 200,
      field: "events",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value !== "") {
          if (params.data.age_category.includes("Under 14")) {
            return params.value + ", Kids Javelin Throw";
          } else {
            return params.value;
          }
        }
      },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 200,
      field: "statename",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 200,
      field: "federation_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 90,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={"/superadmin/viewprofile/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const columnOffs = [
    {
      headerName: "Photo",
      width: 100,
      field: "photograph",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.stateofficial + params.data.photograph}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Full Name",
      width: 200,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        return params.data.first_name + " " + params.data.last_name;
      },
    },

    {
      headerName: "Post",
      width: 140,
      field: "post",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "phone",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 270,
      field: "email",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  const columnArri = [
    {
      headerName: "Arrival Date",
      width: 150,
      field: "dateof_arr",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },

    {
      headerName: "Arrival Time",
      width: 160,
      field: "dateof_arr_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Detail",
      width: 250,
      field: "arr_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mode",
      width: 180,
      field: "travel_by",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remark",
      width: 260,
      field: "remark",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDept = [
    {
      headerName: "Departure Date",
      width: 150,
      field: "dateof_dep",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },

    {
      headerName: "Departure Time",
      width: 160,
      field: "dateof_dep_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Detail",
      width: 250,
      field: "dep_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mode",
      width: 180,
      field: "dep_travel_by",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remark",
      width: 260,
      field: "dep_remarks",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnMos = [
    {
      headerName: "MOS File",
      width: 900,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.statemos + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnPerf = [
    {
      headerName: "Performance File",
      width: 900,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.stateperformance + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnPress = [
    {
      headerName: "District",
      width: 150,
      field: "district",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Press Cutting File",
      width: 750,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.statepress + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnPhoto = [
    {
      headerName: "District",
      width: 150,
      field: "district",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Photo File",
      width: 750,
      field: "file_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "file_name",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              className="btn-sm btn-info"
              href={imageUrl + fileString.statepress + params.value}
            >
              <i className="fa fa-download fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  const columnAccount = [
    {
      headerName: "Passbook",
      width: 100,
      field: "pass_book_image",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.statepass + params.value}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Cheque Copy",
      width: 100,
      field: "cancelled_cheque_copy",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "35px", height: "35px" }}
            src={imageUrl + fileString.statepass + params.value}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "District",
      width: 150,
      field: "district",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Account Name",
      width: 150,
      field: "account_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Account Number",
      width: 150,
      field: "account_number",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "IFSC",
      width: 150,
      field: "ifsc_code",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Bank Name",
      width: 150,
      field: "bank_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Branch",
      width: 150,
      field: "branch_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnSent = [
    {
      headerName: "State",
      width: 150,
      field: "state_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "District",
      width: 150,
      field: "district_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "sent",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Female Count",
      width: 150,
      field: "female_athletes_count",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Male Count",
      width: 150,
      field: "male_athletes_count",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total",
      width: 150,
      field: "totalevent",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      width: 100,
      field: "district_id",
      sortable: true,
      resizable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a className="btn-sm btn-info" onClick={() => viewdistlist(params.value)} title="District Athlete List">
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      }
    },
  ];
  function viewdistlist(did){
    window.open("https://ors.indianathletics.in/#/superadmin/nidjamsentdistrictfederation/" + id + "/" + did, "_blank");
    // navigate("/superadmin/nidjamsentdistrictfederation/" + id + "/" + did);
  }

  function sendexcel() {
    if (linkurl === "nidjamsentfederation") {
      var excelParams = {
        fileName: "Send_Federation_List.csv",
      };
      gridRef.current.api.exportDataAsCsv(excelParams);
    } else if (linkurl === "nidjamathleteentries") {
      window.open(exportUrl + "nidjamsentathletesexcel/" + id, "_self");
    } else if (linkurl === "nidjamofficialentries") {
      window.open(exportUrl + "nidjamsentofficialsexcel/" + id, "_self");
    } else if (linkurl === "nidjamarrivalentries") {
      window.open(exportUrl + "nidjamsentarrivalexcel/" + id, "_self");
    } else if (linkurl === "nidjamdepartureentries") {
      window.open(exportUrl + "nidjamsentdepartureexcel/" + id, "_self");
    } else if (linkurl === "nidjammosentries") {
      window.open(exportUrl + "nidjamsentmosexcel/" + id, "_self");
    } else if (linkurl === "nidjamperformancceentries") {
      window.open(exportUrl + "nidjamsentperformanceproofexcel/" + id, "_self");
    } else if (linkurl === "nidjampresscuttingentries") {
      window.open(exportUrl + "nidjamsentpresscuttingsexcel/" + id, "_self");
    } else if (linkurl === "nidjamphotoentries") {
      window.open(exportUrl + "nidjamsentphotosexcel/" + id, "_self");
    } else if (linkurl === "nidjamaccountdetails") {
      window.open(exportUrl + "nidjamsentaccountdetailsexcel/" + id, "_self");
    }
  }

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          {linkurl === "nidjamsentfederation" && (
            <h2 className="main-content-title tx-24 mg-b-5">Federation Sent</h2>
          )}
          {linkurl === "nidjamathleteentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Athlete Entries</h2>
          )}
          {linkurl === "nidjamofficialentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Official Entries
            </h2>
          )}
          {linkurl === "nidjamarrivalentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Arrival Entries</h2>
          )}
          {linkurl === "nidjamdepartureentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Departure Entries
            </h2>
          )}
          {linkurl === "nidjammosentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">MOS Entries</h2>
          )}
          {linkurl === "nidjamperformancceentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Performance Entries
            </h2>
          )}
          {linkurl === "nidjampresscuttingentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">
              Press Cuttings Entries
            </h2>
          )}
          {linkurl === "nidjamphotoentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Photo Entries</h2>
          )}
          {linkurl === "nidjamaccountdetails" && (
            <h2 className="main-content-title tx-24 mg-b-5">Account Details</h2>
          )}
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  {linkurl === "nidjamsentfederation" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Federation Export
                    </a>
                  )}
                  {linkurl === "nidjamathleteentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Athlete Export
                    </a>
                  )}
                  {linkurl === "nidjamofficialentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Official Export
                    </a>
                  )}
                  {linkurl === "nidjamarrivalentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Arrival Export
                    </a>
                  )}
                  {linkurl === "nidjamdepartureentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Departure Export
                    </a>
                  )}
                  {linkurl === "nidjammosentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      MOS Export
                    </a>
                  )}
                  {linkurl === "nidjamperformancceentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Performance Export
                    </a>
                  )}
                  {linkurl === "nidjampresscuttingentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Press Cuttings Export
                    </a>
                  )}
                  {linkurl === "nidjamphotoentries" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Photos Export
                    </a>
                  )}
                  {linkurl === "nidjamaccountdetails" && (
                    <a className="btn btn-success me-2" onClick={sendexcel}>
                      Account Details Export
                    </a>
                  )}
                  <a
                    className="btn btn-danger"
                    onClick={() => navigate("/superadmin/viewmeet/" + id)}
                  >
                    Back
                  </a>
                </div>
                {linkurl === "nidjamsentfederation" && (
                <>
                <div
                  responsive="true"
                  className="table-responsive border border-bottom-0 mt-2"
                >
                  <Table className="table text-nowrap text-md-nowrap mg-b-0">
                    <thead>
                      <tr>
                        <th>Submitted District</th>
                        <th>Submitted Male</th>
                        <th>Submitted Female</th>
                        <th>Pending District</th>
                        <th>Pending Male</th>
                        <th>Pending Female</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterSentCount.map((list, index) => (
                        <tr key={index} data-index={index}>
                          <td>{list.sdistrict} </td>
                          <td>{list.smalecnt}</td>
                          <td>{list.sfemalecount}</td>
                          <td>{list.pdistrict}</td>
                          <td>{list.pmalecount}</td>
                          <td>{list.pfemalecount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <hr />
                </>
                )}
                

                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  {linkurl === "nidjamsentfederation" && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnSent}
                      rowData={filterSent}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamathleteentries" && (
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamofficialentries" && (
                    <AgGridReact
                      columnDefs={columnOffs}
                      rowData={filterOfficial}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamarrivalentries" && (
                    <AgGridReact
                      columnDefs={columnArri}
                      rowData={filterArrival}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamdepartureentries" && (
                    <AgGridReact
                      columnDefs={columnDept}
                      rowData={filterDeparture}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjammosentries" && (
                    <AgGridReact
                      columnDefs={columnMos}
                      rowData={filterMOS}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamperformancceentries" && (
                    <AgGridReact
                      columnDefs={columnPerf}
                      rowData={filterPerf}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}

                  {linkurl === "nidjampresscuttingentries" && (
                    <AgGridReact
                      columnDefs={columnPress}
                      rowData={filterPress}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamphotoentries" && (
                    <AgGridReact
                      columnDefs={columnPhoto}
                      rowData={filterPhoto}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {linkurl === "nidjamaccountdetails" && (
                    <AgGridReact
                      columnDefs={columnAccount}
                      rowData={filterAccount}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NidjamNationalEntries;
