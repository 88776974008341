export const MENUITEMS = [
  {
    Items: [
      {
        path: "/athlete/dashboard",
        icon: "fa fa-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      // {
      //     path: '/athlete/idcard',
      //     icon: 'fa fa-address-card',
      //     type: 'link',
      //     active: false,
      //     selected: false,
      //     title: 'ID Card'
      // },
      {
        title: "Profile",
        icon: "fa fa-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/athlete/myprofile",
            type: "link",
            active: false,
            selected: false,
            title: "My Profile",
          },
        ],
      },
      // {
      //     title: 'Account Details',
      //     icon: 'fa fa-bank',
      //     type: 'sub',
      //     active: false,
      //     selected: false,
      //     children: [
      //         {
      //             path: '/athlete/accountdetail',
      //             type: 'link',
      //             active: false,
      //             selected: false,
      //             title: 'Account Details'
      //         },
      //     ]
      // },
      {
        title: "NOC",
        icon: "fa fa-address-card",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/athlete/applynoc",
            type: "link",
            active: false,
            selected: false,
            title: "Apply NOC",
          },
        ],
      },
      {
        path: "/athlete/certificatelist",
        icon: "fa fa-certificate",
        type: "link",
        active: false,
        selected: false,
        title: "Certificate",
      },
      {
        title: "Performance",
        icon: "fa fa-list-alt",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/athlete/results",
            type: "link",
            active: false,
            selected: false,
            title: "Results",
          },
        ],
      },
      {
        title: "Reapply IDCard",
        icon: "fa fa-address-card",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/athlete/idcardreapply",
            type: "link",
            active: false,
            selected: false,
            title: "Apply IDCard",
          },
        ],
      },
      
    ],
  },
];
