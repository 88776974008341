import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

export async function bloodlist() {
  try {
    return await axios
      .get(`${baseUrl}/bloodgrouplist`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statelist() {
  try {
    return await axios
      .get(`${baseUrl}/statelist`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function distlist(data) {
  try {
    return await axios
      .get(`${baseUrl}/districtlist/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function registerdata(data) {
  try {
    return await axios
      .post(`${baseUrl}/athleteregister`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}



export async function validatemail(data) {
  try {
    return await axios
      .get(`${baseUrl}/validateemail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function validatemobile(data) {
  try {
    return await axios
      .get(`${baseUrl}/validatemobile/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  // console.log(error.response)
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
