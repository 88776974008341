import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  certificateloglist,
  generatenationallist,
} from "../../../services/superadmin";
const Logfile = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const { id } = useParams();
  const [mname, setMeetName] = useState("");
  const [svalue, setState] = useState("");

  useEffect(() => {
    loadlist();
  }, []);

  function loadlist() {
    setMeetName("");
    setFilterProduct([]);

    certificateloglist(id).then((response) => {
      if (response.status === true) {
        setMeetName(response.data[0].meet_name);
        setFilterProduct(response.data);
      }
    });
  }

  const columnDefs = [
    {
      headerName: "UID",
      width: 170,
      field: "username",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 200,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (
          params.data.middle_name !== null
        ) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },
    {
      headerName: "State",
      width: 200,
      field: "statename",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      width: 170,
      field: "certitype",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Download at",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Device",
      width: 150,
      field: "device",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Browser",
      width: 150,
      field: "browser",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Browser Version",
      width: 160,
      field: "browser_version",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Certificate History
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <div className="col-md-10">
                    <h5>Meet Name - {mname}</h5>
                  </div>
                  <div className="col-md-2">
                    <a
                      className="btn btn-info"
                      onClick={() =>
                        navigate("/superadmin/generatecertificate")
                      }
                    >
                      Back
                    </a>
                  </div>
                </div>

                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Logfile;
