import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
export async function statechangepassword(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/statechangepassword`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateProfileView(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/statedetails/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function stateProfileUpdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/stateupdateinfo`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function stateAthleteInfo(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/athletedetails/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefeddashboard() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/dashboard_count`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedupcoming() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/stateunitmeetlist`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedcompleted() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/completedstateunitmeetlist`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function statefedagecategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getallagecategory`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalagecategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getallagecategory-additional`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedeventlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getalleventsbasedonagecategory`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionaleventlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getalleventsbasedonagecategory-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedathletetlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getathletesbasedoncategory`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalathletetlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/getathletesbasedoncategory-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedeventathletetlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addedathletesforevents`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionaleventathletetlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addedathletesforevents-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedathleteentrylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addedathleteslist`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalathleteentrylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addedathleteslist-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedofficialentrylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addedofficialslist`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedaddathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addnewathlete`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionaladdathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addnewathlete-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function statefedremoveathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/deleteathlete`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalremoveathlete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/deleteathlete-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedaddofficial(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addnewofficial`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedremoveofficial(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/deleteofficial`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedtravelentrylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/travelarrivaldetails`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefeddeptravelentrylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/traveldetailsdeparture`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedaddarrival(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addupdatetravelarrivaldata`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadddeparture(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addupdatetraveldeparturedata`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedremovearrival(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/deletetravelarrivaldetails`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedremovedeparture(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/deletetraveldeparturedetails`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedviewarrival(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/viewtravelarrivaldetails/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedviewdeparture(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/viewtraveldeparturedetails/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedmosupload(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addmos`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedmoslist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/mosinfo`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedfiledelete(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/delete_mos/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedperupload(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/addperformanceproof`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedperlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/performanceproofinfo`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedperdelete(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/delete_performanceproof/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedsummarylist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/viewsummarypage`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedpaymentstatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/check-natfedpayment-status`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalpaymentstatus(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/check-natfedpayment-status-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function statefedoveralllist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/viewoverallsummary`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionaloveralllist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/viewoverallsummary-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function statefedpayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/submitentry`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedfreepayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/free-tournament`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalpayment(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/submitentry-additional`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedadditionalsubmitpayment(id,event) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/submit-pay-additional/${id}/${event}`,  {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedconfirmation(id, fid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/fed-confirmation-pdf/${id}/${fid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedtotalathletelist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/athletelist`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedgenderathletelist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/athletes/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefednocathletelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/noc-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function statefedoverageathletelist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/overage-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedathletedetails(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/athletedetails/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedreportedlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/state/report-profile-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function statefedreportsubmit(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/state/report-profile`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  // console.log(error.response)
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
