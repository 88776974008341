import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { routes } from "./routing/routes";
import App from "./components/app";
import { dashboardroutes } from "./routing/dashboard";
import Network from "./components/network";
import TourRegister from "./components/tournamentregister/register";
import TourAdd from "./components/tournamentregister/touradd";
import TourView from "./components/tournamentregister/tourview";
import TourConfirmation from "./components/tournamentregister/confirm";

const Root = () => {
  return (
    <HashRouter>
      <Network />
      <Routes>
        <Route path="/tournamentregister/:id" element={<TourRegister />} />
        <Route path="/meetadd/:id" element={<TourAdd />} />
        <Route path="/meetview/:id" element={<TourView />} />
        <Route path="/confirmation/:id" element={<TourConfirmation />} />

      </Routes>
      {routes.map((route) => (
        <Provider key={route.id} store={route.provider}>
          <Routes>
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              element={route.element}
            />
          </Routes>
        </Provider>
      ))}

      <Routes>
        <Route element={<App />}>
          {dashboardroutes.map((route) => (
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              element={route.element}
            />
          ))}
        </Route>
      </Routes>
    </HashRouter>

    // <HashRouter>
    //     <Routes>

    //       <Route path={`/`}
    //         element={<App />}>
    //         <Route>
    //           <Route
    //             path={`/dashboard`}
    //             element={<Dashboard />}
    //           />

    //         </Route>

    //       </Route>

    //     </Routes>
    // </HashRouter>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
