import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import {
  agecatadd,
  agecatlist,
  agecatstatus,
  agecatvalidate,
  agecatview,
} from "../../../services/master";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
const Agecategorylist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [status, setStatus] = useState("1");
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [gender, setGender] = useState("Male");

  const [id, setID] = useState(0);

  const [errorage, setAgeMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function Selectstatus(event) {
    setStatus(event.target.value);
  }

  function Selectgender(event) {
    setGender(event.target.value);
  }

  var arr = [];
  useEffect(() => {
    funlist();
  }, []);

  function funlist() {
    setFilterProduct([]);
    agecatlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  const columnDefs = [
    {
      headerName: "Age Category",
      width: 420,
      field: "age_category_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order",
      width: 150,
      field: "orderid",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "is_active",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value === 1 ? true : false}
                onChange={() => handleSwitch(params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },

    {
      headerName: "Actions",
      width: 130,
      field: "id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a onClick={() => viewshow(params.value)} className="btn-sm btn-info">
              <i className="fa fa-edit"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function handleSwitch(list) {
    if (list.value === 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.id);
          temp.append("active", 0);

          agecatstatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("unique_id", list.data.id);
          temp.append("active", 1);

          agecatstatus(temp).then((response) => {
            if (response.status === true) {
              funlist();
            }
          });
        }
      });
    }
  }

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  function handleshow() {
    setID(0);
    setGender("Male");
    setOrder("");
    setName("");
    setStatus("1");
    setAgeMessage("");
    setErrorMessage("");
    setShow(true);
  }

  function handleclose() {
    setID(0);
    setShow(false);
  }

  const [vshow, setVShow] = useState(false);
  function viewshow(vid) {
    setID(vid);
    setAgeMessage("");
    setErrorMessage("");
    setVShow(true);
    agecatview(vid).then((response) => {
      if (response.status === true) {
        setName(response.data.age_category_name);
        setGender(response.data.gender);
        setOrder(response.data.orderid);
        setStatus(response.data.is_active + "");
      }
    });
  }
  function viewclose() {
    setID(0);
    setVShow(false);
  }

  function validate(event) {
    setAgeMessage("");
    setErrorMessage("");
    const temp = new FormData();
    temp.append("agecategory", event.target.value);
    agecatvalidate(temp).then((response) => {
      if (response.status === false) {
        setAgeMessage(response.message);
        setErrorMessage(response.message);
      }
    });
  }

  function Submitage() {
    setErrorMessage("");

    if (name !== "") {
      if (errorage === "") {
        if (order !== "") {
          const tempData = new FormData();
          tempData.append("agecategoryuid", id);
          tempData.append("age_category_name", name);
          tempData.append("orderid", order);
          tempData.append("gender", gender);
          tempData.append("is_active", status);

          agecatadd(tempData).then((response) => {
            if (response.status === true) {
              if (id === 0) {
                handleclose();
              } else {
                viewclose();
              }
              funlist();
              toast.success(response.message);
            } else {
              setErrorMessage(response.data.message);
            }
          });
        } else {
          setErrorMessage("Please Enter Your Order");
        }
      } else {
        setErrorMessage(errorage);
      }
    } else {
      setErrorMessage("Please Enter Your Age Group Name");
    }
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Age Category List</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    onClick={handleshow}
                    variant="success"
                    type="button"
                    className="btn my-1 me-2 btn-success tx-teko"
                  >
                    {" "}
                    Add
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}

                  />
                </div>
              </div>

              <Modal show={show}>
                <Modal.Header>
                  <h4>Add Age Category</h4>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Age Category Name{" "}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your Age Category Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={validate}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Order <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your Order"
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                            maxLength={5}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Gender <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectgender}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="gender"
                                value="Male"
                                checked={gender === "Male"}
                              />
                              Male
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="gender"
                                value="Female"
                                checked={gender === "Female"}
                              />
                              Female
                            </label>
                          </div>
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Status <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />
                              Inactive
                            </label>
                          </div>
                        </div>
                      </Col>
                      {errorMessage !== "" && (
                        <span className="text-danger">{errorMessage}</span>
                      )}
                    </Row>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={vshow}>
                <Modal.Header>
                  <h4>View Age Category</h4>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Age Category Name{" "}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your Age Category Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={validate}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Order <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Enter Your Order"
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Gender <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectgender}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="gender"
                                value="Male"
                                checked={gender === "Male"}
                              />
                              Male
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="gender"
                                value="Female"
                                checked={gender === "Female"}
                              />
                              Female
                            </label>
                          </div>
                        </div>
                      </Col>

                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Status <span className="text-danger"> *</span>
                          </label>
                        </div>

                        <div className="row" onChange={Selectstatus}>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="1"
                                checked={status === "1"}
                              />
                              Active
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="custom-control custom-radio custom-control-md">
                              <input
                                type="radio"
                                className="reg-chk"
                                id="active"
                                value="0"
                                checked={status === "0"}
                              />
                              Inactive
                            </label>
                          </div>
                        </div>
                      </Col>
                      {errorMessage !== "" && (
                        <span className="text-danger">{errorMessage}</span>
                      )}
                    </Row>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    onClick={Submitage}
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={viewclose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Agecategorylist;
