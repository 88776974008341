import React, { Fragment, useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { clubProfileView, clubProfileUpdate } from "../../../services/club";
import "../athlete/profile/profile.css";
const ClubProfile = () => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [contName, setContName] = useState("");
  const [association, setAssociation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function submitinfo(e) {
    e.preventDefault();
    const tempFile = {
      user_id: localStorage.getItem("Userid"),
      email_id: email,
      mobile_no: mobile,
      contact_person_name: contName,
      association_name: association,
    };
    let valEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const re = /^[A-Za-z\s]+$/;
    const numOnly = /^[0-9\b]+$/;
    if (tempFile.email_id !== "" && valEmail.test(email)) {
      if (tempFile.mobile_no !== "" && numOnly.test(tempFile.mobile_no)) {
        if (
          tempFile.contact_person_name !== "" &&
          re.test(tempFile.contact_person_name)
        ) {
          if (
            tempFile.association_name !== "" &&
            re.test(tempFile.association_name)
          ) {
            clubProfileUpdate(tempFile).then((response) => {
              if (response.status === true) {
                window.location.reload();
              } else {
                setErrorMessage(response.message);
              }
            });
          } else {
            setErrorMessage("Please enter valid association name");
          }
        } else {
          setErrorMessage("Please Enter Valid Name");
        }
      } else {
        setErrorMessage("Please enter Valid mobile no");
      }
    } else {
      setErrorMessage("Invalid Email");
    }
  }

  useEffect(() => {
    clubProfileView(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setContName(response.data.contact_person_name);
        setAssociation(response.data.name);
        setEmail(response.data.email_id);
        setMobile(response.data.mobile_no);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Profile</h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container defaultActiveKey="info">
                  <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link eventKey="info" className="mt-1">
                        Your Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="update" className="mt-1">
                        Update Your Information
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="info" className="border">
                      <h5>Personal Information</h5>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">EMAIL</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">MOBILE NO</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">
                                  CONTACT PERSON NAME
                                </b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">{contName}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">ASSOCIATION NAME</b>
                              </div>
                              <div className="col-md-8">
                                <span className="text-inverse">
                                  {association}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="update" className="border">
                      <h5>Personal Information</h5>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">EMAIL</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  autoComplete="off"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">MOBILE NO</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  autoComplete="off"
                                  value={mobile}
                                  onChange={(e) => {
                                    setMobile(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">
                                  CONTACT PERSON NAME
                                </b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  autoComplete="off"
                                  value={contName}
                                  onChange={(e) => {
                                    setContName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-4">
                                <b className="text-inverse">ASSOCIATION NAME</b>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  autoComplete="off"
                                  value={association}
                                  onChange={(e) => {
                                    setAssociation(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {errorMessage !== "" && (
                        <span className="errortxt text-danger">
                          {errorMessage}
                        </span>
                      )}
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <a
                            className="btn btn-secondary"
                            style={{ float: "right" }}
                            onClick={submitinfo}
                          >
                            Update
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ClubProfile;
