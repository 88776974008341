import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  generatebulknationallist,
  generatenationalcertificate,
  generatenationallist,
} from "../../../services/superadmin";
import moment from "moment";
import { statelist } from "../../../services/registerservice";
import { toast } from "react-toastify";
const NationalCertificate = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();

  const { id } = useParams();
  const [mname, setMeetName] = useState("");
  const [svalue, setState] = useState("");

  const [slist, setStatelist] = useState([]);
  useEffect(() => {
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });

    loadlist();
  }, []);

  function loadlist() {
    setMeetName("");
    setFilterProduct([]);
    generatenationallist(id).then((response) => {
      if (response.status === true) {
        setMeetName(response.data[0].meet_name);
        setFilterProduct(response.data);
      }
    });
  }

  const [loading, setLoading] = useState(false);

  function gencert() {
    if (svalue !== "") {
      setLoading(true);
      generatebulknationallist(id, svalue).then((response) => {
        if (response.status === true) {
          setLoading(false);

          // let a = document.createElement("a");
          // a.setAttribute("target", "_self");
          // a.setAttribute("href", response);
          // a.download = response;
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);

          window.open(response.data, "_blank");
          loadlist();
        }
      });
    } else {
      toast.error("Please Select Your State");
    }
  }
  const columnDefs = [
    {
      headerName: "UID",
      width: 200,
      field: "user_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 200,
      field: "full_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "dob",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },
    {
      headerName: "Age Category",
      width: 150,
      field: "age_category",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "event_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "State",
      width: 200,
      field: "state_name",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Generated Date",
      width: 150,
      field: "created_at",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
        // cellRenderer: (data) => {
        //   return moment(data.value).format("DD-MM-YYYY");
        // },
    },
    {
      headerName: "Actions",
      width: 100,
      field: "user_uid",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        if (params.data.certificate_name === null) {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-success"
                onClick={() => certcheck(params.data.certificate_uid, "generate")}
              >
                <i className="fa fa-plus fa-lg"></i>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a
                className="btn-sm btn-info"
                onClick={() => certcheck(params.data.certificate_uid, "download")}
              >
                <i className="fa fa-download fa-lg"></i>
              </a>
            </div>
          );
        }
      },
    },
  ];

  function certcheck(uid, utype) {
    generatenationalcertificate(id, uid, utype).then((response) => {
      if (response.status === true) {
        if (utype === "generate") {
          window.open(response.data, "_blank");

          loadlist();
        } else {
          let a = document.createElement("a");
          a.setAttribute("target", "_blank");
          a.setAttribute("href", response.data);
          a.download = response.data;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    });
  }
  const navigate = useNavigate();

  return (
    <Fragment>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Generate Certificate
          </h2>
        </div>
      </div>
      {/* {gen !== "" && <iframe src={gen} />} */}
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="text-center w-100 mb-4">
                  <h5>Meet Name - {mname}</h5>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State</label>
                      <select
                        value={svalue}
                        onChange={(e) => setState(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Your State
                        </option>
                        <option value="All States">All States</option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.statename} key={i}>
                              {data.statename}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <a className="btn btn-success mt-4" onClick={gencert}>
                      Generate
                    </a>
                  </div>
                  <div className="col-md-3 text-right">
                    <a
                      className="btn btn-info mt-4"
                      onClick={() =>
                        navigate("/superadmin/generatecertificate")
                      }
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NationalCertificate;
