import { fn } from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { statefedaddofficial, statefedpaymentstatus } from "../../../services/state";
import "./state.css";
import StateHeading from "./stateheading";

const AddOfficials = () => {
  const { id } = useParams();

  const [fname, setFirst] = useState("");
  const [lname, setLast] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [post, setPost] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  const navigate = useNavigate();
  function regofficial(e) {
    e.preventDefault();
    setErrorMessage("");
    if (fname !== "") {
      if (lname !== "") {
        if (gender !== "") {
          if (phone !== "") {
            if (email !== "") {
              var valid = "";
              let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
              if (!regEmail.test(email)) {
                valid = "Please Enter a valid Email";
                setErrorMessage("Please Enter a valid Email Address");
              }

              if (valid === "") {
                if (post !== "") {
                  if (formphoto !== "") {
                    if (formphotoerror !== "") {
                      setErrorMessage(formphotoerror);
                    } else {
                      const tempData = new FormData();
                      tempData.append("tournament_meet_id", id);
                      tempData.append("first_name", fname);
                      tempData.append("last_name", lname);
                      tempData.append("gender", gender);
                      tempData.append("post", post);
                      tempData.append("phone", phone);
                      tempData.append("email", email);
                      tempData.append("photograph", formphotoupload);

                      statefedaddofficial(tempData).then((response) => {
                        if (response.status === true) {
                          toast.success(response.message);
                          navigate("/state/officialentrylist/" + id);
                          // setFirst("")
                          // setLast("");
                          // setGender("")
                          // setEmail("")
                          // setPhone("")
                          // setPost("")
                          // setPhotoErrors("")
                          // setPhotoFile("")
                          // setPhotoUpload("")
                        }
                      });
                    }
                  } else {
                    setErrorMessage("Please Choose Your Photograph");
                  }
                } else {
                  setErrorMessage("Please Select Your Post");
                }
              }
            } else {
              setErrorMessage("Please Enter Your Email Address");
            }
          } else {
            setErrorMessage("Please Enter Your Phone Number");
          }
        } else {
          setErrorMessage("Please Select Your Gender");
        }
      } else {
        setErrorMessage("Please Enter Your Last Name");
      }
    } else {
      setErrorMessage("Please Enter Your First Name");
    }
  }
  // Photograph
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        setPhotoUpload(event.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  useEffect(()=>{
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedpaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });
  },[])
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Add Officials
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/state/athleteentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Athlete Entry
                  </Link>
                  <Link
                    to={"/state/travelentrylist/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Travel Details
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-0 border-top bg-white">
          <Col lg={12} md={12} xl={12} sm={12}>
            <Card className="custom-card overflow-hidden eventslist">
              <Card.Body>
                <form>
                  {/* <!-- Row --> */}
                  <Row className="row-sm mx-0">
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          First Name : <span className="text-danger"> *</span>
                        </label>
                        <input
                          name="name"
                          type="text"
                          placeholder="Enter Your First Name"
                          value={fname}
                          onChange={(e) => setFirst(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Last Name : <span className="text-danger"> *</span>
                        </label>
                        <input
                          name="last"
                          type="text"
                          placeholder="Enter Your Last Name"
                          value={lname}
                          onChange={(e) => setLast(e.target.value)}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Gender : <span className="text-danger"> *</span>
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Phone Number : <span className="text-danger"> *</span>
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          maxLength={10}
                          placeholder="Enter Your Phone Number"
                          value={phone}
                          autoComplete="off"
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Email Address :{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          name="email"
                          type="text"
                          placeholder="Enter Your Email Address"
                          autoComplete="off"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Post : <span className="text-danger"> *</span>
                        </label>
                        <select
                          value={post}
                          onChange={(e) => {
                            setPost(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Post
                          </option>
                          <option value="Coach">Coach</option>
                          <option value="Manager">Manager</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group">
                        <label className="mb-2">
                          Photograph : <span className="text-danger"> *</span>
                        </label>
                        <input
                          id="upload"
                          type="file"
                          onChange={photofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      {formphoto !== "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={formphoto}
                          />
                        </div>
                      )}

                      {formphoto === "" && (
                        <div className="form-group">
                          <img
                            alt="avatar"
                            className="uploadimage"
                            src={require("../../../assets/images/placeholder.png")}
                          />
                        </div>
                      )}
                    </Col>
                    {errorMessage !== "" && (
                      <span className="errortxt text-danger">
                        {errorMessage}
                      </span>
                    )}
                    <hr className="w-100" />
                    <Col lg={12} md={12}>
                      <div className="form-group text-center">
                        <a
                          variant="success"
                          type="button"
                          className="btn my-1 btn-success tx-teko tx-uppercase"
                          onClick={regofficial}
                        >
                          {" "}
                          Add Officials
                        </a>
                      </div>
                    </Col>
                  </Row>
                  {/* <!-- End Row --> */}
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AddOfficials;
