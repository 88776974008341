import React, { useState, useEffect, Fragment, useRef } from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { approverejectcount } from "../../../services/superadmin";
const Approvedandrejected = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");

  const gridRef = useRef();
  useEffect(() => {
    loaddate();
  }, []);
  var arr = [];
  function loaddate() {
    var adate =
      new Date().getFullYear() +
      "/" +
      (new Date().getMonth() + 1) +
      "/" +
      new Date().getDate();

    setSDate(moment(adate).format("YYYY-MM-DD"));
    setEDate(moment(adate).format("YYYY-MM-DD"));
    const tempData = new FormData();
    tempData.append("start_date", moment(adate).format("YYYY-MM-DD"));
    tempData.append("end_date", moment(adate).format("YYYY-MM-DD"));
    approverejectcount(tempData).then((response) => {
      if (response.status === true) {
        if (response.data.admin.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.admin.length; i++) {
            arr.push({
              id: response.data.admin[i].id,
              username: response.data.admin[i].username,
              approve: 0,
              reject: 0,
            });
          }
          if (arr.length !== 0) {
            if (response.data.approved.length !== 0) {
              for (let a = 0; a < arr.length; a++) {
                for (let i = 0; i < response.data.approved.length; i++) {
                  if (arr[a].id === response.data.approved[i].appr_or_rejby) {
                    arr[a].approve = response.data.approved[i].approved_count;
                  }
                }
              }
            }
          }

          if (arr.length !== 0) {
            if (response.data.rej_count.length !== 0) {
              for (let a = 0; a < arr.length; a++) {
                for (let i = 0; i < response.data.rej_count.length; i++) {
                  if (arr[a].id === response.data.rej_count[i].appr_or_rejby) {
                    arr[a].reject = response.data.rej_count[i].rej_count;
                  }
                }
              }
            }
          }
        }
      }
      setFilterProduct(arr)
    });
  }

  function searchdata(){
    const tempData = new FormData();
    tempData.append("start_date", sdate);
    tempData.append("end_date",edate);
    approverejectcount(tempData).then((response) => {
      if (response.status === true) {

        setFilterProduct([])
        if (response.data.admin.length !== 0) {
          arr = [];
          for (let i = 0; i < response.data.admin.length; i++) {
            arr.push({
              id: response.data.admin[i].id,
              username: response.data.admin[i].username,
              approve: 0,
              reject: 0,
            });
          }
          if (arr.length !== 0) {
            if (response.data.approved.length !== 0) {
              for (let a = 0; a < arr.length; a++) {
                for (let i = 0; i < response.data.approved.length; i++) {
                  if (arr[a].id === response.data.approved[i].appr_or_rejby) {
                    arr[a].approve = response.data.approved[i].approved_count;
                  }
                }
              }
            }
          }

          if (arr.length !== 0) {
            if (response.data.rej_count.length !== 0) {
              for (let a = 0; a < arr.length; a++) {
                for (let i = 0; i < response.data.rej_count.length; i++) {
                  if (arr[a].id === response.data.rej_count[i].appr_or_rejby) {
                    arr[a].reject = response.data.rej_count[i].rej_count;
                  }
                }
              }
            }
          }
        }
      }
      setFilterProduct(arr)
    });
  }
  const columnDefs = [
    {
      headerName: "User",
      width: 400,
      field: "username",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Approved Count",
      width: 300,
      field: "approve",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Rejected Count",
      width: 300,
      field: "reject",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Approved & Rejected Count
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="profile-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Start Date</b>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="date"
                                placeholder="Enter Your Start Date"
                                value={sdate}
                                onChange={(e) => setSDate(e.target.value)}
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="profile-group">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">End Date</b>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="date"
                                placeholder="Enter Your End Date"
                                value={edate}
                                onChange={(e) => setEDate(e.target.value)}
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="res-right">
                          <a className="btn btn-success mt-2" onClick={searchdata}>
                            Search
                          </a>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="res-right">
                          <a className="btn btn-info mt-2" onClick={loaddate}>
                            Reset
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 525,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Approvedandrejected;
