import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  nationalallentries,
  nationalsententriesexport,
  stateallentries,
  statedistrictallentries,
  statesententriesexport,
} from "../../../services/superadmin";
import imageUrl from "../../../ImageUrl";
import exportUrl from "../../../Export";
import fileString from "../../../assets/language/filepath.json";
import { meetinfo } from "../../../services/meetservice";

const AllEntries = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [entrytype, setEntryType] = useState("");

  const [linkurl, setLink] = useState("");
  const [allent, setAllEnt] = useState(true);
  const { id } = useParams();
  const gridRef = useRef();
  let arr = [];
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setLink(link[1]);
    meetinfo(id).then((response) => {
      if (eid === "nationalallentries") {
        setAllEnt(true);
        nationalallentries(id).then((response) => {
          if (response.status === true) {
            if (response.data.individual_pending_entries.length !== 0) {
              for (
                let i = 0;
                i < response.data.individual_pending_entries.length;
                i++
              ) {
                var mchk = "";
                if (
                  response.data.individual_pending_entries[i].middle_name !==null
                ) {
                  mchk =
                    response.data.individual_pending_entries[i].middle_name +
                    " ";
                }
                arr.push({
                  user_id: response.data.individual_pending_entries[i].user_id,
                  statename:
                    response.data.individual_pending_entries[i].statename,
                  district:
                    response.data.individual_pending_entries[i].district,
                  username:
                    response.data.individual_pending_entries[i].username,

                  first_name:
                    response.data.individual_pending_entries[i].first_name +
                    " " +
                    mchk +
                    response.data.individual_pending_entries[i].last_name,
                  photograph:
                    imageUrl +
                    fileString.athletephoto +
                    response.data.individual_pending_entries[i].photograph,
                  email_id:
                    response.data.individual_pending_entries[i].email_id,
                  mobile_no:
                    response.data.individual_pending_entries[i].mobile_no,
                  date_of_birth:
                    response.data.individual_pending_entries[i].date_of_birth,
                  gender: response.data.individual_pending_entries[i].gender,
                  order_status:
                    response.data.individual_pending_entries[i].order_status,
                  tournament_category:
                    response.data.individual_pending_entries[i]
                      .tournament_category,
                  order_id:
                    response.data.individual_pending_entries[i].order_id,
                  eventslist:
                    response.data.individual_pending_entries[i].eventslist,
                  receipt_no:
                    response.data.individual_pending_entries[i].receipt_no,
                  trans_date:
                    response.data.individual_pending_entries[i].trans_date,
                });
              }
            }

            if (response.data.individual_sent_entries.length !== 0) {
              for (
                let i = 0;
                i < response.data.individual_sent_entries.length;
                i++
              ) {
                var mchk = "";
                if (
                  response.data.individual_sent_entries[i].middle_name !==null
                ) {
                  mchk =
                    response.data.individual_sent_entries[i].middle_name + " ";
                }
                arr.push({
                  user_id: response.data.individual_sent_entries[i].user_id,
                  statename: response.data.individual_sent_entries[i].statename,
                  district: response.data.individual_sent_entries[i].district,
                  username: response.data.individual_sent_entries[i].username,
                  first_name:
                    response.data.individual_sent_entries[i].first_name +
                    " " +
                    mchk +
                    response.data.individual_sent_entries[i].last_name,
                  photograph:
                    imageUrl +
                    fileString.athletephoto +
                    response.data.individual_sent_entries[i].photograph,
                  email_id: response.data.individual_sent_entries[i].email_id,
                  mobile_no: response.data.individual_sent_entries[i].mobile_no,
                  date_of_birth:
                    response.data.individual_sent_entries[i].date_of_birth,
                  gender: response.data.individual_sent_entries[i].gender,
                  order_status:
                    response.data.individual_sent_entries[i].order_status,
                  tournament_category:
                    response.data.individual_sent_entries[i]
                      .tournament_category,
                  order_id: response.data.individual_sent_entries[i].order_id,
                  eventslist:
                    response.data.individual_sent_entries[i].eventslist,
                  receipt_no:
                    response.data.individual_sent_entries[i].receipt_no,
                  trans_date:
                    response.data.individual_sent_entries[i].trans_date,
                });
              }
            }
            setFilterProduct(arr);
          }
        });
      } else if (eid === "nationalsententries") {
        setAllEnt(true);
        nationalallentries(id).then((response) => {
          if (response.status === true) {
            if (response.data.individual_sent_entries.length !== 0) {
              for (
                let i = 0;
                i < response.data.individual_sent_entries.length;
                i++
              ) {
                var mchk = "";
                if (
                  response.data.individual_sent_entries[i].middle_name !==null
                ) {
                  mchk =
                    response.data.individual_sent_entries[i].middle_name + " ";
                }
                arr.push({
                  user_id: response.data.individual_sent_entries[i].user_id,
                  statename: response.data.individual_sent_entries[i].statename,
                  district: response.data.individual_sent_entries[i].district,
                  username: response.data.individual_sent_entries[i].username,
                  first_name:
                    response.data.individual_sent_entries[i].first_name +
                    " " +
                    mchk +
                    response.data.individual_sent_entries[i].last_name,
                  photograph:
                    imageUrl +
                    fileString.athletephoto +
                    response.data.individual_sent_entries[i].photograph,
                  email_id: response.data.individual_sent_entries[i].email_id,
                  mobile_no: response.data.individual_sent_entries[i].mobile_no,
                  date_of_birth:
                    response.data.individual_sent_entries[i].date_of_birth,
                  gender: response.data.individual_sent_entries[i].gender,
                  order_status:
                    response.data.individual_sent_entries[i].order_status,
                  tournament_category:
                    response.data.individual_sent_entries[i]
                      .tournament_category,
                  order_id: response.data.individual_sent_entries[i].order_id,
                  eventslist:
                    response.data.individual_sent_entries[i].eventslist,
                  receipt_no:
                    response.data.individual_sent_entries[i].receipt_no,
                  trans_date:
                    response.data.individual_sent_entries[i].trans_date,
                });
              }
            }
            setFilterProduct(arr);
          }
        });
      } else if (eid === "nationalpendingentries") {
        setAllEnt(false);

        nationalallentries(id).then((response) => {
          if (response.status === true) {
            if (response.data.individual_pending_entries.length !== 0) {
              for (
                let i = 0;
                i < response.data.individual_pending_entries.length;
                i++
              ) {
                var mchk = "";
                if (
                  response.data.individual_pending_entries[i].middle_name !== null
                ) {
                  mchk =
                    response.data.individual_pending_entries[i].middle_name +
                    " ";
                }
                arr.push({
                  user_id: response.data.individual_pending_entries[i].user_id,
                  statename:
                    response.data.individual_pending_entries[i].statename,
                  district:
                    response.data.individual_pending_entries[i].district,
                  username:
                    response.data.individual_pending_entries[i].username,

                  first_name:
                    response.data.individual_pending_entries[i].first_name +
                    " " +
                    mchk +
                    response.data.individual_pending_entries[i].last_name,
                  photograph:
                    imageUrl +
                    fileString.athletephoto +
                    response.data.individual_pending_entries[i].photograph,
                  email_id:
                    response.data.individual_pending_entries[i].email_id,
                  mobile_no:
                    response.data.individual_pending_entries[i].mobile_no,
                  date_of_birth:
                    response.data.individual_pending_entries[i].date_of_birth,
                  gender: response.data.individual_pending_entries[i].gender,
                  order_status:
                    response.data.individual_pending_entries[i].order_status,
                  tournament_category:
                    response.data.individual_pending_entries[i]
                      .tournament_category,
                  order_id:
                    response.data.individual_pending_entries[i].order_id,
                  eventslist:
                    response.data.individual_pending_entries[i].eventslist,
                  receipt_no:
                    response.data.individual_pending_entries[i].receipt_no,
                  trans_date:
                    response.data.individual_pending_entries[i].trans_date,
                });
              }
            }
            setFilterProduct(arr);
          }
        });
      }

      if (eid === "stateallentries") {
        setAllEnt(true);
        setEntryType(response.data.tournament.entry_type);
        if (response.data.tournament.entry_type === "District") {
          statedistrictallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.district_pending_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.district_pending_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.district_pending_entries[i].middle_name !== null
                  ) {
                    mchk =
                      response.data.district_pending_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id: response.data.district_pending_entries[i].user_id,
                    statename:
                      response.data.district_pending_entries[i].statename,
                    district:
                      response.data.district_pending_entries[i].federation_name,
                    username:
                      response.data.district_pending_entries[i].username,
                    first_name:
                      response.data.district_pending_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.district_pending_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.district_pending_entries[i].photograph,
                    email_id:
                      response.data.district_pending_entries[i].email_id,
                    mobile_no:
                      response.data.district_pending_entries[i].mobile_no,
                    date_of_birth:
                      response.data.district_pending_entries[i].date_of_birth,
                    gender: response.data.district_pending_entries[i].gender,
                    order_status:
                      response.data.district_pending_entries[i].order_status,
                    tournament_category:
                      response.data.district_pending_entries[i].age_category,
                    order_id:
                      response.data.district_pending_entries[i].t_order_id,
                    eventslist:
                      response.data.district_pending_entries[i].events,
                    receipt_no:
                      response.data.district_pending_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.district_pending_entries[i].order_date,
                  });
                }
              }

              if (response.data.district_sent_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.district_sent_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.district_sent_entries[i].middle_name !== null
                  ) {
                    mchk =
                      response.data.district_sent_entries[i].middle_name + " ";
                  }
                  arr.push({
                    user_id: response.data.district_sent_entries[i].user_id,
                    statename: response.data.district_sent_entries[i].statename,
                    district:
                      response.data.district_sent_entries[i].federation_name,
                    username: response.data.district_sent_entries[i].username,
                    first_name:
                      response.data.district_sent_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.district_sent_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.district_sent_entries[i].photograph,
                    email_id: response.data.district_sent_entries[i].email_id,
                    mobile_no: response.data.district_sent_entries[i].mobile_no,
                    date_of_birth:
                      response.data.district_sent_entries[i].date_of_birth,
                    gender: response.data.district_sent_entries[i].gender,
                    order_status:
                      response.data.district_sent_entries[i].order_status,
                    tournament_category:
                      response.data.district_sent_entries[i].age_category,
                    order_id: response.data.district_sent_entries[i].t_order_id,
                    eventslist: response.data.district_sent_entries[i].events,
                    receipt_no:
                      response.data.district_sent_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.district_sent_entries[i].order_date,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        } else {
          stateallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.individual_pending_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.individual_pending_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.individual_pending_entries[i].middle_name !==
                     null
                  ) {
                    mchk =
                      response.data.individual_pending_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id:
                      response.data.individual_pending_entries[i].user_id,
                    statename:
                      response.data.individual_pending_entries[i].statename,
                    district:
                      response.data.individual_pending_entries[i].district,
                    username:
                      response.data.individual_pending_entries[i].username,
                    first_name:
                      response.data.individual_pending_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.individual_pending_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.individual_pending_entries[i].photograph,
                    email_id:
                      response.data.individual_pending_entries[i].email_id,
                    mobile_no:
                      response.data.individual_pending_entries[i].mobile_no,
                    date_of_birth:
                      response.data.individual_pending_entries[i].date_of_birth,
                    gender: response.data.individual_pending_entries[i].gender,
                    order_status:
                      response.data.individual_pending_entries[i].order_status,
                    tournament_category:
                      response.data.individual_pending_entries[i].age_category,
                    order_id:
                      response.data.individual_pending_entries[i].t_order_id,
                    eventslist:
                      response.data.individual_pending_entries[i].events,
                    receipt_no:
                      response.data.individual_pending_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.individual_pending_entries[i].order_date,
                  });
                }
              }

              if (response.data.individual_sent_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.individual_sent_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.individual_sent_entries[i].middle_name !==
                      null
                  ) {
                    mchk =
                      response.data.individual_sent_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id: response.data.individual_sent_entries[i].user_id,
                    statename:
                      response.data.individual_sent_entries[i].statename,
                    district: response.data.individual_sent_entries[i].district,
                    username: response.data.individual_sent_entries[i].username,
                    first_name:
                      response.data.individual_sent_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.individual_sent_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.individual_sent_entries[i].photograph,
                    email_id: response.data.individual_sent_entries[i].email_id,
                    mobile_no:
                      response.data.individual_sent_entries[i].mobile_no,
                    date_of_birth:
                      response.data.individual_sent_entries[i].date_of_birth,
                    gender: response.data.individual_sent_entries[i].gender,
                    order_status:
                      response.data.individual_sent_entries[i].order_status,
                    tournament_category:
                      response.data.individual_sent_entries[i].age_category,
                    order_id:
                      response.data.individual_sent_entries[i].t_order_id,
                    eventslist: response.data.individual_sent_entries[i].events,
                    receipt_no:
                      response.data.individual_sent_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.individual_sent_entries[i].order_date,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      } else if (eid === "statesententries") {
        setAllEnt(true);
        setEntryType(response.data.tournament.entry_type);
        if (response.data.tournament.entry_type === "District") {
          statedistrictallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.district_sent_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.district_sent_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.district_sent_entries[i].middle_name !== null
                  ) {
                    mchk =
                      response.data.district_sent_entries[i].middle_name + " ";
                  }
                  arr.push({
                    user_id: response.data.district_sent_entries[i].user_id,
                    statename: response.data.district_sent_entries[i].statename,
                    district:
                      response.data.district_sent_entries[i].federation_name,
                    username: response.data.district_sent_entries[i].username,
                    first_name:
                      response.data.district_sent_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.district_sent_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.district_sent_entries[i].photograph,
                    email_id: response.data.district_sent_entries[i].email_id,
                    mobile_no: response.data.district_sent_entries[i].mobile_no,
                    date_of_birth:
                      response.data.district_sent_entries[i].date_of_birth,
                    gender: response.data.district_sent_entries[i].gender,
                    order_status:
                      response.data.district_sent_entries[i].order_status,
                    tournament_category:
                      response.data.district_sent_entries[i].age_category,
                    order_id: response.data.district_sent_entries[i].t_order_id,
                    eventslist: response.data.district_sent_entries[i].events,
                    receipt_no:
                      response.data.district_sent_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.district_sent_entries[i].order_date,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        } else {
          stateallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.individual_sent_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.individual_sent_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.individual_sent_entries[i].middle_name !== null
                  ) {
                    mchk =
                      response.data.individual_sent_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id: response.data.individual_sent_entries[i].user_id,
                    statename:
                      response.data.individual_sent_entries[i].statename,
                    district: response.data.individual_sent_entries[i].district,
                    username: response.data.individual_sent_entries[i].username,
                    first_name:
                      response.data.individual_sent_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.individual_sent_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.individual_sent_entries[i].photograph,
                    email_id: response.data.individual_sent_entries[i].email_id,
                    mobile_no:
                      response.data.individual_sent_entries[i].mobile_no,
                    date_of_birth:
                      response.data.individual_sent_entries[i].date_of_birth,
                    gender: response.data.individual_sent_entries[i].gender,
                    order_status:
                      response.data.individual_sent_entries[i].order_status,
                    tournament_category:
                      response.data.individual_sent_entries[i].age_category,
                    order_id:
                      response.data.individual_sent_entries[i].t_order_id,
                    eventslist: response.data.individual_sent_entries[i].events,
                    receipt_no:
                      response.data.individual_sent_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.individual_sent_entries[i].order_date,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      } else if (eid === "statependingentries") {
        setAllEnt(false);
        setEntryType(response.data.tournament.entry_type);
        if (response.data.tournament.entry_type === "District") {
          statedistrictallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.district_pending_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.district_pending_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.district_pending_entries[i].middle_name !==
                    null
                  ) {
                    mchk =
                      response.data.district_pending_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id: response.data.district_pending_entries[i].user_id,
                    statename:
                      response.data.district_pending_entries[i].statename,
                    district:
                      response.data.district_pending_entries[i].federation_name,
                    username:
                      response.data.district_pending_entries[i].username,
                    first_name:
                      response.data.district_pending_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.district_pending_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.district_pending_entries[i].photograph,
                    email_id:
                      response.data.district_pending_entries[i].email_id,
                    mobile_no:
                      response.data.district_pending_entries[i].mobile_no,
                    date_of_birth:
                      response.data.district_pending_entries[i].date_of_birth,
                    gender: response.data.district_pending_entries[i].gender,
                    order_status:
                      response.data.district_pending_entries[i].order_status,
                    tournament_category:
                      response.data.district_pending_entries[i].age_category,
                    order_id:
                      response.data.district_pending_entries[i].t_order_id,
                    eventslist:
                      response.data.district_pending_entries[i].events,
                    receipt_no:
                      response.data.district_pending_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.district_pending_entries[i].order_date,
                  });
                }
              }

              setFilterProduct(arr);
            }
          });
        } else {
          stateallentries(id).then((response) => {
            if (response.status === true) {
              if (response.data.individual_pending_entries.length !== 0) {
                for (
                  let i = 0;
                  i < response.data.individual_pending_entries.length;
                  i++
                ) {
                  var mchk = "";
                  if (
                    response.data.individual_pending_entries[i].middle_name !==
                    null
                  ) {
                    mchk =
                      response.data.individual_pending_entries[i].middle_name +
                      " ";
                  }
                  arr.push({
                    user_id:
                      response.data.individual_pending_entries[i].user_id,
                    statename:
                      response.data.individual_pending_entries[i].statename,
                    district:
                      response.data.individual_pending_entries[i].district,
                    username:
                      response.data.individual_pending_entries[i].username,
                    first_name:
                      response.data.individual_pending_entries[i].first_name +
                      " " +
                      mchk +
                      response.data.individual_pending_entries[i].last_name,
                    photograph:
                      imageUrl +
                      fileString.athletephoto +
                      response.data.individual_pending_entries[i].photograph,
                    email_id:
                      response.data.individual_pending_entries[i].email_id,
                    mobile_no:
                      response.data.individual_pending_entries[i].mobile_no,
                    date_of_birth:
                      response.data.individual_pending_entries[i].date_of_birth,
                    gender: response.data.individual_pending_entries[i].gender,
                    order_status:
                      response.data.individual_pending_entries[i].order_status,
                    tournament_category:
                      response.data.individual_pending_entries[i].age_category,
                    order_id:
                      response.data.individual_pending_entries[i].t_order_id,
                    eventslist:
                      response.data.individual_pending_entries[i].events,
                    receipt_no:
                      response.data.individual_pending_entries[i]
                        .payment_or_tracking_id,
                    trans_date:
                      response.data.individual_pending_entries[i].order_date,
                  });
                }
              }
              setFilterProduct(arr);
            }
          });
        }
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "State",
      width: 150,
      field: "statename",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Athlete District",
      width: 220,
      field: "district",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AFI UID",
      width: 130,
      field: "username",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 140,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Age Category",
      width: 140,
      field: "tournament_category",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "eventslist",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Photo",
      width: 140,
      field: "photograph",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => handleshow(params.value)}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Email",
      width: 160,
      field: "email_id",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Contact No",
      width: 160,
      field: "mobile_no",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Order ID",
      width: 200,
      field: "order_id",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment ID",
      width: 150,
      field: "receipt_no",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Status",
      width: 150,
      field: "order_status",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 140,
      field: "trans_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          if (data.value !== null) {
            if (data.value !== "-") {
              return moment(data.value).format("DD-MM-YYYY");
            }
          }
        }
      },
    },
    {
      headerName: "Actions",
      width: 90,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={"/superadmin/viewprofile/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const columnPenDefs = [
    {
      headerName: "State",
      width: 150,
      field: "statename",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Athlete District",
      width: 220,
      field: "district",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "AFI UID",
      width: 130,
      field: "username",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 140,
      field: "first_name",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Age Category",
      width: 140,
      field: "tournament_category",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 150,
      field: "eventslist",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Photo",
      width: 140,
      field: "photograph",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => handleshow(params.value)}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Email",
      width: 160,
      field: "email_id",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Contact No",
      width: 160,
      field: "mobile_no",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 150,
      field: "date_of_birth",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value !== "") {
          return moment(data.value).format("DD-MM-YYYY");
        }
      },
    },
    {
      headerName: "Gender",
      width: 140,
      field: "gender",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment Status",
      width: 150,
      field: "order_status",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        if (data.value === "-") {
          return "Pending";
        } else {
          return data.value;
        }
      },
    },
    {
      headerName: "Actions",
      width: 90,
      field: "user_id",
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={"/superadmin/viewprofile/" + params.value}
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function sendexcel() {
    if (linkurl === "stateallentries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictsententries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualsententries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "statependingentries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictsententries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualsententries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "statesententries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictsententries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualsententries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "nationalallentries") {
      window.open(
        exportUrl + "exportnationalindividualsententries/" + id,
        "_self"
      );
    } else if (linkurl === "nationalsententries") {
      window.open(
        exportUrl + "exportnationalindividualsententries/" + id,
        "_self"
      );
    } else if (linkurl === "nationalpendingentries") {
      window.open(
        exportUrl + "exportnationalindividualsententries/" + id,
        "_self"
      );
    }
  }

  function pendingexcel() {
    if (linkurl === "stateallentries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictpendingentries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualpendingentries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "statependingentries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictpendingentries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualpendingentries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "statesententries") {
      if (entrytype === "District") {
        window.open(
          exportUrl + "exportstateentrydistrictpendingentries/" + id,
          "_self"
        );
      } else {
        window.open(
          exportUrl + "exportstateentryindividualpendingentries/" + id,
          "_self"
        );
      }
    } else if (linkurl === "nationalallentries") {
      window.open(
        exportUrl + "exportnationalindividualpendingentries/" + id,
        "_self"
      );
    } else if (linkurl === "nationalsententries") {
      window.open(
        exportUrl + "exportnationalindividualpendingentries/" + id,
        "_self"
      );
    } else if (linkurl === "nationalpendingentries") {
      window.open(
        exportUrl + "exportnationalindividualpendingentries/" + id,
        "_self"
      );
    }
  }
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          {linkurl === "stateallentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">All Entries</h2>
          )}
          {linkurl === "nationalallentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">All Entries</h2>
          )}
          {linkurl === "nationalsententries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Sent Entries</h2>
          )}
          {linkurl === "statesententries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Sent Entries</h2>
          )}
          {linkurl === "nationalpendingentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Pending Entries</h2>
          )}
          {linkurl === "statependingentries" && (
            <h2 className="main-content-title tx-24 mg-b-5">Pending Entries</h2>
          )}
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a className="btn btn-success me-2" onClick={sendexcel}>
                    Send Entries Export
                  </a>

                  <a className="btn btn-secondary me-2" onClick={pendingexcel}>
                    Pending Entries Export
                  </a>

                  <a
                    className="btn btn-danger me-2"
                    onClick={() => navigate("/superadmin/viewmeet/" + id)}
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  {allent === true && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                  {allent === false && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnPenDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressExcelExport={true}
                      suppressDragLeaveHidesColumns={true}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          <Modal show={show}>
            <Modal.Body>
              <img src={file} alt="Image" onError={(e) => errorhandle(e)} />
            </Modal.Body>
            <Modal.Footer>
              <a
                className="btn btn-next btn-success"
                style={{ float: "right" }}
                href={file}
                download
                target="_blank"
              >
                Download
              </a>
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={handleclose}
              >
                Close
              </a>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllEntries;
