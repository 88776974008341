import { configureStore } from '@reduxjs/toolkit';
import forgotReducer from './action';

const Forgotware = (store) => (next) => (action) => {
    
    return next(action);
};



export default configureStore({
    reducer: {
        user: forgotReducer,
    },
    middleware: [Forgotware]
});
