import { Height } from "@material-ui/icons";
import { Padding } from "@mui/icons-material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Modal, Nav, Tab } from "react-bootstrap";
import {
  certificatemeetlist,
  importcertificate,
  resultimportathletes,
} from "../../../services/superadmin";
import { meetdetails } from "../../../services/meetservice";
import { useNavigate } from "react-router-dom";

const Addresults = () => {
  const [mlist, setMeetlist] = useState([]);
  const [meet, setMeet] = useState("");
  const [mname, setMName] = useState("");
  const [mloc, setMLoc] = useState("");
  const [msdate, setMSDate] = useState("");
  const [medate, setMEDate] = useState("");
  const [udate, setUDate] = useState("");

  const [CFile, setCFile] = useState("");

  useEffect(() => {
    const temp = new FormData();
    temp.append("meet_type", "National Meet");
    temp.append("entry_type", "Federation");

    certificatemeetlist(temp).then((response) => {
      if (response.status === true) {
        setMeetlist(response.data);
      }
    });
  }, []);

  function meetlist(ent) {
    setErrorMessage("");
    cleardata();
    var mtype = "";
    var etype = "";
    if (ent === "federation") {
      mtype = "National Meet";
      etype = "Federation";
    } else if (ent === "individual") {
      mtype = "National Meet";
      etype = "Individual";
    } else if (ent === "state") {
      mtype = "State Entry";
      etype = "0";
    } else if (ent === "notincluded") {
      mtype = "State Entry";
      etype = "1";
    }
    const temp = new FormData();
    temp.append("meet_type", mtype);
    temp.append("entry_type", etype);

    certificatemeetlist(temp).then((response) => {
      if (response.status === true) {
        setMeetlist(response.data);
      }
    });
  }

  function selectmeet(e) {
    setMeet(e.target.value);
    setMName("");
    setMLoc("");
    setMSDate("");
    setMEDate("");
    meetdetails(e.target.value).then((response) => {
      setMName(response.data.tournament.meet_name);
      setMLoc(response.data.tournament.location);
      setMSDate(response.data.tournament.start_date);
      setMEDate(response.data.tournament.end_date);
    });
  }

  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  function certfile(event) {
    setCFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(xls|xlsx)$/i)) {
        setCFile(event.target.files[0]);
      } else {
        inputRef.current.value = null;
        setFileMessage("Result File size should be less than 5MB");
        setErrorMessage("Result File should be xls or xlsx format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setFileMessage("Result File size should be less than 5MB");
        setErrorMessage("Result File size should be less than 5MB");
      }
    }
  }
  const [fileMessage, setFileMessage] = useState("");

  const navigate = useNavigate();
  function certimport() {
    setErrorMessage("");
    if (meet !== "") {
      if (udate !== "") {
        if (CFile !== "") {
          const temp = new FormData();
          temp.append("meet_name", mname);
          temp.append("meet_id", meet);
          temp.append("meet_location", mloc);
          temp.append("meet_start_date", msdate);
          temp.append("meet_end_date", medate);
          temp.append("added_date", udate);
          temp.append("results_excel", CFile);

          resultimportathletes(temp).then((res) => {
            if (res.status === true) {
              navigate("/superadmin/resulthistory");
            }
          });
        } else {
          setErrorMessage("Please Select Your Result File");
        }
      } else {
        setErrorMessage("Please Select Your Upload Date");
      }
    } else {
      setErrorMessage("Please Enter Your Meet");
    }
  }

  function cleardata() {
    setMName("");
    setMeet("");
    setMLoc("");
    setMSDate("");
    setMEDate("");
    setCFile("");
    inputRef.current.value = null;
  }
  return (
    <Fragment>
      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Results</h2>
        </div>
      </div>
      <Card className=" custom-card profilepage">
        <Card.Body>
          <div className="panel profile-top">
            <div className="profile-cover__img"></div>
            <div className="text-right">
              <a className="btn btn-secondary me-2" href={require("../../../assets/images/Result_List.xls")} download>Sample Excel</a>
            </div>
          </div>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container
                  defaultActiveKey="federation"
                  onSelect={meetlist}
                >
                  <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="federation"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Federation Meets
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="individual"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        Individual Meets
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="state"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        State Entries
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="notincluded"
                        className="mt-1 tx-16 tx-uppercase"
                      >
                        State Entries But Not Concluded
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="federation" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Upload Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={udate}
                                onChange={(e) => setUDate(e.target.value)}
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Result File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                ref={inputRef}
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                            <hr />
                            <div className="form-group">
                              <div className="text-center">
                                <a
                                  className="btn btn-sm btn-next btn-success me-2"
                                  onClick={certimport}
                                >
                                  Add Results
                                </a>
                                <a
                                  className="btn btn-sm btn-next btn-danger"
                                  onClick={cleardata}
                                >
                                  Reset
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="individual" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Upload Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={udate}
                                onChange={(e) => setUDate(e.target.value)}
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Result File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                            <hr />
                            <div className="form-group">
                              <div className="text-center">
                                <a
                                  className="btn btn-sm btn-next btn-success me-2"
                                  onClick={certimport}
                                >
                                  Import Result
                                </a>
                                <a
                                  className="btn btn-sm btn-next btn-danger"
                                  onClick={cleardata}
                                >
                                  Reset
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="state" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Upload Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={udate}
                                onChange={(e) => setUDate(e.target.value)}
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Result File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                            <hr />
                            <div className="form-group">
                              <div className="text-center">
                                <a
                                  className="btn btn-sm btn-next btn-success me-2"
                                  onClick={certimport}
                                >
                                  Import Result
                                </a>
                                <a
                                  className="btn btn-sm btn-next btn-danger"
                                  onClick={cleardata}
                                >
                                  Reset
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="notincluded" className="border">
                      <div className="row">
                        <div className="col-md-6 mx-auto">
                          <form>
                            <div className="form-group">
                              <label>
                                Meet Name <span className="text-danger">*</span>
                              </label>
                              <select value={meet} onChange={selectmeet}>
                                <option value="" disabled>
                                  Select Your Meet
                                </option>
                                {mlist.map((data, i) => {
                                  return (
                                    <option value={data.id}>
                                      {data.meet_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Meet Location"
                                value={mloc}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                Meet Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                value={msdate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Meet End Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={medate}
                                readOnly
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Upload Date{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="date"
                                value={udate}
                                onChange={(e) => setUDate(e.target.value)}
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <b className="text-inverse">
                                  Result File{" "}
                                  <span className="text-danger">*</span>
                                </b>
                              </label>
                              <input
                                type="file"
                                onChange={certfile}
                                accept=".xls,.xlsx"
                              ></input>
                            </div>
                            {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )}
                            <hr />
                            <div className="form-group">
                              <div className="text-center">
                                <a
                                  className="btn btn-sm btn-next btn-success me-2"
                                  onClick={certimport}
                                >
                                  Import Result
                                </a>
                                <a
                                  className="btn btn-sm btn-next btn-danger"
                                  onClick={cleardata}
                                >
                                  Reset
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Addresults;
