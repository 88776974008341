import axios from "axios";
import { Link } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";

export async function uservalidate(data, tid) {
  try {
    return await axios
      .get(
        `${baseUrl}/username-form-validations?username=${data}&meet_id=${tid}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function tourdetails(id) {
  try {
    return await axios
      .get(
        `${baseUrl}/meet-details/${id}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function userathleteview(data) {
  try {
    return await axios
      .get(`${baseUrl}/athletedetails/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function usertourview(tid, id) {
  try {
    return await axios
      .get(`${baseUrl}/ind-meet-details-outside-form/${tid}/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function usertouradd(data) {
  try {
    return await axios
      .post(`${baseUrl}/stateindividualentry-outsideform`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function usercreateorderid(data) {
  try {
    return await axios
      .post(`${baseUrl}/state-ind-createorderid-outside-form`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
export async function usertourdelete(data) {
    try {
      return await axios
        .post(`${baseUrl}/remove-ind-entry-outside-form`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

export async function userpaymentsuccess(data) {
    try {
      return await axios
        .post(`${baseUrl}/state-indmeet-paysuccess-outside-form`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  
function catcherror(error) {
  // console.log(error.response);
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
