import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { athleteview } from "../../../services/athlete";
import {
  addnationalindividual,
  addstateindividual,
  addstateindividualpayment,
  meetdetails,
  meetindividualdelete,
  meettourdetails,
  natindccavenuestatus,
  natmeetindividualdelete,
  stateindividualccavenuepayment,
  stateindpaymentupdate,
  stateunltlist,
} from "../../../services/meetservice";
import { distlist } from "../../../services/registerservice";
import Swal from "sweetalert2";
import imageUrl from "../../../ImageUrl";
import PayUrl from "../../../Apiccavenue";
import dataString from "../../../assets/language/ccavenue.json";
import fileString from "../../../assets/language/filepath.json";

const Meetviewathlete = () => {
  const [catname, setCategoryName] = useState("");
  const [mtype, setMType] = useState("");
  const [multi, setMulti] = useState("N");

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");

  const [event1, setEvent1] = useState("");
  const [event1per, setEvent1Per] = useState("");
  const [event1proof, setEvent1Proof] = useState("");

  const [event2, setEvent2] = useState("");
  const [event2per, setEvent2Per] = useState("");
  const [event2proof, setEvent2Proof] = useState("");

  const [district, setDistrict] = useState("");
  const [dlist, setDistlist] = useState([]);

  const [payment, setPayment] = useState("");
  const [paytype, setPaymentType] = useState("Razor Pay");
  const [free, setFree] = useState("N");
  const [state, setStateid] = useState("");

  const [uid, setUniqueid] = useState("");
  const [utname, setUtName] = useState("");
  const [utfeed, setUtFeed] = useState("");

  const [totamt, setTotalAmt] = useState("");

  const [coachname, setCoach] = useState("");
  const [coachph, setCoachPh] = useState("");

  const [dob, setDOB] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    athleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setStateid(response.data.details.permenant_state);

        setDOB(response.data.details.date_of_birth);
        meettourdetails(id, localStorage.getItem("Userid")).then((res) => {
          if (res.status === true) {
            if (res.data.tournament.type === "National Meet") {
              if (res.data.national_ind_entry === null) {
                navigate("/athlete/meetadd/" + id);
              }
            }
            if (res.data.tournament.type === "State Entry") {
              if (res.data.state_ind_entry.length === 0) {
                navigate("/athlete/meetadd/" + id);
              }
            }
            setTourname(res.data.tournament.meet_name);
            setTourloc(res.data.tournament.location);
            setTourdate(
              moment(res.data.tournament.start_date).format("DD/MM/YYYY") +
                " - " +
                moment(res.data.tournament.end_date).format("DD/MM/YYYY")
            );
            setMType(res.data.tournament.type);
            setMulti(res.data.tournament.multiple_events_allowed_flag);
            setPaymentType(res.data.tournament.payment_gateway);
            setFree(res.data.tournament.free_tournament);
            setCategoryName(res.data.tournament_age_category);
            if (res.data.tournament.type === "National Meet") {
              var evt = res.data.national_ind_entry.eventslist;
              var ch = evt.split(",");
              setEvent1(ch[0]);
              setEvent2(ch[1]);
              var st = res.data.national_ind_entry.order_status;

              if (st === "SUCCESS") {
                navigate("/athlete/confirmation/" + id);
              }
              setPayment(st.toLowerCase());
              setUniqueid(res.data.national_ind_entry.unique_no);
              setEvent1Per(res.data.national_ind_entry.performance);
              setEvent2Per(res.data.national_ind_entry.performance_event_1);
              setEvent1Proof(res.data.national_ind_entry.performance_proof);
              setEvent2Proof(
                res.data.national_ind_entry.performance_proof_event_1
              );
              setTotalAmt(res.data.national_ind_entry.amount);
              if (res.data.national_ind_entry.unit_id !== 0) {
                stateunltlist().then((res1) => {
                  if (res1.status === true) {
                    const sdata = res1.data.find(
                      (item) => item.id === res.data.national_ind_entry.unit_id
                    );
                    if (sdata !== undefined) {
                      setUtName(sdata.username);
                    } else {
                      setUtName("");
                    }
                  }
                });
                setUtFeed(res.data.national_ind_entry.unit_id_notes);
              }
            } else {
              setTotalAmt("");
              setUtName("");
              setUtFeed("");
              var st = "";
              if (res.data.state_ind_entry_payment === null) {
                st = "";
              }
              if (res.data.state_ind_entry_payment !== null) {
                st = res.data.state_ind_entry_payment.order_status;
                setPayment(st.toLowerCase());
              }
              if (st === "SUCCESS") {
                navigate("/athlete/confirmation/" + id);
              }

              if (res.data.state_ind_entry.length !== 0) {
                var evt = "";

                for (let k = 0; k < res.data.state_ind_entry.length; k++) {
                  if (
                    res.data.state_ind_entry[k].tournament_meet_id ===
                      parseInt(id) &&
                    res.data.state_ind_entry[k].user_id + "" ===
                      localStorage.getItem("Userid")
                  ) {
                    if (evt === "") {
                      evt = res.data.state_ind_entry[k].eventslist;
                    } else {
                      evt = evt + "," + res.data.state_ind_entry[k].eventslist;
                    }
                    setCoach(res.data.state_ind_entry[k].coach_name);
                    setCoachPh(res.data.state_ind_entry[k].coach_phone_no);

                    setDistrict(res.data.state_ind_entry[k].club_dist_id);
                  }
                }

                var ch = evt.split(",");
                setEvent1(ch[0]);
                setEvent2(ch[1]);
              }
            }
          }
        });
        distlist(response.data.details.permenant_state).then((datas) => {
          if (datas.status === true) {
            setDistlist(datas.data);
          }
        });
      }
    });
  }, []);
  function paymeet() {
    if (mtype === "National Meet") {
      window.open(PayUrl + dataString.natindivial + uid, "_self");
      // navigate(PayUrl + dataString.natindivial + uid);

      // let interval = setInterval(() => {
      //   const temp = new FormData();
      //   temp.append("current_meet_id", id);
      //   natindccavenuestatus(temp).then((res) => {
      //     if (res.status === true) {
      //       if (res.data.Payment_Status === "Paid") {
      //         setLoading(false);
      //         navigate("/athlete/meetview/" + id);
      //         toast.success(res.data.Payment_Status);
      //       } else if (res.data.Payment_Status === "Failed") {
      //         setLoading(false);
      //         navigate("/athlete/meetview/" + id);
      //         toast.error(res.data.Payment_Status);
      //       }
      //     }
      //   });
      // }, 5000);
    } else {
      if (paytype === "Razor Pay") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", localStorage.getItem("Userid"));
        temp.append("state_id", state);
        addstateindividualpayment(temp).then((res) => {
          if (res.status === true) {
            paynow(
              res.data.order_id,
              res.data.statedetails.razorpaykey,
              res.data.inserted_unique_id,
              res.data.user_entry.total_amount
            );
          }
        });
      } else {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", localStorage.getItem("Userid"));
        temp.append("state_id", state);
        stateindividualccavenuepayment(temp).then((res) => {
          if (res.status === true) {
            window.open(
              PayUrl + dataString.stateindivial + res.data.user_entry.unique_no,
              "_self"
            );
          }
        });
      }
    }
  }

  async function paynow(ordid, rkey, payid, tamt) {
    const options = {
      key: rkey,
      order_id: ordid,
      name: "AFI",
      description: "Meet Registration",
      image: require("../../../assets/images/logo/logo-small.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("razorpay_payment_id", response.razorpay_payment_id);
        temp.append("totalAmount", tamt);
        temp.append("current_meet_id", id);
        temp.append("order_id", ordid);
        temp.append("current_ins_id", payid);

        stateindpaymentupdate(temp).then((res) => {
          if (res.status === true) {
            navigate("/athlete/confirmation/" + id);
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function meetdelete() {
    if (mtype === "National Meet") {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);

      natmeetindividualdelete(tempData).then((response) => {
        if (response.status === true) {
          navigate("/athlete/meetadd/" + id);
        }
      });
    } else {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);

      meetindividualdelete(tempData).then((response) => {
        if (response.status === true) {
          navigate("/athlete/meetadd/" + id);
        }
      });
    }
  }

  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">View Meet Entry</h2>
        </div>
      </div>

      <Card className=" custom-card overflow-hidden">
        <Card.Header>
          <Row className="row-sm">
            <Col
              sm={12}
              lg={9}
              xl={9}
              md={9}
              className="align-self-center border-end"
            >
              <h4 className="mb-0">{tourname}</h4>
            </Col>
            <Col sm={12} lg={3} xl={3} md={9}>
              <div className="eventdet ps-2">
                <h6>
                  <i className="fa fa-map-marker me-2 text-danger"></i>
                  {tourloc}
                </h6>
                <h6>
                  <i className="fa fa-calendar me-2 text-danger"></i>
                  {tourdate}
                </h6>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="addmeet">
          {payment === "failed" && (
            <div>
              <div className="text-center">
                <h5 className="text-danger">
                  Payment Failed. Please try again.
                </h5>
              </div>
              <hr />
            </div>
          )}
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                {mtype === "National Meet" && (
                  <div>
                    <div className="profile-update individualentry">
                      <div className="row mx-0">
                        <div className="col-12 text-center pb-3 mb-3 border-bottom">
                          <h6>
                            Category <span className="text-danger">*</span>
                          </h6>

                          <button className="tabbtn active my-2 me-2">
                            {" "}
                            <h5 className="mb-0 tx-14">{catname}</h5>
                          </button>
                        </div>

                        <div className="col-md-6 mb-3">
                          <Card className="custom-card border mb-0">
                            <Card.Header className="bg-light py-3 mb-4">
                              <h6 className="tx-22 text-dark mb-0">
                                Event One{" "}
                              </h6>
                            </Card.Header>
                            <Card.Body>
                              <div className="row mx-0 row-sm">
                                <div className="col-md-12">
                                  <div className="profile-update">
                                    <div className="form-group">
                                      <h6> Event 1 </h6>
                                      <span>{event1}</span>
                                    </div>
                                    <div className="form-group">
                                      <h6> Event 1 Performance </h6>
                                      <span>{event1per}</span>
                                    </div>
                                    {event1proof !== null && (
                                      <div className="form-group">
                                        <h6>
                                          {" "}
                                          Event 1 Performance Proof{" "}
                                          <span className="desc text-danger tx-14">
                                            {" "}
                                            (Accepted Files are .jpg,.jpeg,.pdf)
                                          </span>
                                        </h6>

                                        <div className="imagecontainer mx-auto mt-3">
                                          {event1proof.substr(
                                            event1proof.length - 4
                                          ) !== ".pdf" && (
                                            <img
                                              src={
                                                imageUrl +
                                                fileString.tournamentproof +
                                                event1proof
                                              }
                                              width="100%"
                                              height="220"
                                              onError={(e) => errorhandle(e)}
                                            />
                                          )}

                                          {event1proof.substr(
                                            event1proof.length - 4
                                          ) === ".pdf" && (
                                            <a
                                              href={
                                                imageUrl +
                                                fileString.tournamentproof +
                                                event1proof
                                              }
                                              download
                                            >
                                              <img
                                                src={require("../../../assets/images/pdf.png")}
                                                width="100%"
                                                height="220"
                                                onError={(e) => errorhandle(e)}
                                              />
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                             
                            </Card.Body>
                          </Card>
                        </div>

                        {multi === "Y" && (
                          <div className="col-md-6 mb-3">
                            <Card className="custom-card border mb-0">
                              <Card.Header className="bg-light py-3 mb-4">
                                <h6 className="tx-22 text-dark mb-0">
                                  Event Two{" "}
                                </h6>
                              </Card.Header>
                              <Card.Body>
                                <div className="row mx-0 row-sm">
                                  <div className="col-md-12">
                                    <div className="profile-update">
                                      <div className="form-group">
                                        <h6> Event 2 </h6>
                                        <span>{event2}</span>
                                      </div>
                                      <div className="form-group">
                                        <h6> Event 2 Performance </h6>
                                        <span>{event2per}</span>
                                      </div>
                                      {event2proof !== null && (
                                        <div className="form-group">
                                          <h6>
                                            {" "}
                                            Event 2 Performance Proof{" "}
                                            <span className="desc text-danger tx-14">
                                              {" "}
                                              (Accepted Files are
                                              .jpg,.jpeg,.pdf)
                                            </span>
                                          </h6>

                                          <div className="imagecontainer mx-auto mt-3">
                                            {event2proof.substr(
                                              event2proof.length - 4
                                            ) !== ".pdf" && (
                                              <img
                                                src={
                                                  imageUrl +
                                                  fileString.tournamentproof +
                                                  event2proof
                                                }
                                                width="100%"
                                                height="220"
                                                onError={(e) => errorhandle(e)}
                                              />
                                            )}

                                            {event2proof.substr(
                                              event2proof.length - 4
                                            ) === ".pdf" && (
                                              <a
                                                href={
                                                  imageUrl +
                                                  fileString.tournamentproof +
                                                  event2proof
                                                }
                                                download
                                              >
                                                <img
                                                  src={require("../../../assets/images/pdf.png")}
                                                  width="100%"
                                                  height="220"
                                                  onError={(e) =>
                                                    errorhandle(e)
                                                  }
                                                />
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        )}

{utname !== "" && (
                                <div className="row mx-0 row-sm">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <h6> Unit Name </h6>
                                      <span>{utname}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <h6> Notes </h6>
                                      <span>{utfeed}</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                      </div>
                    </div>
                  </div>
                )}
                {mtype !== "National Meet" && (
                  <div className="row mx-0">
                    <div className="col-12 text-center pb-3 mb-3 border-bottom">
                      <h6>
                        Category <span className="text-danger">*</span>
                      </h6>
                      <button className="tabbtn active my-2 me-2">
                        {" "}
                        <h5 className="mb-0 tx-14">{catname}</h5>
                      </button>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">District</b>
                          </div>
                          <div className="col-md-8">
                            <select
                              value={district}
                              onChange={(e) => {
                                setDistrict(e.target.value);
                              }}
                              disabled
                            >
                              <option value="" disabled>
                                Select Your District
                              </option>
                              {dlist.map((data, i) => {
                                return (
                                  <option value={data.districtuid} key={i}>
                                    {data.districtname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Event 1</b>
                          </div>
                          <div className="col-md-8">
                            <span>{event1}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {multi === "Y" && (
                      <div className="col-md-12">
                        <div className="profile-update">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Event 2</b>
                            </div>
                            <div className="col-md-8">
                              <span>{event2}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

<div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Coach Name</b>
                          </div>
                          <div className="col-md-8">
                            <span>{coachname}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Coach Phone Number</b>
                          </div>
                          <div className="col-md-8">
                            <span>{coachph}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                )}
                <br />
                {mtype === "National Meet" && (
                  <h6>
                    Total Amount <a className="text-danger"> {totamt} INR</a>
                  </h6>
                )}
              </div>
            </div>
            <hr />
          </div>

          <div className="mobile-text-right">
            {free === "N" && payment !== "success" && (
              <a className="btn btn-success mr-2 me-2" onClick={paymeet}>
                Pay Now
              </a>
            )}

            {payment !== "success" && (
              <a className="btn btn-danger mr-2 me-2" onClick={meetdelete}>
                Delete
              </a>
            )}

            <Link
              className="btn btn-secondary mr-2 mt-2"
              to={"/athlete/dashboard"}
            >
              Back
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Meetviewathlete;
