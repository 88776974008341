import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./state.css";
import moment from "moment";
import {
  statefeddeptravelentrylist,
  statefedpaymentstatus,
  statefedremovearrival,
  statefedremovedeparture,
  statefedtravelentrylist,
  statefedviewarrival,
} from "../../../services/state";
import { AgGridReact } from "ag-grid-react";
import StateHeading from "./stateheading";

const TravelEntryList = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [depfilterProduct, setDepFilterProduct] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedpaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });
    arrilist();
    deptlist();
  }, []);

  function arrilist() {
    setFilterProduct([]);
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    statefedtravelentrylist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.travel_details);
      }
    });
  }

  function deptlist() {
    setDepFilterProduct([]);
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    statefeddeptravelentrylist(tempData).then((response) => {
      if (response.status === true) {
        setDepFilterProduct(response.data.travel_departure_details);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "Arrival Date",
      width: 200,
      field: "dateof_arr",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Arrival Time",
      width: 170,
      field: "dateof_arr_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Arrival Details",
      width: 200,
      field: "arr_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 300,
      field: "remark",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 120,
      field: "id",
      resizable: true,
      filter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div>
            <Link
              className="btn-sm btn-info me-3"
              to={"/state/viewarrival/" + id + "/" + params.value}
            >
              <i className="fa fa-edit fa-lg" aria-hidden="true"></i>
            </Link>
            <a
              className="btn-sm btn-danger"
              onClick={() => deletearrival(params.value)}
            >
              <i className="fa fa-trash fa-lg" aria-hidden="true"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function deletearrival(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("travel_details_id", did);
    statefedremovearrival(tempData).then((response) => {
      if (response.status === true) {
        arrilist();
      }
    });
  }

  const columnDep = [
    {
      headerName: "Departure Date",
      width: 200,
      field: "dateof_dep",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Departure Time",
      width: 170,
      field: "dateof_dep_time",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Departure Details",
      width: 200,
      field: "dep_other_details",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Remarks",
      width: 300,
      field: "dep_remarks",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 120,
      field: "id",
      filter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div>
            <Link
              className="btn-sm btn-info me-3"
              to={"/state/viewdeparture/" + id + "/" + params.value}
            >
              <i className="fa fa-edit fa-lg" aria-hidden="true"></i>
            </Link>
            <a
              className="btn-sm btn-danger"
              onClick={() => deletedeparture(params.value)}
            >
              <i className="fa fa-trash fa-lg" aria-hidden="true"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function deletedeparture(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("travel_details_id", did);

    statefedremovedeparture(tempData).then((response) => {
      if (response.status === true) {
        deptlist();
      }
    });
  }

  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <Row className="row-sm mx-0 mt-3">
          <Col xl={12} lg={12}>
            <Card className=" custom-card" id="basic">
              <Card.Body>
                <div className="page-header mt-2 mb-0">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                      Arrival Travel List
                    </h2>
                  </div>
                  <div className="d-flex pe-4">
                    <div className="justify-content-center">
                      <Link
                        to={"/state/officialentrylist/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Official Entry
                      </Link>
                      <Link
                        to={"/state/addarrival/" + id}
                        variant="success"
                        type="button"
                        className="btn my-1 btn-success me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Add Arrival Details
                      </Link>

                      <Link
                        to={"/state/selectioncommittee/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Selection Committee Mins
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 300,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>

              <Card.Body>
                <div className="page-header mt-2 mb-0">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                      Departure Travel List
                    </h2>
                  </div>
                  <div className="d-flex pe-4">
                    <div className="justify-content-center">
                      <Link
                        to={"/state/adddeparture/" + id}
                        variant="success"
                        type="button"
                        className="btn my-1 btn-success tx-teko tx-uppercase"
                      >
                        {" "}
                        Add Departure Details
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{
                      height: 300,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDep}
                      rowData={depfilterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default TravelEntryList;
