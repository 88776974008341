export const MENUITEMS = [
  {
    Items: [
      {
        path: "/club/dashboard",
        icon: "fa fa-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
    ],
  },
];
