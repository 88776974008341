import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { meetlist } from "../../../services/meetservice";

const Listmeet = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  var arr = [];
  useEffect(() => {
    meetlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.tournament.length; i++) {
            arr.push({
              meet_name: response.data.tournament[i].meet_name,
              categories: response.data.tournament[i].categories,
              location: response.data.tournament[i].location,
              start_date: response.data.tournament[i].start_date,
              end_date: response.data.tournament[i].end_date,
              type: response.data.tournament[i].type,
              entry_type: response.data.tournament[i].entry_type,
              circular_file_1:
                response.data.s3path +
                response.data.tournament[i].circular_file_1,
              id: response.data.tournament[i].id,
            });
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Meet Name",
      width: 180,
      field: "meet_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 220,
      field: "categories",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Place",
      width: 130,
      field: "location",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 140,
      field: "start_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      width: 140,
      field: "end_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Type",
      width: 130,
      field: "type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Entry Type",
      width: 130,
      field: "entry_type",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Circular",
      width: 100,
      field: "circular_file_1",
      filter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <div className="action-btn">
            <a download href={params.value} target={"_blank"}>
              <span className="col-reptwo">
                <i className="fa fa-file-pdf fa-lg" aria-hidden="true"></i>
              </span>
            </a>
          </div>
        );
      },
    },

    {
      headerName: "Actions",
      width: 90,
      field: "id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link to={"/superadmin/viewmeet/" + params.value} className="btn-sm btn-info">
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];

 
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">List Meet</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Listmeet;
