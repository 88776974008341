import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { meetdetails } from "../../../services/meetservice";
import {
  statefedadditionaloveralllist,
  statefedadditionalpayment,
  statefedadditionalpaymentstatus,
  statefedadditionalsubmitpayment,
  statefedsummarylist,
} from "../../../services/state";
import "./state.css";
import dataString from "../../../assets/language/ccavenue.json";
import PayUrl from "../../../Apiccavenue";

const AdditionalSendEntries = () => {
  const { id } = useParams();
  const [agelist, setAgeList] = useState([]);
  const [alist, setAllList] = useState("");

  const [totevent, setTotalEvent] = useState("");
  const [totamt, setTotalAmt] = useState("");
  const [totrelay, setTotalRelay] = useState("");
  const [relayfee, setRelayFee] = useState("");

  const [acount, setAthCount] = useState(0);
  const navigate = useNavigate();

  const [free, setFree] = useState("");
  const [paytype, setPayType] = useState("");
  const [paystatus, setPayStatus] = useState("");
  const [gateway, setGateway] = useState("");
  const [tname, setTourName] = useState("");

  useEffect(() => {
    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTourName(response.data.tournament.meet_name);
        setFree(response.data.tournament.free_tournament);
        setPayType(response.data.tournament.payment_gateway);
        if (response.data.tournament.gateway_charges === "Y") {
          setGateway("(Including Gateway Charges)");
        } else {
          setGateway("");
        }
      }
    });

    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    statefedadditionalpaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        setPayStatus(response.data.Payment_Status);
        if (response.data.Payment_Status === "Paid") {
          navigate("/state/confirmation/" + id);
        }
      }
    });

    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    statefedadditionaloveralllist(tempData).then((response) => {
      if (response.status === true) {
        setTotalAmt(response.data.over_all_amount);
        setTotalEvent(response.data.totaleventscount);
        setTotalRelay(response.data.total_relay_team);
        setRelayFee(response.data.relayTeamCount_fees);

        setAllList(response.data);
        setTotalEvent(response.data.totaleventscount);
        setAgeList(response.data.totalathletescountbasedonagecategory);
        let tamt = response.data.total_relay_team;
        if (response.data.totalathletescountbasedonagecategory.length !== 0) {
          for (
            let i = 0;
            i < response.data.totalathletescountbasedonagecategory.length;
            i++
          ) {
            if (
              response.data.totalathletescountbasedonagecategory[i].totalevent
            ) {
              tamt =
                tamt +
                response.data.totalathletescountbasedonagecategory[i]
                  .totalevent;
            }
          }
        }
        setAthCount(tamt);
      }
    });

    // statefedsummarylist(tempData).then((response) => {
    //   if (response.status === true) {
    //     setAthCount(response.data.athletes_list.length);
    //     setOffCount(response.data.officials_list.length);
    //     setArrCount(response.data.travel_arrival_details.length);
    //     setDepCount(response.data.travel_departure_details.length);
    //     setMosCount(response.data.Minutesofselection);
    //     setPerCount(response.data.performanceproof);
    //   }
    // });
  }, []);

  function submitsummary() {
    if (totamt === 0) {
      statefedadditionalsubmitpayment(id, totevent).then((response) => {
        if (response.status === true) {
          navigate("/state/confirmation/" + id);
        }
      });
    }
  }
  function submitpay() {
    if (acount !== 0) {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("totalevent", totevent);
      temp.append("totalamount", totamt);

      statefedadditionalpayment(temp).then((response) => {
        if (response.status === true) {
          window.open(
            PayUrl +
              dataString.natfederationadditional +
              response.data.info.unique_no,
            "_self"
          );
        }
      });
    }
  }
  return (
    <Fragment>
      <div id="statedash">
        <div className="page-header d-block">
          <div>
            <Card className="card card-aside custom-card">
              <Card.Body>
                <div className="d-flex align-items-center pt-0 mt-auto">
                  <div className="me-3">
                    <a>
                      <h5 className="main-content-label tx-24 mb-0 tx-medium">
                        {tname}
                      </h5>
                    </a>
                  </div>

                  <div className="ms-auto">
                    <Link
                      to={"/state/dashboard"}
                      className="icon d-none d-md-inline-block ms-3 logout text-primary"
                    >
                      <i className="fe fe-home me-1 tx-20"></i> Back to home
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2 border-bottom">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Sent Entries
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/state/additionalathleteentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Athlete Entry
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        <Row className="row-sm mx-0 bg-white sendentry">
          <Col lg={8} md={8} xl={8} sm={12} className="mx-auto">
            <Card className="custom-card">
              {agelist.map((data, i) => {
                return (
                  <Card.Body key={i}>
                    <div className="border">
                      <div className="main-list-item p-3 bg-light border-bottom border-danger">
                        <div>
                          <h6 className="mb-0">{data.age_categories}</h6>
                        </div>
                        <div>
                          <h6>Count</h6>
                        </div>
                      </div>

                      <div className="main-list-item p-3 border-top">
                        <div>
                          <h6 className="mb-0">Total Events</h6>
                        </div>
                        <div>
                          <h6>{data.totalevent}</h6>
                        </div>
                      </div>
                      <div className="main-list-item p-3 border-top bg-dark">
                        <div>
                          <h6 className="mb-0 text-white">Total Amount</h6>
                        </div>
                        <div>
                          <h6 className="mb-0 text-white">{data.fees} INR</h6>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                );
              })}

              {totrelay !== 0 && (
                <Card.Body>
                  <div className="border">
                    <div className="main-list-item p-3 bg-light border-bottom border-danger">
                      <div>
                        <h6 className="mb-0">Relay</h6>
                      </div>
                      <div>
                        <h6>Count</h6>
                      </div>
                    </div>

                    <div className="main-list-item p-3 border-top">
                      <div>
                        <h6 className="mb-0">Total Events</h6>
                      </div>
                      <div>
                        <h6>{totrelay}</h6>
                      </div>
                    </div>
                    <div className="main-list-item p-3 border-top bg-dark">
                      <div>
                        <h6 className="mb-0 text-white">Total Amount</h6>
                      </div>
                      <div>
                        <h6 className="mb-0 text-white">{relayfee} INR</h6>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              )}

              <div className="main-list-item p-3 border-top bg-dark">
                <div>
                  <h6 className="mb-0 text-white">Overall Amount {gateway}</h6>
                </div>
                <div>
                  <h6 className="mb-0 text-white">{totamt} INR</h6>
                </div>
              </div>

              <hr className="mb-4" />
              {free === "N" && paystatus !== "paid" && (
                <div className="text-center">
                  {totamt !== 0 && (
                    <a
                      variant="success"
                      type="button"
                      className="btn my-1 btn-success tx-teko tx-uppercase"
                      onClick={submitpay}
                    >
                      {" "}
                      Submit & Pay
                    </a>
                  )}
                  {totamt === 0 && acount !== 0 && (
                    <a
                      variant="success"
                      type="button"
                      className="btn my-1 btn-success tx-teko tx-uppercase"
                      onClick={submitsummary}
                    >
                      {" "}
                      Submit
                    </a>
                  )}
                </div>
              )}

              <Card.Footer className="send-footer">
                {acount === 0 && <p>* ADD ATHLETES BEFORE SUBMIT ENTRIES</p>}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AdditionalSendEntries;
