import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
//import SideBar from "./dashboard/sidebar/sidebar";
import Header from "./dashboard/header";
import Footer from "./dashboard/footer";
import Switcher from "./dashboard/switcher/switcher";
import PrivateRoute from "./dashboard/privateroute";
import "../index.scss";
import AthleteSideBar from "./dashboard/athlete/sidebar/athletesidebar";
import StateSideBar from "./dashboard/state/sidebar/statesidebar";
import Officialsidebar from "./dashboard/official/sidebar/sidebar";
import Districtsidebar from "./dashboard/district/sidebar/sidebar";
import Clubsidebar from "./dashboard/club/sidebar/sidebar";
import SideBar from "./superadmin/sidebar/sidebar";
import TwoSideBar from "./superadmin/subroletwo/sidebar";
import ThreeSideBar from "./superadmin/subrolethree/sidebar";
import { Backtotop1 } from "./dashboard/athlete/support";
const App = () => {
  document.querySelector("body").classList.remove("error-1");
  document
    .querySelector("body")
    .classList.remove("app", "sidebar-mini", "landing-page", "horizontalmenu");
  document.querySelector("body").classList.add("main-body", "leftmenu");
  const remove = () => {
    // document.querySelector(".sidebar-right").classList.remove("sidebar-open");
    document.querySelector("body").classList.remove("main-sidebar-show");
    document.querySelector(".demo_changer").classList.remove("active");
    document.querySelector(".demo_changer").style.right = "-270px";
  };

  return (
    <Fragment>
      <div className="horizontalMenucontainer">
        <Switcher />
        <div className="page">
          <Header />
          {localStorage.getItem("SubRoleid") === "1" && <SideBar />}
          {localStorage.getItem("SubRoleid") === "2" && <TwoSideBar />}
          {localStorage.getItem("SubRoleid") === "3" && <ThreeSideBar />}

          {localStorage.getItem("Roleid") === "2" && <AthleteSideBar />}
          {localStorage.getItem("Roleid") === "3" && <Officialsidebar />}
          {localStorage.getItem("Roleid") === "4" && <StateSideBar />}
          {localStorage.getItem("Roleid") === "5" && <Districtsidebar />}
          {localStorage.getItem("Roleid") === "6" && <Clubsidebar />}
          <div className="main-content side-content">
            <div
              className="main-container container-fluid"
              onClick={() => remove()}
            >
              <div className="inner-body">
                <PrivateRoute />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        {localStorage.getItem("Roleid") === "2" && <Backtotop1 />}
        <Footer />
      </div>
    </Fragment>
  );
};
export default App;
