import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import fileString from "../../assets/language/filepath.json";
import imageUrl from "../../ImageUrl";
import {
  userathleteview,
  usercreateorderid,
  userpaymentsuccess,
  usertourdelete,
  usertourview,
} from "../../services/tourreg";
import { distlist } from "../../services/registerservice";

const TourView = () => {
  const [catname, setCategoryName] = useState("");
  const [mtype, setMType] = useState("");
  const [multi, setMulti] = useState("N");

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");

  const [event1, setEvent1] = useState("");
  const [event1per, setEvent1Per] = useState("");
  const [event1proof, setEvent1Proof] = useState("");

  const [event2, setEvent2] = useState("");
  const [event2per, setEvent2Per] = useState("");
  const [event2proof, setEvent2Proof] = useState("");

  const [district, setDistrict] = useState("");
  const [dlist, setDistlist] = useState([]);

  const [payment, setPayment] = useState("");
  const [paytype, setPaymentType] = useState("Razor Pay");
  const [free, setFree] = useState("N");
  const [state, setStateid] = useState("");

  const [uid, setUniqueid] = useState("");

  const [totamt, setTotalAmt] = useState("");

  const [dob, setDOB] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("Userid") === null) {
      navigate("/tournamentregister/" + id);
    }
    userathleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setStateid(response.data.details.permenant_state);

        setDOB(response.data.details.date_of_birth);
        usertourview(id, localStorage.getItem("Userid")).then((res) => {
          if (res.status === true) {
            if (res.data.tournament.type === "National Meet") {
              if (res.data.national_ind_entry === null) {
                navigate("/meetadd/" + id);
              }
            }
            if (res.data.tournament.type === "State Entry") {
              if (res.data.state_ind_entry.length === 0) {
                navigate("/meetadd/" + id);
              }
            }
            setTourname(res.data.tournament.meet_name);
            setTourloc(res.data.tournament.location);
            setTourdate(
              moment(res.data.tournament.start_date).format("DD/MM/YYYY") +
                " - " +
                moment(res.data.tournament.end_date).format("DD/MM/YYYY")
            );
            setMType(res.data.tournament.type);
            setMulti(res.data.tournament.multiple_events_allowed_flag);
            setPaymentType(res.data.tournament.payment_gateway);
            setFree(res.data.tournament.free_tournament);
            setCategoryName(res.data.tournament_age_category);
            if (res.data.tournament.type === "National Meet") {
              var evt = res.data.national_ind_entry.eventslist;
              var ch = evt.split(",");
              setEvent1(ch[0]);
              setEvent2(ch[1]);
              var st = res.data.national_ind_entry.order_status;

              if (st === "SUCCESS") {
                navigate("/confirmation/" + id);
              }
              setPayment(st.toLowerCase());
              setUniqueid(res.data.national_ind_entry.unique_no);
              setEvent1Per(res.data.national_ind_entry.performance);
              setEvent2Per(res.data.national_ind_entry.performance_event_1);
              setEvent1Proof(res.data.national_ind_entry.performance_proof);
              setEvent2Proof(
                res.data.national_ind_entry.performance_proof_event_1
              );
              setTotalAmt(res.data.national_ind_entry.amount);
            } else {
              setTotalAmt("");

              var st = "";
              if (res.data.state_ind_entry_payment === null) {
                st = "";
              }
              if (res.data.state_ind_entry_payment !== null) {
                st = res.data.state_ind_entry_payment.order_status;
                setPayment(st.toLowerCase());
              }
              if (st === "SUCCESS") {
                navigate("/confirmation/" + id);
              }

              if (res.data.state_ind_entry.length !== 0) {
                var evt = "";

                for (let k = 0; k < res.data.state_ind_entry.length; k++) {
                  if (
                    res.data.state_ind_entry[k].tournament_meet_id ===
                      parseInt(id) &&
                    res.data.state_ind_entry[k].user_id + "" ===
                      localStorage.getItem("Userid")
                  ) {
                    if (evt === "") {
                      evt = res.data.state_ind_entry[k].eventslist;
                    } else {
                      evt = evt + "," + res.data.state_ind_entry[k].eventslist;
                    }

                    setDistrict(res.data.state_ind_entry[k].club_dist_id);
                  }
                }

                var ch = evt.split(",");
                setEvent1(ch[0]);
                setEvent2(ch[1]);
              }
            }
          }
        });
        distlist(response.data.details.permenant_state).then((datas) => {
          if (datas.status === true) {
            setDistlist(datas.data);
          }
        });
      }
    });
  }, []);
  function paymeet() {
    if (mtype === "National Meet") {
      //   window.open(PayUrl + dataString.natindivial + uid, "_self");
    } else {
      if (paytype === "Razor Pay") {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", localStorage.getItem("Userid"));
        temp.append("state_id", state);
        usercreateorderid(temp).then((res) => {
          if (res.status === true) {
            paynow(
              res.data.order_id,
              res.data.statedetails.razorpaykey,
              res.data.inserted_unique_id,
              res.data.user_entry.total_amount
            );
          }
        });
      } else {
        const temp = new FormData();
        temp.append("tournament_meet_id", id);
        temp.append("user_id", localStorage.getItem("Userid"));
        temp.append("state_id", state);
        // stateindividualccavenuepayment(temp).then((res) => {
        //   if (res.status === true) {
        //     window.open(
        //       PayUrl + dataString.stateindivial + res.data.user_entry.unique_no,
        //       "_self"
        //     );
        //   }
        // });
      }
    }
  }

  async function paynow(ordid, rkey, payid, tamt) {
    const options = {
      key: rkey,
      order_id: ordid,
      name: "AFI",
      description: "Meet Registration",
      image: require("../../assets/images/logo/logo-small.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("razorpay_payment_id", response.razorpay_payment_id);
        temp.append("totalAmount", tamt);
        temp.append("current_meet_id", id);
        temp.append("order_id", ordid);
        temp.append("current_ins_id", payid);
        temp.append("userUID", localStorage.getItem("Userid"));

        userpaymentsuccess(temp).then((res) => {
          if (res.status === true) {
            navigate("/confirmation/" + id);
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function errorhandle(ev) {
    ev.target.src = require("../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function meetdelete() {
    if (mtype === "National Meet") {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);

      //   natmeetindividualdelete(tempData).then((response) => {
      //     if (response.status === true) {
      //       navigate("/meetadd/" + id);
      //     }
      //   });
    } else {
      const tempData = new FormData();
      tempData.append("tournament_meet_id", id);
      tempData.append("user_id", localStorage.getItem("Userid"));

      usertourdelete(tempData).then((response) => {
        if (response.status === true) {
          navigate("/meetadd/" + id);
        }
      });
    }
  }

  return (
    <Fragment>
      <div id="registrationpage">
        <div className="registrationmain">
          <div className="registrationleft">
            <img
              src={require("../../assets/images/logo/logo.png")}
              height="60"
              alt="AFI"
              onClick={() => {
                navigate("/login");
              }}
            />
          </div>
          <div className="registrationright mt-2">
            <form action="#" className="form">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <h2 className="text-right">View Meet</h2>
                </div>
              </div>
              <Row className="row-sm mt-2">
                <Col
                  sm={12}
                  lg={9}
                  xl={9}
                  md={9}
                  className="align-self-center border-end"
                >
                  <h4 className="mb-0">{tourname}</h4>
                </Col>
                <Col sm={12} lg={3} xl={3} md={9}>
                  <div className="eventdet ps-2">
                    <h6>
                      <i className="fa fa-map-marker me-2 text-danger"></i>
                      {tourloc}
                    </h6>
                    <h6>
                      <i className="fa fa-calendar me-2 text-danger"></i>
                      {tourdate}
                    </h6>
                  </div>
                </Col>
              </Row>
              <hr />

              <div>
                {payment === "failed" && (
                  <div>
                    <div className="text-center">
                      <h5 className="text-danger">
                        Payment Failed. Please try again.
                      </h5>
                    </div>
                    <hr />
                  </div>
                )}
                <div className="panel panel-primary tabs-style-2">
                  <div className=" tab-menu-heading">
                    <div className="tabs-menu1">
                      {mtype === "National Meet" && (
                        <div>
                          <div className="profile-update individualentry">
                            <div className="row mx-0">
                              <div className="col-12 text-center pb-3 mb-3 border-bottom">
                                <h6>
                                  Category{" "}
                                  <span className="text-danger">*</span>
                                </h6>

                                <button className="tabbtn active my-2 me-2">
                                  {" "}
                                  <h5 className="mb-0 tx-14">{catname}</h5>
                                </button>
                              </div>

                              <div className="col-md-6 mb-3">
                                <Card className="custom-card border mb-0">
                                  <Card.Header className="bg-light py-3 mb-4">
                                    <h6 className="tx-22 text-dark mb-0">
                                      Event One{" "}
                                    </h6>
                                  </Card.Header>
                                  <Card.Body>
                                    <div className="row mx-0 row-sm">
                                      <div className="col-md-12">
                                        <div className="profile-update">
                                          <div className="form-group">
                                            <h6> Event 1 </h6>
                                            <span>{event1}</span>
                                          </div>
                                          <div className="form-group">
                                            <h6> Event 1 Performance </h6>
                                            <span>{event1per}</span>
                                          </div>
                                          {event1proof !== null && (
                                            <div className="form-group">
                                              <h6>
                                                {" "}
                                                Event 1 Performance Proof{" "}
                                                <span className="desc text-danger tx-14">
                                                  {" "}
                                                  (Accepted Files are
                                                  .jpg,.jpeg,.pdf)
                                                </span>
                                              </h6>

                                              <div className="imagecontainer mx-auto mt-3">
                                                {event1proof.substr(
                                                  event1proof.length - 4
                                                ) !== ".pdf" && (
                                                  <img
                                                    src={
                                                      imageUrl +
                                                      fileString.tournamentproof +
                                                      event1proof
                                                    }
                                                    width="100%"
                                                    height="220"
                                                    onError={(e) =>
                                                      errorhandle(e)
                                                    }
                                                  />
                                                )}

                                                {event1proof.substr(
                                                  event1proof.length - 4
                                                ) === ".pdf" && (
                                                  <a
                                                    href={
                                                      imageUrl +
                                                      fileString.tournamentproof +
                                                      event1proof
                                                    }
                                                    download
                                                  >
                                                    <img
                                                      src={require("../../assets/images/pdf.png")}
                                                      width="100%"
                                                      height="220"
                                                      onError={(e) =>
                                                        errorhandle(e)
                                                      }
                                                    />
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>

                              {multi === "Y" && (
                                <div className="col-md-6 mb-3">
                                  <Card className="custom-card border mb-0">
                                    <Card.Header className="bg-light py-3 mb-4">
                                      <h6 className="tx-22 text-dark mb-0">
                                        Event Two{" "}
                                      </h6>
                                    </Card.Header>
                                    <Card.Body>
                                      <div className="row mx-0 row-sm">
                                        <div className="col-md-12">
                                          <div className="profile-update">
                                            <div className="form-group">
                                              <h6> Event 2 </h6>
                                              <span>{event2}</span>
                                            </div>
                                            <div className="form-group">
                                              <h6> Event 2 Performance </h6>
                                              <span>{event2per}</span>
                                            </div>
                                            {event2proof !== null && (
                                              <div className="form-group">
                                                <h6>
                                                  {" "}
                                                  Event 2 Performance Proof{" "}
                                                  <span className="desc text-danger tx-14">
                                                    {" "}
                                                    (Accepted Files are
                                                    .jpg,.jpeg,.pdf)
                                                  </span>
                                                </h6>

                                                <div className="imagecontainer mx-auto mt-3">
                                                  {event2proof.substr(
                                                    event2proof.length - 4
                                                  ) !== ".pdf" && (
                                                    <img
                                                      src={
                                                        imageUrl +
                                                        fileString.tournamentproof +
                                                        event2proof
                                                      }
                                                      width="100%"
                                                      height="220"
                                                      onError={(e) =>
                                                        errorhandle(e)
                                                      }
                                                    />
                                                  )}

                                                  {event2proof.substr(
                                                    event2proof.length - 4
                                                  ) === ".pdf" && (
                                                    <a
                                                      href={
                                                        imageUrl +
                                                        fileString.tournamentproof +
                                                        event2proof
                                                      }
                                                      download
                                                    >
                                                      <img
                                                        src={require("../../assets/images/pdf.png")}
                                                        width="100%"
                                                        height="220"
                                                        onError={(e) =>
                                                          errorhandle(e)
                                                        }
                                                      />
                                                    </a>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {mtype !== "National Meet" && (
                        <div className="row mx-0">
                          <div className="col-12 text-center pb-3 mb-3 border-bottom">
                            <h6>
                              Category <span className="text-danger">*</span>
                            </h6>
                            <button className="tabbtn active my-2 me-2">
                              {" "}
                              <h5 className="mb-0 tx-14">{catname}</h5>
                            </button>
                          </div>

                          <div className="col-md-12">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">District</b>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    value={district}
                                    onChange={(e) => {
                                      setDistrict(e.target.value);
                                    }}
                                    disabled
                                  >
                                    <option value="" disabled>
                                      Select Your District
                                    </option>
                                    {dlist.map((data, i) => {
                                      return (
                                        <option
                                          value={data.districtuid}
                                          key={i}
                                        >
                                          {data.districtname}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="profile-update">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">Event 1</b>
                                </div>
                                <div className="col-md-8">
                                  <span>{event1}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {multi === "Y" && (
                            <div className="col-md-12">
                              <div className="profile-update">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Event 2</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span>{event2}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <br />
                      {mtype === "National Meet" && (
                        <h6>
                          Total Amount{" "}
                          <a className="text-danger"> {totamt} INR</a>
                        </h6>
                      )}
                    </div>
                  </div>
                  <hr />
                </div>

                <div className="mobile-text-right">
                  {free === "N" && payment !== "success" && (
                    <a className="btn btn-success mr-2 me-2" onClick={paymeet}>
                      Pay Now
                    </a>
                  )}

                  {payment !== "success" && (
                    <a
                      className="btn btn-danger mr-2 me-2"
                      onClick={meetdelete}
                    >
                      Delete
                    </a>
                  )}

                  <Link
                    className="btn btn-secondary mr-2 mt-2"
                    to={"/tournamentregister/" + id}
                  >
                    Back
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TourView;
