export const MENUITEMS = [
    {
        Items: [
            {
                path: '/district/dashboard',
                icon: 'fa fa-home',
                type: 'link',
                active: false,
                selected: false,
                title: 'Dashboard',
            },
            // {
            //     path: '/district/entrylist',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Meet'
            // },
            // {
            //     path: '/district/entrylist',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Entry List'
            // },
            // {
            //     path: '/district/viewsummary',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'View Summary'
            // },
            // {
            //     path: '/district/confirmation',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Confirmation'
            // },
            // {
            //     path: '/district/payment',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Payment'
            // },
            // {
            //     path: '/district/upcoming',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Upcoming'
            // },
            // {
            //     path: '/district/success',
            //     icon: 'fa fa-address-card',
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Success'
            // },
        ],
    },
];
