import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../../../ImageUrl";
import fileString from "../../../../assets/language/filepath.json";
import "./state.css";
import StateHeading from "./stateheading";
import {
  nidjamfiledelete,
  nidjammoslist,
  nidjammosupload,
  nidjampaymentstatus,
  nidjamperdelete,
  nidjamperlist,
  nidjamperupload,
  nidjamphotocreate,
  nidjamphotodelete,
  nidjamphotolist,
  nidjampresscreate,
  nidjampressdelete,
  nidjampresslist,
} from "../../../../services/nidjamdistrict";

const NidjamAddCommittee = () => {
  const { id } = useParams();

  const [fileerror, setFileErrors] = useState("");
  const [formfile, setFile] = useState("");

  function uploadfile(event) {
    setFileErrors("");
    setFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf|doc|docs|docs|xls)$/i)) {
        setFile(event.target.files[0]);
      } else {
        setFileErrors(
          "File should be pdf or doc or docs or docx or xls format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setFileErrors("File size should be less than 5MB");
      }
    }
  }

  function addfile() {
    if (formfile !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("mos", formfile);
      nidjammosupload(temp).then((response) => {
        if (response.status === true) {
          setFile("");
          setFileErrors("");
          fileList();
        }
      });
    } else {
      setFileErrors("Please Select Your Upload File");
    }
  }

  const [pererror, setPerErrors] = useState("");
  const [formper, setPer] = useState("");
  function uploadper(event) {
    setPerErrors("");
    setPer("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(pdf|doc|docs|docs|xls)$/i)) {
        setPer(event.target.files[0]);
      } else {
        setPerErrors(
          "Performance File should be Pdf or doc or docs or docx or xls format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPerErrors("Performance File size should be less than 5MB");
      }
    }
  }

  function addper() {
    if (formper !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("performanceproof", formper);
      nidjamperupload(temp).then((response) => {
        if (response.status === true) {
          setPer("");
          setPerErrors("");
          perlist();
        }
      });
    } else {
      setPerErrors("Please Select Your Performance File");
    }
  }
  const [presslist, setPressList] = useState([]);
  const [presserror, setPressErrors] = useState("");
  const [formpress, setPress] = useState("");
  const [precount, setPreCount] = useState([]);

  let arr = [];
  let arr1 = [];
  function uploadpress(event) {
    setPressErrors("");
    setPress("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        var size = event.target.files[0].size / 1024 / 1024;
        if (size > 5) {
          setPressErrors(
            event.target.files[0].name + " size should be less than 5MB"
          );
          setPressErrors([...arr1]);
        } else {
          setPress(event.target.files[0]);
        }
      } else {
        setPressErrors(
          event.target.files[0].name + " should be jpg or jpeg or png format"
        );
      }
    }
  }

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
    setPressErrors("");
    setPress("");
  }

  function handleClose() {
    setShow(false);
    setPressErrors("");
    setPress("");
  }

  function addpress() {
    setPressErrors("");
    if (formpress !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("press_cuttings_count", 1);
      // if (formpress.length !== 0) {
      //   for (let i = 0; i < formpress.length; i++) {
      temp.append("press_cuttings_1", formpress);
      //   }
      // }
      nidjampresscreate(temp).then((response) => {
        if (response.status === true) {
          handleClose();
          setPress("");
          setPressErrors("");
          // setPreErr("");
          preslist();
        }
      });
    } else {
      setPressErrors("Please Select Your Press Cuttings File");
    }
  }

  const [photolist, setPhotoList] = useState([]);
  const [photoerror, setPhotoErrors] = useState("");
  const [formphoto, setPhoto] = useState("");
  const [photocount, setPhotoCount] = useState([]);
  function uploadphoto(event) {
    setPhotoErrors("");
    setPhoto("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        var size = event.target.files[0].size / 1024 / 1024;
        if (size > 5) {
          setPhotoErrors(
            event.target.files[0].name + " size should be less than 5MB"
          );
          setPhotoErrors([...arr1]);
        } else {
          setPhoto(event.target.files[0]);
        }
      } else {
        setPhotoErrors(
          event.target.files[0].name + " should be jpg or jpeg or png format"
        );
      }
    }
  }

  const [pshow, setPShow] = useState(false);
  function handlePShow() {
    setPShow(true);
    setPhotoErrors("");
    setPhoto("");
  }

  function handlePClose() {
    setPShow(false);
    setPhotoErrors("");
    setPhoto("");
  }

  function addphoto() {
    setPhotoErrors("");
    if (formphoto !== "") {
      const temp = new FormData();
      temp.append("tournament_meet_id", id);
      temp.append("photos_count", 1);
      temp.append("photos_1", formphoto);
      nidjamphotocreate(temp).then((response) => {
        if (response.status === true) {
          handlePClose();
          setPhoto("");
          setPhotoErrors("");
          listphoto();
        }
      });
    } else {
      setPressErrors("Please Select Your Photo of Meet File");
    }
  }

  function listphoto() {
    setPhotoList([]);
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjamphotolist(temp).then((response) => {
      if (response.status === true) {
        setPhotoList(response.data.photos);
        var final = 5;
        let arr2 = [];
        if (response.data.photos.length !== 0) {
          final = 5 - response.data.photos.length;
        }
        for (let i = 0; i < final; i++) {
          arr2.push({
            id: i,
            load: "",
          });
        }
        setPhotoCount([...arr2]);
      }
    });
  }

  function photodelete(did) {
    nidjamphotodelete(did).then((response) => {
      if (response.status === true) {
        listphoto();
      }
    });
  }

  const [filename, setFileName] = useState("");
  const [filetime, setFileTime] = useState("");
  const [fileid, setFileId] = useState("");
  const [filestatus, setFileStatus] = useState("");

  const [pername, setPerName] = useState("");
  const [pertime, setPerTime] = useState("");
  const [perid, setPerId] = useState("");
  const [perstatus, setPerStatus] = useState("");

  function fileList() {
    setFileStatus("");
    setFileName("");
    setFileTime("");
    setFileId("");
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjammoslist(temp).then((res) => {
      if (res.status === true) {
        setFileStatus(res.data.mos);
        if (res.data.mos !== null) {
          setFileName(res.data.mos.file_name);
          setFileTime(moment(res.data.mos.created_at).format("hh:mm:ss a"));
          setFileId(res.data.mos.id);
        }
      }
    });
  }

  function perlist() {
    setPerStatus("");
    setPerName("");
    setPerTime("");
    setPerId("");
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjamperlist(temp).then((response) => {
      if (response.status === true) {
        setPerStatus(response.data.mos);
        if (response.data.mos !== null) {
          setPerName(response.data.mos.file_name);
          setPerTime(moment(response.data.mos.created_at).format("hh:mm:ss a"));
          setPerId(response.data.mos.id);
        }
      }
    });
  }

  function preslist() {
    setPressList([]);
    const temp = new FormData();
    temp.append("tournament_meet_id", id);
    nidjampresslist(temp).then((response) => {
      if (response.status === true) {
        setPressList(response.data.press_cuttings);
        var final = 5;
        let arr2 = [];
        if (response.data.press_cuttings.length !== 0) {
          final = 5 - response.data.press_cuttings.length;
        }
        for (let i = 0; i < final; i++) {
          arr2.push({
            id: i,
            load: "",
          });
        }
        setPreCount([...arr2]);
      }
    });
  }

  function pressdelete(did) {
    nidjampressdelete(did).then((response) => {
      if (response.status === true) {
        preslist();
      }
    });
  }

  function filedelete(did) {
    nidjamfiledelete(did).then((response) => {
      if (response.status === true) {
        fileList();
      }
    });
  }

  function perdelete(did) {
    nidjamperdelete(did).then((response) => {
      if (response.status === true) {
        perlist();
      }
    });
  }

  const navigate = useNavigate();
  useEffect(() => {
    const temp1 = new FormData();
    temp1.append("current_meet_id", id);
    nidjampaymentstatus(temp1).then((response) => {
      if (response.status === true) {
        if (response.data.Payment_Status === "Paid") {
          navigate("/district/meetconfirmation/" + id);
        }
      }
    });
    fileList();
    perlist();
    preslist();
    listphoto();
  }, []);
  return (
    <Fragment>
      <div id="statedash">
        <StateHeading />
        <hr className="mb-0" />

        {/* <!--Row--> */}
        <Row className="row-sm mx-0 bg-white py-2">
          <Col sm={12} md={12} lg={12} xl={12}>
            <div className="page-header mt-2 mb-0">
              <div>
                <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                  Selection Committee Minutes
                </h2>
              </div>
              <div className="d-flex pe-4">
                <div className="justify-content-center">
                  <Link
                    to={"/district/travelentrylist/" + id}
                    variant="secondary"
                    type="button"
                    className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                  >
                    {" "}
                    Travel Details
                  </Link>
                  <Link
                    to={"/district/accountdetails/" + id}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Account Details
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <!--End row--> */}

        {/* <!-- Row --> */}
        <Row className="row-sm mx-0 mt-0 border-top bg-white pb-3">
          {filestatus === null && (
            <Col lg={6} md={6} xl={6} sm={12}>
              <Card className="custom-card overflow-hidden eventslist mb-0">
                <Card.Header className="text-center">
                  <div>
                    <h3 className="card-title tx-18 lineheight1 mb-0">
                      <span className="main-content-label tx-20">
                        Upload File Details
                      </span>
                    </h3>
                  </div>
                </Card.Header>
                <Card.Body className="pb-0">
                  <form>
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            MOS File : <b className="text-danger">*</b>
                            <span>
                              Upload PDF,XLS,DOC,DOCS,DOCX files{" "}
                              <b className="text-danger">(Max 5MB)</b>
                            </span>
                          </label>
                          <input
                            type="file"
                            name="file"
                            id="photo"
                            onChange={uploadfile}
                            accept=".pdf,.xls,.doc,.docs,.docx"
                          />
                        </div>
                      </Col>
                      {fileerror !== "" && (
                        <span className="text-danger">{fileerror}</span>
                      )}
                      <hr className="w-100" />
                    </Row>
                  </form>

                  <a
                    onClick={addfile}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Upload
                  </a>
                </Card.Body>
              </Card>
            </Col>
          )}

          {perstatus === null && (
            <Col lg={6} md={6} xl={6} sm={12}>
              <Card className="custom-card overflow-hidden eventslist mb-0">
                <Card.Header className="text-center">
                  <div>
                    <h3 className="card-title tx-18 lineheight1 mb-0">
                      <span className="main-content-label tx-20">
                        Performance Proof
                      </span>
                    </h3>
                  </div>
                </Card.Header>
                <Card.Body className="pb-0">
                  <form>
                    {/* <!-- Row --> */}
                    <Row className="row-sm mx-0">
                      <Col lg={12} md={12}>
                        <div className="form-group">
                          <label className="mb-2">
                            Performance Proof : <b className="text-danger">*</b>
                            <span>
                              Upload PDF,XLS,DOC,DOCS,DOCX files{" "}
                              <b className="text-danger">(Max 5MB)</b>
                            </span>
                          </label>
                          <input
                            id="upload"
                            type="file"
                            accept=".pdf,.xls,.doc,.docs,.docx"
                            onChange={uploadper}
                          />
                        </div>
                      </Col>
                      {pererror !== "" && (
                        <span className="text-danger">{pererror}</span>
                      )}
                      <hr className="w-100" />
                    </Row>
                    {/* <!-- End Row --> */}
                  </form>

                  <a
                    onClick={addper}
                    variant="success"
                    type="button"
                    className="btn my-1 btn-success tx-teko tx-uppercase"
                  >
                    {" "}
                    Upload Performance
                  </a>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        {filestatus !== null && (
          <Row className="row-sm mx-0 bg-white">
            <Col lg={12} md={12} xl={12} sm={12}>
              <div className="custom-card px-4">
                <div
                  className="table-responsive border border-bottom-0"
                  responsive="true"
                >
                  <Table
                    hover
                    className="table text-nowrap border table-striped"
                  >
                    <thead className="table border-bottom">
                      <tr>
                        <th className="">MOS File</th>
                        <th className="text-center">Uploaded Time</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={imageUrl + fileString.statemos + filename}
                            download
                          >
                            {filename}
                          </a>
                        </td>
                        <td className="text-center">{filetime}</td>
                        <td className="text-center">
                          <a
                            onClick={() => filedelete(fileid)}
                            className="btn btn-danger btn-sm"
                          >
                            DELETE
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {perstatus !== null && (
          <Row className="row-sm mx-0 bg-white">
            <Col lg={12} md={12} xl={12} sm={12}>
              <div className="custom-card px-4">
                <div
                  className="table-responsive border border-bottom-0"
                  responsive="true"
                >
                  <Table
                    hover
                    className="table text-nowrap border table-striped"
                  >
                    <thead className="table border-bottom">
                      <tr>
                        <th className="">Performance Proof File</th>
                        <th className="text-center">Uploaded Time</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={
                              imageUrl + fileString.stateperformance + pername
                            }
                            download
                          >
                            {pername}
                          </a>
                        </td>
                        <td className="text-center">{pertime}</td>
                        <td className="text-center">
                          <a
                            onClick={() => perdelete(perid)}
                            className="btn btn-danger btn-sm"
                          >
                            DELETE
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <hr />
        <h3 className="card-title tx-18 lineheight1">
          <span className="main-content-label tx-20">Press Cuttings Proof</span>
        </h3>
        <h4 className="tx-20 text-danger">Minimum 2 proof</h4>
        <Row className="row-sm mx-0 mt-2">
          {presslist.map((data, i) => {
            return (
              <Col xl={2} lg={6} md={6} sm={6} key={i}>
                <a className="photo-mark1" onClick={() => pressdelete(data.id)}>
                  <i
                    className="fa fa-trash fa-lg"
                    style={{ color: "#fff" }}
                  ></i>
                </a>

                <img
                  className="photoimg"
                  src={imageUrl + fileString.statepress + data.file_name}
                  height="100px"
                  alt="Photo"
                />
              </Col>
            );
          })}
          {precount.map((data, i) => {
            return (
              <Col xl={2} lg={6} md={6} sm={6} key={i}>
                <Card
                  className="custom-card our-team border border-light shadow-base bg-light"
                  onClick={handleShow}
                >
                  <Card.Body>
                    <div className="tx-26 d-block py-5 w-100 text-center">
                      {" "}
                      <i className="fa fa-plus-circle text-danger"></i>{" "}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>

        <Modal show={show}>
          <Modal.Header>
            <h6 className="rep-head">Press Cuttings Proof</h6>
            <i
              className="fa fa-window-close fa-lg"
              onClick={handleClose}
              aria-hidden="true"
            ></i>
          </Modal.Header>
          <Modal.Body className="mod-body">
            <input
              id="upload"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={uploadpress}
            />
            {presserror !== "" && (
              <span className="text-danger">{presserror}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <a
              className="btn btn-success btn-sm mb-0 me-2 tx-14"
              onClick={addpress}
            >
              Submit
            </a>
            <button onClick={handleClose} className="text-center can-small">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <hr />
        <h3 className="card-title tx-18 lineheight1">
          <span className="main-content-label tx-20">Photos of Meet</span>
        </h3>
        <h4 className="tx-20 text-danger">Minimum 2 proof</h4>

        <Row className="row-sm mx-0 mt-2">
          {photolist.map((data, i) => {
            return (
              <Col xl={2} lg={6} md={6} sm={6} key={i}>
                <a className="photo-mark1" onClick={() => photodelete(data.id)}>
                  <i
                    className="fa fa-trash fa-lg"
                    style={{ color: "#fff" }}
                  ></i>
                </a>

                <img
                  className="photoimg"
                  src={imageUrl + fileString.statephoto + data.file_name}
                  height="190px"
                  alt="Photo"
                />
              </Col>
            );
          })}
          {photocount.map((data, i) => {
            return (
              <Col xl={2} lg={6} md={6} sm={6} key={i}>
                <Card
                  className="custom-card our-team border border-light shadow-base bg-light"
                  onClick={handlePShow}
                >
                  <Card.Body>
                    <div className="tx-26 d-block py-5 w-100 text-center">
                      {" "}
                      <i className="fa fa-plus-circle text-danger"></i>{" "}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <hr/>

        <Modal show={pshow}>
          <Modal.Header>
            <h6 className="rep-head">Photos of Meet</h6>
            <i
              className="fa fa-window-close fa-lg"
              onClick={handlePClose}
              aria-hidden="true"
            ></i>
          </Modal.Header>
          <Modal.Body className="mod-body">
            <input
              id="upload"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={uploadphoto}
            />
            {photoerror !== "" && (
              <span className="text-danger">{photoerror}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <a
              className="btn btn-success btn-sm mb-0 me-2 tx-14"
              onClick={addphoto}
            >
              Submit
            </a>
            <button onClick={handlePClose} className="text-center can-small">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default NidjamAddCommittee;
