import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card, Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  agecategorylist,
  agecategoryupdate,
  meetinfo,
} from "../../../services/meetservice";

const EditAgeCategory = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const navigate = useNavigate()
  const { id } = useParams();
  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");

  useEffect(() => {
    meetinfo(id).then((response) => {
      if (response.status === true) {
        setTourname(response.data.tournament.meet_name);
        setTourloc(response.data.tournament.location);
        setTourdate(
          moment(response.data.tournament.start_date).format("DD/MM/YYYY") +
            " - " +
            moment(response.data.tournament.end_date).format("DD/MM/YYYY")
        );
      }
    });

    loadlist();
  }, []);

  function loadlist() {
    agecategorylist(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data.age_categories);
      }
    });
  }
  const columnDefs = [
    {
      headerName: "Age Category",
      width: 180,
      field: "age_categories",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 140,
      field: "dob_start_date",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "End Date",
      width: 140,
      field: "dob_end_date",
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Max Events",
      width: 130,
      field: "max_event_athlete",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Max Athletes",
      width: 130,
      field: "max_athlete_event",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Fees",
      width: 100,
      field: "fees",
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 90,
      field: "id",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <a
              onClick={() => ageshow(params.value)}
              className="btn-sm btn-success"
            >
              <i className="fa fa-edit fa-lg"></i>
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Events",
      width: 90,
      field: "id",
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <Link
              to={
                "/superadmin/editagecategoryevents/" +
                params.data.tournament_meet_id +
                "/" +
                params.value
              }
              className="btn-sm btn-info"
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [aname, setAName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [mathlete, setAthlete] = useState("");
  const [mevent, setEvent] = useState("");
  const [mfees, setFees] = useState("");
  const [ageid, setAgeid] = useState("");

  function ageshow(tid) {
    agecategorylist(id).then((response) => {
      if (response.status === true) {
        if (response.data.age_categories.length !== 0) {
          for (let i = 0; i < response.data.age_categories.length; i++) {
            if (response.data.age_categories[i].id === tid) {
              setAgeid(response.data.age_categories[i].id);
              setAName(response.data.age_categories[i].age_categories);
              setSDate(response.data.age_categories[i].dob_start_date);
              setEDate(response.data.age_categories[i].dob_end_date);
              setAthlete(response.data.age_categories[i].max_athlete_event);
              setEvent(response.data.age_categories[i].max_event_athlete);
              setFees(response.data.age_categories[i].fees);
              setShow(true);
            }
          }
        }
      }
    });
  }

  function ageclose() {
    setShow(false);
  }

  const [errorMessage, setErrorMessage] = useState("");
  function updateage() {
    setErrorMessage("");
    if (sdate !== "") {
      if (edate !== "") {
        if (mathlete !== "") {
          if (mevent !== "") {
            if (mfees !== "") {
              const tempData = new FormData();
              tempData.append("age_category_id", ageid);
              tempData.append("dob_start_date", sdate);
              tempData.append("dob_end_date", edate);
              tempData.append("max_athlete_event", mathlete);
              tempData.append("max_event_athlete", mevent);
              tempData.append("fees", mfees);

              agecategoryupdate(tempData).then((response) => {
                if (response.status === true) {
                  loadlist();
                  ageclose();
                }
              });
            } else {
              setErrorMessage("Please Enter Your Fees");
            }
          } else {
            setErrorMessage("Please Enter Your Maximum Event Per Athlete");
          }
        } else {
          setErrorMessage("Please Enter Your Maximum Athlete Per Event");
        }
      } else {
        setErrorMessage("Please Select Your DOB End Date");
      }
    } else {
      setErrorMessage("Please Select Your DOB State Date");
    }
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Edit Age Category</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Header>
              <div className="text-center">
                <h4 className="mb-0 mt-3">{tourname}</h4>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-map-marker me-2 text-danger"></i>
                    {tourloc}
                  </h6>
                </div>
                <div className="eventdet ps-2">
                  <h6>
                    <i className="fa fa-calendar me-2 text-danger"></i>
                    {tourdate}
                  </h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="responsive">
                <div className="res-right">
                  <a
                    className="btn btn-danger"
                    onClick={() => navigate("/superadmin/viewmeet/" + id)}
                  >
                    Back
                  </a>
                </div>
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 500,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show}>
        <Modal.Header>
          <h4>Update Age Category</h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row className="row-sm mx-0">
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">Age Category Name</label>
                </div>
                <div className="form-group">
                  <b>{aname}</b>
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    DOB Start Date <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    value={sdate}
                    onChange={(e) => setSDate(e.target.value)}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    DOB End Date <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    value={edate}
                    onChange={(e) => setEDate(e.target.value)}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Maximum Athlete Per Event
                    <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please Enter Your Max Athlete"
                    value={mathlete}
                    onChange={(e) => setAthlete(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Maximum Event Per Athlete
                    <span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please Enter Your Max Event"
                    value={mevent}
                    onChange={(e) => setEvent(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Col>
              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mb-2">
                    Fees<span className="text-danger"> *</span>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Please Enter Your Fees"
                    value={mfees}
                    onChange={(e) => setFees(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </div>
              </Col>
              {errorMessage !== "" && (
                <span className="text-danger">{errorMessage}</span>
              )}
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            onClick={updateage}
          >
            Update
          </a>
          <a
            className="btn btn-next btn-danger"
            style={{ float: "right" }}
            onClick={ageclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default EditAgeCategory;
