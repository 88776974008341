import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { athleteresultlist } from "../../../services/athlete";
const AthleteResult = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const gridRef = useRef();
  useEffect(() => {
    athleteresultlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  const columnDefs = [
    {
      headerName: "Meet",
      width: 330,
      field: "tour_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event",
      width: 130,
      field: "event_name",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 130,
      field: "age_category",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Date",
      width: 150,
      field: "uploaded_date",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Venue",
      width: 150,
      field: "tour_location",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Race",
      width: 150,
      field: "event_status",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Result",
      width: 100,
      field: "result",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Performance",
      width: 140,
      field: "time_taken",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Results</h2>
        </div>
      </div>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                    suppressDragLeaveHidesColumns={true}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AthleteResult;
