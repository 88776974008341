import React, { Fragment, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changepassword } from "../../services/athlete";
import { officialchangepassword } from "../../services/official";
import { statechangepassword } from "../../services/state";
import { districtchangepassword } from "../../services/district";
import { clubchangepassword } from "../../services/club";
const Accountsetting = () => {
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confirmpass, setConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  function submitpassword() {
    setErrorMessage("");
    const tempData = {
      currentpassword: oldpass,
      newpassword: newpass,
      confirmpassword: confirmpass,
    };

    if (tempData.currentpassword !== "") {
      if (tempData.newpassword !== "") {
        if (tempData.newpassword.length === 10) {
          if (
            tempData.newpassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
          ) {
            if (tempData.confirmpassword !== "") {
              if (tempData.newpassword === tempData.confirmpassword) {
                if (localStorage.getItem("Roleid") === "2") {
                  changepassword(tempData).then((response) => {
                    if (response.status === true) {
                      localStorage.clear()
                      navigate("/login");
                    } else {
                      setErrorMessage(response.message);
                    }
                  });
                } else if (localStorage.getItem("Roleid") === "3") {
                  officialchangepassword(tempData).then((response) => {
                    if (response.status === true) {
                      localStorage.removeItem()
                      navigate("/login");
                    } else {
                      setErrorMessage(response.message);
                    }
                  });
                } else if (localStorage.getItem("Roleid") === "4") {
                  statechangepassword(tempData).then((response) => {
                    if (response.status === true) {
                      localStorage.removeItem()
                      navigate("/login");
                    } else {
                      setErrorMessage(response.message);
                    }
                  });
                } else if (localStorage.getItem("Roleid") === "5") {
                  districtchangepassword(tempData).then((response) => {
                    if (response.status === true) {
                      localStorage.removeItem()
                      navigate("/login");
                    } else {
                      setErrorMessage(response.message);
                    }
                  });
                } else if (localStorage.getItem("Roleid") === "6") {
                  clubchangepassword(tempData).then((response) => {
                    if (response.status === true) {
                      localStorage.removeItem()
                      navigate("/login");
                    } else {
                      setErrorMessage(response.message);
                    }
                  });
                }
              } else {
                setErrorMessage(
                  "Confirm Password should be same as New Password"
                );
              }
            } else {
              setErrorMessage("Please Enter Your Confirm Password");
            }
          } else {
            setErrorMessage(
              "Your password should be minimum 10 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
            );
          }
        } else {
          setErrorMessage("New Password should be minimum 10 characters");
        }
      } else {
        setErrorMessage("Please Enter Your New Password");
      }
    } else {
      setErrorMessage("Please Enter Your Current Password");
    }
  }
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Account Settings</h2>
        </div>
      </div>

      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="row">
            <div className="col-md-5">
              <b className="text-inverse">Current Password <span className="text-danger">*</span></b>
            </div>
            <div className="col-md-7 mb-3">
              <input
                type="text"
                placeholder="Enter Your Current Password"
                id="current"
                autoComplete="off"
                value={oldpass}
                onChange={(e) => {
                  setOldpass(e.target.value);
                }}
                maxLength={10}

              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <b className="text-inverse">New Password <span className="text-danger">*</span></b>
            </div>
            <div className="col-md-7 mb-3">
              <input
                type="text"
                id="new"
                placeholder="Enter Your New Password"
                autoComplete="off"
                title="Your password should be minimum 10 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
                value={newpass}
                onChange={(e) => {
                  setNewpass(e.target.value);
                }}
                maxLength={10}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <b className="text-inverse">Confirm Password <span className="text-danger">*</span></b>
            </div>
            <div className="col-md-7 mb-3">
              <input
                id="confirm"
                type="text"
                placeholder="Enter Your Confirm Password"
                autoComplete="off"
                value={confirmpass}
                onChange={(e) => {
                  setConfirm(e.target.value);
                }}
                maxLength={10}
              />
            </div>
          </div>
          {errorMessage !== "" && (
            <span className="errortxt text-danger">{errorMessage}</span>
          )}
        </Card.Body>
        <Card.Footer>
          <div className="mobile-text-right">
            <a
              className="btn btn-success me-1"
              onClick={submitpassword}
            >
              Change Password
            </a>
            <a className="btn btn-secondary" onClick={()=>navigate("/athlete/dashboard")}>Cancel</a>
          </div>
        </Card.Footer>
      </Card>
    </Fragment>
  );
};

export default Accountsetting;
