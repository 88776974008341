import React, { Fragment, useEffect, useState } from "react";
// import { AccordionWizardForm } from "../../../data/Ecommerce/Checkout/checkout";
import StepZilla from "react-stepzilla";
//import png19 from "../../../assets/img/pngs/19.png";
//import png16 from "../../../assets/img/pngs/16.png";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  FormGroup,
  Nav,
  Row,
  Tab,
  Card,
} from "react-bootstrap";
import "./district.css";
import Searchable from "react-searchable-dropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  districtgetorderid,
  districtpaymentupdate,
  districtsummarydetail,
  meetdetails,
} from "../../../services/meetservice";
import moment from "moment";
// const Signin = () => {

//   return (
//       <Fragment>
//           <div>
//               <section className="pt-3">
//                   <form>
//                       <h5 className="text-start mb-2">
//                         District Information
//                       </h5>
//                       <FormGroup className="form-group text-start">
//                           <Form.Label>District Email</Form.Label>
//                           <input
//                               placeholder="Enter your email"
//                               type="text"
//                           />
//                       </FormGroup>
//                       <FormGroup className="form-group text-start">
//                           <Form.Label>District Mobile</Form.Label>
//                           <input
//                               placeholder="Enter your Mobile"
//                               type="password"
//                           />
//                       </FormGroup>
//                   </form>
//               </section>
//           </div>
//       </Fragment>
//   )
// }
// const Billing = () => {
//   return (
//       <Fragment>
//           <div>

//               <section className="pt-3">
//                   <Form className="needs-validation">
//                       <h5 className="text-start mb-2">Billing Information</h5>
//                       <p className="mb-4 text-muted tx-13 ms-0 text-start">
//                           Lorem Ipsum has been the industry's standard dummy
//                           text ever since
//                       </p>
//                       <Row>
//                           <div className="col-md-6 mb-3">
//                               <Form.Label htmlFor="firstName">First name</Form.Label>
//                               <input
//                                   type="text"
//                                   id="firstName"
//                                   placeholder=""
//                                   defaultValue=""
//                                   required=""
//                               />
//                               <div className="invalid-feedback">
//                                   Valid first name is required.
//                               </div>
//                           </div>
//                           <div className="col-md-6 mb-3">
//                               <Form.Label htmlFor="lastName">Last name</Form.Label>
//                               <input
//                                   type="text"
//                                   id="lastName"
//                                   placeholder=""
//                                   defaultValue=""
//                                   required=""
//                               />
//                               <div className="invalid-feedback">
//                                   Valid last name is required.
//                               </div>
//                           </div>
//                       </Row>
//                       <Row>
//                           <Col md={12} className="mb-3">
//                               <Form.Label htmlFor="address">Address</Form.Label>
//                               <input
//                                   type="text"
//                                   id="address"
//                                   placeholder="1234 Main St"
//                                   required=""
//                               />
//                               <div className="invalid-feedback">
//                                   Please enter your shipping address.
//                               </div>
//                           </Col>
//                       </Row>
//                       <Row>
//                           <Col md={12} className="mb-3">
//                               <Form.Label htmlFor="address2">
//                                   Address 2
//                                   <span className="text-muted">(Optional)</span>
//                               </Form.Label>
//                               <input
//                                   type="text"
//                                   id="address2"
//                                   placeholder="Apartment or suite"
//                               />
//                           </Col>
//                       </Row>
//                       <Row>
//                           <Col md={12} className="mb-3">
//                               <Form.Label htmlFor="mobile">Mobile Number</Form.Label>
//                               <input
//                                   type="text"
//                                   id="mobile"
//                               />
//                           </Col>
//                       </Row>
//                       <Row>
//                           <Col md={4} className="mb-3">
//                               <Form.Label htmlFor="country">Country</Form.Label>
//                               <Country />
//                               <div className="invalid-feedback">
//                                   Please select a valid country.
//                               </div>
//                           </Col>
//                           <div className="col-md-4 mb-3">
//                               <Form.Label htmlFor="state">State</Form.Label>
//                               <State />
//                               <div className="invalid-feedback">
//                                   Please provide a valid state.
//                               </div>
//                           </div>
//                           <div className="col-md-4 mb-3">
//                               <Form.Label htmlFor="zip">Zip</Form.Label>
//                               <input
//                                   type="text"
//                                   id="zip"
//                                   placeholder=""
//                                   required=""
//                               />
//                               <div className="invalid-feedback">
//                                   Zip code required.
//                               </div>
//                           </div>
//                       </Row>
//                       <hr className="mb-4" />
//                       <button className="btn btn-success btn-lg btn-block mb-3" type="submit">
//                           Submit & Pay
//                       </button>
//                   </Form>
//               </section></div>
//       </Fragment>
//   )
// }
// const Country = () => {
//   return (
//       <div >
//           <Searchable className="form-control select2"
//               value="test"
//               placeholder="Choose one" // by default "Search"
//               notFoundText="No result found" // by default "No result found"
//               noInput
//               options={[
//                   {
//                       value: "",
//                       label: "All categories"

//                   },
//                   {
//                       value: "USA",
//                       label: "USA"
//                   },
//                   {
//                       value: "India",
//                       label: "India"
//                   },
//               ]}
//               onSelect={(value) => {
//                   console.log(value);
//               }}
//               listMaxHeight={140} //by default 140
//           />
//       </div>
//   );
// }
// const State = () => {
//   return (
//       <div >
//           <Searchable className="form-control select2"
//               value="test"
//               placeholder="Choose one" // by default "Search"
//               notFoundText="No result found" // by default "No result found"
//               noInput
//               options={[
//                   {
//                       value: "",
//                       label: "All categories"

//                   },
//                   {
//                       value: "California",
//                       label: "California"
//                   },
//                   {
//                       value: "Telangana",
//                       label: "Telangana"
//                   },
//               ]}
//               onSelect={(value) => {
//                   console.log(value);
//               }}
//               listMaxHeight={140} //by default 140
//           />
//       </div>
//   );
// }

// const PaymentDetails = () => {
//   return (
//       <Fragment>
//           <div>
//               <section className="pt-3">
//                   <div className="card-pay">
//                       <h5 className="text-start mb-2">Payments</h5>
//                       <p className="mb-4 text-muted tx-13 ms-0 text-start">Lorem Ipsum has been the industry's standard dummy text ever since</p>
//                       <Tab.Container id="left-tabs-example" defaultActiveKey="tab20" className="card-pay">
//                           <Nav variant="pills" className="tabs-menu nav">
//                               <Nav.Item>

//                                   <Nav.Link eventKey="tab20"><i className="fa fa-credit-card"></i> Credit Card</Nav.Link>
//                               </Nav.Item>
//                               <Nav.Item>

//                                   <Nav.Link eventKey="tab21"><i className="fab fa-paypal"></i>  Paypal</Nav.Link>
//                               </Nav.Item>
//                               <Nav.Item>

//                                   <Nav.Link eventKey="tab22"><i className="fa fa-university"></i>  Bank Transfer</Nav.Link>
//                               </Nav.Item>
//                           </Nav>
//                           <div className="tab-content">
//                               <Tab.Pane className="tab-pane px-0 py-2 br-3 mb-4" eventKey="tab20">
//                                   <FormGroup className="form-group mt-4">
//                                       <Form.Label className="form-label">CardHolder Name</Form.Label>
//                                       <Form.Control type="text" placeholder="First Name" />
//                                   </FormGroup>
//                                   <FormGroup className="form-group ">
//                                       <Form.Label className="form-label">Card number</Form.Label>
//                                       <div className="input-group">
//                                           <Form.Control type="text" placeholder="Search for..." />
//                                           <span className="">
//                                               <button className="btn btn-secondary box-shadow-0" type="button"><i className="fab fa-cc-visa"></i> &nbsp; <i className="fab fa-cc-amex"></i> &nbsp;
//                                                   <i className="fab fa-cc-mastercard"></i></button>
//                                           </span>
//                                       </div>
//                                   </FormGroup>
//                                   <Row className="row">
//                                       <div className="col-sm-8">
//                                           <FormGroup className="form-group">
//                                               <Form.Label className="form-label">Expiration</Form.Label>
//                                               <div className="input-group">
//                                                   <Form.Control type="number" placeholder="MM" name="Month" />
//                                                   <Form.Control type="number" placeholder="YY" name="Year" />
//                                               </div>
//                                           </FormGroup>
//                                       </div>
//                                       <div className="col-sm-4">
//                                           <FormGroup className="form-group">
//                                               <Form.Label className="form-label">CVV <i className="fa fa-question-circle"></i></Form.Label>
//                                               <Form.Control type="number" required="" />
//                                           </FormGroup>
//                                       </div>
//                                   </Row>
//                               </Tab.Pane>
//                               <Tab.Pane className="tab-pane px-0" eventKey="tab21">
//                                   <p className="mt-4">Paypal is easiest way to pay online</p>
//                                   <p>
//                                       <Button href="#" className="btn btn-primary">
//                                           <i className="fab fa-paypal"></i> Log    in my Paypal</Button>
//                                   </p>
//                                   <p className=""><strong>Note:</strong> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
//                               </Tab.Pane>
//                               <Tab.Pane className="tab-pane px-0" eventKey="tab22">
//                                   <p className="mt-4">Bank account details</p>
//                                   <dl className="card-text">
//                                       <dt>BANK: </dt>
//                                       <dd> THE UNION BANK 0456</dd>
//                                   </dl>
//                                   <dl className="card-text">
//                                       <dt>Account number: </dt>
//                                       <dd> 67542897653214</dd>
//                                   </dl>
//                                   <dl className="card-text">
//                                       <dt>IBAN: </dt>
//                                       <dd>543218769</dd>
//                                   </dl>
//                                   <p className=""><strong>Note:</strong> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
//                               </Tab.Pane>
//                           </div>
//                       </Tab.Container>
//                   </div>

//               </section>
//           </div>
//       </Fragment>
//   )
// }

// const stepswizard =
//   [
//       { name: 'Registration Payment', component: <Signin /> },
//       { name: 'Billing Information', component: <Billing /> },
//       // { name: 'Order', component: <Order /> },
//       // { name: 'Payment', component: <PaymentDetails /> },
//       // { name: 'Finish', component: <Finished /> },

//   ]
// export const AccordionWizardForm = () => {
//   return (
//       <div className='step-progress'>

//           <StepZilla steps={stepswizard} />

//       </div>
//   );
// }
const Payments = () => {
  const { id } = useParams();
  const [tname, setTName] = useState("");
  const [tdate, setTDate] = useState("");
  const [tcode, setTCode] = useState("");
  const [tamt, setTAmt] = useState(0);
  const [gcharge, setGateway] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rkey, setRKey] = useState("");
  const navigate = useNavigate();
  function paysubmit() {
    setErrorMessage("");
    if (email !== "") {
      var check = "";
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        check = "Please Enter a Valid Email Address";
        setErrorMessage("Please Enter a Valid Email Address");
      }
      if (check === "") {
        if (mobile !== "") {
          if (mobile.length === 10) {
            paynow();
          } else {
            setErrorMessage("Mobile Number should be 10 digits");
          }
        } else {
          setErrorMessage("Please Enter a Mobile Number");
        }
      }
    } else {
      setErrorMessage("Please Enter a Email Address");
    }
  }

  async function paynow() {
    const options = {
      key: rkey,
      // payment_amount: 10000,
      // payment_currency: "INR",
      order_id: orderid,
      name: "AFI",
      description: "Meet Registration",
      image: require("../../../assets/images/logo/logo-small.png"),
      handler: function (response) {
        const temp = new FormData();
        temp.append("razorpay_payment_id", response.razorpay_payment_id);
        temp.append("totalAmount", tamt);
        temp.append("current_meet_id", id);
        temp.append("order_id", orderid);
        temp.append("current_ins_id", payid);

        districtpaymentupdate(temp).then((res) => {
          if (res.status === true) {
            navigate("/district/confirmation/" + id);
          }
        });
      },
      // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  const [orderid, setOrder] = useState("");
  const [payid, setPayment] = useState("");

  useEffect(() => {
    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTName(response.data.tournament.meet_name);
        setTDate(
          moment(response.data.tournament.start_date).format("DD-MM-YYYY") +
            " to " +
            moment(response.data.tournament.end_date).format("DD-MM-YYYY")
        );
        setTCode(response.data.tournament.meet_code);
        if (response.data.gateway_charges === "Y") {
          setGateway("(Including Gateway Charges)");
        }
      }
    });

    districtgetorderid(
      id,
      localStorage.getItem("Userid"),
      localStorage.getItem("State")
    ).then((response) => {
      if (response.status === true) {
        setTAmt(response.data.tour_dist_club.total_amount);
        setOrder(response.data.tour_dist_club.order_id);
        setPayment(response.data.tour_dist_club.id);
        setRKey(response.data.statedetails.razorpaykey);
      }
    });
  }, []);

  function backplay() {
    navigate("/district/viewsummary/" + id);
  }
  return (
    <Fragment>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">{tname}</h2>
          <Breadcrumb>
            <Breadcrumb.Item>
              Event Code : <span>{tcode}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Date : <span>{tdate}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      {/* <!-- Row --> */}
      <Row>
        <Col xl={12}>
          <Card className="custom-card">
            <Card.Header className="bg-transparent border-bottom-0 text-center">
              <div>
                <label className="main-content-label mb-2 tx-18 text-danger">
                  Payment Information
                </label>
                <span className="d-block tx-12 mb-0 text-muted">
                  Entry Fees : {tamt}.00 INR {gcharge}
                </span>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={6} className="col-xl-6 mx-auto">
                  <div className="checkout-steps wrapper">
                    <div id="checkoutsteps">
                      <section className="pt-3">
                        <form>
                          <h5 className="text-start mb-2">
                            District Information
                          </h5>
                          <FormGroup className="form-group text-start">
                            <Form.Label>District Email</Form.Label>

                            <input
                              placeholder="Enter your Email"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup className="form-group text-start">
                            <Form.Label>District Mobile</Form.Label>
                            <input
                              placeholder="Enter your Mobile"
                              type="text"
                              value={mobile}
                              maxLength={10}
                              onChange={(e) => setMobile(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </FormGroup>
                        </form>
                      </section>
                    </div>
                  </div>
                  {errorMessage !== "" && (
                    <span className="text-danger">{errorMessage}</span>
                  )}
                  <hr className="mb-4" />
                  <div className="text-center">
                    <button
                      className="btn btn-danger mb-3 me-2"
                      onClick={backplay}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-success mb-3 me-2"
                      onClick={paysubmit}
                    >
                      Submit & Pay
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </Fragment>
  );
};

export default Payments;
