import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    password: null,
    token: null,
};

const loginSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            return Object.assign({}, state, { user: action.payload.user,password:action.payload.password,token:action.payload.token });
        },
    },
});

export const { login } = loginSlice.actions;
// export const selectUser = (state) => state.user;
// export const selectPassword = (state) => state.password;
export const selectToken = (state) => state.user.token !== null;


export default loginSlice.reducer;