import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import { athleteupdate, viewprofile } from "../../../services/superadmin";
import "./viewprofile.css";
import {
  bloodlist,
  distlist,
  statelist,
} from "../../../services/registerservice";
import fileString from "../../../assets/language/filepath.json";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { stubTrue } from "lodash";

const EditAthlete = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");
  const [uid, setUid] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [formbpl, setBpl] = useState("");
  const [bplstatus, setBplstatus] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [Errmsg, setErrMsg] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function LastNameOnly(eve) {
    if (eve.key !== ".") {
      if (/^[a-zA-Z\s]*$/.test(eve.key)) {
        return true;
      } else {
        eve.preventDefault();
        return false;
      }
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [blist, setBloodlist] = useState([]);
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);
  const [cdlist, setCDList] = useState([]);

  const [pdist, setPDist] = useState("");
  const [pstate, setPState] = useState("");
  const [cdist, setCDist] = useState("");
  const [cpin, setCPin] = useState("");
  const [cstate, setCState] = useState("");
  const [check, setCheck] = useState("");

  function checkcomm(e) {
    setCheck(e.target.checked);
    setCommAddr("");
    setCommaddr2("");
    setCDist("");
    setCPin("");
    setCState("");
    if (e.target.checked === true) {
      setCommAddr(addr);
      setCommaddr2(addr2);
      setCDist(pdist);
      setCPin(ppin);
      setCState(pstate);
      setCDList([]);
      distlist(pstate).then((response) => {
        setCDList(response.data);
      });
    } else {
      setCommAddr("");
      setCommaddr2("");
      setCDist("");
      setCPin("");
      setCState("");
    }
  }

  ///Permanent District
  function Selectpdist(e) {
    setPState(e.target.value);
    setPDist("");
    distlist(e.target.value).then((response) => {
      setPDList(response.data);
    });
  }

  ///Communication District
  function Selectcdist(e) {
    setCState(e.target.value);
    setCDist("");
    distlist(e.target.value).then((response) => {
      setCDList(response.data);
    });
  }
  // Photograph
  const [formphotoerror, setPhotoErrors] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");

  const [cshow, setCShow] = useState(false);
  function setIShow() {
    setCShow(true);
  }
  const setIClose = () => setCShow(false);

  const cropperRef = useRef(null);
  const [croppedImg, setCroppedImg] = useState("");
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());

    const base64 = cropper.getCroppedCanvas().toDataURL();

    const current = new Date();
    const timestamp = current.getTime();
    const imageName = first + timestamp + ".png";
    const arr = base64.split(",");
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var pfile = new File([u8arr], imageName, { type: "image/png" });
    setPhotoUpload(new File([u8arr], imageName, { type: "image/png" }));

    var size = pfile.size / 1024 / 1024;
    if (size > 5) {
      setPhotoErrors("Photograph size should be less than 5MB");
    }
  };

  function photofile(event) {
    setPhotoErrors("");
    setPhotoFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoFile(URL.createObjectURL(event.target.files[0]));
        // setPhotoUpload(event.target.files[0]);
        setIShow();
      } else {
        setErrMsg("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("Photograph size should be less than 3MB");
      }
    }
  }

  // DOB Proof
  const [formdobupload, setDobUpload] = useState("");

  function dobfile(event) {
    setIDFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDFile(URL.createObjectURL(event.target.files[0]));
        setDobUpload(event.target.files[0]);
      } else {
        setErrMsg("DOB Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("DOB Proof size should be less than 3MB");
      }
    }
  }

  // Address
  const [formaddrupload, setAddrUpload] = useState("");

  function addrfile(event) {
    setAddrFile("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setAddrFile(URL.createObjectURL(event.target.files[0]));
        setAddrUpload(event.target.files[0]);
      } else {
        setErrMsg("Address Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("Address Proof size should be less than 3MB");
      }
    }
  }

  // BPL Status
  function Selectbplstatus(event) {
    setBplstatus(event.target.value);
    // if (event.target.value === "N") {
    // setBPLFile("");
    // }
  }

  // BPL Card
  const [formbplupload, setBPLUpload] = useState("");

  function bplfile(event) {
    setBpl("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setBpl(URL.createObjectURL(event.target.files[0]));
        setBPLUpload(event.target.files[0]);
      } else {
        setErrMsg("BPL Card should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrMsg("BPL Card size should be less than 3MB");
      }
    }
  }

  function updateathlete(e) {
    e.preventDefault();
    if (blood !== "") {
      const tempData = new FormData();
      tempData.append("user_id", id);
      tempData.append("first_name", first);
      tempData.append("middle_name", "");
      tempData.append("last_name", last);
      tempData.append("date_of_birth", dob);
      tempData.append("gender", gender);
      tempData.append("blood_group", blood);
      tempData.append("aadhaar_no", aadhar);
      tempData.append("email_id", email);
      tempData.append("mobile_no", mobile);
      tempData.append("father_name", father);
      tempData.append("mother_name", mother);
      tempData.append("permenant_address_one", addr);
      tempData.append("permenant_address_two", addr2);
      tempData.append("permenant_state", pstate);
      tempData.append("permenant_district", pdist);
      tempData.append("permenant_zipcode", ppin);
      tempData.append("communication_address_one", commaddr);
      tempData.append("communication_address_two", commaddr2);
      tempData.append("communication_state", cstate);
      tempData.append("communication_district", cdist);
      tempData.append("communication_zipcode", cpin);
      tempData.append("photograph", formphotoupload);
      tempData.append("address_proof", formaddrupload);
      tempData.append("dob_proof", formdobupload);
      tempData.append("below_poverty_file", formbplupload);
      tempData.append("below_poverty_card", bplstatus);

      athleteupdate(tempData).then((response) => {
        if (response.status === true) {
          navigate("/superadmin/viewprofile/" + id);
        } else {
          setErrMsg(response.message);
        }
      });
    } else {
      setErrMsg("Please Select Blood Group");
    }
  }
  const [subrole, setSubRole] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("SubRoleid") === "1") {
      setSubRole(false);
    } else if (localStorage.getItem("SubRoleid") === "2") {
      setSubRole(false);
    } else {
      setSubRole(true);
    }
    viewprofile(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setLast(response.data.details.last_name);
        setDOB(response.data.details.date_of_birth);
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        if (response.data.details.blood_group_uid !== null) {
          setBlood(response.data.details.blood_group_uid);
        }
        if (response.data.details.aadhaar_no !== null) {
          setAadhar(response.data.details.aadhaar_no);
        }
        setCommAddr(response.data.details.communication_address_one);
        if (response.data.details.communication_address_two !== null) {
          setCommaddr2(response.data.details.communication_address_two);
        }
        setCDist(response.data.details.communication_district);
        setCState(response.data.details.communication_state);
        setCPin(response.data.details.communication_zipcode);
        setAddr(response.data.details.permenant_address_one);
        if (response.data.details.permenant_address_two !== null) {
          setAddr2(response.data.details.permenant_address_two);
        }
        setPDist(response.data.details.permenant_district);
        setPState(response.data.details.permenant_state);

        setFather(response.data.details.father_name);
        if (response.data.details.mother_name !== null) {
          setMother(response.data.details.mother_name);
        }
        setDistname(response.data.details.districtname);
        setPincode(response.data.details.permenant_zipcode);
        setState(response.data.details.statename);
        setStatus(response.data.details.status);
        setBplstatus(response.data.details.below_poverty_card);
        setPhotoFile(
          imageUrl + fileString.athletephoto + response.data.details.photograph
        );
        setIDFile(
          imageUrl + fileString.athleteidproof + response.data.details.dob_proof
        );
        setAddrFile(
          imageUrl +
            fileString.athleteaddress +
            response.data.details.address_proof
        );
        if (response.data.details.below_poverty_file !== null) {
          setBpl(
            imageUrl +
              fileString.athletebplcard +
              response.data.details.below_poverty_file
          );
        }

        setUid(response.data.details.username);
        distlist(response.data.details.permenant_state).then((response1) => {
          setPDList(response1.data);
        });

        distlist(response.data.details.communication_state).then(
          (response1) => {
            setCDList(response1.data);
          }
        );

        registerlist();
      }
    });
  }, []);

  function registerlist() {
    bloodlist().then((response) => {
      if (response.status === true) {
        setBloodlist(response.data);
      }
    });
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <h2 className="main-content-title tx-24 mg-b-5">Edit Athlete</h2>
      </div>{" "}
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your First Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            value={first}
                            onChange={(e) => setFirst(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Last/Family Name"
                            value={last}
                            onChange={(e) => setLast(e.target.value)}
                            onKeyPress={LastNameOnly}
                            onPaste={onPasteLetter}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="Enter Your Date of Birth"
                            value={dob}
                            onChange={(e) => setDOB(e.target.value)}
                            autoComplete="off"
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            id="gender"
                            disabled
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email</b>
                        </div>
                        <div className="col-md-8">
                          {!subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Email"
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          )}
                          {subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Email"
                              autoComplete="off"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Blood Group</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={blood}
                            onChange={(e) => {
                              setBlood(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Blood Group
                            </option>
                            {blist.map((data, i) => {
                              return (
                                <option value={data.blooduid} key={i}>
                                  {data.bloodtype}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mobile</b>
                        </div>
                        <div className="col-md-8">
                          {!subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Mobile Number"
                              maxLength={10}
                              autoComplete="off"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                          {subrole && (
                            <input
                              type="text"
                              placeholder="Enter Your Mobile Number"
                              maxLength={10}
                              autoComplete="off"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar Number</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Aadhar Number"
                            maxLength={16}
                            autoComplete="off"
                            value={aadhar}
                            onChange={(e) => {
                              setAadhar(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Father&apos;s Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Father's Name"
                            onKeyPress={LetterOnly}
                            autoComplete="off"
                            onPaste={onPasteLetter}
                            value={father}
                            onChange={(e) => {
                              setFather(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mother&apos;s Name</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Mother's Name"
                            onKeyPress={LetterOnly}
                            autoComplete="off"
                            onPaste={onPasteLetter}
                            value={mother}
                            onChange={(e) => {
                              setMother(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h6>PERMANENT ADDRESS</h6>
                  </div>
                  <div className="col-md-6">
                    <h6>COMMUNICATION ADDRESS</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            value={addr}
                            onChange={(e) => {
                              setAddr(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            value={commaddr}
                            onChange={(e) => {
                              setCommAddr(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            value={addr2}
                            onChange={(e) => {
                              setAddr2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">ADDRESS 2</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            value={commaddr2}
                            onChange={(e) => {
                              setCommaddr2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pstate}
                            onChange={Selectpdist}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cstate}
                            onChange={Selectcdist}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.stateuid} key={i}>
                                  {data.statename}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={pdist}
                            onChange={(e) => {
                              setPDist(e.target.value);
                            }}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {pdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={cdist}
                            onChange={(e) => {
                              setCDist(e.target.value);
                            }}
                            disabled
                          >
                            <option value="" disabled>
                              Select Your District
                            </option>
                            {cdlist.map((data, i) => {
                              return (
                                <option value={data.districtname} key={i}>
                                  {data.districtname}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={ppin}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            maxLength={6}
                            placeholder="Enter Your Pincode/Zipcode"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={cpin}
                            onChange={(e) => {
                              setCPin(e.target.value);
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="checkbox checkbox-1 rhk-gap mt-4">
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    className="reg-chk"
                    onChange={checkcomm}
                    checked={check}
                  />
                  <label htmlFor="checkbox-1">
                    Communication address is same as permanent address
                  </label>
                </div>

                <br />
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="">
                      Do you hold a Below Poverty Line Card?
                    </label>
                  </div>

                  <div className="col-md-6">
                    <div className="radioclass">
                      <input
                        type="radio"
                        id="no"
                        className="reg-chk"
                        name="fav_language"
                        value="N"
                        checked={bplstatus === "N"}
                        onChange={Selectbplstatus}
                      />
                      <label htmlFor="no" className="reg-radio">
                        No
                      </label>
                      <input
                        type="radio"
                        id="yes"
                        className="reg-chk"
                        name="fav_language"
                        value="Y"
                        checked={bplstatus === "Y"}
                        onChange={Selectbplstatus}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formphoto}
                          alt="photo"
                          onClick={() => handleshow(formphoto)}
                          onError={(e) => errorhandle(e)}
                        />

                        <input
                          type="file"
                          name="file"
                          onChange={photofile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">ID Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formid}
                          alt="photo"
                          onClick={() => handleshow(formid)}
                          onError={(e) => errorhandle(e)}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={dobfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Address Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formaddr}
                          alt="photo"
                          onClick={() => handleshow(formaddr)}
                          onError={(e) => errorhandle(e)}
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={addrfile}
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  {bplstatus === "Y" && (
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={formbpl}
                            alt="photo"
                            onClick={() => handleshow(formbpl)}
                            onError={(e) => errorhandle(e)}
                          />

                          <input
                            type="file"
                            name="file"
                            onChange={bplfile}
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Modal show={cshow} size="xl">
                  <Modal.Header>
                    <h6 className="rep-head">Crop Your Photograph</h6>
                    <i
                      className="fa fa-window-close fa-lg"
                      onClick={setIClose}
                      aria-hidden="true"
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="mod-body">
                    <Row>
                      <Col md={6}>
                        <Cropper
                          src={formphoto}
                          style={{ width: "100%" }}
                          initialAspectRatio={16 / 9}
                          guides={false}
                          crop={onCrop}
                          ref={cropperRef}
                        />
                      </Col>
                      <Col md={6}>
                        {croppedImg && (
                          <img
                            alt="Crop"
                            style={{ width: "100%" }}
                            src={croppedImg}
                          />
                        )}
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      onClick={setIClose}
                      className="text-center btn-info"
                    >
                      Ok
                    </button>
                  </Modal.Footer>
                </Modal>

                <Modal show={show}>
                  <Modal.Body>
                    <img
                      src={file}
                      alt="Image"
                      onError={(e) => errorhandle(e)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <a
                      className="btn btn-next btn-success"
                      style={{ float: "right" }}
                      href={file}
                      download
                      target="_blank"
                    >
                      Download
                    </a>
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={handleclose}
                    >
                      Close
                    </a>
                  </Modal.Footer>
                </Modal>
                {Errmsg !== "" && <span className="text-danger">{Errmsg}</span>}
                <hr />
                <div className="text-right">
                  <button
                    className="btn-f btn-secondary"
                    onClick={() => navigate("/superadmin/viewprofile/" + id)}
                  >
                    back
                  </button>
                  <button className="btn-f btn-success" onClick={updateathlete}>
                    Submit
                  </button>
                </div>

                <br />
                <hr></hr>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default EditAthlete;
