import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  athletemissingpayment,
  athleteupdatepayment,
} from "../../../services/superadmin";
import { toast } from "react-toastify";
import exportUrl from "../../../Export";
const UpdateMissing = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [userid, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [texcel, setExcel] = useState("");
  const gridRef = useRef();
  useEffect(() => {}, []);
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
    };
  }, []);
  const columnDefs = [
    {
      headerName: "UID",
      width: 150,
      field: "user_id",
      sortable: true,
      resizable: true,
      editable: false,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      width: 170,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      editable: false,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (
          params.data.middle_name !== null
        ) {
            return params.data.first_name + " " + params.data.middle_name + " " + params.data.last_name;
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },
    {
      headerName: "AFI UID",
      width: 170,
      field: "username",
      editable: false,
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 150,
      field: "mobile_no",
      editable: false,
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 150,
      field: "email_id",
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "payment_status",
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value === 1) {
          return "Paid";
        } else if (params.value === 0) {
          return "Not Paid";
        }
      },
    },
    {
      headerName: "Order ID",
      width: 300,
      field: "order_id",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      width: 100,
      editable: false,
      field: "user_id",
      filter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <a
            onClick={() => submitpayment(params)}
            className="btn-sm btn-success"
          >
            <i className="fa fa-edit fa-lg"></i>
          </a>
        );
      },
    },
  ];

  const navigate = useNavigate();
  function searchpayment() {
    setFilterProduct([]);
    const tempData = new FormData();
    tempData.append("mobile_no", mobile);
    tempData.append("email_id", email);
    tempData.append("user_uid", userid);

    athletemissingpayment(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  function generateexcel() {
    if (texcel !== "") {
      if (sdate !== "") {
        if (edate !== "") {
          window.open(
            exportUrl +
              "missing-pay-excel?type=" +
              texcel +
              "&start_date=" +
              sdate +
              "&end_date=" +
              edate,
            "_self"
          );
        } else {
          toast.error("Please Select End Date");
        }
      } else {
        toast.error("Please Select Start Date");
      }
    } else {
      toast.error("Please Select Excel Type");
    }
  }
  // const updateorder = useCallback((event) => {
  //   var data = event.data;
  // }, []);

  function submitpayment(datas) {
    if (datas.data.order_id !== null) {
      const tempData = new FormData();
      tempData.append("user_id", datas.data.user_id);
      tempData.append("order_id", datas.data.order_id);
      athleteupdatepayment(tempData).then((response) => {
        if (response.status === true) {
          searchpayment();
        }
      });
    } else {
      toast.error("Please Enter the Order ID for " + datas.data.user_id);
    }
  }
  function clear() {
    setUser("");
    setEmail("");
    setMobile("");
    setFilterProduct([]);
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Update Missing Payment Athletes
          </h2>
        </div>
      </div>
      <Card className=" custom-card profilepage">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Excel Type</label>
                      <select
                        value={texcel}
                        onChange={(e) => {
                          setExcel(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Excel Type
                        </option>
                        <option value="Missing">Missing</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Approved">Approved</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Start Date</label>
                      <input
                        type="date"
                        placeholder="Enter Your Start Date"
                        value={sdate}
                        onChange={(e) => setSDate(e.target.value)}
                        autoComplete="off"
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>End Date</label>
                      <input
                        type="date"
                        placeholder="Enter Your End Date"
                        value={edate}
                        onChange={(e) => setEDate(e.target.value)}
                        autoComplete="off"
                        onKeyPress={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <a
                      className="btn btn-success me-2 mt-4"
                      onClick={generateexcel}
                    >
                      Generate
                    </a>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>UID</label>
                      <input
                        type="text"
                        placeholder="Enter UID"
                        value={userid}
                        onChange={(e) => setUser(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <a
                      className="btn btn-success me-2 mt-4"
                      onClick={searchpayment}
                    >
                      Search
                    </a>
                  </div>
                </div>
                {/* <hr />
                <div className="form-group">
                  <div className="text-center">
                    <a className="btn btn-success me-2" onClick={searchpayment}>
                      Search
                    </a>
                    <a className="btn btn-danger" onClick={clear}>
                      Reset
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card profilepage">
            <Card.Body>
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                    marginTop: "2rem",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    defaultColDef={defaultColDef}
                    pagination="true"
                    paginationPageSize="10"
                    suppressExcelExport={true}
                    suppressDragLeaveHidesColumns={true}
                    // onRowValueChanged={updateorder}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UpdateMissing;
