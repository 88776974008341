import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { certificatedashboard } from "../../../services/superadmin";

export const Certdashboard = () => {
  const [totnat, setTotalNat] = useState("");
  const [totstate, setTotState] = useState("");
  const [totoverall, setTotOverall] = useState("");

  const [totnatcert, setTotalNatCert] = useState("");
  const [totstatecert, setTotStateCert] = useState("");
  const [totoverallcert, setTotOverallCert] = useState("");

  const [natmerit, setNatMerit] = useState("");
  const [natpart, setNatPart] = useState("");
  const [natcert, setNatCert] = useState("");

  const [statemerit, setStateMerit] = useState("");
  const [statepart, setStatePart] = useState("");
  const [statecert, setStateCert] = useState("");
  useEffect(() => {
    certificatedashboard().then((response) => {
      if (response.status === true) {
        setTotalNat(response.data.national_tour_count);
        setTotState(response.data.state_tour_count);
        setTotOverall(response.data.national_tour_count + response.data.state_tour_count);

        setTotalNatCert(response.data.national_total);
        setTotStateCert(response.data.state_total);
        setTotOverallCert(response.data.total);

        setNatMerit(0);
        setNatPart(
          response.data.federation_meet_count + response.data.nat_ind_count
        );
        setNatCert(response.data.national_total);

        setStateMerit(response.data.state_merit_count);
        setStatePart(response.data.state_participate_count);
        setStateCert(response.data.state_total);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Certificate Dashboard
          </h2>
        </div>
      </div>
      <Row className="row-sm mx-0">
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    NATIONAL MEET
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totnat}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    STATE ENTRY
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totstate}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totoverall}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="row-sm mx-0">
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    NATIONAL MEET CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totnatcert}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    STATE ENTRY CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totstatecert}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{totoverallcert}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">National Meet</h2>
        </div>
      </div>

      <Row className="row-sm mx-0">
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL MERIT CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{natmerit}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL PARTICIPANTS CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{natpart}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{natcert}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="page-header mb-4 ">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">State Entry</h2>
        </div>
      </div>

      <Row className="row-sm mx-0">
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL MERIT CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{statemerit}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL PARTICIPANTS CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{statepart}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className="custom-card">
            <Card.Body>
              <div className="card-item">
                <div className="card-item-title mb-2">
                  <label className="main-content-label tx-13 font-weight-bold mb-1">
                    TOTAL CERTIFICATES
                  </label>
                </div>
                <div className="card-item-body">
                  <div className="card-item-stat">
                    <h4 className="font-weight-bold">{statecert}</h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
