import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import "./state.css";
import moment from "moment";
import {
    statefedadditionalathleteentrylist,
  statefedadditionalremoveathlete,
} from "../../../services/state";
import { AgGridReact } from "ag-grid-react";
import imageUrl from "../../../ImageUrl";
import { meetdetails } from "../../../services/meetservice";
import fileString from "../../../assets/language/filepath.json";

const AdditionalAthleteEntryList = () => {
  const { id } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);
  const [cir, setCircular] = useState("");
  const [cir2, setCircular2] = useState("");
  const [cir3, setCircular3] = useState("");
  const [cir4, setCircular4] = useState("");
  const [cir5, setCircular5] = useState("");

  const [tname, setTourName] = useState("");
  useEffect(() => {
    
    funlist();
  }, []);

  const columnDefs = [
    {
      headerName: "Full Name",
      width: 400,
      field: "first_name",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      valueGetter: function sumField(params) {
        if (
          params.data.middle_name !== null
        ) {
          return (
            params.data.first_name +
            " " +
            params.data.middle_name +
            " " +
            params.data.last_name
          );
        } else {
          return params.data.first_name + " " + params.data.last_name;
        }
      },
    },

    {
      headerName: "Event",
      width: 200,
      field: "event_selected",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Gender",
      width: 130,
      field: "gender",
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "DOB",
      width: 140,
      field: "date_of_birth",
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      width: 120,
      resizable: true,
      field: "id",
      filter: false,
      cellRenderer: function (params) {
        return (
          <a
            onClick={() => athletedelete(params.value)}
            className="btn-sm btn-danger"
          >
            <i className="fa fa-trash fa-lg"></i>
          </a>
        );
      },
    },
  ];

  function athletedelete(did) {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    tempData.append("unique_id", did);

    statefedadditionalremoveathlete(tempData).then((response) => {
      if (response.status === true) {
        funlist();
      }
    });
  }

  function funlist() {
    const tempData = new FormData();
    tempData.append("tournament_meet_id", id);
    statefedadditionalathleteentrylist(tempData).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });

    meetdetails(id).then((response) => {
      if (response.status === true) {
        setTourName(response.data.tournament.meet_name);
        if (response.data.tournament.circular_file_1 !== null) {
          if (response.data.tournament.circular_file_1 !== "-") {
            setCircular(response.data.tournament.circular_file_1);
          }
        }
        if (response.data.tournament.circular_file_2 !== null) {
          if (response.data.tournament.circular_file_2 !== "-") {
            setCircular2(response.data.tournament.circular_file_2);
          }
        }

        if (response.data.tournament.circular_file_3 !== null) {
          if (response.data.tournament.circular_file_3 !== "-") {
            setCircular3(response.data.tournament.circular_file_3);
          }
        }

        if (response.data.tournament.circular_file_4 !== null) {
          if (response.data.tournament.circular_file_4 !== "-") {
            setCircular4(response.data.tournament.circular_file_4);
          }
        }

        if (response.data.tournament.circular_file_5 !== null) {
          if (response.data.tournament.circular_file_5 !== "-") {
            setCircular5(response.data.tournament.circular_file_5);
          }
        }
      }
    });
  }
  return (
    <Fragment>
      <div id="statedash">
        <div className="page-header d-block">
          <div>
            <Card className="card card-aside custom-card">
              <Card.Body>
                <div className="d-flex align-items-center pt-0 mt-auto">
                  <div className="me-3">
                    <a>
                      <h5 className="main-content-label tx-24 mb-0 tx-medium">
                        {tname}
                      </h5>
                    </a>
                  </div>

                  <div className="ms-auto">
                    <Link
                      to={"/state/dashboard"}
                      className="icon d-none d-md-inline-block ms-3 logout text-primary"
                    >
                      <i className="fe fe-home me-1 tx-20"></i> Back to home
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        <Row className="row-sm mx-0 mt-3">
          <Col xl={12} lg={12}>
            <Card className=" custom-card" id="basic">
              <Card.Body>
                <div className="page-header mt-2 mb-0">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-0 ps-4">
                      Athlete List
                    </h2>
                  </div>
                  <div className="d-flex pe-4">
                    <div className="justify-content-center">
                      <a
                        href={imageUrl + fileString.tournamentfiles + cir}
                        download
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Download Circular
                      </a>

                      {cir2 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir2}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir3 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir3}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir4 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir4}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      {cir5 !== "" && (
                        <a
                          href={imageUrl + fileString.tournamentfiles + cir5}
                          download
                          variant="secondary"
                          type="button"
                          className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                        >
                          {" "}
                          Download Circular
                        </a>
                      )}
                      <Link
                        to={"/state/additionaladdathlete/" + id}
                        variant="success"
                        type="button"
                        className="btn my-1 btn-success me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        Add Athletes
                      </Link>

                      <Link
                        to={"/state/additionalsendentries/" + id}
                        variant="secondary"
                        type="button"
                        className="btn my-1 btn-secondary me-2 tx-teko tx-uppercase"
                      >
                        {" "}
                        SENT ENTRIES
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{
                      height: 600,
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                      suppressDragLeaveHidesColumns={true}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AdditionalAthleteEntryList;
