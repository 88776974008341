import React, { Fragment, useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import imageUrl from "../../../ImageUrl";
import {
  officialupdate,
  officialview,
  officialLevels,
  officialPreferredAreas,
} from "../../../services/official";
import { distlist, statelist } from "../../../services/registerservice";
import "./profile.css";
import fileString from "../../../assets/language/filepath.json";

const OfficialProfile = () => {
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [addr, setAddr] = useState("");
  const [pstate, setState] = useState("");
  const [pdist, setDist] = useState("");
  const [pstatename, setStatename] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");

  const [lastlevel, setLastlevel] = useState("");
  const [lastexam, setLastexam] = useState("");
  const [area1, setArea1] = useState("");
  const [area2, setArea2] = useState("");
  const [certificate, setCertificate] = useState("");
  const [certexam, setCertexam] = useState("");
  const [comp1, setComp1] = useState("");
  const [event1, setEvent1] = useState("");
  const [year1, setYear1] = useState("");
  const [offi1, setOffi1] = useState("");
  const [comp2, setComp2] = useState("");
  const [event2, setEvent2] = useState("");
  const [year2, setYear2] = useState("");
  const [offi2, setOffi2] = useState("");

  const [fullname, setBankfull] = useState("");
  const [panno, setPAN] = useState("");
  const [bankname, setBankname] = useState("");
  const [branch, setBranch] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [accno, setAccno] = useState("");

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [formaddrupload, setAddrUpload] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [level, setLevel] = useState([]);
  const [pareas, setPareas] = useState([]);
  function photofile(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(event.target.files[0]));
        setPhotoFile(event.target.files[0]);
      } else {
        setErrorMessage("Photograph should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Photograph size should be less than 3MB");
      }
    }
  }

  function dobfile(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setErrorMessage("DOB Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("DOB Proof size should be less than 3MB");
      }
    }
  }

  function addrfile(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setAddrUpload(URL.createObjectURL(event.target.files[0]));
        setAddrFile(event.target.files[0]);
      } else {
        setErrorMessage("Address Proof should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Address Proof size should be less than 3MB");
      }
    }
  }

  function certfile(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setCertexam(URL.createObjectURL(event.target.files[0]));
        setCertificate(event.target.files[0]);
      } else {
        setErrorMessage(
          "Certificate Proof should be jpg or png or jpeg format"
        );
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setErrorMessage("Certificate Proof size should be less than 3MB");
      }
    }
  }
  const [slist, setStatelist] = useState([]);
  const [pdlist, setPDList] = useState([]);

  function profilelist() {
    statelist().then((response) => {
      if (response.status === true) {
        setStatelist(response.data);
      }
    });
  }

  function Selectpdist(e) {
    setState(e.target.value);
    setDist("");
    distlist(e.target.value).then((response) => {
      setPDList(response.data);
    });
  }

  function updateprofile(e) {
    e.preventDefault();
    const tempFile = new FormData();
    tempFile.append("email_id", email);
    tempFile.append("mobile_no", mobile);
    tempFile.append("first_name", first);
    tempFile.append("middle_name", middle);
    tempFile.append("last_name", last);
    tempFile.append("date_of_birth", dob);
    tempFile.append("gender", gender);
    tempFile.append("permenant_address_one", addr);
    tempFile.append("permenant_district", pdist);
    tempFile.append("permenant_zipcode", ppin);
    tempFile.append("permenant_state", pstate);
    tempFile.append("last_level", lastlevel);
    tempFile.append("last_level_cleared_date", lastexam);
    tempFile.append("prefer_area_of_officiating_one", area1);
    tempFile.append("prefer_area_of_officiating_two", area2);
    tempFile.append("competion_one", comp1);
    tempFile.append("competition_year_one", year1);
    tempFile.append("event_name_one", event1);
    tempFile.append("area_of_officiating_one", offi1);
    tempFile.append("competition_two", comp2);
    tempFile.append("competition_year_two", year2);
    tempFile.append("event_name_two", event2);
    tempFile.append("area_of_officiating_two", offi2);
    tempFile.append("account_name", fullname);
    tempFile.append("bank_name", bankname);
    tempFile.append("ifsc_code", ifsc);
    tempFile.append("branch_name", branch);
    tempFile.append("pan_no", panno);
    tempFile.append("account_no", accno);
    tempFile.append("photograph", formphoto);
    tempFile.append("dob_proof", formid);
    tempFile.append("address_proof", formaddr);
    tempFile.append("certificate_exam_passed", certificate);

    if (first !== "") {
      if (last !== "") {
        if (dob !== "") {
          if (gender !== "") {
            if (email !== "") {
              if (mobile !== "") {
                if (mobile.length === 10) {
                  if (addr !== "") {
                    if (pstate !== "") {
                      if (pdist !== "") {
                        if (ppin !== "") {
                          if (lastlevel !== "") {
                            if (lastexam !== "") {
                              if (area1 !== "") {
                                if (area2 !== "") {
                                  if (comp1 !== "") {
                                    if (event1 !== "") {
                                      if (year1 !== "") {
                                        if (offi1 !== "") {
                                          if (comp2 !== "") {
                                            if (event2 !== "") {
                                              if (year2 !== "") {
                                                if (offi2 !== "") {
                                                  if (panno !== "") {
                                                    if (bankname !== "") {
                                                      if (branch !== "") {
                                                        if (accno !== "") {
                                                          officialupdate(
                                                            tempFile
                                                          ).then((response) => {
                                                            if (
                                                              response.status ===
                                                              true
                                                            ) {
                                                              window.location.reload();
                                                            } else {
                                                              setErrorMessage(
                                                                response.message
                                                              );
                                                            }
                                                          });
                                                        } else {
                                                          setErrorMessage(
                                                            "Please Enter Your Account No"
                                                          );
                                                        }
                                                      } else {
                                                        setErrorMessage(
                                                          "Please Enter Your Branch Name"
                                                        );
                                                      }
                                                    } else {
                                                      setErrorMessage(
                                                        "Please Enter Your Bank Name"
                                                      );
                                                    }
                                                  } else {
                                                    setErrorMessage(
                                                      "Please Enter Your PAN No"
                                                    );
                                                  }
                                                } else {
                                                  setErrorMessage(
                                                    "Please Enter Your Area of Officiating"
                                                  );
                                                }
                                              } else {
                                                setErrorMessage(
                                                  "Please Enter Your Year"
                                                );
                                              }
                                            } else {
                                              setErrorMessage(
                                                "Please Enter Your Event"
                                              );
                                            }
                                          } else {
                                            setErrorMessage(
                                              "Please Enter Your Competition"
                                            );
                                          }
                                        } else {
                                          setErrorMessage(
                                            "Please Enter Your Area of Officiating"
                                          );
                                        }
                                      } else {
                                        setErrorMessage(
                                          "Please Enter Your Year"
                                        );
                                      }
                                    } else {
                                      setErrorMessage(
                                        "Please Enter Your Event"
                                      );
                                    }
                                  } else {
                                    setErrorMessage(
                                      "Please Select Your Competition"
                                    );
                                  }
                                } else {
                                  setErrorMessage(
                                    "Please Select Your Area of Officiating"
                                  );
                                }
                              } else {
                                setErrorMessage(
                                  "Please Select Your Area of Officiating"
                                );
                              }
                            } else {
                              setErrorMessage(
                                "Please Enter Your Date Of Last Exam"
                              );
                            }
                          } else {
                            setErrorMessage("Please Select Your Last Level");
                          }
                        } else {
                          setErrorMessage("Please Enter Your Pincode");
                        }
                      } else {
                        setErrorMessage("Please Select Your District");
                      }
                    } else {
                      setErrorMessage("Please Select Your State");
                    }
                  } else {
                    setErrorMessage("Please Enter Your Address");
                  }
                } else {
                  setErrorMessage("Mobile Number must be 10 digits");
                }
              } else {
                setErrorMessage("Please Enter Your Mobile Number");
              }
            } else {
              setErrorMessage("Please Enter Your Email");
            }
          } else {
            setErrorMessage("Please Select Your Gender");
          }
        } else {
          setErrorMessage("Please Enter Your DOB");
        }
      } else {
        setErrorMessage("Please Enter Your LastP Name");
      }
    } else {
      setErrorMessage("Please Enter Your First Name");
    }
  }
  useEffect(() => {
    officialview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setFirst(response.data.first_name);
        setMiddle(response.data.middle_name);
        setLast(response.data.last_name);
        setDOB(response.data.date_of_birth);
        setGender(response.data.gender);
        setEmail(response.data.email_id);
        setMobile(response.data.mobile_no);
        setAddr(response.data.permenant_address_one);
        setState(response.data.permenant_state);
        setStatename(response.data.statename);
        setDist(response.data.permenant_district);
        setDistname(response.data.districtname);
        setPincode(response.data.permenant_zipcode);

        setLastlevel(response.data.last_level);
        setLastexam(response.data.last_level_cleared_date);
        setArea1(response.data.prefer_area_of_officiating_one);
        setArea2(response.data.prefer_area_of_officiating_two);
        setCertexam(
          imageUrl + fileString.athleteaddress + response.data.certificate_exam_passed
        );

        setComp1(response.data.competion_one);
        setEvent1(response.data.event_name_one);
        setYear1(response.data.competition_year_one);
        setOffi1(response.data.area_of_officiating_one);
        setComp2(response.data.competition_two);
        setEvent2(response.data.event_name_two);
        setYear2(response.data.competition_year_two);
        setOffi2(response.data.area_of_officiating_two);

        setBankfull(response.data.account_name);
        setPAN(response.data.pan_no);
        setBankname(response.data.bank_name);
        setBranch(response.data.branch_name);
        setIFSC(response.data.ifsc_code);
        setAccno(response.data.account_no);

        setPhotoUpload(imageUrl + fileString.athletephoto + response.data.photograph);
        setIDUpload(imageUrl + fileString.athleteidproof + response.data.dob_proof);
        setAddrUpload(
          imageUrl + fileString.athleteaddress + response.data.address_proof
        );

        distlist(response.data.permenant_state).then((response1) => {
          setPDList(response1.data);
        });
      }
    });
    profilelist();
    officialLevels().then((response) => {
      if (response.status === true) {
        setLevel(response.data);
      }
    });
    officialPreferredAreas().then((response) => {
      if (response.status === true) {
        setPareas(response.data);
      }
    });
  }, []);
  let date = new Date(dob).getDate();
  let month = new Date(dob).getMonth() + 1;
  let year = new Date(dob).getFullYear();
  let newDate = `${date}-${month}-${year}`;
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Profile</h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container defaultActiveKey="info">
                  <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link eventKey="info" className="mt-1">
                        Your Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="update" className="mt-1">
                        Update Your Information
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="info" className="border">
                      <h5>Personal Information</h5>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">First Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{first}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Middle Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{middle}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Last Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{last}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">DOB</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{newDate}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Gender</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{gender}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Email</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{email}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Mobile</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{mobile}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Address</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{addr}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">District</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">
                                  {pdistname}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">State</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">
                                  {pstatename}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Pincode</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{ppin}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Last Level</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">
                                  {lastlevel}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Date of Last Exam Cleared
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{lastexam}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Prefers Area of Officiating 1
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{area1}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Prefers Area of Officiating 2
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{area2}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-5">
                              <b className="text-inverse">
                                Certificate of Exam Passed
                              </b>
                            </div>
                            <div className="col-md-7">
                              <img
                                className="profile_img"
                                src={certexam}
                                alt="photo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <h5>
                        Select Major Events in which you have Officiated during
                        the last five years
                      </h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Select Competition
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{comp1}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Name of the Event
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{event1}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Year</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{year1}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Area of Officiating
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{offi1}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Select Competition
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{comp2}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Name of the Event
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{event2}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Year</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{year2}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Area of Officiating
                                </b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{offi2}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <h5>Account Details</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Full Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{fullname}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">PAN No</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{panno}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Bank Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{bankname}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Branch Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{branch}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">IFSC Code</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{ifsc}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-group">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Account No</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{accno}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-5">
                              <b className="text-inverse">Photograph</b>
                            </div>
                            <div className="col-md-7">
                              <img
                                className="profile_img"
                                src={formphotoupload}
                                alt="photo"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-5">
                              <b className="text-inverse">ID Proof</b>
                            </div>
                            <div className="col-md-7">
                              <img
                                className="profile_img"
                                src={formidupload}
                                alt="photo"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-5">
                              <b className="text-inverse">Address Proof</b>
                            </div>
                            <div className="col-md-7">
                              <img
                                className="profile_img"
                                src={formaddrupload}
                                alt="photo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="update" className="border">
                      <h5>Personal Information</h5>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">First Name</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your First Name"
                                  autoComplete="off"
                                  value={first}
                                  onChange={(e) => {
                                    setFirst(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Middle Name</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Middle Name"
                                  autoComplete="off"
                                  value={middle}
                                  onChange={(e) => {
                                    setMiddle(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Last Name</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Last Name"
                                  autoComplete="off"
                                  value={last}
                                  onChange={(e) => {
                                    setLast(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">DOB</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="date"
                                  placeholder="Enter Your DOB"
                                  autoComplete="off"
                                  value={dob}
                                  onChange={(e) => {
                                    setDOB(e.target.value);
                                  }}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Gender</b>
                              </div>
                              <div className="col-md-7">
                                {/* <input
                                                                    type="text"
                                                                    placeholder="Select Your Gender"
                                                                    autoComplete='off'
                                                                    value={gender}
                                                                    onChange={(e) => {
                                                                        setGender(e.target.value);
                                                                    }}
                                                                /> */}
                                <select
                                  value={gender}
                                  onChange={(e) => {
                                    setGender(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Your Gender
                                  </option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Email</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Mobile</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  value={mobile}
                                  maxLength={10}
                                  onChange={(e) => {
                                    setMobile(e.target.value);
                                  }}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Address</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address"
                                  autoComplete="off"
                                  value={addr}
                                  onChange={(e) => {
                                    setAddr(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">State</b>
                              </div>
                              <div className="col-md-7">
                                <select value={pstate} onChange={Selectpdist}>
                                  <option value="" disabled>
                                    Select Your State
                                  </option>
                                  {slist.map((data, i) => {
                                    return (
                                      <option value={data.stateuid} key={i}>
                                        {data.statename}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">District</b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={pdist}
                                  onChange={(e) => {
                                    setDist(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Your District
                                  </option>
                                  {pdlist.map((data, i) => {
                                    return (
                                      <option value={data.districtuid} key={i}>
                                        {data.districtname}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Pincode</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Pincode"
                                  autoComplete="off"
                                  maxLength={6}
                                  value={ppin}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setPincode(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Last Level</b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={lastlevel}
                                  onChange={(e) => {
                                    setLastlevel(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Your Last Level
                                  </option>
                                  {level.map((data, i) => {
                                    return (
                                      <option value={data.level_name} key={i}>
                                        {data.level_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Date of Last Exam Cleared
                                </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="date"
                                  placeholder="Enter Your Date of Last Exam Cleared"
                                  autoComplete="off"
                                  value={lastexam}
                                  onChange={(e) => {
                                    setLastexam(e.target.value);
                                  }}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Prefers Area Of Officiating 1
                                </b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={area1}
                                  onChange={(e) => {
                                    setArea1(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Prefers Area Of Officiating
                                  </option>
                                  {pareas.map((data, i) => {
                                    return (
                                      <option value={data.area_name} key={i}>
                                        {data.area_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Prefers Area Of Officiating 2
                                </b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={area2}
                                  onChange={(e) => {
                                    setArea2(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Prefers Area Of Officiating
                                  </option>
                                  {pareas.map((data, i) => {
                                    return (
                                      <option value={data.area_name} key={i}>
                                        {data.area_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="photo">
                              Certificate of Exam Passed
                            </label>
                            <span className="desc">
                              {" "}
                              (Accepted Files are .png, .jpg, .jpeg. Max file
                              size 3Mb)
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={certfile}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img src={certexam} className="profile_img" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <h4>
                        Select Major Events in which you have Officiated during
                        the last five years
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Select Competition
                                </b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={comp1}
                                  onChange={(e) => {
                                    setComp1(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Competition
                                  </option>
                                  <option value="National">
                                    National Events
                                  </option>
                                  <option value="International">
                                    International Events
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Name of the Event
                                </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Name of the Event"
                                  autoComplete="off"
                                  value={event1}
                                  onChange={(e) => {
                                    setEvent1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Year</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Year"
                                  autoComplete="off"
                                  value={year1}
                                  onChange={(e) => {
                                    setYear1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Area of Officiating{" "}
                                </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Area of Offciating"
                                  autoComplete="off"
                                  value={offi1}
                                  onChange={(e) => {
                                    setOffi1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Select Competition
                                </b>
                              </div>
                              <div className="col-md-7">
                                <select
                                  value={comp2}
                                  onChange={(e) => {
                                    setComp2(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Competition
                                  </option>
                                  <option value="National">
                                    National Events
                                  </option>
                                  <option value="International">
                                    International Events
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Name of the Event
                                </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Name of the Event"
                                  autoComplete="off"
                                  value={event2}
                                  onChange={(e) => {
                                    setEvent2(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Year</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Year"
                                  autoComplete="off"
                                  value={year2}
                                  onChange={(e) => {
                                    setYear2(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">
                                  Area of Officiating{" "}
                                </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Area of Offciating"
                                  autoComplete="off"
                                  value={offi2}
                                  onChange={(e) => {
                                    setOffi2(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>
                      <h4>Account Details</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Full Name</b>
                              </div>
                              <div className="col-md-7">
                                <span className="text-inverse">{fullname}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">PAN No</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your PAN No"
                                  autoComplete="off"
                                  value={panno}
                                  onChange={(e) => {
                                    setPAN(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Bank Name</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Bank Name"
                                  autoComplete="off"
                                  value={bankname}
                                  onChange={(e) => {
                                    setBankname(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Branch Name </b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Branch Name"
                                  autoComplete="off"
                                  value={branch}
                                  onChange={(e) => {
                                    setBranch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">IFSC Code</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your IFSC Code"
                                  autoComplete="off"
                                  value={ifsc}
                                  onChange={(e) => {
                                    setIFSC(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="profile-update">
                            <div className="row">
                              <div className="col-md-5">
                                <b className="text-inverse">Account No</b>
                              </div>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  placeholder="Enter Your Account No"
                                  autoComplete="off"
                                  value={accno}
                                  onChange={(e) => {
                                    setAccno(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="photo">Photograph</label>
                            <span className="desc">
                              {" "}
                              (Accepted Files are .png, .jpg, .jpeg. Max file
                              size 3Mb)
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={photofile}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img
                                src={formphotoupload}
                                className="profile_img"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="photo">ID Proof</label>
                            <span className="desc">
                              {" "}
                              (Accepted Files are .png, .jpg, .jpeg. Max file
                              size 3Mb)
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={dobfile}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img src={formidupload} className="profile_img" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-9">
                            <label htmlFor="photo">Address Proof</label>
                            <span className="desc">
                              {" "}
                              (Accepted Files are .png, .jpg, .jpeg. Max file
                              size 3Mb)
                            </span>
                            <div className="mt-2">
                              <input
                                type="file"
                                name="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={addrfile}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="imagecontainer">
                              <img
                                src={formaddrupload}
                                className="profile_img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>

                      <div className="row">
                        <div className="col-md-6">
                          {errorMessage !== "" && (
                            <span className="errortxt text-danger">
                              {errorMessage}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <a
                            className="btn btn-secondary"
                            style={{ float: "right" }}
                            onClick={updateprofile}
                          >
                            Update
                          </a>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default OfficialProfile;
